import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('palmDrawingOptions', () => ({
		template: require('../../views/directives/palm-drawing-options.html'),
		restrict: 'E',
		replace: true,
		scope: {
			options: '=pdoOptions',
			title: '@pdoTitle',
			dropdownClass: '@pdoDropdownClass'
		}
	}));
