import angular from 'angular';
import i18n from '../../config/localization';

angular.module('neurotecAbisWebClientApp')
	.service('LanguagesService', ['store', '$http', '$translate', function (store, $http, $translate) {
		var defaultLanguage = 'en_US';
		var map = {
			en_US: 'resources/locale-en_US.json',
			ru_RU: 'resources/locale-ru_RU.json',
			ua_UA: 'resources/locale-ua_UA.json',
			zh_hans: 'resources/locale-zh_hans.json',
		};
		var languageOptions = {
			en_US: 'English',
			ru_RU: 'русский',
			ua_UA: 'українська',
			zh_hans: '简体中文'
		};
		var availableOptions = (function () {
			var internalAvailableOptions = {};
			function checkResource(option) {
				$http.get(map[option]).then((response) => {
					if (response.status === 200) {
						internalAvailableOptions[option] = languageOptions[option];
						if (store.get('webclient_language') === option && $translate.use() !== option) {
							$translate.use(option);
							i18n.changeLanguage(option);
						}
					}
				}).catch(() => {
					// do nothing
				});
			}
			Object.keys(languageOptions).forEach((option) => {
				checkResource(option);
			});
			return internalAvailableOptions;
		}());

		this.isLanguageAvalable = function (code) {
			return Object.keys(this.getLanguageOptions()).includes(code);
		};

		this.getDefaultLanguage = function () {
			return defaultLanguage;
		};

		this.getLanguageOptions = function () {
			return availableOptions;
		};

		this.setDefaultLanguage = function (code) {
			defaultLanguage = code;
		};

		this.getPreferredLanguage = function () {
			var storeLanguage = store.get('webclient_language');
			if (availableOptions[storeLanguage]) {
				return storeLanguage;
			}
			return defaultLanguage;
		};

		this.setPreferredLanguage = function (code) {
			if (map[code]) {
				store.set('webclient_language', code);
				$translate.use(code);
				i18n.changeLanguage(code);
			}
		};
	}]);
