import angular from 'angular';
import FileSaver from 'file-saver';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.controller('TransactionCtrl', ['$scope', '$state', '$stateParams', '$translate', '$q', '$filter', '$window', '$uibModal', 'AlertService', 'AuthDataHolder', 'blockUI', 'ConvertionUtils', 'EncountersResource', 'TransactionsResource', 'EncounterService', 'ReportsResource', 'BiometricsService', 'ParametersService', 'AdjudicationCasesResource', 'EncountersDecisionsMapper', 'AbisService', 'SubjectsResource',
		function ($scope, $state, $stateParams, $translate, $q, $filter, $window, $uibModal, AlertService, AuthDataHolder, blockUI, ConvertionUtils, EncountersResource, TransactionsResource, EncounterService, ReportsResource, BiometricsService, ParametersService, AdjudicationCasesResource, EncountersDecisionsMapper, AbisService, SubjectsResource) {
			let flattenSubjects = false;
			const transactionId = $stateParams.transactionID || $scope.transaction.requestId;
			$scope.encounter = {};
			$scope.encounter.encounterId = $state.params.hitID;
			$scope.actionPageName = $stateParams.previousState ? $stateParams.previousState.name : '';
			$scope.encounters = [];
			$scope.selectedHit = { subjectId: null, hitId: null };
			$scope.encountersGroup = {
				options: [],
				selected: {
					master: {},
					encounter: {},
					group: []
				}
			};
			$scope.hitsIds = [];

			$scope.cnv = ConvertionUtils;
			$scope.types = {};
			$scope.statuses = {};

			$scope.allowedModalities = new Set();
			ParametersService.getParameter('mmabis.management.extraction-modalities').then((data) => {
				$scope.allowedModalities = new Set(data);
			});

			$scope.rejectInfo = {
				isAdvancedOpen: false
			};

			$scope.viewMode = {
				active: 'compare'
			};

			$scope.opts = $scope.opts || {};
			$scope.isLoadingError = false;

			$scope.priorityOptions = AbisService.getPriorityOptions();
			$scope.transactionPriorityOption = {};
			function setTransactionPriorityOption() {
				if ($scope.transaction.priority === 0) {
					$scope.transactionPriorityOption = { name: 'forms.request.priority.options.none', score: 0 };
					return;
				}
				$scope.transactionPriorityOption = $scope.priorityOptions.find(option => option.value === $scope.transaction.priority);
			}

			function changeStateUrl() {
				if (!$scope.encounter.encounterId) return;
				$state.transitionTo('actions.transaction.hit', {
					transactionID: transactionId,
					hitID: $scope.encounter.encounterId
				}, { reload: false, notify: false, location: 'replace' });
			}

			$scope.goBack = function () {
				switch ($scope.actionPageName) {
				case 'actions.search':
					$state.go('actions.search', { location: 'replace' });
					break;
				default:
					$window.history.back();
				}
			};

			$scope.goToCompare = function (candidate) {
				$scope.changeEncounterByEncounterId(candidate.encounterId);
				$scope.viewMode.active = 'compare';
			};

			$scope.$watchGroup(['encounter', 'enrolledSubject'], updateModalities);

			$scope.modalitiesOptions = {
				showFingers: { common: true, unknownCount: 0 },
				showIrises: false,
				showPalms: false,
				showSignature: false
			};

			function updateModalities() {
				if ($scope.encounter && Array.isArray($scope.encounter.fingers) && $scope.enrolledSubject) {
					$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.encounter, $scope.enrolledSubject);
				} else if ($scope.enrolledSubject) {
					$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.enrolledSubject);
				}
			}

			$scope.opts.activeTab = $scope.opts.activeTab || 'tab-result';

			$translate([
				'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
				'transactions.type.identify', 'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update', 'transactions.type.delete',
				'transactions.status.registered', 'transactions.status.in-progress',
				'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
				'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok',
				'adjudication.case.status.unique', 'adjudication.case.status.undecided', 'adjudication.case.status.conflict', 'adjudication.case.status.duplicate'
			]).then((translations) => {
				$scope.types = {
					/* jshint sub:true */
					ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
					ENROLL: translations['transactions.type.enroll'],
					IDENTIFY: translations['transactions.type.identify'],
					VERIFY: translations['transactions.type.verify'],
					VERIFY_UPDATE: translations['transactions.type.verify-update'],
					UPDATE: translations['transactions.type.update'],
					DELETE: translations['transactions.type.delete']
					/* jshint sub:false */
				};

				$scope.statuses = {
					/* jshint sub:true */
					REGISTERED: translations['transactions.status.registered'],
					IN_PROGRESS: translations['transactions.status.in-progress'],
					ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
					ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
					ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
					REJECTED: translations['transactions.status.rejected'],
					OK: translations['transactions.status.ok'],
					MATCHED: translations['transactions.status.matched'],
					NOT_MATCHED: translations['transactions.status.not-matched'],
					DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
					/* jshint sub:false */
				};

				$scope.decisions = {
					UNIQUE: translations['adjudication.case.status.unique'],
					UNDECIDED: translations['adjudication.case.status.undecided'],
					CONFLICT: translations['adjudication.case.status.conflict'],
					DUPLICATE: translations['adjudication.case.status.duplicate']
				};
			});

			$scope.getHitsIndex = function (selectedHit) {
				if ($scope.encountersGroup.options.length !== 0) {
					let index = $scope.encountersGroup.options.findIndex(hit =>
						(hit.subjectId === selectedHit.subjectId
						&& hit.hitId === selectedHit.hitId));

					if (index === -1) {
						const masterEncounterSubjectId = $scope.encounters.find(element => element.encounterId === selectedHit.hitId).subjectId;
						index = $scope.encountersGroup.options.findIndex(groupedEncounter =>
							masterEncounterSubjectId === groupedEncounter.subjectId);
					}
					return index;
				}
				return false;
			};

			$scope.maxProperty = function (obj, prop) {
				const keys = Object.keys(obj);
				let maxKey = keys[0];
				let maxVal = obj[maxKey][prop];
				keys.forEach((key) => {
					if (obj[key][prop] > maxVal) {
						maxKey = key;
						maxVal = obj[key][prop];
					}
				});
				return obj[maxKey];
			};

			function copyMatchingDetailsToProbe() {
				function deleteOldMatchingDetails(probeBiometric) {
					if (Array.isArray(probeBiometric)) {
						probeBiometric.forEach((elem) => {
							if (elem.matchingDetails) {
								delete elem.matchingDetails;
							}
							if (elem.index !== undefined && elem.index !== null) {
								delete elem.index;
							}
						});
					} else if (probeBiometric.matchingDetails && probeBiometric.index) {
						delete probeBiometric.matchingDetails;
						delete probeBiometric.index;
					}
				}

				const probe = $scope.enrolledSubject;
				const hit = $scope.encounter;

				['faces', 'irises', 'fingers', 'palms', 'signature'].forEach((biometric) => {
					if (probe[biometric] && hit[biometric]) {
						deleteOldMatchingDetails(probe[biometric]);
						if (Array.isArray(probe[biometric]) && Array.isArray(hit[biometric])) {
							hit[biometric].forEach((elem) => {
								if (elem.matchingDetails) {
									for (let i = 0; i < elem.matchingDetails.length; i += 1) {
										const match = elem.matchingDetails[i];
										const matchedProbe = probe[biometric][match.probeIndex];
										// eslint-disable-next-line no-continue
										if (!matchedProbe) continue;

										matchedProbe.matchingDetails = [];
										matchedProbe.matchingDetails.push({
											hitIndex: hit[biometric].indexOf(elem),
											score: match.score
										});
									}
								}
								elem.index = hit[biometric].indexOf(elem);
							});
							probe[biometric].forEach((elem) => {
								elem.index = probe[biometric].indexOf(elem);
							});
						} else {
							probe[biometric].index = 0;
							probe[biometric].matchingDetails = hit[biometric].matchingDetails;
						}
					}
				});
			}

			function checkIfHasScores(encounter) {
				$scope.hasScores = false;
				['score', 'facesScore', 'irisesScore', 'fingersScore', 'palmsScore'].forEach((score) => {
					if (encounter[score]) {
						$scope.hasScores = true;
					}
				});
			}

			function populateEncounters(encounter) {
				if (flattenSubjects) {
					$scope.encountersGroup.options.push({ hitId: encounter.encounterId, subjectId: encounter.subjectId });
				} else if (!$scope.encountersGroup.options.some(hit => hit.subjectId === encounter.subjectId)) {
					$scope.encountersGroup.options.push({ hitId: encounter.encounterId, subjectId: encounter.subjectId });
				}
			}

			function selectHit(subjectId, encounterId) {
				$scope.selectedHit.subjectId = subjectId !== undefined ? subjectId : null;
				$scope.selectedHit.hitId = subjectId !== undefined ? encounterId : null;
			}

			$scope.isTransactionInAdjudication = function () {
				const adjudicationStatuses = ['ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT'];
				return $scope.transaction.history && $scope.transaction.history.some(event => adjudicationStatuses.includes(event.status));
			};

			$scope.hasAnyHistoryEvent = function () {
				return $scope.adjudicationDecisions.some(decision => decision.type);
			};

			function getHitByEncounterId(encounterId, encounters) {
				return encounters.find(encounter => encounter.encounterId === encounterId);
			}

			$scope.adjudicationDecisions = [];
			$scope.getDecisionType = function (encounterId) {
				let decisionIdx = $scope.adjudicationDecisions.findIndex(hit => hit.hitId === encounterId);

				if (decisionIdx === -1) {
					decisionIdx = $scope.adjudicationDecisions.findIndex(decision =>
						getHitByEncounterId(encounterId, $scope.encounters).subjectId === getHitByEncounterId(decision.hitId, $scope.encounters).subjectId);
				}

				return $scope.adjudicationDecisions[decisionIdx] && $scope.adjudicationDecisions[decisionIdx].type;
			};

			function isFinalStatus(status) {
				return ['OK', 'DUPLICATE_FOUND'].includes(status);
			}

			const decisionsMapper = EncountersDecisionsMapper;
			function fetchAdjudicationDetails() {
				function getLastElement(list) {
					return list[list.length - 1];
				}

				function getDecisionsFromLastHistoryEvent(caseResult, history) {
					const decisions = caseResult.hits.map(encounter => ({ hitId: encounter.encounterId, type: null }));
					const lastEvent = getLastElement(history);
					if (decisions.length !== 0 && lastEvent) {
						decisionsMapper.copyEventHitsStatusesToDecision($scope.adjudicationDecisions, decisions, lastEvent.hits);
					}
					return decisions;
				}

				function onCase(caseResult) {
					if (isFinalStatus(caseResult.status)) {
						$scope.adjudicationDecisions = getDecisionsFromLastHistoryEvent(caseResult, caseResult.history);
					} else {
						$scope.adjudicationDecisions = decisionsMapper.getCurrentTypes($scope.encounters, $scope.encountersGroup.options, caseResult.history);
					}
					blockUI.stop();
				}

				blockUI.start('app.loading');
				AdjudicationCasesResource.get({ caseId: transactionId }, onCase, blockUI.stop);
			}

			function setTransactionLifecycleDates() {
				$scope.transactionLifecycleDates = [[]];
				if ($scope.transaction.createdAt) {
					$scope.transactionLifecycleDates[0].push({
						name: 'transactions.created-at',
						changedAt: $scope.transaction.createdAt
					});
				}
				if ($scope.transaction.startedAt) {
					$scope.transactionLifecycleDates[0].push({
						name: 'transactions.started-at',
						changedAt: $scope.transaction.startedAt
					});
				}
			}

			$scope.timelineConfig = {
				colorfulBadges: false,
				showId: false
			};

			function setTimelineProperties() {
				$scope.historyEvents = [$scope.transaction.history];
				setTransactionLifecycleDates();
			}

			function determineModalitiesVisibilityMode(subject) {
				function determineModalityVisibilityMode(modality) {
					modality.forEach((biometric) => {
						biometric.isFailedToExtract = !isTransactionRejected && !isTransactionRegistered && !!biometric.isFailedToExtract;
					});
				}

				const {
					faces, fingers, palms, irises, signature
				} = subject;
				const isTransactionRejected = $scope.transaction.status === 'REJECTED';
				const isTransactionRegistered = $scope.transaction.status === 'REGISTERED';
				determineModalityVisibilityMode(faces);
				determineModalityVisibilityMode(fingers);
				determineModalityVisibilityMode(palms);
				determineModalityVisibilityMode(irises);
				determineModalityVisibilityMode(signature ? [signature] : []);
			}

			function determineGoBackPageByTransactionStatus() {
				if ($stateParams.previousState
					&& $stateParams.previousState.name === 'actions.subject.encounter'
					&& $scope.transaction.status === 'VERIFY') {
					$scope.actionPageName = 'actions.search';
				}
			}

			function isTransactionLinkedToSubject() {
				const isTypeLinkedToSubject = ['ENROLL_WITH_DUPLICATE_CHECK', 'ENROLL', 'UPDATE', 'VERIFY_UPDATE', 'DELETE', 'SWITCH_PRIMARY'].includes($scope.transaction.type);
				const isStatusSuccessful = !['REJECTED', 'NOT_MATCHED'].includes($scope.transaction.status);
				return isTypeLinkedToSubject && isStatusSuccessful;
			}

			blockUI.start('app.loading'); // loading #1
			ParametersService.getGroupEncountersProperty()
				.then((result) => {
					flattenSubjects = result[0].value !== 'true'; // flatten subjects if group-encounters is false
				})
				.finally(() => {
					TransactionsResource.get({ id: transactionId }).$promise
						.then((response) => {
							$scope.transaction = response;
							$scope.isTransactionLinkedToSubject = isTransactionLinkedToSubject;
							determineGoBackPageByTransactionStatus();
							setTransactionPriorityOption();
							setTimelineProperties();

							if ($scope.transaction.type === 'DELETE' || $scope.transaction.type === 'SWITCH_PRIMARY') {
								$scope.opts.activeTab = 'tab-details';
								return;
							}

							if (AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_VIEW')) {
								blockUI.start('app.loading'); // loading #2
								EncounterService.getHits(transactionId).then((hits) => {
									$scope.transaction.candidates = hits;
									var paramHit = null;
									if ($scope.encounter.encounterId) {
										var t = $scope.transaction.candidates.filter(candidate => candidate.encounterId === $scope.encounter.encounterId)[0];
										if (t) {
											paramHit = $scope.encounter.encounterId;
										}
									}

									$scope.scoresLabels = {};
									$translate('transactions.report.score').then((scoreTitle) => {
										$scope.transaction.candidates.forEach((candidate) => {
											$scope.scoresLabels[candidate.encounterId] = `${scoreTitle}: ${candidate.score}`;
										});
									});

									blockUI.start('app.loading'); // loading #3
									EncountersResource.get({ encounterId: transactionId }).$promise
										.then((response) => {
											const subject = response;
											blockUI.start('app.loading'); // loading #4
											EncounterService.loadEncounter(subject, transactionId)
												.then(updateModalities)
												.finally(() => {
													$scope.enrolledSubject = subject;
													moveGalleriesFromTransactionToProbe();
													determineModalitiesVisibilityMode(subject);
													blockUI.stop(); // stopping #4
												});
										})
										.catch((error) => {
											AlertService.show(error.data.message, { type: 'danger', translate: false });
											$scope.isLoadingError = true;
											$scope.opts.activeTab = 'tab-details';
										})
										.finally(() => {
											changeStateUrl();
											updateModalities();

											const encounterIds = $scope.transaction.candidates.map(candidate => candidate.encounterId);
											const selectedCandidate = $scope.transaction.candidates.find(candidate => candidate.encounterId === encounterIds[0]);
											if (selectedCandidate !== undefined) {
												selectHit(selectedCandidate.subjectId, selectedCandidate.encounterId);
											}
											[$scope.encounter.encounterId] = encounterIds;

											blockUI.start('app.loading'); // loading #5
											$q.all(encounterIds.map(id => (EncounterService.getHit(transactionId, id))))
												.then((responses) => {
													if ($scope.isTransactionInAdjudication()) {
														fetchAdjudicationDetails();
													}

													responses.forEach((encounter) => {
														populateEncounters(encounter);
														$scope.encounters.push(encounter);

														if ($scope.encounter.encounterId === encounter.encounterId) {
															const tempEncounter = encounter;
															blockUI.start('app.loading'); // loading #6
															EncounterService.loadEncounter(tempEncounter, $scope.encounter.encounterId)
																.then(updateModalities)
																.finally(() => {
																	$scope.encounter = tempEncounter;
																	checkIfHasScores(tempEncounter);
																	determineModalitiesVisibilityMode(tempEncounter);
																	copyMatchingDetailsToProbe();
																	$scope.encountersGroup.selected = $scope.getSelectedGroupedEncounter($scope.encounter);
																	blockUI.reset(); // stopping #6 and hanging #5
																});
														}
													});
												});
											if (paramHit) {
												[$scope.encounter] = $scope.transaction.candidates.filter(candidate => candidate.encounterId === paramHit);
												selectHit($scope.encounter.subjectId, $scope.encounter.encounterId);
											}
											if ($scope.transaction.candidates.length > 0) {
												changeStateUrl();
											} else {
												blockUI.stop(); // stopping #5
											}
											blockUI.reset(); // stopping #3
										});
								})
									.finally(blockUI.stop); // stopping #2
							} else {
								$scope.opts.activeTab = 'tab-details';
							}
						})
						.catch((error) => {
							AlertService.show(error.data.message, { type: 'danger', translate: false });
						})
						.finally(blockUI.stop); // stopping #1
				});

			function moveGalleriesFromTransactionToProbe() {
				if ($scope.enrolledSubject.galleryId) {
					$scope.enrolledSubject.galleryId = $scope.transaction.galleryId;
				}
			}

			$scope.getSelectedGroupedEncounter = function (encounter, setEncounterAsSelected = false) {
				function getGroupByEncounter() {
					return $scope.encounters.filter(element => element.subjectId === encounter.subjectId
						&& (flattenSubjects ? element.encounterId === encounter.encounterId : true));
				}

				let index = $scope.encountersGroup.options.findIndex(groupedEncounter => groupedEncounter.hitId === encounter.encounterId && groupedEncounter.subjectId === encounter.subjectId);
				if (index === -1) {
					const masterEncounterSubjectId = $scope.encounters.find(element => element.encounterId === encounter.encounterId).subjectId;
					index = $scope.encountersGroup.options.findIndex(groupedEncounter =>
						masterEncounterSubjectId === groupedEncounter.subjectId);
				}

				const selectedEncounterGroup = {
					master: null,
					encounter: null,
					group: getGroupByEncounter()
				};
				selectedEncounterGroup.master = $scope.maxProperty(selectedEncounterGroup.group, 'score');
				selectedEncounterGroup.encounter = setEncounterAsSelected ? encounter : selectedEncounterGroup.master;

				return selectedEncounterGroup;
			};

			async function changeItem(encounter, encounterId, setEncounterAsSelected = false) {
				await EncounterService.loadEncounter(encounter, encounterId);
				$scope.encounter = encounter;
				selectHit($scope.encounter.subjectId, $scope.encounter.encounterId);
				$scope.encountersGroup.selected = $scope.getSelectedGroupedEncounter($scope.encounter, setEncounterAsSelected);
				updateModalities();
				checkIfHasScores(encounter);
				determineModalitiesVisibilityMode(encounter);
				copyMatchingDetailsToProbe();
				changeStateUrl();
			}

			$scope.prevEncounter = function () {
				blockUI.start('app.loading');
				let newIndex = $scope.encountersGroup.selected.group.findIndex(encounter => encounter.encounterId === $scope.encounter.encounterId) - 1;
				if (newIndex < 0) {
					newIndex = $scope.encountersGroup.selected.group.length - 1;
				}
				$scope.encounter = $scope.encounters.find(encounter => encounter.encounterId === $scope.encountersGroup.selected.group[newIndex].encounterId);
				changeItem($scope.encounter, $scope.encounter.encounterId, true)
					.finally(blockUI.stop);
			};

			$scope.nextEncounter = function () {
				blockUI.start('app.loading');
				let newIndex = $scope.encountersGroup.selected.group.findIndex(encounter => encounter.encounterId === $scope.encounter.encounterId) + 1;
				if (newIndex >= $scope.encountersGroup.selected.group.length) {
					newIndex = 0;
				}
				$scope.encounter = $scope.encounters.find(encounter => encounter.encounterId === $scope.encountersGroup.selected.group[newIndex].encounterId);
				changeItem($scope.encounter, $scope.encounter.encounterId, true)
					.finally(blockUI.stop);
			};

			$scope.changeEncounterBySubjectId = function (subject) {
				const { subjectId, hitId } = subject;
				const selectedEncounterId = $scope.encountersGroup.options.find(groupedEncounter => groupedEncounter.subjectId === subjectId && groupedEncounter.hitId === hitId).hitId;
				$scope.changeEncounterByEncounterId(selectedEncounterId);
			};

			$scope.changeEncounterByEncounterId = function (encounterId = null) {
				if (encounterId === null) { return; }

				blockUI.start('app.loading');
				const encounter = $scope.encounters.find(encounter => encounter.encounterId === encounterId);
				changeItem(encounter, encounterId, true)
					.finally(blockUI.stop);
			};

			$scope.exportEncounter = function (event, encounterId) {
				event.stopPropagation();
				EncountersResource.export({ encounterId }).$promise
					.then((value) => {
						FileSaver.saveAs(value.nist, `encounter_${encounterId}.nist`);
					});
			};

			$scope.canDownloadMatchingReport = function () {
				if (!$scope.transaction) return;

				const isEligibleEnrollWithDuplicateCheckTask = $scope.transaction.type === 'ENROLL_WITH_DUPLICATE_CHECK'
					&& ['OK', 'DUPLICATE_FOUND'].includes($scope.transaction.status);
				const isEligibleIdentifyTask = $scope.transaction.type === 'IDENTIFY'
					&& ['MATCHED', 'NOT_MATCHED'].includes($scope.transaction.status);

				return isEligibleEnrollWithDuplicateCheckTask || isEligibleIdentifyTask;
			};

			function downloadReport(reportType) {
				ReportsResource.getReportPdf(angular.extend({
					id: transactionId,
					timeZone: moment.tz.guess(),
					reportType
				}), (value) => {
					FileSaver.saveAs(value.document, `transaction_${transactionId}_${reportType ? 'matching_' : ''}report_${$filter('date')(new Date(), 'yyyyMMdd_HHmmss')}.pdf`);
				});
			}

			$scope.downloadDetailsReport = downloadReport;
			$scope.downloadMatchingReport = function () {
				downloadReport('MATCHING');
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.isApplicableToViewSubject = function () {
				return $scope.hasAnyAuthority('PERMISSION_ENCOUNTER_VIEW')
					&& $scope.hasAnyAuthority('PERMISSION_SUBJECT_VIEW');
			};

			$scope.showSimpleFaceProbePreview = function (image, title) {
				var scope = $scope.$new(true);
				scope.title = title;
				scope.getImageUrl = () => $q(resolve => resolve(image));
				scope.modality = 'face';
				$uibModal.open({
					template: require('../../views/modal/simple-preview-modal.html'),
					controller: 'SimplePreviewModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.showFaceComparison = function () {
				if (!hasMatchingDetailsClickable('faces', $scope.enrolledSubject.faces[0].matchingDetails !== null) || !AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_FACE_FEATURES')) {
					$scope.showSimpleFaceProbePreview($scope.enrolledSubject.faces[0].imageUrl, 'preview.face');
				}

				var scope = $scope.$new(true);
				scope.probe = {};
				scope.probe.imageUrl = $scope.enrolledSubject.faces[0].imageUrl;
				scope.hit = {};
				scope.hit.imageUrl = $scope.encounter.faces[0].imageUrl;
				scope.title = 'comparison.face';

				translateTitle('comparison.face', scope);

				scope.probe.getFaceFeatures = function () {
					return $q((resolve) => {
						EncountersResource.getFaceFeatures({
							encounterId: $scope.enrolledSubject.encounterId, modalityId: 0
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.hit.getFaceFeatures = function () {
					return $q((resolve) => {
						EncountersResource.getFaceFeatures({
							encounterId: $scope.encounter.encounterId, modalityId: 0
						}, (data) => {
							resolve(data);
						});
					});
				};

				$uibModal.open({
					template: require('../../views/modal/face-comparison-modal.html'),
					controller: 'FaceComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			function isEveryItemClickable(modality) {
				return !modality.some(item => item.unclickable);
			}

			function subjectHasModality(modality) {
				return modality && modality.length > 0;
			}

			function hasMatchingDetails(modalityGroup, isMathingDetails) {
				return $scope.encounter && (subjectHasModality($scope.encounter[modalityGroup]) && isMathingDetails)
					&& $scope.enrolledSubject && subjectHasModality($scope.enrolledSubject[modalityGroup]);
			}

			function hasMatchingDetailsClickable(modalityGroup, isMathingDetails) {
				return hasMatchingDetails(modalityGroup, isMathingDetails) && isEveryItemClickable($scope.encounter[modalityGroup])
					&& isEveryItemClickable($scope.enrolledSubject[modalityGroup]);
			}

			$scope.showSimpleIrisProbePreview = function (index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.iris';
				scope.getImageUrl = () => $q(resolve => resolve($scope.enrolledSubject.irises[index].imageUrl));
				scope.modality = 'iris';
				singleImagePreview(scope);
			};

			$scope.showIrisComparison = function (index) {
				if (!hasMatchingDetailsClickable('irises', $scope.enrolledSubject.irises[index].matchingDetails !== null) || !AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_IRIS_IMAGE')) {
					$scope.showSimpleIrisProbePreview(index);
				}

				var probe = $scope.enrolledSubject;
				var hit = $scope.encounter;
				var scope = $scope.$new(true);
				scope.modality = 'iris';
				scope.probe = probe.irises[index];
				scope.hit = hit.irises[scope.probe.matchingDetails[0].hitIndex];
				$translate([`biometrics.irises.position.${scope.probe.position}`, `biometrics.irises.position.${scope.hit.position}`]).then((translations) => {
					$translate('comparison.finger.modal-title', {
						probePosition: translations[`biometrics.irises.position.${scope.probe.position}`],
						hitPosition: translations[`biometrics.irises.position.${scope.hit.position}`],
						score: scope.hit.matchingDetails[0].score
					}).then((translation) => {
						scope.title = translation;
					});
				});
				showSimpleComparison(scope);
			};

			function showSimplePalmProbePreview(index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.palm';
				scope.getImageUrl = () => ($q((resolve) => {
					EncountersResource.getPalmImage({
						encounterId: $scope.enrolledSubject.encounterId,
						modalityId: index,
						type: 'ORIGINAL'
					}, (data) => {
						resolve(URL.createObjectURL(data.image));
					});
				}));
				scope.modality = 'palm';
				singleImagePreview(scope);
			}

			if (AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_PALM_FEATURES')) {
				$scope.showPalmComparison = function (index) {
					const probe = $scope.enrolledSubject.palms[index];
					if (!hasMatchingDetails('palms', probe.matchingDetails !== null)) {
						showSimplePalmProbePreview(index);
					}
					const [matchingDetails] = probe.matchingDetails;
					const hit = $scope.encounter.palms[matchingDetails.hitIndex];
					const probeIndex = index;
					const { hitIndex } = matchingDetails;

					var scope = $scope.$new(true);

					$translate([`biometrics.palms.position.${probe.position}`, `biometrics.palms.position.${hit.position}`]).then((translations) => {
						$translate('comparison.palm.modal-title', {
							probePosition: translations[`biometrics.palms.position.${probe.position}`],
							hitPosition: translations[`biometrics.palms.position.${hit.position}`],
							score: matchingDetails.score
						}).then((translation) => {
							scope.title = translation;
						});
					});

					scope.probe = {};
					scope.probe.getImageUrl = function (type) {
						return $q((resolve, reject) => {
							if (probe.forceImageType === 'NONE') {
								reject({ status: 404 });
							}
							if (type == null) {
								resolve(probe.imageUrl);
							} else {
								switch (type) {
								case 'ORIGINAL':
									resolve(probe.imageUrl);
									return;
								case 'SKELETONIZED':
									EncountersResource.getPalmImage({
										encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, type: 'SKELETONIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'BINARIZED':
									EncountersResource.getPalmImage({
										encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, type: 'BINARIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								default:
									return reject();
								}
							}
						});
					};

					scope.probe.getFeatures = function (getAllFeatues) {
						return $q((resolve) => {
							getAllFeatues = getAllFeatues || false;
							EncountersResource.getPalmFeatures({
								encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, all: getAllFeatues
							}, (data) => {
								resolve(data);
							});
						});
					};

					scope.hit = {};
					scope.hit.getImageUrl = function (type) {
						return $q((resolve, reject) => {
							if (hit.forceImageType === 'NONE') {
								reject({ status: 404 });
							}
							if (type == null) {
								resolve(hit.imageUrl);
							} else {
								switch (type) {
								case 'ORIGINAL':
									resolve(hit.imageUrl);
									return;
								case 'SKELETONIZED':
									EncountersResource.getPalmImage({
										encounterId: $scope.encounter.encounterId, modalityId: hitIndex, type: 'SKELETONIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'BINARIZED':
									EncountersResource.getPalmImage({
										encounterId: $scope.encounter.encounterId, modalityId: hitIndex, type: 'BINARIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								default:
									return reject();
								}
							}
						});
					};

					scope.hit.getFeatures = function (getAllFeatues) {
						return $q((resolve) => {
							getAllFeatues = getAllFeatues || false;
							EncountersResource.getPalmFeatures({
								encounterId: $scope.encounter.encounterId, modalityId: hitIndex, all: getAllFeatues
							}, (data) => {
								resolve(data);
							});
						});
					};

					scope.getMatchingDetails = function () {
						return $q((resolve) => {
							EncountersResource.getPalmMatchingDetails({
								encounterId: $scope.enrolledSubject.encounterId,
								modalityId: $scope.encounter.encounterId,
								segmentId: hitIndex
							}, (palmsMatchingDetails) => {
								resolve(palmsMatchingDetails.find(palm => palm.probeIndex === probeIndex));
							});
						});
					};

					$uibModal.open({
						template: require('../../views/modal/palm-comparison-modal.html'),
						controller: 'PalmComparisonModalCtrl',
						size: 'dynamic',
						scope
					}).result.catch((res) => {
						if (!['backdrop click', 'escape key press'].includes(res)) {
							throw new Error(res);
						}
					});
				};
			} else {
				$scope.showPalmComparison = (index) => {
					if (!hasMatchingDetails('palms', $scope.enrolledSubject.palms[index].matchingDetails !== null)) {
						showSimplePalmProbePreview(index);
					}

					const scope = $scope.$new(true);
					scope.modality = 'palm';
					scope.probe = $scope.enrolledSubject.palms[index];
					[scope.matchingDetails] = scope.probe.matchingDetails;
					scope.hit = $scope.encounter.palms[scope.matchingDetails.hitIndex];
					translateTitle(`comparison.palm.${scope.probe.position}`, scope);
					showSimpleComparison(scope);
				};
			}

			$scope.showSignatureComparison = function () {
				var probe = $scope.enrolledSubject;
				var hit = $scope.encounter;
				var scope = $scope.$new(true);
				if (!probe.signature || !hit.signature) {
					scope.title = 'comparison.signature';
					scope.getImageUrl = () => $q(resolve => resolve(probe.signature ? probe.signature.imageUrl : hit.signature.imageUrl));
					scope.modality = 'signature';
					singleImagePreview(scope);
					return;
				}
				scope.probe = probe.signature;
				scope.hit = hit.signature;
				translateTitle('comparison.signature', scope);
				showSimpleComparison(scope);
			};

			$scope.showSimpleFingerHitPreview = function (index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.finger';
				scope.getImageUrl = () => ($q((resolve) => {
					EncountersResource.getFingerImage({
						encounterId: $scope.encounter.encounterId,
						modalityId: index,
						type: 'ORIGINAL'
					}, (data) => {
						resolve(URL.createObjectURL(data.image));
					});
				}));
				scope.modality = 'finger';
				singleImagePreview(scope);
			};

			$scope.showSimplePalmHitPreview = function (index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.palm';
				scope.getImageUrl = () => ($q((resolve) => {
					EncountersResource.getPalmImage({
						encounterId: $scope.encounter.encounterId,
						modalityId: index,
						type: 'ORIGINAL'
					}, (data) => {
						resolve(URL.createObjectURL(data.image));
					});
				}));
				scope.modality = 'palm';
				singleImagePreview(scope);
			};

			$scope.showSimpleFaceHitPreview = function () {
				var scope = $scope.$new(true);
				scope.title = 'preview.face';
				scope.getImageUrl = () => $q(resolve => resolve($scope.encounter.faces[0].imageUrl));
				scope.modality = 'face';
				singleImagePreview(scope);
			};

			$scope.showSimpleIrisHitPreview = function (index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.iris';
				scope.getImageUrl = () => $q(resolve => resolve($scope.encounter.irises[index].imageUrl));
				scope.modality = 'iris';
				singleImagePreview(scope);
			};

			$scope.showSimpleSignatureHitPreview = function () {
				var scope = $scope.$new(true);
				scope.title = 'preview.signature';
				scope.getImageUrl = () => $q(resolve => resolve($scope.encounter.signature.imageUrl));
				scope.modality = 'signature';
				singleImagePreview(scope);
			};

			function showSimpleFingerProbePreview(index) {
				const scope = $scope.$new(true);
				scope.title = 'preview.finger';
				scope.getImageUrl = () => ($q((resolve) => {
					EncountersResource.getFingerImage({
						encounterId: $scope.enrolledSubject.encounterId,
						modalityId: index,
						type: 'ORIGINAL'
					}, (data) => {
						resolve(URL.createObjectURL(data.image));
					});
				}));
				scope.modality = 'finger';
				singleImagePreview(scope);
			}

			if (AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_FINGER_FEATURES')) {
				$scope.showFingerComparison = function (index) {
					const probe = $scope.enrolledSubject.fingers[index];
					if (!hasMatchingDetails('fingers', probe.matchingDetails !== null)) {
						showSimpleFingerProbePreview(index);
					}
					const [matchingDetails] = probe.matchingDetails;
					const hit = $scope.encounter.fingers[matchingDetails.hitIndex];
					const probeIndex = index;
					const { hitIndex } = matchingDetails;

					var scope = $scope.$new(true);

					$translate([`biometrics.fingers.position.${probe.position}`, `biometrics.fingers.position.${hit.position}`]).then((translations) => {
						$translate('comparison.finger.modal-title', {
							probePosition: translations[`biometrics.fingers.position.${probe.position}`],
							hitPosition: translations[`biometrics.fingers.position.${hit.position}`],
							score: matchingDetails.score
						}).then((translation) => {
							scope.title = translation;
						});
					});

					scope.probe = { fingerId: probeIndex, encounterId: $scope.enrolledSubject.encounterId };
					scope.probe.getImageUrl = function (type) {
						return $q((resolve, reject) => {
							if (probe.forceImageType === 'NONE') {
								reject({ status: 404 });
							}
							if (type == null) {
								resolve(probe.imageUrl);
							} else {
								switch (type) {
								case 'TRANSPARENT':
									resolve(probe.imageUrl);
									return;
								case 'SKELETONIZED':
									EncountersResource.getFingerImage({
										encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, type: 'SKELETONIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'BINARIZED':
									EncountersResource.getFingerImage({
										encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, type: 'BINARIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'ORIGINAL':
									EncountersResource.getFingerImage({
										encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, type: 'ORIGINAL'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								default:
									return reject();
								}
							}
						});
					};

					scope.probe.getFeatures = function (getAllFeatues) {
						return $q((resolve) => {
							getAllFeatues = getAllFeatues || false;
							EncountersResource.getFingerFeatures({
								encounterId: $scope.enrolledSubject.encounterId, modalityId: probeIndex, all: getAllFeatues
							}, (data) => {
								resolve(data);
							});
						});
					};

					scope.hit = { fingerId: hitIndex, encounterId: $scope.encounter.encounterId };
					scope.hit.getImageUrl = function (type) {
						return $q((resolve, reject) => {
							if (hit.forceImageType === 'NONE') {
								reject({ status: 404 });
							}
							if (type == null) {
								resolve(hit.imageUrl);
							} else {
								switch (type) {
								case 'TRANSPARENT':
									resolve(hit.imageUrl);
									return;
								case 'SKELETONIZED':
									EncountersResource.getFingerImage({
										encounterId: $scope.encounter.encounterId, modalityId: hitIndex, type: 'SKELETONIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'BINARIZED':
									EncountersResource.getFingerImage({
										encounterId: $scope.encounter.encounterId, modalityId: hitIndex, type: 'BINARIZED'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								case 'ORIGINAL':
									EncountersResource.getFingerImage({
										encounterId: $scope.encounter.encounterId, modalityId: hitIndex, type: 'ORIGINAL'
									}, (data) => {
										resolve(URL.createObjectURL(data.image));
									});
									return;
								default:
									return reject();
								}
							}
						});
					};

					scope.hit.getFeatures = function (getAllFeatues) {
						return $q((resolve) => {
							getAllFeatues = getAllFeatues || false;
							EncountersResource.getFingerFeatures({
								encounterId: $scope.encounter.encounterId, modalityId: hitIndex, all: getAllFeatues
							}, (data) => {
								resolve(data);
							});
						});
					};

					scope.getMatchingDetails = function () {
						return $q((resolve) => {
							EncountersResource.getFingerMatchingDetails({
								encounterId: $scope.enrolledSubject.encounterId,
								modalityId: $scope.encounter.encounterId,
								segmentId: hitIndex
							}, (fingersMatchingDetails) => {
								resolve(fingersMatchingDetails.find(finger => finger.probeIndex === probeIndex));
							});
						});
					};

					$uibModal.open({
						template: require('../../views/modal/finger-comparison-modal.html'),
						controller: 'FingerComparisonModalCtrl',
						size: 'dynamic',
						scope
					}).result.catch((res) => {
						if (!['backdrop click', 'escape key press'].includes(res)) {
							throw new Error(res);
						}
					});
				};
			} else {
				$scope.showFingerComparison = (index) => {
					if (!hasMatchingDetails('fingers', $scope.enrolledSubject.fingers[index].matchingDetails !== null)) {
						showSimpleFingerProbePreview(index);
					}

					const scope = $scope.$new(true);
					scope.modality = 'finger';
					scope.probe = $scope.enrolledSubject.fingers[index];
					[scope.matchingDetails] = scope.probe.matchingDetails;
					scope.hit = $scope.encounter.fingers[scope.matchingDetails.hitIndex];
					translateTitle(`comparison.finger.${scope.probe.position}`, scope);
					showSimpleComparison(scope);
				};
			}

			$scope.hasAnyAuthority = auth => AuthDataHolder.hasAnyAuthority(auth);

			function translateTitle(id, scope) {
				$translate(id).then((translation) => {
					scope.title = translation;
				}, (translationId) => {
					scope.title = translationId;
				});
			}

			function singleImagePreview(scope) {
				if (!scope.getImageUrl) { return; }
				$uibModal.open({
					template: require('../../views/modal/simple-preview-modal.html'),
					controller: 'SimplePreviewModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}

			function showSimpleComparison(scope) {
				$uibModal.open({
					template: require('../../views/modal/simple-comparison-modal.html'),
					controller: 'SimpleComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}

			$scope.getBadgeClass = function () {
				if (!$scope.transaction || !$scope.transaction.status) return null;

				switch ($scope.transaction.status) {
				case 'ADJUDICATION_CONFLICT':
					return 'bg-warning';
				case 'OK':
					return 'bg-success';
				case 'REJECTED':
					return 'bg-danger';
				default:
					return 'bg-primary';
				}
			};

			$scope.isRedirectionInitLoading = false;
			let encounterIdForRedirection = null;
			$scope.getRedirectToSubjectPayload = function () {
				function getPrimaryEncounterId() {
					return $q((resolve) => {
						SubjectsResource.get({ id: $scope.transaction.subjectId, galleryId: $scope.transaction.galleryId }).$promise
							.then((subject) => {
								resolve(subject.primaryEncounterId);
							});
					});
				}

				function getEncounterId() {
					if ($scope.transaction.type === 'DELETE' || $scope.transaction.type === 'SWITCH_PRIMARY') {
						$scope.isRedirectionInitLoading = true;
						getPrimaryEncounterId()
							.then((primaryEncounterId) => {
								encounterIdForRedirection = primaryEncounterId;
								$scope.isRedirectionInitLoading = false;
							});
					}
					return $scope.transaction.requestId;
				}

				if (!$scope.transaction) return null;

				if (encounterIdForRedirection === null) {
					encounterIdForRedirection = getEncounterId();
				}

				return !$scope.isRedirectionInitLoading ? {
					encounterId: encounterIdForRedirection,
					subjectId: $scope.transaction.subjectId,
					galleryId: $scope.transaction.galleryId,
					previousState: $state.current
				} : {};
			};

			$scope.isResultTabAvailable = function () {
				return $scope.hasAnyAuthority('PERMISSION_ENCOUNTER_VIEW')
					&& $scope.transaction?.type !== 'DELETE'
					&& !$scope.isLoadingError;
			};
		}]);
