import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('ExaminersResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/examiners/finger/calculate-ridge-count`, {}, {
			CountRidges: {
				method: 'POST',
			}
		});
	}]);
