import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ToolsCtrl', ['$scope', '$translate', 'UtilitiesService', 'TaskPollerService', 'AuthDataHolder',
		function ($scope, $translate, UtilitiesService, TaskPollerService, AuthDataHolder) {
			$scope.clearTransactionsAlerts = [];
			$scope.reapplyMatchingRulesAlerts = [];
			$scope.calculateNfiqAlerts = [];

			$scope.clearTransactions = function () {
				var startMsg = 'admin.tools.saving.clear-transactions';
				var successMsg = 'admin.tools.success.clear-transactions';
				$translate([startMsg, successMsg]).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						UtilitiesService.clearTransactions(success, error);
					}, $scope.clearTransactionsAlerts, translations[startMsg], translations[successMsg]);
				});
			};

			$scope.reapplyMatchingRules = function () {
				const startMsg = 'admin.tools.saving.matching-rules';
				const successMsg = 'admin.tools.success.matching-rules';
				$translate([startMsg, successMsg]).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						UtilitiesService.reapplyMatchingRules(success, error);
					}, $scope.reapplyMatchingRulesAlerts, translations[startMsg], translations[successMsg]);
				});
			};

			$scope.calculateNfiq = function () {
				var startMsg = 'admin.tools.saving.missing-nfiq';
				var successMsg = 'admin.tools.saving.missing-nfiq';
				$translate([startMsg, successMsg]).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						UtilitiesService.calculateNfiq(success, error);
					}, $scope.calculateNfiqAlerts, translations[startMsg], translations[successMsg]);
				});
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};
		}]);
