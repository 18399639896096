import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('headerNotification', () => ({
		template: require('../../views/directives/header-notification.html'),
		restrict: 'E',
		replace: true,
		scope: {},
		controller: ['$rootScope', '$scope', '$state', '$uibModal', 'AuthDataHolder', 'AuthService',
			function ($rootScope, $scope, $state, $uibModal, AuthDataHolder, AuthService) {
				$scope.userInfo = AuthDataHolder.getUserInfo();
				$scope.currentPage = $state.current.name;
				$scope.hasAdministrativeAccess = AuthDataHolder.hasAdministrativeAccess();

				$scope.showSettings = function () {
					$uibModal.open({
						template: require('../../views/modal/settings-modal.html'),
						controller: 'SettingsModalCtrl',
						windowClass: 'fullheight-modal-window',
					}).result.catch((res) => {
						if (!['backdrop click', 'escape key press'].includes(res)) {
							throw new Error(res);
						}
					});
				};
				$scope.showHelp = function () {
					$uibModal.open({
						template: require('../../views/modal/help-modal.html'),
						controller: 'HelpModalCtrl',
						windowClass: 'fit-content-modal',
					}).result.catch((res) => {
						if (!['backdrop click', 'escape key press'].includes(res)) {
							throw new Error(res);
						}
					});
				};

				$scope.hasAnyAuthority = function (...args) {
					return AuthDataHolder.hasAnyAuthority(args);
				};

				$scope.logout = function () {
					$rootScope.logoutReason = 'LOGOUT';
					AuthService.logout().finally(() => {
						$state.reload();
					});
				};

				$scope.goToAdmin = function () {
					$state.go('admin.dashboard');
				};

				$scope.goToClient = function () {
					$state.go('actions.home');
				};
			}
		]
	}));
