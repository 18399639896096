import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.service('Utils', ['$q', '$http', function ($q, $http) {
		var self = this;

		this.b64toBlob = function (b64Data, contentType, sliceSize) {
			contentType = contentType || '';
			sliceSize = sliceSize || 512;

			var byteCharacters = atob(b64Data);
			var byteArrays = [];

			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);

				var byteNumbers = new Array(slice.length);
				for (var i = 0; i < slice.length; i += 1) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				var byteArray = new Uint8Array(byteNumbers);

				byteArrays.push(byteArray);
			}

			var blob = new Blob(byteArrays, { type: contentType });
			return blob;
		};

		function arrayBufferToBase64(buffer) {
			var binary = '';
			var bytes = new Uint8Array(buffer);
			var len = bytes.byteLength;
			for (var i = 0; i < len; i += 1) {
				binary += String.fromCharCode(bytes[i]);
			}
			return window.btoa(binary);
		}

		this.blobURLtoB64 = function (url) {
			const deferred = $q.defer();
			$http({
				method: 'GET',
				url,
				responseType: 'arraybuffer'
			}).then((result) => {
				deferred.resolve(arrayBufferToBase64(result.data));
			});
			return deferred.promise;
		};

		this.blobToB64 = function (blob) {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			return $q((resolve) => {
				reader.onloadend = () => {
					resolve(reader.result.replace(/^data:image\/(png|jpeg);base64,/, ''));
				};
			});
		};

		this.createObjectURL = function (image) {
			return URL.createObjectURL(this.b64toBlob(image, 'image/x-ms-bmp'));
		};

		this.dashToUnderscore = function (str) {
			return str.replace(/-/g, '_').toUpperCase();
		};

		this.underscoreToDash = function (str) {
			return str.replace(/_/g, '-').toLowerCase();
		};

		this.camelCaseToUpperUnderscore = function (str) {
			return str.replace(/\.?([A-Z]+)/g, (x, y) => `_${y}`).replace(/^_/, '').toUpperCase();
		};

		this.upperUnderscoreToCamelCase = function (str) {
			str = `_${str}`; // workaround
			return str.toLowerCase().replace(/_(.)/g, (match, firstGroup) => firstGroup.toUpperCase());
		};

		this.upperUnderscoreToCamelCaseArray = function (array) {
			var arr = [];
			for (var i = 0; i < array.length; i += 1) {
				arr.push(self.upperUnderscoreToCamelCase(array[i]));
			}
			return arr;
		};

		this.kebabToPascal = function (str) {
			return str
				.replace(/-([a-z])/g, g => g[1].toUpperCase())
				.replace(/^\w/, c => c.toUpperCase());
		};

		this.pascalToKebab = function (str) {
			return str
				.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
				.replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
				.toLowerCase();
		};

		this.toKebabCase = function (str) {
			return str.match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
				.filter(Boolean)
				.map(x => x.toLowerCase())
				.join('-');
		};

		this.getBlankBase64Image = function () {
			return 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
		};

		this.inArray = function (needle, haystack) {
			if (haystack.indexOf(needle) !== -1) {
				return true;
			}
			return false;
		};

		this.allInArray = function (needles, haystack) {
			for (var i = 0; i < needles.length; i += 1) {
				if (!self.inArray(needles[i], haystack)) {
					return false;
				}
			}
			return true;
		};

		this.isObject = function (obj) {
			return typeof obj === 'object' && obj !== null;
		};

		this.isObjectEmpty = function (obj) {
			return Object.entries(obj).length === 0 && obj.constructor === Object;
		};

		this.capitalizeFirstLetter = function (str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		};

		this.isBoolean = function (variable) {
			return typeof variable === 'boolean';
		};

		function sortFn(a, b, sortField, reverse) {
			function handleStringComparison(fieldA, fieldB) {
				const strA = fieldA ? fieldA.toUpperCase() : '';
				const strB = fieldB ? fieldB.toUpperCase() : '';
				if (strA < strB) {
					return -1 * reverse;
				}
				if (strA > strB) {
					return 1 * reverse;
				}
				return 0;
			}

			function checkIfDateAndCompare(fieldA, fieldB) {
				const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
				const dateA = moment(fieldA, DATE_FORMAT);
				const dateB = moment(fieldB, DATE_FORMAT);
				if (dateA.isValid() && dateB.isValid()) {
					return (dateA.diff(dateB)) * reverse;
				}
				return false;
			}

			const fieldA = a[sortField];
			const fieldB = b[sortField];
			if (typeof fieldA === 'number' && typeof fieldB === 'number') {
				return (fieldA - fieldB) * reverse;
			}

			const isDate = checkIfDateAndCompare(fieldA, fieldB);
			if (isDate) {
				return isDate;
			}
			return handleStringComparison(fieldA, fieldB);
		}

		this.sortData = function (data, sortField, isReversed) {
			const reverse = isReversed ? -1 : 1;
			data.sort((a, b) => sortFn(a, b, sortField, reverse));
		};

		this.mergeSortedArrays = function (a, b, sortField, isReversed) {
			let sorted = [];
			let indexA = 0;
			let indexB = 0;
			const reverse = isReversed ? -1 : 1;

			while (indexA < a.length && indexB < b.length) {
				if (sortFn(a[indexA], b[indexB], sortField, reverse) > 0) {
					sorted.push(b[indexB]);
					indexB += 1;
				} else {
					sorted.push(a[indexA]);
					indexA += 1;
				}
			}

			if (indexB < b.length) {
				sorted = sorted.concat(b.slice(indexB));
			} else {
				sorted = sorted.concat(a.slice(indexA));
			}

			return sorted;
		};
	}])
	.filter('orderObjectBy', () => function (items, field, reverse) {
		var filtered = [];
		angular.forEach(items, (item) => {
			filtered.push(item);
		});
		filtered.sort((a, b) => (a[field] > b[field] ? 1 : -1));
		if (reverse) {
			filtered.reverse();
		}
		return filtered;
	});
