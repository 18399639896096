import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('pwdTemplates', () => ({
		pwd: require('../../../views/directives/admin/pwd-validation.html'),
		pwdMatch: require('../../../views/directives/admin/pwd-validation-match.html')
	}))
	.directive('pwdValidationWell', pwdTemplates => ({
		template($elem, $attr) {
			return pwdTemplates[$attr.mode];
		},
		restrict: 'E',
		replace: true,
		scope: {
			showValidation: '=',
			newPassword: '=',
			passwordConfirm: '=',
		},
		controller: 'pwdValidationCtrl'
	}))
	.controller('pwdValidationCtrl', ['$scope', 'PasswordValidation', function ($scope, PasswordValidation) {
		$scope.getPasswordRequirmentStatus = PasswordValidation.getPasswordRequirmentStatus;
	}]);
