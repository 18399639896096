import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('FingerIdentifier', [
		function () {
			const positions = [
				'PLAIN_LEFT_FOUR_FINGERS', 'LEFT_LITTLE_FINGER', 'LEFT_RING_FINGER',
				'LEFT_MIDDLE_FINGER', 'LEFT_INDEX_FINGER', 'PLAIN_RIGHT_FOUR_FINGERS',
				'RIGHT_LITTLE_FINGER', 'RIGHT_RING_FINGER', 'RIGHT_MIDDLE_FINGER',
				'RIGHT_INDEX_FINGER', 'PLAIN_THUMBS', 'LEFT_THUMB', 'RIGHT_THUMB'
			];
			const positionToId = positions.reduce((positionMap, position, index) => {
				positionMap[position] = index + 1;
				return positionMap;
			}, {});

			function getIdByPosition(position) {
				if (position === 'UNKNOWN') {
					const highestId = positions.length + 1;
					const currentUnknownId = unknownFingersCount;
					unknownFingersCount += 1;
					return highestId + currentUnknownId;
				}

				const id = positionToId[position];
				if (!id) {
					throw new Error(`Unknown position: ${position}`);
				}
				return id;
			}

			let unknownFingersCount = 0;
			function start(capturedFingers) {
				unknownFingersCount = 0;
				if (capturedFingers && capturedFingers.length > 0) {
					unknownFingersCount = capturedFingers.filter(finger => finger.some(f => f.position === 'UNKNOWN')).length;
				}

				return {
					getIdByPosition
				};
			}

			return {
				start
			};
		}]);
