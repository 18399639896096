import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('TransactionParams', ['OperationParametersTemplates',
		function (OperationParametersTemplates) {
			const self = this;
			let params = null;

			this.initializeParameters = function (type = null, force = false) {
				if (params === null || force) {
					params = OperationParametersTemplates.getParamsByTypeOrDefault(type);
				}
				return params;
			};

			function getWithoutNullParams() {
				return Object.fromEntries(Object.entries(params).filter(([, v]) => v !== null));
			}

			this.getParams = function (type = null, removeNull = false) {
				if (params === null) {
					return self.initializeParameters(type);
				}
				return removeNull
					? getWithoutNullParams()
					: params;
			};

			function copySavedToOperationSpecificParameters(operationParameters) {
				Object.keys(operationParameters).forEach((paramKey) => {
					operationParameters[paramKey] = operationParameters[paramKey] !== null && params[paramKey] !== null
						? params[paramKey]
						: operationParameters[paramKey];
				});
			}

			this.changeTypeParameters = function (type) {
				const newOperationParameters = OperationParametersTemplates.getParamsByTypeOrDefault(type);
				if (type !== null) {
					copySavedToOperationSpecificParameters(newOperationParameters);
				}
				params = newOperationParameters;
				return params;
			};

			this.invalidate = function () {
				params = null;
			};
		}]);
