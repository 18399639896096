import angular from 'angular';
import * as $ from 'jquery';
import { ResetAuthData, UpdateAuthData } from '../../../store/Auth/actions';
import { DefaultAuthData } from '../../../store/Auth/types';
import Store from '../../../store/Store';

angular.module('neurotecAbisWebClientApp')
	.service('AuthDataHolder', ['store', function (store) {
		this._authDataKey = 'mmabisAuthData';
		this._load = function () {
			this._authData = store.get(this._authDataKey);
			if (!this._authData) {
				this._authData = DefaultAuthData();
			}
			Store.dispatch(UpdateAuthData(this._authData));
		};
		this._load();

		this._save = function () {
			store.set(this._authDataKey, this._authData);
			Store.dispatch(UpdateAuthData(this._authData));
		};

		this._clear = function () {
			store.remove(this._authDataKey);
			Store.dispatch(ResetAuthData());
		};

		this.authenticate = function (info) {
			var currentTime = new Date().getTime();

			// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
			this._authData.accessToken = info.access_token;
			this._authData.tokenType = info.token_type;
			this._authData.refreshToken = info.refresh_token;
			this._authData.validUntil = info.expires_in * 1000 + currentTime;
			// jscs:enable requireCamelCaseOrUpperCaseIdentifiers

			this._save();
		};

		this.invalidate = function () {
			this._clear();
			this._load();
		};

		this.isAuthenticated = function () {
			return new Date().getTime() < this._authData.validUntil;
		};

		this.getAccessToken = function () {
			return this._authData.accessToken;
		};

		this.getTokenType = function () {
			return this._authData.tokenType;
		};

		this.getRefreshToken = function () {
			return this._authData.refreshToken;
		};

		this.getValidUntil = function () {
			return this._authData.validUntil;
		};

		this.setUserInfo = function (userInfo) {
			this._authData.userInfo = userInfo;
			this._authData.userInfo.timestamp = new Date().getTime();
			this._save();
		};

		this.getUserInfo = function () {
			return this._authData.userInfo;
		};

		this.hasAuthority = function (authority) {
			return $.inArray(authority, this._authData.userInfo.authorities) !== -1;
		};

		this.hasAnyAuthority = function (...args) {
			for (var i = 0; i < args.length; i += 1) {
				if (args[i] != null) {
					if ($.isArray(args[i])) {
						// eslint-disable-next-line prefer-spread
						if (this.hasAnyAuthority.apply(this, args[i])) {
							return true;
						}
					} else if (this.hasAuthority(args[i])) {
						return true;
					}
				}
			}
			return false;
		};

		this.matchAnyAuthority = function (regex) {
			return this._authData.userInfo.authorities.some(authority => regex.test(authority));
		};

		this.hasGallery = function (gallery) {
			if (!gallery) return true;

			const galleryId = gallery.split(',');
			return galleryId.every(val => this._authData.userInfo.galleries?.includes(val));
		};

		this.hasAnyGallery = function (...args) {
			for (var i = 0; i < args.length; i += 1) {
				if (args[i] != null) {
					if ($.isArray(args[i])) {
						// eslint-disable-next-line prefer-spread
						if (this.hasAnyGallery.apply(this, args[i])) {
							return true;
						}
					} else if (this.hasGallery(args[i])) {
						return true;
					}
				}
			}
			return false;
		};

		this.hasAdministrativeAccess = function () {
			return this.matchAnyAuthority(/PERMISSION_ADMINISTRATION.*/g);
		};
	}]);
