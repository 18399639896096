import { NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type FlexWrap = 'wrap';
type WrapItems = `${FlexWrap}${NLayoutViewPorts}`;
type WrapValues = 'nowrap' | 'wrap' | 'wrap-reverse';
export type FlexWrapProps = { [Property in WrapItems]: WrapValues } & { [Property in FlexWrap]: WrapValues };

const getWrapClasses = (suffix?: NLayoutViewPorts): Array<[string, WrapValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-flex-${suffix.toLowerCase()}-nowrap`, 'nowrap'],
			[`fs-flex-${suffix.toLowerCase()}-wrap`, 'wrap'],
			[`fs-flex-${suffix.toLowerCase()}-wrap-reverse`, 'wrap-reverse'],
		];
	}
	return [
		[`fs-flex-nowrap`, 'nowrap'],
		[`fs-flex-wrap`, 'wrap'],
		[`fs-flex-wrap-reverse`, 'wrap-reverse'],
	];
};

const useFlexWrapClasses = (props: Partial<FlexWrapProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'wrap', getWrapClasses),
	});
	return [containerClass, classes];
};

export default useFlexWrapClasses;
