import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('captureOptions', () => ({
		template: require('../../views/directives/capture-options.html'),
		restrict: 'E',
		replace: true,
		scope: {
			deviceLabel: '@',
			scenarioLabel: '@',
			devices: '=',
			deviceId: '=',
			disableSelection: '=',
			scenarioSelected: '=',
			deviceScenarios: '=',
			isWarningVisible: '&?',
			warningText: '@?'
		}
	}));
