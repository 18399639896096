import React from 'react';

const Header: React.FC = ({ children }) => {
	return (
		<div id="header" className="row">
			<div className="col-xs-12">
				<h2 className="text-primary">{children}</h2>
			</div>
		</div>
	);
};

export default Header;
