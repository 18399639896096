import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('SubjectPageService', ['$q', 'SubjectsResource', 'EncountersResource', 'EncounterService', 'TransactionsResource', 'EncounterToSubjectConverter', 'SubjectPageData',
		function ($q, SubjectsResource, EncountersResource, EncounterService, TransactionsResource, EncounterToSubjectConverter, SubjectPageData) {
			const self = this;
			const lifecycleStatuses = ['REGISTERED', 'ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT', 'OK', 'DUPLICATE_FOUND', 'MATCHED'];
			const lifecycleTypes = ['ENROLL_WITH_DUPLICATE_CHECK', 'ENROLL', 'UPDATE', 'VERIFY_UPDATE', 'DELETE'];

			let saveFetchedResults = false;
			this.invalidateFetchedResults = function () {
				if (!saveFetchedResults) {
					SubjectPageData.invalidate();
					timelinePaginator.reset();
					eventsPaginators.forEach(paginator => paginator.reset());
					groupPaginator.reset();
				}
				saveFetchedResults = false;
			};

			this.setSaveFetchedResults = function (newSaveFetchedResults, transactions, events) {
				saveFetchedResults = newSaveFetchedResults;
				if (saveFetchedResults) {
					SubjectPageData.setTransactions(transactions, timelinePaginator);
					SubjectPageData.setEvents(events, eventsPaginators);
				}
			};

			this.getSubject = function (subjectId, galleryId) {
				return SubjectsResource.get({ id: subjectId, galleryId }).$promise;
			};

			this.getEncounters = function (encounterIds) {
				const deferred = $q.defer();
				$q.all(encounterIds.map(id => EncountersResource.get({ encounterId: id }).$promise))
					.then(results => deferred.resolve(results));
				return deferred.promise;
			};

			function prepareBiometrics(encounter) {
				['irises', 'fingers', 'palms', 'signature'].forEach((biometric) => {
					if (encounter[biometric]) {
						if (Array.isArray(encounter[biometric])) {
							encounter[biometric].forEach((elem) => {
								elem.index = encounter[biometric].indexOf(elem);
								elem.matchingDetails = [{}];
							});
						} else {
							encounter[biometric].index = 0;
							encounter[biometric].matchingDetails = [{}];
						}
					}
				});
			}

			this.loadEncounter = function (encounterId, container) {
				return $q((resolve) => {
					EncounterService.loadEncounter(container, encounterId)
						.then(() => {
							prepareBiometrics(container);
							resolve();
						});
				});
			};

			this.deleteSubject = function (subjectId, galleryId) {
				const data = {
					subject: {
						subjectId
					},
					type: 'DELETE'
				};

				if (galleryId) {
					data.galleryId = galleryId;
				}

				return TransactionsResource.post(data).$promise;
			};

			this.deleteEncounter = function (subjectId, encounterId, galleryId) {
				const data = {
					subject: {
						subjectId,
						encounterId
					},
					type: 'DELETE'
				};

				if (galleryId) {
					data.galleryId = galleryId;
				}

				return TransactionsResource.post(data).$promise;
			};

			class Paginator {
				page;
				itemsPerPage;
				hasNextPage;
				maxPages;
				firstLoad;

				constructor(initialValue = 0, itemsPerPage = 10, maxPages = 1000) {
					this.page = initialValue;
					this.itemsPerPage = itemsPerPage;
					this.hasNextPage = true;
					this.maxPages = maxPages;
					this.firstLoad = true;
				}

				getPage() {
					return this.page;
				}

				getItemsPerPage() {
					return this.itemsPerPage;
				}

				getHasNextPage() {
					return this.hasNextPage;
				}

				nextIfAvailable(hasNextPage) {
					this.firstLoad = false;
					this.hasNextPage = hasNextPage;
					this.page += this.isLoadingAvailable() ? 1 : 0;
				}

				isLoadingAvailable() {
					return this.firstLoad || (this.hasNextPage && !this.isPagesLimitReached());
				}

				isPagesLimitReached() {
					return this.page >= (this.maxPages - 1) && !this.firstLoad;
				}

				reset() {
					this.page = 0;
					this.hasNextPage = true;
					this.firstLoad = true;
				}
			}

			let timelinePaginator = new Paginator();
			function toTransactionQueryPromise(requestBody, paginator = null) {
				return $q((resolve) => {
					TransactionsResource.query(angular.extend({
						page: paginator !== null ? paginator.getPage() : 0,
						size: paginator !== null ? paginator.getItemsPerPage() : 10,
					}, requestBody), (res, responseHeaders) => {
						resolve([res, responseHeaders]);
					});
				});
			}

			function queryWithEverySubject(subjectIds, paginator) {
				return subjectIds.map(subjectId => toTransactionQueryPromise({ subjectId, type: lifecycleTypes, status: lifecycleStatuses }, paginator));
			}

			function extractResultsAndResponse(response) {
				const results = [];
				const responseHeaders = [];
				response.forEach(([res, headers]) => {
					results.push(res);
					responseHeaders.push(headers);
				});
				return [results, responseHeaders];
			}

			function fetchLifecycleTransactions(subjectIds, paginator) {
				return $q((resolve) => {
					$q.all(queryWithEverySubject(subjectIds, paginator))
						.then((response) => {
							const [results, headers] = extractResultsAndResponse(response);
							resolve([results.flat(), headers]);
						});
				});
			}

			this.getTransactions = function (encounterIds, paginator = null) {
				return $q((resolve) => {
					$q.all(encounterIds.map(encounterId => toTransactionQueryPromise({ requestId: encounterId }, paginator)))
						.then((response) => {
							const [results] = extractResultsAndResponse(response);
							resolve(results.flat());
						});
				});
			};

			function getTransactionSubjectIds(transactions) {
				return transactions
					.map(transaction => transaction.subjectId)
					.reduce((acc, subjectId) => {
						const duplicate = acc.some(item => item === subjectId);
						if (!duplicate) {
							acc.push(subjectId);
						}
						return acc;
					}, []);
			}

			function getPaginatorOptions(paginator) {
				return {
					isLoadingAvailable: paginator.isLoadingAvailable(),
					maxPagesReached: paginator.isPagesLimitReached()
				};
			}

			this.getTimelineElements = function (encounterIds) {
				return $q((resolve, reject) => {
					self.getTransactions(encounterIds)
						.then((subjectsTransactions) => {
							const originalSubjectIds = getTransactionSubjectIds(subjectsTransactions);
							fetchLifecycleTransactions(originalSubjectIds, timelinePaginator)
								.then(([results, responseHeaders]) => {
									const anyTransactionHasPage = responseHeaders.some(res => res('X-Has-Next-Page') === 'true');
									timelinePaginator.nextIfAvailable(anyTransactionHasPage);
									resolve([results.flat(), getPaginatorOptions(timelinePaginator)]);
								})
								.catch(reject);
						});
				});
			};

			this.reloadTimeline = function (encounterIds) {
				timelinePaginator.reset();
				return self.getTimelineElements(encounterIds);
			};

			this.getSavedTimeline = function () {
				const { transactions, pager } = SubjectPageData.getTransactionsData();
				timelinePaginator = pager;
				SubjectPageData.invalidate('transactions');
				return [transactions, pager.getHasNextPage()];
			};

			const DEFAULT_NAVIGATION_OPTIONS = { activeTab: 'tab-subject', activeSubTab: 'subtab-subject' };
			let navigationOptions = angular.copy(DEFAULT_NAVIGATION_OPTIONS);
			let saveNavigationOptions = false;
			this.getNavigationOptions = function () {
				return navigationOptions;
			};

			this.setNavigationTab = function (newTab) {
				navigationOptions.activeTab = angular.copy(newTab);
			};

			this.setNavigationSubTab = function (newSubTab) {
				navigationOptions.activeSubTab = angular.copy(newSubTab);
			};

			this.invalidateNavigationOptions = function () {
				if (!saveNavigationOptions) {
					navigationOptions = angular.copy(DEFAULT_NAVIGATION_OPTIONS);
				}
				saveNavigationOptions = false;
			};

			this.setSaveNavigationOptions = function (newSaveNavigationOptions) {
				saveNavigationOptions = newSaveNavigationOptions;
			};

			this.getSaveNavigationOptions = function () {
				return saveNavigationOptions;
			};

			function getSubjects(groupId, galleryId, paginator) {
				return $q((resolve, reject) => {
					SubjectsResource.query(angular.extend({
						page: paginator !== null ? paginator.getPage() : 0,
						size: paginator !== null ? paginator.getItemsPerPage() : 10,
					}, { groupId, galleryId }), (results, responseHeaders) => {
						resolve([results, responseHeaders]);
					}, reject);
				});
			}

			this.getGroupElement = function (subjectId, galleryId) {
				return $q((resolve, reject) => {
					SubjectsResource.query({ subjectId, galleryId }, (results) => {
						const [subject] = results;
						$q.all([
							self.getEncounters([subject.primaryEncounterId]),
							self.getTransactions([subject.primaryEncounterId])
						])
							.then(resolve)
							.catch(reject);
					});
				});
			};

			let groupPaginator = new Paginator(0, 20, 500);
			this.getGroupElements = function (groupId, galleryId) {
				return $q((resolve, reject) => {
					getSubjects(groupId, galleryId, groupPaginator)
						.then(([group, responseHeaders]) => {
							const groupEncountersIds = group
								.filter((subject, index) => group.indexOf(subject) === index)
								.map(member => member.primaryEncounterId);
							$q.all([
								self.getEncounters(groupEncountersIds),
								self.getTransactions(groupEncountersIds)
							])
								.then((results) => {
									groupPaginator.nextIfAvailable(responseHeaders('X-Has-Next-Page') === 'true');
									resolve([...results, getPaginatorOptions(groupPaginator)]);
								})
								.catch(reject);
						});
				});
			};

			this.saveMasterForUpdateOperation = function (encounter) {
				return EncounterToSubjectConverter.encounterToSubjectService(encounter);
			};

			function queryWhereSubjectIsHit(subjectId, paginator, sort) {
				return $q((resolve, reject) => {
					TransactionsResource.query(angular.extend({
						page: paginator.getPage(),
						size: paginator.getItemsPerPage(),
						type: ['IDENTIFY', 'ENROLL_WITH_DUPLICATE_CHECK', 'VERIFY_UPDATE'],
						status: ['MATCHED', 'NOT_MATCHED', 'ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT',
							'OK', 'DUPLICATE_FOUND', 'REJECTED'],
						reverse: sort.reverse,
						sort: sort.field
					}, {
						matchSubjectId: subjectId,
					}), (results, responseHeaders) => {
						resolve([results.flat(), responseHeaders]);
					}, reject);
				});
			}

			function queryWhereSubjectIdEquals(subjectId, paginator, sort) {
				return $q((resolve, reject) => {
					TransactionsResource.query(angular.extend({
						page: paginator.getPage(),
						size: paginator.getItemsPerPage(),
						type: ['ENROLL', 'ENROLL_WITH_DUPLICATE_CHECK', 'UPDATE', 'DELETE', 'VERIFY'],
						status: ['ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT',
							'MATCHED', 'NOT_MATCHED', 'OK', 'DUPLICATE_FOUND', 'REJECTED'],
						subjectId,
						reverse: sort.reverse,
						sort: sort.field
					}), (results, responseHeaders) => {
						resolve([results.flat(), responseHeaders]);
					}, reject);
				});
			}

			function extractResults(responses) {
				const [transactionsWhereProbe, transactionsWhereHit] = responses;
				return [
					transactionsWhereProbe ? transactionsWhereProbe[0] : [],
					transactionsWhereHit ? transactionsWhereHit[0] : []
				];
			}

			function getEventsPromises(subjectId, sort) {
				return [
					eventsPaginators[0].isLoadingAvailable() ? queryWhereSubjectIdEquals(subjectId, eventsPaginators[0], sort) : null,
					eventsPaginators[1].isLoadingAvailable() ? queryWhereSubjectIsHit(subjectId, eventsPaginators[1], sort) : null
				];
			}

			function getEventsOptions() {
				return {
					isLoadingAvailable: eventsPaginators.some(paginator => paginator.isLoadingAvailable()),
					maxPagesReached: eventsPaginators.some(paginator => paginator.isPagesLimitReached())
				};
			}

			let eventsPaginators = [new Paginator(), new Paginator()];
			this.getEventsElements = function (subjectId, sort) {
				const deferred = $q.defer();
				const promises = getEventsPromises(subjectId, sort);
				$q.all(promises).then((responses) => {
					responses.forEach((response, idx) => (response && response.length > 0 && eventsPaginators[idx].nextIfAvailable(response[1]('X-Has-Next-Page') === 'true')));
					deferred.resolve([extractResults(responses.filter(r => r !== null)), getEventsOptions()]);
				});
				return deferred.promise;
			};

			this.isDataSaved = function (dataType) {
				switch (dataType) {
				case 'events':
					return SubjectPageData.isEventsSaved();
				case 'transactions':
					return SubjectPageData.isTransactionsSaved();
				default:
					throw Error(`Invalid dataType: ${dataType} is not saved in subject page data`);
				}
			};

			this.getSavedEvents = function () {
				const { events, pager } = SubjectPageData.getEventsData();
				eventsPaginators = pager;
				SubjectPageData.invalidate('events');
				return [events, getEventsOptions()];
			};

			this.reloadEvents = function (subjectId, sort) {
				eventsPaginators.forEach(paginator => paginator.reset());
				SubjectPageData.invalidate('events');
				return self.getEventsElements(subjectId, sort);
			};
		}]);
