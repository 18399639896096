import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.factory('OAuth2Resource', ['$resource', '$httpParamSerializerJQLike', 'BaseUrlService', function ($resource, $httpParamSerializerJQLike, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseOAuthUrl()}/:action`, null, {
			token: {
				method: 'POST',
				params: {
					action: 'token'
				},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				transformRequest(data) {
					return `client_id=mmabis&${$httpParamSerializerJQLike(data)}`;
				}
			}
		});
	}]);
