import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ConvertionUtils', function () {
		var self = this;

		this.toReadable = function (map, key) {
			var readable = map[key];
			if (readable) {
				return readable;
			}
			return key;
		};

		this.manyToReadable = function (map, array) {
			var string = '';
			for (var i = 0; i < array.length; i += 1) {
				if (i !== 0) {
					string += ', ';
				}
				string += self.toReadable(map, array[i]);
			}
			return string;
		};

		this.radiansToDegress = function (radians) {
			return radians * 180 / Math.PI;
		};

		this.degreesToRadians = function (degrees) {
			return degrees / 180 * Math.PI;
		};

		this.markDuplicateValuesWithKeys = function (array) {
			const valueToCount = {};
			let duplicates = 0;
			let value;
			for (let i = 0; i < array.length; i += 1) {
				value = array[i];
				if (!valueToCount[value.name]) {
					valueToCount[value.name] = 1;
				} else {
					valueToCount[value.name] += 1;
					duplicates += 1;
				}
			}

			if (duplicates > 0) {
				const newArr = [];
				let value;
				for (let i = 0; i < array.length; i += 1) {
					value = array[i];
					if (valueToCount[value.name] > 1) {
						newArr.push({
							id: value.id,
							name: `${value.name} (ID: ${value.id})`,
							description: value.description,
							state: value.state,
						});
					} else {
						newArr.push(value);
					}
				}
				return newArr;
			}
			return array;
		};
	});
