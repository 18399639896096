import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('DeduplicationCtrl', [
		'$scope', '$translate', '$q', '$state', 'AuthDataHolder', 'FilterUtils', 'SortHelper', 'store', 'ConvertionUtils', 'MultiselectModule',
		'TransactionsResource', 'FilterDataHolder', 'GalleriesService', 'SettingsService', 'DeduplicationService', 'Utils',
		function (
			$scope, $translate, $q, $state, AuthDataHolder, FilterUtils, SortHelper, store, ConvertionUtils, MultiselectModule,
			TransactionsResource, FilterDataHolder, GalleriesService, SettingsService, DeduplicationService, Utils
		) {
			const self = this;
			self.sort = SortHelper.create('createdAt', true);
			self.cnv = ConvertionUtils;
			self.isTransactionLoading = () => DeduplicationService.isLoading();
			self.wasTransactionsLoaded = () => DeduplicationService.wasLoaded();
			self.isLoadingDisabled = () => DeduplicationService.isLoadingDisabled();
			self.hasAnyAuthority = auth => AuthDataHolder.hasAnyAuthority(auth);

			function updateFilterStatuses() {
				function getValueOrDefault(filter) {
					return (self.filterData.status && self.filterData.status[filter.key]) || false;
				}

				const filters = self.tabs[self.activeTabIndex].getFilterStatuses();
				if (filters) {
					self.filterData.status = filters.reduce((acc, filter) => {
						acc[filter.key] = getValueOrDefault(filter);
						return acc;
					}, {});
				}
			}

			self.casesCount = {
				count: 0,
				message: ''
			};

			$scope.$on('$viewContentLoaded', () => {
				DeduplicationService.getCasesCount()
					.then((data) => {
						let translationString;
						if (data.count === 0) {
							translationString = 'cases.no-cases';
						} else if (data.count === 1) {
							translationString = 'cases.case-left';
						} else {
							translationString = 'cases.cases-left';
						}
						self.casesCount.count = data.count;
						$translate(translationString, { casesCount: data.count }).then((translation) => {
							self.casesCount.message = translation;
						});
					});
			});

			function watchForTabChanges() {
				$scope.$watch(angular.bind(self, function () {
					return this.activeTabIndex;
				}), (newValue) => {
					if (newValue !== undefined) {
						updateFilterStatuses();
						self.filter();
						store.set(ACTIVE_TAB_STORE_NAME, newValue);
					}
				});
			}

			function removeGalleryFromFilterIfExists() {
				if (Object.hasOwnProperty.call(self.filterData, 'galleryId')) {
					delete self.filterData.galleryId;
					FilterDataHolder.set(FILTER_DATA_NAME, self.filterData);
				}
			}

			function setGalleryStates() {
				self.galleryStartIndex = GalleriesService.getBeginningGalleryIndexForFiltering();
				self.selectedGallery = self.galleries.find(g => g.id === self.filterData.galleryId)
					|| SettingsService.getPreferredGallery()
					|| self.galleries[self.galleryStartIndex];
			}

			function initGalleriesAndItsStates() {
				const deferred = $q.defer();
				fetchGalleries()
					.then(() => {
						if (self.galleries.length !== 0) {
							setDefaultGalleries();
							setGalleryStates();
						}
						deferred.resolve();
					});
				return deferred.promise;
			}

			function fetchGalleries() {
				const deferred = $q.defer();
				GalleriesService.getAvailableGalleries()
					.then((galleries) => {
						self.galleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
						deferred.resolve();
					});
				return deferred.promise;
			}

			function setDefaultGalleries() {
				self.galleries.unshift(
					GalleriesService.getAllGalleriesOption(),
					GalleriesService.getDefaultGallery(),
				);
			}

			function setGalleryToFilter() {
				self.filterData.galleryId = self.selectedGallery.id;
			}

			function initGalleriesOrDefaults() {
				const deferred = $q.defer();
				self.galleries = [];
				self.selectedGallery = {};
				self.galleryStartIndex = 0;

				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					initGalleriesAndItsStates()
						.then(() => {
							setGalleryToFilter();
							deferred.resolve();
						});
				} else {
					removeGalleryFromFilterIfExists();
					deferred.resolve();
				}
				return deferred.promise;
			}

			const FILTER_DATA_NAME = ':deduplication:filterData';
			let filterDataInternal = {};
			function retrieveFilters() {
				self.firstTimeLoading = true;
				retainOrDefaultFilter();
				self.filterForm = {};
			}

			function retainOrDefaultFilter() {
				const retainFilter = $state.previous.includes('transaction')
					|| $state.previous.includes('case')
					|| $state.previous.includes('subject');
				if (!retainFilter) {
					FilterDataHolder.remove(FILTER_DATA_NAME);
				}
				self.filterData = FilterDataHolder.get(FILTER_DATA_NAME);
			}

			const ACTIVE_TAB_STORE_NAME = ':deduplication:activeTab';
			function retrieveActiveTab() {
				const storeActiveTab = store.get(ACTIVE_TAB_STORE_NAME) || 0;
				self.activeTabIndex = self.availableTabs.includes(storeActiveTab)
					? storeActiveTab
					: self.availableTabs[0];
			}

			self.datetimePickerOptions = {
				format: 'YYYY-MM-DD HH:mm:ss',
				showClear: true,
				allowInputToggle: true
			};

			function getTransactionListItemLink() {
				return {
					goToState: 'actions.transaction',
					toPayload(transaction) {
						return { transactionID: transaction.requestId, previousState: $state.current };
					}
				};
			}

			function getSubjectListItemLink() {
				return {
					goToState: 'actions.subject.encounter',
					toPayload(transaction) {
						return {
							subjectId: transaction.subjectId,
							encounterId: transaction.requestId,
							galleryId: transaction.galleryId,
							previousState: $state.current
						};
					}
				};
			}

			function getAdjudicationListItemLink() {
				return {
					goToState: 'actions.adjudication.case',
					toPayload(transaction) {
						return {
							caseId: transaction.requestId,
							previousState: $state.current
						};
					}
				};
			}

			function fetchFromTransactions() {
				const deferred = $q.defer();
				DeduplicationService.fetchFromTransactions(filterDataInternal, self.sort)
					.then(({ data, counts }) => {
						self.data = self.data.concat(data);
						self.statusCounts = counts;
						deferred.resolve();
					});
				return deferred.promise;
			}

			function fetchFromAdjudication() {
				const deferred = $q.defer();
				DeduplicationService.fetchFromAdjudication(filterDataInternal, self.sort)
					.then(({ data }) => {
						self.data = self.data.concat(data);
						deferred.resolve();
					});
				return deferred.promise;
			}

			function getLinkToElementByStatus(transaction) {
				if (transaction.status === 'REJECTED') {
					return getTransactionListItemLink();
				}
				return getSubjectListItemLink();
			}

			const tabToListItemFunction = {
				all: getLinkToElementByStatus,
				adjudication: getAdjudicationListItemLink,
				accepted: getSubjectListItemLink,
				rejected: getTransactionListItemLink
			};

			function getListItem(transaction) {
				const currentTab = self.tabs[self.activeTabIndex];
				return tabToListItemFunction[currentTab.key](transaction);
			}

			const adjudicationGroupStatus = {
				key: 'ADJUDICATION',
				name: '',
				statuses: ['ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT']
			};
			const acceptedGroupStatus = {
				key: 'ACCEPTED',
				name: '',
				statuses: ['OK', 'DUPLICATE_FOUND']
			};

			self.tabs = [
				// All
				{
					key: 'all',
					filters: ['galleryId', 'username', 'requestId', 'subjectId', 'status', 'createdAt', 'startedAt', 'completedAt', 'adjudicated'],
					filterGroups: [adjudicationGroupStatus.key, acceptedGroupStatus.key],
					filter() {
						fetchFromTransactions();
					},
					getFilterStatuses() { return self.isFilterAdvanced ? self.statusesFilters : self.groupStatusesFilters; },
					listItem: null
				},
				// Adjudication
				{
					key: 'adjudication',
					filters: ['galleryId', 'username', 'status', 'requestId', 'subjectId', 'createdAt', 'startedAt', 'completedAt'],
					defaultFilters: {
						status: ['ADJUDICATION_CONFLICT', 'ADJUDICATION_IN_PROGRESS']
					},
					filterGroups: null,
					filter() {
						fetchFromAdjudication();
					},
					getFilterStatuses() { return self.adjudicationStatusesFilters; },
					listItem: null
				},
				// Accepted
				{
					key: 'accepted',
					subKey: 'OK',
					filters: ['galleryId', 'requestId', 'subjectId', 'createdAt', 'completedAt', 'adjudicated', 'status'],
					defaultFilters: {
						status: ['OK']
					},
					filterGroups: null,
					filter() {
						fetchFromTransactions();
					},
					getFilterStatuses() { return self.acceptedStatusesFilters; },
					listItem: null
				},
				// Rejected
				{
					key: 'rejected',
					filters: ['galleryId', 'requestId', 'subjectId', 'createdAt', 'completedAt'],
					filterGroups: null,
					filter() {
						filterDataInternal.status = ['REJECTED'];
						fetchFromTransactions()
							.then(() => {
								DeduplicationService.setIsLoading(true);
								$q.all(self.data.map(transaction => TransactionsResource.get({ id: transaction.requestId }).$promise))
									.then((results) => {
										results.forEach((transaction) => {
											const transactionIdx = self.data.findIndex(savedTransaction => savedTransaction.requestId === transaction.requestId);
											self.data[transactionIdx] = angular.extend(self.data[transactionIdx], transaction);
										});
										DeduplicationService.setIsLoading(false);
									});
							});
					},
					getFilterStatuses() { return null; },
					listItem: null
				}
			];

			self.statuses = {};
			function loadStatusTranslations() {
				const deferred = $q.defer();
				$translate([
					'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-in-progress', 'transactions.status.rejected', 'cases.filter.unique',
					'transactions.status.adjudication-conflict', 'cases.filter.duplicate', 'transactions.status.adjudication-waiting',
					'deduplication.filter.adjudication', 'deduplication.filter.accepted'
				]).then((translations) => {
					adjudicationGroupStatus.name = translations['deduplication.filter.adjudication'];
					acceptedGroupStatus.name = translations['deduplication.filter.accepted'];

					self.statuses = {
					/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						OK: translations['cases.filter.unique'],
						DUPLICATE_FOUND: translations['cases.filter.duplicate'],
						REJECTED: translations['transactions.status.rejected']
					/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			self.dateRangeOptions = {
				showDropdowns: true,
				timePicker24Hour: true,
				autoUpdateInput: false,
				alwaysShowCalendars: true,
				opens: 'left',
				drops: 'bottom',
			};

			self.allStatus = {
				key: 'all',
				name: '',
			};
			function loadAdjudicationTranslations() {
				const deferred = $q.defer();
				$translate([
					'deduplication.filter.all',
					'cases.filter.conflict',
					'cases.filter.duplicate',
					'cases.filter.unique',
					'cases.filter.waiting',
					'cases.filter.in-progress'
				]).then((translations) => {
					self.allStatus.name = translations['deduplication.filter.all'];

					self.adjudicationStatuses = {
					/* jshint sub:true */
						ADJUDICATION_CONFLICT: translations['cases.filter.conflict'],
						DUPLICATE_FOUND: translations['cases.filter.duplicate'],
						OK: translations['cases.filter.unique'],
						ADJUDICATION_WAITING: translations['cases.filter.waiting'],
						ADJUDICATION_IN_PROGRESS: translations['cases.filter.in-progress']
					/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			function loadTranslations() {
				return $q.all([
					loadStatusTranslations(),
					loadAdjudicationTranslations()
				]);
			}

			const ADVANCED_FILTER_KEY = 'deduplication:advancedFilterOption';
			self.isFilterAdvanced = false;
			self.isAdvancedVisible = function () {
				const ADVANCED_FILTERS = ['username', 'started'];
				return self.tabs[self.activeTabIndex].filters.some(filter => ADVANCED_FILTERS.includes(filter));
			};

			function mapStatusesAndGroups(acceptedGroups) {
				function mapStatusesToGroup(group) {
					group.statuses.forEach((status) => { mapper[status] = [group.key]; });
				}

				let mapper = {};
				const statusesByGroupKey = {
					[adjudicationGroupStatus.key]: adjudicationGroupStatus.statuses,
					[acceptedGroupStatus.key]: acceptedGroupStatus.statuses,
				};
				acceptedGroups.forEach((key) => {
					mapper[key] = statusesByGroupKey[key];
				});

				mapStatusesToGroup(adjudicationGroupStatus);
				mapStatusesToGroup(acceptedGroupStatus);

				self.filterData.status = Object.keys(self.filterData.status).reduce((acc, statusKey) => {
					if (Object.hasOwnProperty.call(mapper, statusKey)) {
						mapper[statusKey].forEach((status) => { acc[status] = acc[status] || self.filterData.status[statusKey]; });
					} else {
						acc[statusKey] = self.filterData.status[statusKey];
					}
					return acc;
				}, []);
			}

			self.onToggleAdvancedFilter = function (value) {
				store.set(ADVANCED_FILTER_KEY, value);
				if (self.tabs[self.activeTabIndex].filterGroups) {
					mapStatusesAndGroups(self.tabs[self.activeTabIndex].filterGroups);
				}
			};

			function retrieveAdvancedFilterOption() {
				self.isFilterAdvanced = store.get(ADVANCED_FILTER_KEY) || false;
			}

			function getDefaultTabFilter() {
				function markDefaultStatuses() {
					if (self.tabs[self.activeTabIndex].defaultFilters.status) {
						self.tabs[self.activeTabIndex].getFilterStatuses().forEach(({ key }) => {
							self.filterData.status[key] = self.tabs[self.activeTabIndex].defaultFilters.status.includes(key);
						});
					}
				}

				self.filterData.status = self.filterData.status || {};
				if (Utils.isObjectEmpty(self.filterData.status) && self.tabs[self.activeTabIndex].defaultFilters) {
					markDefaultStatuses();
				}
			}

			self.availableTabs = [];
			function getAvailableTabsFromPermissions() {
				const [ALL_TAB, ADJUDICATION_TAB, ACCEPTED_TAB, REJECTED_TAB] = [0, 1, 2, 3];
				if (self.hasAnyAuthority('PERMISSION_TRANSACTION_LIST')) {
					self.availableTabs.push(ALL_TAB, ACCEPTED_TAB, REJECTED_TAB);
				}
				if (self.hasAnyAuthority('PERMISSION_ADJUDICATION_VIEW')) {
					self.availableTabs.push(ADJUDICATION_TAB);
				}
			}

			function init() {
				getAvailableTabsFromPermissions();
				retrieveActiveTab();
				retrieveFilters();
				retrieveAdvancedFilterOption();
				initGalleriesOrDefaults()
					.then(() => {
						loadTranslations()
							.then(() => {
								loadMSModule();
								initStatusesFilters();
								getDefaultTabFilter();
								watchForTabChanges();
							})
							.finally(() => {
								self.firstTimeLoading = false;
							});
					});
			}
			init();

			function loadMSModule() {
				function getDefaultModel(translations) {
					return [
						[
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'status', label: translations['adjudication.case.status'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
						],
						// Adjudication
						[
							{ id: 'status', label: translations['adjudication.case.status'] },
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'hitsCount', label: translations['adjudication.case.hits'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'firstHitId', label: translations['adjudication.case.rank-one-id'] },
							{ id: 'firstHitOverallScore', label: translations['adjudication.case.rank-overall-score'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
						],
						// accepted
						{
							OK: [
								{ id: 'requestId', label: translations['adjudication.case.requestId'] },
								{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
								{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
								{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
							],
							DUPLICATE_FOUND: [
								{ id: 'requestId', label: translations['adjudication.case.requestId'] },
								{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
								{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
								{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
							],
						},
						// Rejected
						[
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'rejectReason', label: translations['deduplication.filter-fields.reject-reason'] }
						]
					];
				}

				$translate([
					'adjudication.case.requestId',
					'adjudication.case.status',
					'adjudication.case.hits',
					'adjudication.case.rank-one-id',
					'adjudication.case.rank-overall-score',
					'adjudication.case.rank-faces-score',
					'adjudication.case.rank-fingers-score',
					'adjudication.case.rank-irises-score',
					'adjudication.case.rank-palms-score',
					'adjudication.case.subject-id',
					'adjudication.case.gallery',
					'adjudication.case.created-at',
					'adjudication.case.started-at',
					'adjudication.case.completed-at',
					'adjudication.case.priority',
					'adjudication.column-visibility',
					'deduplication.filter-fields.reject-reason',
				]).then((translations) => {
					const msData = [
						// All
						[
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'status', label: translations['adjudication.case.status'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
						],
						// Adjudication
						[
							{ id: 'status', label: translations['adjudication.case.status'] },
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'hitsCount', label: translations['adjudication.case.hits'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'firstHitId', label: translations['adjudication.case.rank-one-id'] },
							{ id: 'firstHitOverallScore', label: translations['adjudication.case.rank-overall-score'] },
							{ id: 'firstHitFacesScore', label: translations['adjudication.case.rank-faces-score'] },
							{ id: 'firstHitFingersScore', label: translations['adjudication.case.rank-fingers-score'] },
							{ id: 'firstHitIrisesScore', label: translations['adjudication.case.rank-irises-score'] },
							{ id: 'firstHitPalmsScore', label: translations['adjudication.case.rank-palms-score'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
						],
						// accepted
						{
							OK: [
								{ id: 'requestId', label: translations['adjudication.case.requestId'] },
								{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
								{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
								{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
							],
							DUPLICATE_FOUND: [
								{ id: 'requestId', label: translations['adjudication.case.requestId'] },
								{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
								{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
								{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
							],
						},
						// Rejected
						[
							{ id: 'requestId', label: translations['adjudication.case.requestId'] },
							{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
							{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
							{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
							{ id: 'rejectReason', label: translations['deduplication.filter-fields.reject-reason'] }
						]
					];

					const defaultModel = getDefaultModel(translations);

					const msCustomTexts = {
						buttonDefaultText: translations['adjudication.column-visibility']
					};

					self.msModule.moduleData = self.msModule.initialize(msData, defaultModel, msCustomTexts, 'deduplication:columnsToShow', {
						dynamicTitle: false,
						scrollableHeight: '200px',
						scrollable: true
					});
					self.isVisibleColumn = column => self.msModule.isVisibleColumn(self.activeTabIndex, self.tabs[self.activeTabIndex].subKey, column);
				});
			}

			self.msModule = MultiselectModule;
			self.multiSelectEventsHandler = {
				onSelectionChanged: self.msModule.eventsHandler.onSelectionChanged
			};

			self.multiSelectEventsHandler = {
				onSelectionChanged: () => self.multiSelectModule.events.onSelectionChanged()
			};

			self.getFilterStatuses = function () {
				return self.tabs[self.activeTabIndex].getFilterStatuses() || [];
			};

			function isFilterChecked(...statusKeys) {
				const container = self.filterData.status;
				if (statusKeys.some(k => k === 'all')) {
					return container.length === self.filterData.status;
				}
				return Utils.allInArray(container, statusKeys);
			}

			function initAllStatusesFilters() {
				function newFilter(key, name, checkValues = null) {
					return {
						key,
						name,
						isChecked: () => isFilterChecked(...checkValues || key)
					};
				}

				const statusUtils = group => ({
					isGroupStatus(key) {
						return group.statuses.some(k => k === key);
					},
					hasFilter(acc) {
						return acc.some(filter => filter.key === group.key);
					},
					addGroupStatus(acc) {
						acc.push(newFilter(group.key, group.name, group.statuses));
					}
				});

				const adjudicationGroupUtils = statusUtils(adjudicationGroupStatus);
				const acceptedGroupUtils = statusUtils(acceptedGroupStatus);

				self.groupStatusesFilters = Object.keys(self.statuses).reduce((acc, statusKey) => {
					if (adjudicationGroupUtils.isGroupStatus(statusKey)) {
						if (!adjudicationGroupUtils.hasFilter(acc)) adjudicationGroupUtils.addGroupStatus(acc);
					} else if (acceptedGroupUtils.isGroupStatus(statusKey)) {
						if (!acceptedGroupUtils.hasFilter(acc)) acceptedGroupUtils.addGroupStatus(acc);
					} else {
						acc.push(newFilter(statusKey, self.statuses[statusKey]));
					}
					return acc;
				}, []);

				self.statusesFilters = Object.keys(self.statuses).map(statusKey => ({
					key: statusKey,
					name: self.statuses[statusKey],
					isChecked: () => isFilterChecked(statusKey)
				}));
			}

			function initAdjudicationStatusesFilters() {
				self.adjudicationStatusesFilters = Object.keys(self.adjudicationStatuses).map(statusKey => ({
					key: statusKey,
					name: self.adjudicationStatuses[statusKey],
					isChecked: () => isFilterChecked(statusKey)
				}));
			}

			function initAcceptedStatusesFilters() {
				function onSelectionChanged(filterKey) {
					self.tabs[self.activeTabIndex].subKey = filterKey;
				}

				self.acceptedStatusesFilters = [
					{
						key: 'OK',
						name: self.adjudicationStatuses.OK,
						isChecked: () => isFilterChecked('OK'),
						onChange: () => onSelectionChanged('OK')
					},
					{
						key: 'DUPLICATE_FOUND',
						name: self.adjudicationStatuses.DUPLICATE_FOUND,
						isChecked: () => isFilterChecked('DUPLICATE_FOUND'),
						onChange: () => onSelectionChanged('DUPLICATE_FOUND')
					},
				];
			}

			function initStatusesFilters() {
				initAllStatusesFilters();
				initAdjudicationStatusesFilters();
				initAcceptedStatusesFilters();
			}

			self.loadMore = function () {
				function setSortingIfExists() {
					if (store.get(':cases:sort')) {
						self.sort = SortHelper.create(store.get(':cases:sort').field, true, store.get(':cases:sort').reverse);
					}
				}

				if (DeduplicationService.isLoadingDisabled()) {
					return;
				}

				setSortingIfExists();
				self.tabs[self.activeTabIndex].filter();
			};

			self.resetLoaded = function () {
				if (DeduplicationService.isLoading()) {
					return;
				}

				self.statusCounts = {};
				DeduplicationService.resetLoadingMoreState();
				self.pagesLoaded = 0;
				self.data = [];
				self.loadMore();
			};

			self.dateFieldsValid = function (prefix) {
				return FilterUtils.dateRangeIsValid(self.filterData, prefix, true);
			};

			function isFormValid() {
				return self.filterForm.$valid && self.dateFieldsValid('createdAt') && self.dateFieldsValid('startedAt') && self.dateFieldsValid('completedAt');
			}

			self.isUserSupervisor = function () {
				return AuthDataHolder.hasAnyAuthority('MODIFIER_ADJUDICATION_SUPERVISOR');
			};

			function getGroupByStatusOrNull(status) {
				switch (status) {
				case adjudicationGroupStatus.key:
					return adjudicationGroupStatus;
				case acceptedGroupStatus.key:
					return acceptedGroupStatus;
				default:
					return null;
				}
			}

			function isFilterByGalleryAvailable() {
				return AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')
				&& self.galleries.length > self.galleryStartIndex
				&& self.selectedGallery.id !== GalleriesService.getAllGalleriesOption().id;
			}

			function copyProcessedFilterData(to) {
				function transformStatusesIfCompatible(statuses) {
					function isStatusCompatible(status) {
						return filters.some(filter => filter.key === status);
					}

					function isChecked(key) {
						return statuses[key];
					}

					const filters = self.tabs[self.activeTabIndex].getFilterStatuses();
					return Object.keys(statuses).reduce((acc, status) => {
						if (isChecked(status) && isStatusCompatible(status)) {
							const group = getGroupByStatusOrNull(status);
							if (group) {
								acc = acc.concat(group.statuses);
							} else {
								acc.push(status);
							}
						}
						return acc;
					}, []);
				}

				['userId', 'requestId', 'requestId', 'subjectId', 'adjudicated'].forEach((filterElem) => {
					if (self.isFieldCompatibleWithFilter(filterElem)) {
						FilterUtils.filterBySingleField(self.filterData, to, filterElem);
					}
				});
				['createdAt', 'startedAt', 'completedAt'].forEach((filterElem) => {
					if (self.isFieldCompatibleWithFilter(filterElem)) {
						FilterUtils.filterByDateRanges(self.filterData, to, filterElem);
					}
				});

				if (isFilterByGalleryAvailable()) {
					FilterUtils.filterBySingleField(self.filterData, to, 'galleryId');
				}

				if (self.isFieldCompatibleWithFilter('status')) {
					FilterUtils.filterBySingleField(self.filterData, to, 'status', transformStatusesIfCompatible);
				}

				filterDataInternal.status = getFilterStatusesOrDefault();
			}

			function getFilterStatusesOrDefault() {
				function getTabStatuses() {
					const filterStatuses = self.tabs[self.activeTabIndex].getFilterStatuses();
					return filterStatuses ? filterStatuses.reduce((acc, status) => {
						const group = getGroupByStatusOrNull(status.key);
						if (group) {
							acc = acc.concat(group.statuses);
						} else {
							acc.push(status.key);
						}
						return acc;
					}, []) : [];
				}

				if (filterDataInternal.status && filterDataInternal.status.length > 0) {
					return filterDataInternal.status;
				}
				return getTabStatuses();
			}

			self.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					self.resetLoaded();
					FilterDataHolder.add(FILTER_DATA_NAME, self.filterData);
				}
			};

			function resetFilterData() {
				const tempFilterData = angular.copy(self.filterData);
				self.filterData = { status: {} };
				Object.keys(tempFilterData.status).forEach((key) => {
					self.filterData.status[key] = false;
				});
			}

			self.resetFilter = function () {
				FilterDataHolder.remove(FILTER_DATA_NAME);
				resetFilterData();
				FilterDataHolder.add(FILTER_DATA_NAME, self.filterData);

				self.filterForm.$setPristine();
				filterDataInternal = {};
				self.resetLoaded();
			};

			self.isLinkAvailable = function () {
				if (['all', 'adjudicated', 'rejected'].includes(self.tabs[self.activeTabIndex].key)) {
					return self.hasAnyAuthority('PERMISSION_TRANSACTION_VIEW');
				}
				return self.hasAnyAuthority('PERMISSION_ADJUDICATION_VIEW');
			};

			self.getTransactionLinkPayload = function (transaction) {
				return { transactionID: transaction.requestId, previousState: $state.current };
			};

			self.getLink = function (transaction) {
				return getListItem(transaction).goToState;
			};
			self.getLinkPayload = function (transaction) {
				return getListItem(transaction).toPayload(transaction);
			};

			self.sortBy = function (field) {
				if (DeduplicationService.isLoading()) {
					return;
				}

				self.sort.sort(field);
				store.set(':cases:sort', self.sort);
				self.resetLoaded();
			};

			self.isFieldCompatibleWithFilter = function (elementName) {
				return self.tabs.length > 0 && self.tabs[self.activeTabIndex].filters.includes(elementName);
			};

			self.isFilterElementsCountEqual = function () {
				function isTabForGallery(tab) {
					return tab === 'galleryId';
				}

				function getElementsCount() {
					return tabs.reduce((acc, tab) => {
						let foundElements = 0;
						foundElements += isTabForGallery(tab) ? self.isGalleriesPresent() : 1;
						return acc + foundElements;
					}, 0);
				}

				if (self.tabs[self.activeTabIndex].length > 0) return;

				const tabs = self.tabs[self.activeTabIndex].filters;
				const elementCount = getElementsCount();
				return elementCount % 2 === 0;
			};

			self.getGalleryNameById = galleryId => GalleriesService.getGalleryNameById(galleryId, self.galleries);

			self.isGalleriesPresent = function () {
				return self.galleries.length > self.galleryStartIndex;
			};

			function onAcceptedFilterChange(filterName) {
				function onStatusChange() {
					self.tabs[self.activeTabIndex].subKey = self.filterData.status.DUPLICATE_FOUND ? 'DUPLICATE_FOUND' : 'OK';
				}

				if (filterName === 'status') {
					onStatusChange();
				}
			}

			self.changeValue = function (filterName) {
				if (self.tabs[self.activeTabIndex].key === 'accepted') {
					onAcceptedFilterChange(filterName);
				}
			};

			self.getUserTranslation = function () {
				switch (self.tabs[self.activeTabIndex].key) {
				case 'adjudication':
					return 'cases.operator';
				default:
					return 'user';
				}
			};

			self.goToSubjects = function (transaction) {
				$state.go('actions.subject.encounter', {
					subjectId: transaction.subjectId,
					encounterId: transaction.requestId,
					galleryId: transaction.galleryId,
					previousState: $state.current
				});
			};

			self.onGalleryChoose = function () {
				if (self.selectedGallery) {
					self.filterData.galleryId = self.selectedGallery.id;
				}
			};
		}]);
