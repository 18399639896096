import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('LoginRedirectService', [function () {
		this.state = null;
		this.params = null;

		this.setRedirectParams = function (state, params) {
			this.state = state;
			this.params = params;
		};

		this.getRedirectParams = function () {
			const { state, params } = this;
			return { state, params };
		};

		this.clear = function () {
			this.state = null;
			this.params = null;
		};
	}]);
