import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('DatetimePickerService', ['$translate', function ($translate) {
		this.getDefaultOptions = function () {
			return $translate([
				'deduplication.date.apply',
				'deduplication.date.clear',
				'deduplication.date.from',
				'deduplication.date.to',
				'deduplication.date.custom'
			]).then(translations => ({
				showDropdowns: true,
				locale: {
					format: 'YYYY-MM-DD',
					separator: ' - ',
					applyLabel: translations['deduplication.date.apply'],
					cancelLabel: translations['deduplication.date.clear'],
					fromLabel: translations['deduplication.date.from'],
					toLabel: translations['deduplication.date.to'],
					customRangeLabel: translations['deduplication.date.custom'],
					firstDay: 1
				},
				autoUpdateInput: false,
				alwaysShowCalendars: true,
				opens: 'left',
				drops: 'bottom',
				singleDatePicker: true,
				autoApply: true,
			}));
		};

		this.getDefaultRangeOptions = function () {
			return $translate([
				'deduplication.date.ranges.last-hour',
				'deduplication.date.ranges.last-day',
				'deduplication.date.ranges.last-week',
				'deduplication.date.ranges.last-month',
				'deduplication.date.apply',
				'deduplication.date.clear',
				'deduplication.date.from',
				'deduplication.date.to',
				'deduplication.date.custom'
			]).then(translations => ({
				showDropdowns: true,
				timePicker: true,
				timePicker24Hour: true,
				ranges: {
					[translations['deduplication.date.ranges.last-hour']]: [moment().subtract(1, 'hours'), moment()],
					[translations['deduplication.date.ranges.last-day']]: [moment().subtract(1, 'days'), moment()],
					[translations['deduplication.date.ranges.last-week']]: [moment().subtract(1, 'weeks'), moment()],
					[translations['deduplication.date.ranges.last-month']]: [moment().subtract(1, 'month'), moment()]
				},
				locale: {
					format: 'YYYY-MM-DD HH:mm:ss',
					separator: ' - ',
					applyLabel: translations['deduplication.date.apply'],
					cancelLabel: translations['deduplication.date.clear'],
					fromLabel: translations['deduplication.date.from'],
					toLabel: translations['deduplication.date.to'],
					customRangeLabel: translations['deduplication.date.custom'],
					firstDay: 1
				},
				autoUpdateInput: false,
				alwaysShowCalendars: true,
				opens: 'left',
				drops: 'bottom',
			}));
		};
	}]);
