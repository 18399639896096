import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('fingers', () => ({
		template: require('../../views/directives/fingers.html'),
		restrict: 'E',
		replace: true,
		scope: {
			fingers: '=',
			fingersScore: '=',
			unknownOnly: '=',
			imgClick: '=',
			unknownFingersCount: '=',
			showMatchingDetails: '=',
			hasSlap: '=',
			noHover: '='
		},
		link(scope) {
			const fingerOrder = {
				RIGHT: ['RIGHT_THUMB', 'RIGHT_INDEX_FINGER', 'RIGHT_MIDDLE_FINGER',	'RIGHT_RING_FINGER', 'RIGHT_LITTLE_FINGER'],
				LEFT: ['LEFT_LITTLE_FINGER', 'LEFT_RING_FINGER', 'LEFT_MIDDLE_FINGER', 'LEFT_INDEX_FINGER', 'LEFT_THUMB'],
				SLAP: ['PLAIN_LEFT_FOUR_FINGERS', 'PLAIN_THUMBS', 'PLAIN_RIGHT_FOUR_FINGERS'],
				UNKNOWN: ['UNKNOWN']
			};
			scope.handOrder = ['RIGHT', 'LEFT', 'SLAP', 'UNKNOWN'];
			scope.rightHandFingersOrder = ['RIGHT_THUMB', 'RIGHT_INDEX_FINGER', 'RIGHT_MIDDLE_FINGER', 'RIGHT_RING_FINGER', 'RIGHT_LITTLE_FINGER'];
			scope.leftHandFingersOrder = ['LEFT_THUMB', 'LEFT_INDEX_FINGER', 'LEFT_MIDDLE_FINGER', 'LEFT_RING_FINGER', 'LEFT_LITTLE_FINGER'];

			function update() {
				scope.fingerOrder = {
					RIGHT: [],
					LEFT: [],
					SLAP: [],
					UNKNOWN: []
				};
				let i;
				let j;
				let k;
				let l;
				let position;

				for (i = 0; i < scope.handOrder.length; i += 1) {
					const hand = scope.handOrder[i];
					for (j = 0; j < fingerOrder[hand].length; j += 1) {
						let found = false;
						position = fingerOrder[hand][j];
						if (scope.fingers && scope.fingers.length > 0) {
							for (k = 0; k < scope.fingers.length; k += 1) {
								const fingerImage = scope.fingers[k];
								if (position === fingerImage.position) {
									scope.fingerOrder[hand].push({
										position,
										index: k
									});
									found = true;
									if (position !== 'UNKNOWN') {
										break;
									}
								}
							}
						}
						if (!found && position !== 'UNKNOWN') {
							scope.fingerOrder[hand].push({
								position,
								index: null
							});
						}
						if (!found && position === 'UNKNOWN' && scope.unknownFingersCount > 0) {
							for (l = 1; l <= scope.unknownFingersCount; l += 1) {
								scope.fingerOrder[hand].push({
									position: 'UNKNOWN',
									index: null
								});
							}
						}
					}
				}
			}

			scope.$watchGroup(['fingers', 'unknownFingersCount'], update, true);
		}
	}));
