import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('SessionExpiringCtrl', ['$scope', '$interval', '$translate', 'AuthDataHolder',
		function ($scope, $interval, $translate, AuthDataHolder) {
			function refresh() {
				if (!AuthDataHolder.isAuthenticated()) {
					$scope.$close();
					return;
				}

				var timeLeftMillis = AuthDataHolder.getValidUntil() - new Date().getTime();
				var seconds = Math.floor(timeLeftMillis / 1000 % 60);
				var minutes = Math.floor(timeLeftMillis / 1000 / 60);
				if (minutes > 0) {
					$scope.timeLeftReadable = 'auth.session-expiration.minutes-seconds';
				} else {
					$scope.timeLeftReadable = 'auth.session-expiration.seconds';
				}
				$translate($scope.timeLeftReadable, {
					minutes,
					seconds
				}).then((translation) => {
					$scope.timeLeftReadable = translation;
				});
			}

			refresh();

			var refreshPromise = $interval(refresh, 1000);
			$scope.$on('$destroy', () => {
				$interval.cancel(refreshPromise);
			});

			$scope.logout = function () {
				$scope.$close('LOGOUT');
			};

			$scope.renew = function () {
				$scope.$close('RENEW');
			};
		}]);
