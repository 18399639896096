import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CaptureFingersCtrl', ['$scope', '$timeout', '$translate', '$q', '$confirm', 'AbisService', 'AlertService', 'BiometricsService', 'CapturerService', 'PainterService', 'ScenarioService', 'StatusService', 'SubjectService', 'Utils', 'ParserResource', 'CapturePageService', 'namingService', 'SettingsService', 'DSParametersSettingsService', 'AuthDataHolder',
		function ($scope, $timeout, $translate, $q, $confirm, AbisService, AlertService, BiometricsService, CapturerService, PainterService, ScenarioService, StatusService, SubjectService, Utils, ParserResource, CapturePageService, namingService, SettingsService, DSParametersSettingsService, AuthDataHolder) {
			$scope.isFinalStatus = CapturerService.isFinalStatus;
			$scope.importImageFiles = {};
			$scope.capturingPosition = null;
			$scope.capturingFingerId = null;
			$scope.fingerImages = {};
			$scope.fingersQualities = {};
			$scope.fingerPosition = '';
			$scope.fingerId = '';
			$scope.parsedImages = {};
			$scope.scenarioSelected = '';
			let startSequenceOnNextDeviceLock = false;
			$scope.failedScan = false;
			$scope.previousFinger = {};
			$scope.errorOccured = false;
			$scope.captureTimeout = '';
			$scope.fingersWereSkipped = false;

			$scope.upperSnakeToKebabCase = namingService.upperSnakeToKebabCase;
			$scope.isSlapPosition = BiometricsService.isSlapPosition;

			$scope.isBlur = SettingsService.getShouldBlurImages().finger;

			let scenarioWatcher = () => {};
			function watchForScenarioChange() {
				scenarioWatcher();
				scenarioWatcher = $scope.$watch('scenario', (newScenario) => {
					$scope.iterativeScenario = ScenarioService.makeScenarioIterative(newScenario);
				});
			}
			$scope.hasFingers = () => (SubjectService.getFingersCount() > 0);

			StatusService.refresh()
				.catch((err) => {
					if (err.message) {
						AlertService.show(err.message, { type: 'danger', translate: true });
					}
				});

			function startScenarioManager() {
				let isFirstTime = true;
				let unwatchScenarioSelected = null;
				let unwatchScenarioFromService = null;
				let unwatchScenarioOptions = null;

				return {
					isFirstTime,
					identifyScenarioByFingers(fingers) {
						if (isFirstTime) {
							ScenarioService.identifyScenario(fingers);
							isFirstTime = false;
						}
					},
					initWatchers() {
						if (!unwatchScenarioFromService && !unwatchScenarioSelected && !unwatchScenarioOptions) {
							// TODO: don't clear on scenario change, but try to fill steps while removing unused data.
							unwatchScenarioSelected = $scope.$watch('scenarioSelected', (newValue, oldValue) => {
								const isDifferentScenario = newValue !== oldValue && newValue !== undefined && oldValue !== undefined;
								if (isDifferentScenario) {
									ScenarioService.setSelected(newValue);
									CapturerService.setDeviceByModality('Finger', !$scope.device ? null : $scope.device.id, newValue);

									invalidate();
									const tempMissingFingers = BiometricsService.getMissingFingersMap();
									PainterService.paintMissingPositions(tempMissingFingers);

									$scope.scenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
								} else {
									const capturedSteps = SubjectService.getFingers();
									$scope.scenario = $scope.scenarioSelected && capturedSteps ? ScenarioService.recreateScenarioDto($scope.scenarioSelected, capturedSteps) : null;
								}
								$scope.isScenarioAcceptsPositions = $scope.scenario.some(step => step[0].position !== 'UNKNOWN');
								$scope.init();
							});
							unwatchScenarioFromService = $scope.$watch(() => ScenarioService.getSelected(), (newValue) => {
								$scope.scenarioSelected = newValue;
								$scope.scenarioSettings = ScenarioService.getScenarioSettings(newValue);
								if (!$scope.scenarioSettings.visibleByDefault) {
									watchForScenarioChange();
								}
							});
							unwatchScenarioOptions = $scope.$watch(() => ScenarioService.getOptions(), (newOptions) => {
								$scope.scenarioOptions = newOptions;
								try {
									$scope.optionsPayload = $scope.device.scenarios.options || $scope.scenarioOptions;
								} catch (e) {
									$scope.optionsPayload = $scope.scenarioOptions;
								}
							});
						}
					}
				};
			}
			$scope.$on('$viewContentLoaded', () => {
				$scope.actionPageName = AbisService.getRequestTypeReadable();
				$scope.$watch(() => StatusService.getFingerScannerStatus(), (newValue) => {
					$scope.status = newValue;
				});
				$scope.$watch(() => CapturerService.getDevice('Finger'), (newDevice) => {
					$scope.device = newDevice;
					if (newDevice) {
						$scope.optionsPayload = $scope.device.scenarios.options;
						$scope.scenarioSelected = $scope.device.scenarios.selected;
					}
				}, true);
				const scenarioManager = startScenarioManager();
				$scope.$watch(() => SubjectService.getFingers(), (newCapturedSteps) => {
					if (newCapturedSteps && scenarioManager.isFirstTime) {
						scenarioManager.identifyScenarioByFingers(newCapturedSteps);
						$scope.scenarioSelected = ScenarioService.getSelected();
						CapturerService.setDeviceByModality('Finger', !$scope.device ? null : $scope.device.id, $scope.scenarioSelected);
						scenarioManager.initWatchers();
					}
					const capturedSteps = newCapturedSteps;
					const newScenario = $scope.scenarioSelected && capturedSteps ? ScenarioService.recreateScenarioDto($scope.scenarioSelected, capturedSteps) : null;
					if (newScenario !== null) {
						setFingersStatusFromArray(newScenario, BiometricsService.getMissingFingersPositions());
					}
					$scope.scenario = newScenario;
				}, true);
				$scope.$watch(() => CapturerService.getDevices('Finger'), (newDevices) => {
					$scope.devices = newDevices;
				}, true);
				$scope.$watch('device.id', (newFingerScannerId) => {
					CapturerService.setDeviceByModality('Finger', newFingerScannerId);
				});
				$scope.isCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Finger');
				$scope.$watch(() => SettingsService.isCaptureOptionsVisible('Finger'), (newValue) => {
					$scope.isCaptureOptionsVisible = newValue;
				});
				// broadcast from 'clickables.js' -> regionClick()
				$scope.$on('missingFingers:updated', (_event, missingFingers) => {
					setFingersStatusFromArray($scope.scenario, BiometricsService.getMissingFingersPositions());
					if ($scope.isCapturing) {
						const isCapturingPos = missingFingers[$scope.capturingPosition] || BiometricsService.getPositions($scope.capturingPosition).some(pos => missingFingers[pos]);
						if (isCapturingPos) {
							PainterService.paintMissingPositions(BiometricsService.getMissingFingersMap(), BiometricsService.getPositions($scope.capturingPosition));
							$scope.fingersWereSkipped = true;
							$timeout.cancel($scope.captureTimeout);
							CapturerService.cancel($scope.device.id).then(() => {
								$scope.continueCapture();
							});
						} else {
							PainterService.paintCapturingPositions('hands', BiometricsService.getMissingFingersMap(), BiometricsService.getPositions($scope.capturingPosition));
						}
					} else if (missingFingers[$scope.fingerPosition]) {
						$scope.fingerPosition = '';
						$scope.fingerId = '';
						$scope.previewQuality = null;
						$scope.previewImage = revokeAndNullUrl($scope.previewImage);
					}
				});
			});

			$scope.$on('$destroy', () => {
				if ($scope.isCapturing) {
					$scope.stopCapture(false);
				}
			});

			$scope.init = function () {
				const missingFingers = BiometricsService.getMissingFingersPositions();
				setFingersStatusFromArray($scope.scenario, missingFingers);
			};

			function setFingersStatusFromArray(fingerArray, data) {
				if (fingerArray === null) return;

				fingerArray.forEach((elem) => {
					if (Array.isArray(elem)) {
						elem.forEach((obj) => { if (obj.status === 'skipped') obj.status = 'queued'; });
						data.forEach((missingFingerPos) => {
							const foundIndex = elem.findIndex(x => x.position === missingFingerPos);
							if (foundIndex !== -1) {
								elem[foundIndex].status = 'skipped';
							}
						});
					} else {
						if (elem.status === 'skipped') {
							elem.status = 'queued';
						}
						if (data.indexOf(fingerArray.position) !== -1) {
							elem.status = 'skipped';
						}
					}
				});

				if (Array.isArray(fingerArray[0])) {
					for (let i = 0; i < fingerArray.length; i += 1) {
						const elem = fingerArray[i];
						if (!BiometricsService.isSlapPosition(elem[0].position)) break;
						let allSkipped = true;
						for (let j = 1; j < elem.length; j += 1) {
							if (elem[j].status !== 'skipped') {
								allSkipped = false;
								break;
							}
						}
						if (allSkipped) {
							elem[0].status = 'skipped';
						}
					}
				} else if (BiometricsService.isSlapPosition(fingerArray[0].position)) {
					let allSkipped = true;
					for (let i = 0; i < fingerArray.length; i += 0) {
						if (fingerArray[i].status !== 'skipped') {
							allSkipped = false;
							break;
						}
					}
					if (allSkipped) {
						fingerArray[0].status = 'skipped';
					}
				}
			}

			function revokeAndNullUrl(url) {
				if (url !== null && url !== undefined) {
					URL.revokeObjectURL(url);
					url = null;
				}
				return url;
			}

			function revokeAndInvalidateParsedImages() {
				Object.keys($scope.parsedImages).forEach((id) => {
					revokeAndNullUrl($scope.parsedImages[id]);
				});
				$scope.parsedImages = {};
			}

			function invalidate() {
				var fingers = SubjectService.getFingers();
				for (var i = 0; i < fingers.length; i += 1) {
					URL.revokeObjectURL($scope.fingerImages[fingers[i].position]);
					$scope.fingerImages[fingers[i].position] = null;
					$scope.fingersQualities[fingers[i].position] = null;
				}
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);
				SubjectService.invalidate('fingers');
				BiometricsService.resetMissingFingers();
				$scope.errorOccured = false;
				$scope.isCapturing = false;
				$scope.capturingPosition = '';
				$scope.capturingFingerId = '';
				revokeAndInvalidateParsedImages();
			}

			$scope.captureCompound = function () {
				const tempMissingFingers = BiometricsService.getMissingFingersPositions();
				const tempMissingFingersMap = BiometricsService.getMissingFingersMap();
				invalidate();
				SubjectService.setMissingFingers(tempMissingFingers);
				BiometricsService.setMissingFingers(tempMissingFingersMap);

				var captureQueue;
				const newScenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
				setFingersStatusFromArray(newScenario, BiometricsService.getMissingFingersPositions());
				$scope.scenario = newScenario;
				CapturerService.refreshDevices().then(() => {
					if ($scope.status === 'SOURCE_MISSING') {
						return;
					}

					if (!Utils.inArray($scope.scenarioSelected, $scope.device.scenarios.options)) {
						AlertService.show('scenarios.device-not-supported', { type: 'danger' });
						return;
					}

					captureQueue = ScenarioService.getCaptureQueue($scope.scenario);
					startSequenceOnNextDeviceLock = true;

					if (captureQueue === null) {
						throw new Error('Unknown capture scenario');
					}

					queuedCapture(captureQueue);
				});
			};

			$scope.removePosition = function (finger, $event) {
				$event.stopPropagation();
				$translate('scenarios.confirm-remove').then((translation) => {
					$confirm({ text: translation }).then(() => {
						SubjectService.removeFingersById(finger.id);
						SubjectService.attachFingersIdentifier();
						previewFrame();
					}, () => {
						// do nothing
					});
				});
			};

			$scope.recapturePosition = function (finger, $event) {
				$event.stopPropagation();
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);
				$scope.fingerPosition = '';
				$scope.fingerId = '';
				queuedCapture([{ position: finger.position, id: finger.id }], true);
			};

			function getFingerDataById(steps, id) {
				for (let i = 0; i < steps.length; i += 1) {
					for (let j = 0; j < steps[i].length; j += 1) {
						if (id === steps[i][j].id) {
							return steps[i][j];
						}
					}
				}
			}

			$scope.imageOnError = function () {
				if ($scope.parsedImages[$scope.fingerId] !== undefined) {
					if ($scope.previewImage !== null && $scope.previewImage !== undefined) {
						URL.revokeObjectURL($scope.previewImage);
						$scope.previewImage = null;
					}
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob($scope.parsedImages[$scope.fingerId], 'image/x-ms-bmp'));
				} else {
					const steps = SubjectService.getFingers();
					if (steps.length !== 0) {
						const imageData = getFingerDataById(steps, $scope.fingerId).data;
						ParserResource.parseImage({ data: imageData }, (result) => {
							$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(result.data, 'image/x-ms-bmp'));
							$scope.parsedImages[$scope.fingerId] = result.data;
						});
					}
				}
			};

			$scope.previewPosition = function (fingerId) {
				if ($scope.fingerId === fingerId) return;

				$scope.fingerId = fingerId;
				if ($scope.previewImage !== null && $scope.previewImage !== undefined) {
					URL.revokeObjectURL($scope.previewImage);
					$scope.previewImage = null;
				}

				const steps = SubjectService.getFingers();
				if (!!steps && steps.length !== 0) {
					if ($scope.parsedImages[$scope.fingerId] !== undefined) {
						$scope.previewImage = URL.createObjectURL(Utils.b64toBlob($scope.parsedImages[$scope.fingerId], 'image/x-ms-bmp'));
					} else {
						const imageData = getFingerDataById(steps, fingerId);
						if (imageData) {
							previewFrame(imageData.data, imageData.quality);
						}
					}
				}
			};

			function previewFrame(image, quality) {
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);

				if (typeof image === 'string') {
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(image, 'image/x-ms-bmp'));
				} else if (image && image.size === 0) {
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(Utils.getBlankBase64Image(), 'image/x-ms-bmp'));
				} else {
					$scope.previewImage = URL.createObjectURL(image);
				}

				$scope.previewQuality = quality !== undefined ? BiometricsService.getQualityLevel(quality) : null;
			}

			function downloadPromise(deviceId, captureId) {
				return CapturerService.download(deviceId, captureId);
			}

			function checkFingersCompatibility(fingers) {
				if (fingers.some(finger => !$scope.device.validMissingFingers.includes(finger))) {
					return [];
				}
				return fingers;
			}

			function assignQuality(config, quality) {
				['Fingers.CalculateNfiq', 'Fingers.CalculateNfiq2', 'Fingers.CalculateNfiq21'].forEach((algorithm) => {
					config[algorithm] = quality !== 'Off' && algorithm === quality;
				});
			}

			function mergeFetchedConfigurationWithLocal(config, fetchedConfig) {
				const nfiqAlgorithm = fetchedConfig.QualityAlgorithm;
				delete fetchedConfig.QualityAlgorithm;
				assignQuality(config, nfiqAlgorithm);
				angular.merge(config, fetchedConfig);
			}

			function buildConfiguration(fetchedConfig) {
				function getMissingFingers() {
					defaultConfig.MissingFingers = Utils.upperUnderscoreToCamelCaseArray(checkFingersCompatibility(BiometricsService.getMissingFingersPositions()));
				}
				function getSubmodality() {
					defaultConfig.Submodality = Utils.upperUnderscoreToCamelCase($scope.device.positions.indexOf($scope.capturingPosition) === -1 ? 'UNKNOWN' : $scope.capturingPosition);
				}
				function getImpressionType() {
					defaultConfig.ImpressionType = ScenarioService.getScenarioConfig($scope.scenarioSelected).impressionType || defaultConfig.ImpressionType;
				}

				const defaultConfig = {
					'Fingers.CalculateNfiq': true,
					'Fingers.CalculateNfiq2': false,
					'Fingers.CalculateNfiq21': false,
					Segment: true,
					ReturnAllSequenceFingers: true,
					CheckForDuplicates: true,
					MissingFingers: [],
					Submodality: 'UNKNOWN',
					ImpressionType: 'LiveScanPlain'
				};
				getMissingFingers();
				getSubmodality();
				getImpressionType();
				mergeFetchedConfigurationWithLocal(defaultConfig, fetchedConfig);
				return defaultConfig;
			}

			let checkNfiqQuality = 'Nfiq10';
			function queuedCapture(queue, recapturing = false) {
				if (!$scope.device) return;

				if ($scope.failedScan) {
					queue.unshift($scope.previousFinger);
					$scope.failedScan = false;
					$scope.previousFinger = {};
				}

				if (queue.length === 0) {
					$scope.previewImage = revokeAndNullUrl($scope.previewImage);
					$scope.fingerPosition = '';
					$scope.fingerId = '';
					$scope.isCapturing = false;
					return;
				}

				$scope.capturingPosition = queue[0].position;
				$scope.capturingFingerId = queue[0].id;
				var positions = BiometricsService.getPositions($scope.capturingPosition);
				if (BiometricsService.arePositionsMissing(positions, BiometricsService.getMissingFingersPositions())) {
					var positionToMark = positions.slice();
					if (BiometricsService.isSlapPosition($scope.capturingPosition)) {
						positionToMark.push($scope.capturingPosition);
					}
					var fingers = [];
					for (var i = 0; i < positionToMark.length; i += 1) {
						fingers.push({
							position: positionToMark[i],
							status: 'skipped',
							id: $scope.capturingFingerId
						});
					}
					SubjectService.setFingers(fingers);
					$scope.previousFinger = queue.shift();
					queuedCapture(queue);
				} else {
					$q.all([
						CapturerService.registerAndLockDevice($scope.device.id),
						...(AuthDataHolder.hasAnyAuthority(['PERMISSION_ADMINISTRATION_PARAMETERS']) ? [DSParametersSettingsService.getFingersConfiguration()] : []),
					])
						.then((res) => {
							const [deviceId, fetchedConfig] = res;
							const config = buildConfiguration(fetchedConfig);
							checkNfiqQuality = config['Fingers.CalculateNfiq'] ? 'Nfiq10' : false;
							checkNfiqQuality = config['Fingers.CalculateNfiq2'] ? 'Nfiq20' : checkNfiqQuality;
							checkNfiqQuality = config['Fingers.CalculateNfiq21'] ? 'Nfiq21' : checkNfiqQuality;

							if (startSequenceOnNextDeviceLock) {
								CapturerService.startSequence(deviceId);
								startSequenceOnNextDeviceLock = false;
							}
							$scope.captureTimeout = $timeout(() => {
								CapturerService.setConfiguration(deviceId, config)
									.then(() => {
										capture($scope.device.id);
									}, () => {
										AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
									});
							}, 2000);
						}, (error) => {
							CapturerService.handleError(error, AlertService);
							$scope.errorOccured = true;
						});
				}

				function checkIfFingersMeetRequirements(results) {
					for (let i = 0; i < results.length; i += 1) {
						const isNfiqPrefered = checkNfiqQuality === 'Nfiq10' && !BiometricsService.isPreferedQuality(Utils.camelCaseToUpperUnderscore(results[i].metadata.Nfiq10));
						const isNfiq2Prefered = checkNfiqQuality === 'Nfiq20' && !BiometricsService.isPreferedQuality(parseInt(results[i].metadata.Nfiq20, 10));
						const isNfiq21Prefered = checkNfiqQuality === 'Nfiq21' && !BiometricsService.isPreferedQuality(parseInt(results[i].metadata.Nfiq21, 10));
						if ((isNfiqPrefered || isNfiq2Prefered || isNfiq21Prefered) && !BiometricsService.isSlapPosition(Utils.camelCaseToUpperUnderscore(results[i].metadata.Submodality))) {
							$scope.failedScan = true;
							$scope.status = 'RETRYING';
							break;
						}
					}
				}

				function handleErrors(error) {
					if ($scope.fingersWereSkipped && error.status === 'canceled') {
						$scope.fingersWereSkipped = false;
						$scope.status = 'PROCESS_NEXT_STEP';
					} else {
						$scope.errorMessage = CapturerService.handleErrorMessage(error);
						const tempStatus = CapturerService.handleError(error);
						if (!CapturerService.isFinalStatus(tempStatus)) {
							AlertService.show(`capture-service.capture.status.${$scope.errorMessage}`, { type: 'info' });
							$scope.status = 'RETRYING';
							const retryDelay = 3000;
							$timeout(() => {
								$scope.errorMessage = '';
								queuedCapture(queue);
							}, retryDelay);
						} else {
							$scope.isCapturing = false;
							$scope.status = tempStatus;
							if (!recapturing) $scope.errorOccured = true;
						}
					}
				}

				function getFingerStatus(position, quality) {
					if (!checkNfiqQuality) return 'ok';

					return BiometricsService.isPreferedQuality(quality) || BiometricsService.isSlapPosition(position)
						? 'ok'
						: 'bad-quality';
				}

				function capture(deviceId) {
					$scope.isCapturing = true;
					PainterService.paintCapturingPositions('hands', BiometricsService.getMissingFingersMap(), BiometricsService.getPositions($scope.capturingPosition));
					CapturerService.capture(deviceId).then((result) => {
						var promises = [];
						for (var i = 0; i < result.captureIds.length; i += 1) {
							var promise = downloadPromise($scope.device.id, result.captureIds[i]);
							promises.push(promise);
						}
						$q.all(promises).then((results) => {
							$scope.fingerPosition = $scope.capturingPosition;
							$scope.fingerId = $scope.capturingFingerId;
							const emptyStep = ScenarioService.getStep(ScenarioService.getEmptyScenario($scope.scenarioSelected), $scope.fingerId);
							setFingersStatusFromArray(emptyStep, BiometricsService.getMissingFingersPositions());
							$scope.status = 'PROCESS_NEXT_STEP';

							if (checkNfiqQuality) {
								checkIfFingersMeetRequirements(results);
							}

							function getFingersWithStatuses() {
								const fingers = [];
								for (let k = 0; k < emptyStep.length; k += 1) {
									const finger = emptyStep[k];
									let notFound = true;
									const isUnknown = results.length === 1 && results[0].metadata.Submodality === 'Unknown';
									for (let m = 0; m < results.length; m += 1) {
										const resultPosition = Utils.camelCaseToUpperUnderscore(results[m].metadata.Submodality);
										if (finger.position === resultPosition || isUnknown) {
											let quality = Utils.camelCaseToUpperUnderscore(results[m].metadata.Nfiq10 || 'UNKNOWN');
											if (results[m].metadata.Nfiq20) {
												quality = !BiometricsService.isFailedToGetQuality(results[m].metadata.Nfiq20)
													? parseInt(results[m].metadata.Nfiq20, 10)
													: quality;
											}
											if (results[m].metadata.Nfiq21) {
												quality = !BiometricsService.isFailedToGetQuality(results[m].metadata.Nfiq21)
													? parseInt(results[m].metadata.Nfiq21, 10)
													: quality;
											}
											const status = getFingerStatus(finger.position, quality);
											fingers.push({
												position: finger.position,
												quality,
												status,
												data: results[m].sensorData,
												id: finger.id
											});
											notFound = false;
										}
									}
									const isMissing = BiometricsService.getMissingFingersPositions().indexOf(finger.position) !== -1;
									if (notFound && isMissing) {
										fingers.push({
											position: finger.position,
											status: 'skipped',
											id: finger.id
										});
									}
								}
								return fingers;
							}
							const fingers = getFingersWithStatuses();
							// to force recapture based on fingerprints quality:
							// if (BiometricsService.hasFingersPreferedQuality(fingers, BiometricsService.getMissingFingersPositions())) {
							SubjectService.setFingers(fingers);
							$scope.previousFinger = queue.shift();
							// } else {
							// 	AlertService.show('capture-service.capture.alert.poor-quality', { type: 'danger' });
							// }
							queuedCapture(queue);
						}, (error) => {
							handleErrors(error);
						}).finally(() => {
							if (queue.length === 0) {
								$scope.isCapturing = false;
								CapturerService.unlock($scope.device.id);
							}
						});
					}, (error) => {
						previewFrame(Utils.getBlankBase64Image());
						handleErrors(error);
					}).finally(() => {
						PainterService.paintCapturingPositions('hands', BiometricsService.getMissingFingersMap(), []);
						const newScenario = ScenarioService.recreateScenarioDto($scope.scenarioSelected, SubjectService.getFingers());
						setFingersStatusFromArray(newScenario, BiometricsService.getMissingFingersPositions());
						$scope.scenario = newScenario;
					});

					function preview(deviceId, etag) {
						CapturerService.previewImage(deviceId, etag).then((response) => {
							if (CapturerService.isFinalStatus($scope.status) || response.isFinalImage) {
								return;
							}

							if (response.status) {
								$scope.biometricStatus = Utils.camelCaseToUpperUnderscore(response.status);
							} else {
								$scope.biometricStatus = '';
							}

							ScenarioService.setStepStatus($scope.scenario, $scope.capturingFingerId, 'capturing');
							$scope.status = 'IN_PROGRESS';
							previewFrame(response.image);
							preview(deviceId, response.etag);
						}, () => {
							if (!CapturerService.isFinalStatus($scope.status)) {
								$timeout(() => {
									preview(deviceId, '0');
								}, 100);
							}
						});
					}
					$scope.status = 'STARTING';
					preview(deviceId, '0');
				}
			}

			$scope.forceCapture = function () {
				CapturerService.save({ type: 'FORCE_CAPTURE' });
			};

			$scope.continueCapture = function () {
				function updateScenario() {
					const scenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
					setFingersStatusFromArray(scenario, BiometricsService.getMissingFingersPositions());
					const missingFingers = BiometricsService.getMissingFingersPositions();
					const scannedFingers = SubjectService.getFingers();
					for (let i = 0; i < scenario.length; i += 1) {
						scenario[i] = scenario[i].filter(elem => missingFingers.indexOf(elem.position) === -1);

						let include = true;
						scenario[i] = scenario[i].filter((elem) => {
							for (let j = 0; j < scannedFingers.length; j += 1) {
								if (scannedFingers[j].some(e => (e.position === elem.position && e.id === elem.id))) include = false;
							}
							return include;
						});

						if (scenario[i].length === 0) {
							scenario.splice(i, 1);
							i -= 1;
						}
					}
					return scenario;
				}
				$scope.errorOccured = false;
				const newScenario = updateScenario();
				const captureQueue = ScenarioService.getCaptureQueue(newScenario);
				if (captureQueue.length !== 0) {
					CapturerService.refreshDevices().then(() => {
						if ($scope.status === 'SOURCE_MISSING') {
							return;
						}

						if (!Utils.inArray($scope.scenarioSelected, $scope.device.scenarios.options)) {
							AlertService.show('scenarios.device-not-supported', { type: 'danger' });
						}

						startSequenceOnNextDeviceLock = false;
						queuedCapture(captureQueue);
					});
				} else {
					$scope.stopCapture(false);
					$scope.isCapturing = false;
				}
			};

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			$scope.stopCapture = function (doClearData) {
				$scope.fingersWereSkipped = false;
				if (doClearData) {
					invalidate();
				}
				if ($scope.device !== null) {
					CapturerService.cancel($scope.device.id).finally(() => {
						CapturerService.unlock($scope.device.id);
					});
				}
			};

			$scope.biometricsPage = function () {
				CapturePageService.goBack();
			};

			$scope.importImage = function (finger) {
				function setImportData(data, notValidImage) {
					$scope.fingerId = finger.id;
					SubjectService.setFingers([{
						position: finger.position,
						status: 'ok',
						isImported: true,
						data: $scope.importImageFiles[finger.id].content,
						id: finger.id
					}]);

					$scope.fingerPosition = finger.position;
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(data, 'image/x-ms-bmp'));
					if ($scope.parsedImages[$scope.fingerId] !== undefined) {
						delete $scope.parsedImages[$scope.fingerId];
					}
					if (notValidImage) {
						$scope.parsedImages[$scope.fingerId] = data;
					}

					AlertService.show('biometrics.import.imported', { type: 'success' });
				}
				if ($scope.importImageFiles[finger.id].type.match(/^image\//)) {
					setImportData($scope.importImageFiles[finger.id].content, false);
				} else {
					const data = $scope.importImageFiles[finger.id].content;
					ParserResource.parseImage({ data }, (result) => {
						setImportData(result.data, true);
					}, () => {
						AlertService.show('forms.invalid-image', { type: 'danger' });
					});
				}
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.getFingersCount = function () {
				return SubjectService.getModalitiesCounts().fingersCount;
			};

			$scope.isNfiq2 = BiometricsService.isNfiq2;
		}]);
