import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.controller('BiometricsCtrl', ['$confirm', '$scope', '$state', '$stateParams', '$translate', '$uibModal', '$rootScope', '$q', 'AbisService', 'AlertService', 'AuthDataHolder', 'blockUI', 'DatetimePickerService', 'ParserResource', 'InputUtils', 'SubjectService', 'BiographicDataService', 'SettingsService', 'BiometricsService', 'EncountersResource', 'GalleriesService', 'ConvertionUtils', 'ParametersService', 'EncounterToSubjectConverter', 'TransactionParams', 'MultiselectModule',
		function ($confirm, $scope, $state, $stateParams, $translate, $uibModal, $rootScope, $q, AbisService, AlertService, AuthDataHolder, blockUI, DatetimePickerService, ParserResource, InputUtils, SubjectService, BiographicDataService, SettingsService, BiometricsService, EncountersResource, GalleriesService, ConvertionUtils, ParametersService, EncounterToSubjectConverter, TransactionParams, MultiselectModule) {
			$rootScope.$stateParams = $stateParams;
			$scope.abisRequestData = {};
			$scope.priorityOptions = AbisService.getPriorityOptions();
			$scope.subjectModalities = SubjectService.getModalitiesCounts();
			$scope.datetimePickerOptions = DatetimePickerService.getDefaultOptions();

			$scope.transactionOptions = {
				useDuplicateCheck: SettingsService.getUseDuplicateCheck(),
				verifyDuringUpdate: SettingsService.getVerifyDuringUpdate()
			};
			$scope.fields = [];
			$scope.importFormModel = {};
			$scope.forms = {};

			$scope.iconClass = 'pointer-events-none icon-modality';

			$scope.galleries = [];

			$scope.msModule = MultiselectModule;

			$scope.hasKey = BiographicDataService.hasKey;

			ParametersService.getParameter('mmabis.management.generate-subject-id').then((data) => {
				$scope.allowSubjectId = data !== 'true';
			});

			ParametersService.getParameter('mmabis.management.extraction-modalities').then((data) => {
				$scope.extractionModalities = data;
			});

			BiographicDataService.get().then((fields) => {
				fields = angular.copy(fields);
				fields = fields.map((f) => {
					if (f.type === 'ENUM') {
						const entries = Object.values(f.values);
						f.originalValues = f.values;
						f.values = entries;
						for (let i = 0; i < f.values.length; i += 1) {
							f.values[i] = $translate.instant(f.values[i]);
						}
					} else if (f.type !== 'STRING') {
						f.viewFormat = f.viewFormat.replace(/y/g, 'Y').replace(/d/g, 'D');
						f.options = $scope.getDateTimeFormat(f);
					}
					if (!f.flags) {
						f.flags = [];
					}
					return f;
				});
				$scope.fields = fields;
			});

			$scope.homePage = function () {
				if ($stateParams.previousState) {
					let gallery = AbisService.getGalleryId($state.current.name);
					[gallery] = GalleriesService.fromRequestString(gallery, $scope.galleries);
					$state.go($stateParams.previousState.name, {
						subjectId: SubjectService.getId(),
						encounterId: SubjectService.getEncounterId(),
						galleryId: gallery.id,
					});
				} else {
					$state.go('actions.home');
				}
			};

			function setGalleryIdToService(galleryId) {
				AbisService.setGalleryId($state.current.name, galleryId);
			}

			function loadSubject(subjectId) {
				EncountersResource.query(angular.extend({
					status: 'ENROLLED'
				}, { subjectId }), (value) => {
					if (value.length > 0) {
						$scope.subjectData.biographicData = value[0].biographicData;
						SubjectService.setBiographicData(value[0].biographicData);

						if (value[0].galleryId && AuthDataHolder.hasAnyGallery(value[0].galleryId)) {
							setGalleryIdToService(value[0].galleryId);
						}
					}
				});
			}

			if ($state.is('actions.enroll')) {
				if ($state.params.subject) {
					SubjectService.setBiographicData($state.params.subject.biographicData);
				}
				$scope.$watch('transactionOptions.useDuplicateCheck', (useDuplicateCheck) => {
					if (useDuplicateCheck === undefined) return;
					SettingsService.setUseDuplicateCheck(useDuplicateCheck);
					const type = useDuplicateCheck ? 'ENROLL_WITH_DUPLICATE_CHECK' : 'ENROLL';
					AbisService.setRequestType(type);
					$scope.transactionParams = TransactionParams.changeTypeParameters(type);
				});
				$scope.transactionParams = TransactionParams.initializeParameters($scope.transactionOptions.useDuplicateCheck
					? 'ENROLL_WITH_DUPLICATE_CHECK' : 'ENROLL');
			} else if ($state.is('actions.update')) {
				$scope.$watch('transactionOptions.verifyDuringUpdate', (verifyDuringUpdate) => {
					if (verifyDuringUpdate === undefined) return;
					SettingsService.setVerifyDuringUpdate(verifyDuringUpdate);
					const type = verifyDuringUpdate ? 'VERIFY_UPDATE' : 'UPDATE';
					AbisService.setRequestType(type);
					$scope.transactionParams = TransactionParams.changeTypeParameters(type);
				});

				if ($state.params.subject && $state.params.subject.encounterId) {
					if (!SubjectService.hasBiometrics()) {
						EncounterToSubjectConverter.encounterToSubjectService($state.params.subject);
					}

					SubjectService.setEncounterId($state.params.subject.encounterId);
				}
				$scope.transactionParams = TransactionParams.initializeParameters($scope.transactionOptions.verifyDuringUpdate
					? 'VERIFY_UPDATE' : 'UPDATE');
			} else if ($state.is('actions.verify')) {
				if (!$state.params.subjectId && !SubjectService.getId()) {
					$state.go('actions.home', null, { location: 'replace' });
				} else {
					if ($state.params.subjectId) {
						SubjectService.setId($state.params.subjectId);
					} else {
						$state.transitionTo('actions.verify', {
							subjectId: SubjectService.getId(),
							previousState: $state.params.previousState
						}, { reload: false, notify: false, location: 'replace' });
					}

					if ($state.params.subject) {
						SubjectService.setBiographicData($state.params.subject.biographicData);

						if ($state.params.subject.encounterId) SubjectService.setEncounterId($state.params.subject.encounterId);
					} else {
						loadSubject($state.params.subjectId || SubjectService.getId());
					}
				}
				$scope.transactionParams = TransactionParams.initializeParameters('VERIFY');
			} else if ($state.is('actions.identify')) {
				$scope.transactionParams = TransactionParams.initializeParameters('IDENTIFY');
			}

			$scope.$on('$viewContentLoaded', () => {
				if ($stateParams.previousState) {
					$scope.actionPageName = $stateParams.previousState.name;
				}
				$scope.$watch(() => AbisService.getRequest($state.current.name), (newValue) => {
					$scope.abisRequestData = newValue;
				}, true);
				// broadcast from 'settings-service.js' -> setPreferredGallery()
				$scope.$on('preferredGallery:updated', (_event, newValue) => {
					if ($state.is('actions.verify') || $state.is('actions.update')) {
						return;
					}

					$scope.abisRequestData.galleryId = newValue.id // append new value
						|| $scope.abisRequestData.galleryId || // assign old galleryId (ex. when going back from capture page)
						($scope.galleries.length !== 1
							? $scope.galleries[1].id // get first FETCHED gallery (not default one)
							: GalleriesService.getDefaultGallery().id); // assign default one if no galleries are given

					$scope.msModule.multiSelectModule.model[0] = $scope.galleries.find(gallery => gallery.id === $scope.abisRequestData.galleryId); // fing gallery from chosen id

					setGalleryIdToService(newValue ? newValue.id : GalleriesService.toRequestString($scope.msModule.multiSelectModule.getSelected()));
				}, true);
				$scope.$watch(() => SubjectService.getData(), (newValue) => {
					for (let i = 0; i < $scope.fields.length; i += 1) {
						const field = $scope.fields[i];
						const data = !!newValue.biographicData && newValue.biographicData[field.key];
						if (data) {
							if (field.type === 'ENUM') {
								const parsedValue = field.originalValues[newValue.biographicData[field.key]];
								newValue.biographicData[field.key] = parsedValue || newValue.biographicData[field.key];
							} else if ((field.type === 'DATE' || field.type === 'DATE_TIME') && !(newValue.biographicData[field.key] instanceof moment)) {
								const formatting = (field.type === 'DATE_TIME') ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
								newValue.biographicData[field.key] = moment(newValue.biographicData[field.key], formatting);
							}
						}
					}

					$scope.subjectData = newValue;
					if ($state.is('actions.update')) {
						if ($scope.subjectData.subjectId === null) {
							$scope.homePage();
						}
					}
				}, true);
				$scope.$watch(() => SubjectService.getModalitiesCounts(), (newValue) => {
					$scope.subjectModalities = newValue;
				}, true);
			});

			/**
			 * @typedef {{
					description: string;
					id: string;
					name: string;
					state:'ACTIVE' | 'INACTIVE'
				}} Gallery
			 * @param {Gallery[]} galleries
			 * @param {Gallery[]} defaultGallery
 			*/
			function loadMSModule(galleries, defaultGallery) {
				const msData = galleries.map(gallery => ({
					id: gallery.id,
					label: gallery.name,
				}));

				$scope.msModule.moduleData = $scope.msModule.initialize(msData, defaultGallery, {}, null, {
					dynamicTitle: false,
					scrollableHeight: '200px',
					scrollable: true
				});
			}

			if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST') && (!$state.is('actions.verify') || !$state.is('actions.update'))) {
				GalleriesService.getAvailableGalleries().then((galleries) => {
					$scope.galleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
					$scope.galleries.unshift(GalleriesService.getDefaultGallery());

					$scope.abisRequestData.galleryId = AbisService.getGalleryId($state.current.name) // get selected gallery (or null)
						|| (SettingsService.getPreferredGallery() && SettingsService.getPreferredGallery().id) // get preferred gallery (if not default)
						|| ($scope.galleries.length !== 1
							? $scope.galleries[1].id // get first FETCHED gallery (not default one)
							: GalleriesService.getDefaultGallery().id); // get default gallery

					const selectedGallery = GalleriesService.fromRequestString($scope.abisRequestData.galleryId, $scope.galleries);
					loadMSModule($scope.galleries, selectedGallery);

					if (!$state.previous.includes('actions.capture')) {
						setGalleryIdToService(selectedGallery ? GalleriesService.toRequestString(selectedGallery) : GalleriesService.getDefaultGallery());
					}
				});
			} else {
				$scope.galleries = [];
			}

			$scope.capturePage = function (page) {
				$state.go(page, {
					previousState: $state.params.previousState
				}, { location: 'replace' });
			};

			$scope.clearData = function (type) {
				if ($state.current.name === 'actions.verify'
					|| ($stateParams.previousState && $stateParams.previousState.name === 'actions.scan')) {
					$translate('subject.actions.confirm-clear-biometrics').then((translation) => {
						$confirm({ text: translation }).then(() => {
							SubjectService.invalidate('faces');
							SubjectService.invalidate('fingers');
							SubjectService.invalidate('irises');
							SubjectService.invalidate('palms');
							SubjectService.invalidate('signature');
							BiometricsService.resetMissingFingers();
							BiometricsService.resetMissingIrises();
							AlertService.show('biometrics.clear.success', { type: 'success' });
						}, () => {
							// do nothing
						});
					});
				} else {
					$translate('subject.actions.confirm-clear').then((translation) => {
						$confirm({ text: translation }).then(() => {
							SubjectService.invalidate(type);
							BiometricsService.resetMissingFingers();
							BiometricsService.resetMissingIrises();
							AlertService.show('biometrics.clear.success', { type: 'success' });
						}, () => {
							// do nothing
						});
					});
				}
			};

			$scope.saveData = function () {
				Object.keys($scope.subjectData.biographicData).forEach((field) => {
					if ($scope.subjectData.biographicData[field] === null) {
						delete $scope.subjectData.biographicData[field];
					}
				});
				SubjectService.save();
				AbisService.setPriority($scope.abisRequestData.priority);

				if (!$state.is('actions.verify') && !$state.is('actions.update')) {
					let selectedGalleries = $scope.msModule.multiSelectModule.getSelected();
					selectedGalleries = GalleriesService.toRequestString(selectedGalleries);
					setGalleryIdToService(selectedGalleries || null);
				}
			};

			$scope.multiSelectEventsHandler = {
				onSelectionChanged: () => {
					$scope.msModule.eventsHandler.onSelectionChanged();
					$scope.saveData();
				}
			};

			$scope.importNIST = function () {
				if ($scope.forms.importNISTForm.$valid) {
					blockUI.start('app.loading');
					ParserResource.parseNist({
						data: $scope.importFormModel.NISTFile.content
					}).$promise.then((data) => {
						var scope = $scope.$new(true);
						scope.isFromFile = true;
						scope.subject = data;
						$translate('biometrics.import').then((translations) => { scope.title = translations; })
							.then(() => {
								$uibModal.open({
									template: require('../../views/modal/subject-modal.html'),
									animation: false,
									controller: 'SubjectModalCtrl',
									windowClass: 'subject-modal-window modal-w-6',
									scope
								}).result.catch((res) => {
									if (!['backdrop click', 'escape key press'].includes(res)) {
										throw new Error(res);
									}
								});
							});
					}, () => {
						AlertService.show('forms.invalid-nist', { type: 'danger' });
					}).finally(() => {
						BiometricsService.setMissingIrises({ LEFT: false, RIGHT: false });
						blockUI.stop();
					});
				}
			};

			$scope.submitAbisRequest = function () {
				$scope.form.$submitted = true;
				if (!$scope.form.$valid && $state.current.name !== 'actions.verify') {
					AlertService.show('forms.missing-or-invalid', { type: 'danger' });
					return;
				}
				if (!SubjectService.hasBiometrics()) {
					AlertService.show('forms.empty-biometric-data', { type: 'danger' });
					return;
				}
				var scope = $scope.$new(true);
				scope.isBiometricPreview = true;
				scope.invalidate = function () {
					$scope.subjectData = SubjectService.getData();
				};
				scope.subject = SubjectService.getDto();

				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					scope.subject.galleryId = $scope.abisRequestData.galleryId;
				}

				scope.requestTypeName = $state.current.name;
				$translate($state.current.name).then((translations) => { scope.title = translations; })
					.then(() => {
						$uibModal.open({
							template: require('../../views/modal/subject-modal.html'),
							animation: false,
							controller: 'SubjectModalCtrl',
							windowClass: 'subject-modal-window modal-w-6',
							scope
						}).result.catch((res) => {
							if (!['backdrop click', 'escape key press'].includes(res)) {
								throw new Error(res);
							}
						});
					});
			};

			$scope.defaultDateOptions = { opens: 'left' };
			let isLoaded = false;
			function getDefaultOptions() {
				if (!isLoaded) {
					return DatetimePickerService.getDefaultOptions()
						.then((options) => {
							$scope.defaultDateOptions = options;
							isLoaded = true;
						});
				}
				return $q(resolve => resolve());
			}

			$scope.getDateTimeFormat = function (field) {
				return $q((resolve) => {
					getDefaultOptions()
						.then(() => {
							const options = angular.copy($scope.defaultDateOptions);
							options.locale.format = field.viewFormat;
							resolve(options);
						});
				});
			};

			$scope.validateDate = function (field) {
				const { key } = field;
				const format = field.viewFormat;
				var datetime = $scope.subjectData.biographicData[key];
				if (!InputUtils.isValidDatetime(datetime, format)) {
					$scope.subjectData.biographicData[key] = null;
				}
				if (datetime instanceof moment && !datetime.isSame($scope.subjectData.biographicData[key])) {
					$scope.subjectData.biographicData[key] = datetime.format(format);
				}
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.hasAuthority = function (authority) {
				return AuthDataHolder.hasAuthority(authority);
			};

			$scope.hasAnyGallery = function (...args) {
				return AuthDataHolder.hasAnyGallery(args);
			};

			$scope.hasModalityParameter = (modalityName) => {
				if (!$scope.extractionModalities) {
					return false;
				}
				return $scope.extractionModalities.includes(modalityName.toLowerCase());
			};
		}]);
