import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('fingerDrawingOptions', () => ({
		template: require('../../views/directives/finger-drawing-options.html'),
		restrict: 'E',
		replace: true,
		scope: {
			options: '=fdoOptions',
			title: '@fdoTitle',
			dropdownClass: '@fdoDropdownClass'
		}
	}));
