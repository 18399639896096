import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('BiographicDataService', ['BiographicDataResource', '$q', 'AuthDataHolder', function (BiographicDataResource, $q, AuthDataHolder) {
		this.data = null;
		this._isLoading = false;
		this.dataPromise = null;

		this.downloadData = function () {
			return BiographicDataResource.get((response) => {
				this.data = response;
			}).$promise;
		};

		this.hasKey = function (keys, key) {
			if (!keys) return false;
			for (let i = 0; i < keys.length; i += 1) {
				if (keys[i] === key) return true;
			}
			return false;
		};

		this.loadData = function () {
			if (!AuthDataHolder.isAuthenticated()) return false;
			if (!this.data && !this._isLoading) {
				this._isLoading = true;
				this.dataPromise = this.downloadData();
				this.dataPromise.then(() => { this._isLoading = false; }, () => {
					this.data = null;
					this._isLoading = false;
					this.dataPromise = null;
				}).catch(() => {
					this.data = null;
					this._isLoading = false;
					this.dataPromise = null;
				});
			}
			return true;
		};

		this.get = function () {
			const t = this.loadData();
			const p = $q((resolve, reject) => {
				if (!t) reject();
				if (this._isLoading) {
					this.dataPromise.then(resolve, reject).catch(reject);
				} else {
					resolve(this.data);
				}
			});
			return p;
		};

		if (AuthDataHolder.isAuthenticated()) {
			this.get();
		}
	}]);
