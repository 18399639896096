import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('CapturerService', ['$q', 'CapturerDevicesResource', 'ScenarioService', 'store', 'ETagService',
		function ($q, CapturerDevicesResource, ScenarioService, store, ETagService) {
			var self = this;
			var storeName = ':capturerDevices';
			const finalStatuses = [
				'CAPTURE_ERROR', 			'CANCELED',			'BAD_VALUE',
				'PROCESS_NEXT_STEP', 		'CAPTURING_READY', 	'INVALID_ID',
				'CANCELED_SENSOR_FAILURE', 	'UNSUPPORTED',		'INITIALIZATION_NEEDED',
				'CONFIGURATION_NEEDED',		'BUSY',				'NO_SUCH_PARAMETER',
				'FAILURE',					'TIMEOUT'
			];

			const modalitiesStatus = {
				Iris: false,
				Face: false,
				Finger: false,
				Palm: false,
				Signature: false,
				Document: false,
			};

			this._load = function () {
				this._devices = store.get(storeName);
				if (!this._devices) {
					this._devices = {};
				}
			};
			this._load();

			this._save = function () {
				store.set(storeName, this._devices);
			};

			this._clear = function () {
				store.remove(storeName);
			};

			this._registerDevice = function (deviceId) {
				var deferred = $q.defer();
				CapturerDevicesResource.register(
					{ deviceId }, null,
					(result) => {
						if (result.status === 'success') {
							deferred.resolve(result.sessionId);
						} else {
							deferred.reject();
						}
					}, () => {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			this._lockDevice = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var deferred = $q.defer();
				CapturerDevicesResource.lock(
					{ deviceId, actionId: sessionId }, null,
					(result) => {
						if (result.status === 'success') {
							deferred.resolve(result.deviceId);
						} else {
							deferred.reject(result);
						}
					}, () => {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			this._http = function (endpoint, params, data) {
				var deferred = $q.defer();
				endpoint(
					params, data,
					(result) => {
						if (result.status === 'success') {
							deferred.resolve(result);
						} else {
							deferred.reject(result);
						}
					}, () => {
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			this.unlock = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var promise = self._http(CapturerDevicesResource.unlock, { deviceId, actionId: sessionId });
				return promise;
			};

			this.isFinalStatus = function (status) {
				return finalStatuses.indexOf(status) > -1;
			};

			this.capture = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var promise = self._http(CapturerDevicesResource.capture, { deviceId, actionId: sessionId });
				return promise;
			};

			this.status = function () {
				var promise = self._http(CapturerDevicesResource.capture, {});
				return promise;
			};

			this.force = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var promise = self._http(CapturerDevicesResource.force, { deviceId, actionId: sessionId });
				return promise;
			};

			this.startSequence = function (deviceId) {
				const { sessionId } = self._devices[deviceId];
				const promise = self._http(CapturerDevicesResource.startSequence, { deviceId, actionId: sessionId });
				return promise;
			};

			this.previewImage = function (deviceId, etag) {
				var { sessionId } = self._devices[deviceId];
				ETagService.setETag(etag);
				var promise = CapturerDevicesResource.previewImage({ deviceId, actionId: sessionId }).$promise;
				return promise;
			};

			this.preview = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var promise = self._http(CapturerDevicesResource.preview, { deviceId, actionId: sessionId });
				return promise;
			};

			this.download = function (deviceId, captureId) {
				var promise = self._http(CapturerDevicesResource.download, { deviceId, actionId: captureId });
				return promise;
			};

			this.cancel = function (deviceId) {
				var { sessionId } = self._devices[deviceId];
				var promise = self._http(CapturerDevicesResource.cancel, { deviceId, actionId: sessionId });
				return promise;
			};

			this.getConfiguration = function (deviceId) {
				const { sessionId } = self._devices[deviceId];
				const promise = self._http(CapturerDevicesResource.getConfiguration, { deviceId, actionId: sessionId });
				return promise;
			};

			this.getInfo = function (deviceId) {
				const promise = self._http(CapturerDevicesResource.getInfo, { deviceId });
				return promise;
			};

			this.invoke = function (deviceId, action, data) {
				const { sessionId } = self._devices[deviceId];
				const promise = self._http(CapturerDevicesResource.invoke, { deviceId, actionId: sessionId, type: action }, data);
				return promise;
			};

			this.setConfiguration = function (deviceId, items) {
				function mapToConfiguration(itemKey) {
					function handleMissingModalities() {
						const itemPayload = { key: itemKey, value: 'None' };
						if (Array.isArray(items[itemKey]) && items[itemKey].length !== 0) {
							itemPayload.value = items[itemKey];
						}
						return itemPayload;
					}

					function handleDocumentImages() {
						const itemPayload = { key: itemKey, value: items[itemKey] === 'All' ? items[itemKey] : 'None' };
						if (Array.isArray(items[itemKey]) && items[itemKey].length !== 0) {
							itemPayload.value = items[itemKey];
						}
						return itemPayload;
					}

					switch (itemKey) {
					case 'MissingFingers':
					case 'MissingEyes':
						return handleMissingModalities();
					case 'DocumentImages':
						return handleDocumentImages();
					case 'Segment':
					case 'ReturnAllSequenceFingers':
					case 'ContentType':
					case 'Submodality':
					case 'Fingers.CalculateNfiq':
					case 'Fingers.CalculateNfiq2':
					case 'Fingers.CalculateNfiq21':
					case 'CheckForDuplicates':
					case 'CaptureFormat':
					case 'DocumentFaceImage':
					case 'DocumentBiographicData':
					case 'Faces.DetectProperties':
					case 'Faces.DetermineGender':
					case 'Faces.RecognizeEmotion':
					case 'Faces.DetermineAge':
					case 'Faces.DetermineEthnicity':
					case 'Faces.CheckIcaoCompliance':
					case 'Faces.IcaoMaximalRoll':
					case 'Faces.IcaoMaximalYaw':
					case 'Faces.IcaoMaximalPitch':
					case 'Faces.IcaoSaturationThreshold':
					case 'Faces.IcaoSharpnessThreshold':
					case 'Faces.IcaoBackgroundUniformityThreshold':
					case 'Faces.IcaoGrayscaleDensityThreshold':
					case 'Faces.IcaoLookingAwayThreshold':
					case 'Faces.IcaoRedEyeThreshold':
					case 'Faces.IcaoFaceDarknessThreshold':
					case 'Faces.IcaoUnnaturalSkinToneThreshold':
					case 'Faces.IcaoWashedOutThreshold':
					case 'Faces.IcaoPixelationThreshold':
					case 'Faces.IcaoSkinReflectionThreshold':
					case 'Faces.IcaoGlassesReflectionThreshold':
					case 'Faces.IcaoRemoveRedEye':
					case 'Faces.IcaoRemoveBackground':
					case 'Faces.IcaoGlassesConfidenceThreshold':
					case 'Faces.IcaoHatConfidenceThreshold':
					case 'ImpressionType':
						return { key: itemKey, value: String(items[itemKey]) };
					default:
						throw new Error(`Configuration: invalid item key "${itemKey}"`);
					}
				}

				const { sessionId } = self._devices[deviceId];
				if (self._devices[deviceId].selectedFormat && !items.CaptureFormat) {
					items.CaptureFormat = self._devices[deviceId].selectedFormat;
				}

				const configuration = Object.keys(items).map(mapToConfiguration);
				const promise = self._http(CapturerDevicesResource.setConfiguration, { deviceId, actionId: sessionId }, configuration);
				return promise;
			};

			this.refreshDevices = function () {
				var deferred = $q.defer();
				CapturerDevicesResource.get(
					{},
					(devices) => {
						function assignDeviceInfo(deviceInfo) {
							Object.assign(devices[deviceInfo.deviceId], deviceInfo);
						}
						function removeUnsupported(devices) {
							Object.keys(devices.toJSON()).forEach((deviceId) => {
								if (devices[deviceId].types.indexOf('Microphone') > -1) {
									delete devices[deviceId];
								}
							});
						}
						var promises = [];
						removeUnsupported(devices);
						Object.keys(devices.toJSON()).forEach((deviceId) => {
							var promise = CapturerDevicesResource.getInfo({ deviceId }, assignDeviceInfo).$promise;
							promises.push(promise);
						});
						$q.all(promises).then(() => {
							var sessions = {};
							var selections = {};
							var scenarios = {};
							var formats = {};
							Object.keys(self._devices).forEach((deviceId) => {
								if (Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'sessionId')) {
									sessions[deviceId] = self._devices[deviceId].sessionId;
								}
								if (Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'selected')) {
									selections[deviceId] = self._devices[deviceId].selected;
								}
								if (Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'scenarios')) {
									scenarios[deviceId] = self._devices[deviceId].scenarios;
								}
								if (Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'selectedFormat')) {
									formats[deviceId] = self._devices[deviceId].selectedFormat;
								}
							});

							self._devices = devices.toJSON();

							Object.keys(self._devices).forEach((deviceId) => {
								if (sessions[deviceId] !== undefined) {
									self._devices[deviceId].sessionId = sessions[deviceId];
								}
								if (selections[deviceId] !== undefined) {
									self._devices[deviceId].selected = selections[deviceId];
								}
								if (scenarios[deviceId] !== undefined) {
									self._devices[deviceId].scenarios = scenarios[deviceId];
								}

								if (formats[deviceId] !== undefined) {
									self._devices[deviceId].selectedFormat = formats[deviceId];
								} else {
									self._devices[deviceId].selectedFormat = self._devices[deviceId].defaultFormat;
								}
							});
							Object.keys(self._devices).forEach((deviceId) => {
								if (!Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'scenarios')) {
									self._devices[deviceId].scenarios = ScenarioService.getCapturerOptionsByDeviceProperties(
										self._devices[deviceId].modality,
										self._devices[deviceId].positions
									);
								}
							});

							self._save();
							deferred.resolve(devices);
						});
					}, () => {
						self._clear();
						self._load();
						deferred.reject();
					}
				);
				return deferred.promise;
			};

			this.registerAndLockDevice = function (deviceId) {
				var deferred = $q.defer();
				if (Object.prototype.hasOwnProperty.call(self._devices[deviceId], 'sessionId')) {
					this._lockDevice(deviceId).then((result) => {
						deferred.resolve(result);
					}, (error) => {
						if (error.status === 'invalidId' || error.status === 'badValue') {
							delete self._devices[deviceId].sessionId;
							self._save();
							self.registerAndLockDevice(deviceId).then((result) => {
								deferred.resolve(result);
							}, (error) => {
								deferred.reject(error);
							});
						} else {
							deferred.reject(error);
						}
					});
				} else {
					this._registerDevice(deviceId).then((sessionId) => {
						self._devices[deviceId].sessionId = sessionId;
						self._save();
						self.registerAndLockDevice(deviceId).then((result) => {
							deferred.resolve(result);
						}, (error) => {
							deferred.reject(error);
						});
					}, (error) => {
						deferred.reject(error);
					});
				}

				return deferred.promise;
			};

			this.getDevice = function (modality, id) {
				var firstDevice = null;
				var result = null;
				Object.keys(this._devices).forEach((deviceId) => {
					if (this._devices[deviceId].modality === modality) {
						if (id === undefined) {
							if (firstDevice === null) {
								firstDevice = this._devices[deviceId];
							}
							if (this._devices[deviceId].selected) {
								result = this._devices[deviceId];
							}
						} else if (id === deviceId) {
							result = this._devices[deviceId];
						}
					}
				});
				if (result) {
					return result;
				}
				if (firstDevice === null) {
					return null;
				}
				firstDevice.selected = true;
				self._save();
				return firstDevice;
			};

			this.setDeviceByModality = function (modality, id, scenario) {
				Object.keys(this._devices).forEach((deviceId) => {
					if (this._devices[deviceId].modality === modality) {
						if (id === deviceId) {
							if (scenario !== undefined) {
								this._devices[deviceId].scenarios.selected = scenario;
							}
							this._devices[deviceId].selected = true;
						} else {
							this._devices[deviceId].selected = false;
						}
					}
				});
				self._save();
			};

			this.getDevices = function (modality) {
				if (modality === undefined) {
					return this._devices;
				}

				var devices = [];
				Object.keys(this._devices).forEach((deviceId) => {
					if (this._devices[deviceId].modality === modality) {
						devices.push(this._devices[deviceId]);
					}
				});
				return devices;
			};

			this.getAvailableSubmodalities = function () {
				var availability = {
					fingers: { scannerAvailable: false },
					faces: { cameraAvailable: false	},
					irises: { scannerAvailable: false },
					palms: { scannerAvailable: false },
					signature: { scannerAvailable: false },
					document: { scannerAvailable: false }
				};
				Object.keys(this._devices).forEach((device) => {
					var { modality } = this._devices[device];
					if (modality === 'Face') {
						availability.faces.cameraAvailable = true;
					}
					if (modality === 'Finger') {
						availability.fingers.scannerAvailable = true;
					}
					if (modality === 'Iris') {
						availability.irises.scannerAvailable = true;
					}
					if (modality === 'Palm') {
						availability.palms.scannerAvailable = true;
					}
					if (modality === 'Signature') {
						availability.signature.scannerAvailable = true;
					}
					if (modality === 'Document') {
						availability.document.scannerAvailable = true;
					}
				});
				return {
					devices: availability
				};
			};

			this.setDeviceCaptureFormat = function (deviceId, format) {
				if (this._devices[deviceId] && this._devices[deviceId].formats && this._devices[deviceId].formats.indexOf(format) !== 1) {
					this._devices[deviceId].selectedFormat = format;
				}
				this._save();
			};

			function identifyError(error) {
				switch (error) {
				case 'failure':
					return 'FAILURE';
				case 'invalidId':
					return 'INVALID_ID';
				case 'canceled':
					return 'CANCELED';
				case 'canceledWithSensorFailure':
					return 'CANCELED_SENSOR_FAILURE';
				case 'sensorFailure':
					return 'SENSOR_FAILURE';
				case 'initializationNeeded':
					return 'INITIALIZATION_NEEDED';
				case 'configurationNeeded':
					return 'CONFIGURATION_NEEDED';
				case 'sensorBusy':
					return 'BUSY';
				case 'sensorTimeout':
					return 'TIMEOUT';
				case 'unsupported':
					return 'UNSUPPORTED';
				case 'badValue':
					return 'BAD_VALUE';
				case 'noSuchParameter':
					return 'NO_SUCH_PARAMETER';
				case 'preparingDownload':
					return 'PREPARING_DOWNLOAD';
				case 'Canceled':
					return 'CANCELED';
				case 'None':
					return 'NONE';
				case 'SourceMissing: Source missing':
					return 'SOURCE_MISSING';
				case 'CleaningNeeded':
					return 'CLEANING_NEEDED';
				case 'ObjectsNotRemoved':
					return 'OBJECTS_NOT_REMOVED';
				case 'ObjectMissing':
					return 'OBJECT_MISSING';
				case 'ObjectNotFound':
					return 'OBJECT_NOT_FOUND';
				case 'SensorTimeout':
					return 'TIMEOUT';
				case 'TooFewObjects':
					return 'TOO_FEW_OBJECTS';
				case 'TooManyObjects':
					return 'TOO_MANY_OBJECTS';
				case 'BadObjectSequence':
					return 'BAD_OBJECT_SEQUENCE';
				case 'SpoofDetected':
					return 'SPOOF_DETECTED';
				case 'BadObject':
					return 'BAD_OBJECT';
				case 'BadDynamicRange':
					return 'BAD_DYNAMIC_RANGE';
				case 'BadExposure':
					return 'BAD_EXPOSURE';
				case 'BadSharpness':
					return 'BAD_SHARPNESS';
				case 'TooNoisy':
					return 'TOO_NOISY';
				case 'BadContrast':
					return 'BAD_CONTRAST';
				case 'BadLighting':
					return 'BAD_LIGHTING';
				case 'Occlusion':
					return 'OCCLUSION';
				case 'BadPose':
					return 'BAD_POSE';
				case 'TooFewFeatures':
					return 'TOO_FEW_FEATURES';
				case 'TooSoft':
					return 'TOO_SOFT';
				case 'TooHard':
					return 'TOO_HARD';
				case 'BadPosition':
					return 'BAD_POSITION';
				case 'TooNorth':
					return 'TOO_NORTH';
				case 'TooEast':
					return 'TOO_EAST';
				case 'TooSouth':
					return 'TOO_SOFT';
				case 'TooWest':
					return 'TOO_WEST';
				case 'TooClose':
					return 'TOO_CLOSE';
				case 'TooFar':
					return 'TOO_FAR';
				case 'BadSpeed':
					return 'BAD_SPEED';
				case 'TooSlow':
					return 'TOO_SLOW';
				case 'TooFast':
					return 'TOO_FAST';
				case 'BadSize':
					return 'BAD_SIZE';
				case 'TooShort':
					return 'TOO_SHORT';
				case 'TooLong':
					return 'TOO_LONG';
				case 'TooNarrow':
					return 'TOO_NARROW';
				case 'TooWide':
					return 'TOO_WIDE';
				case 'TooSkewed':
					return 'TOO_SKEWED';
				case 'WrongDirection':
					return 'WRONG_DIRECTION';
				case 'WrongHand':
					return 'WRONG_HAND';
				case 'TooFewSamples':
					return 'TOO_FEW_SAMPLES';
				case 'IncompatibleSamples':
					return 'INCOMPATIBLE_SAMPLES';
				case 'SourceNotFound':
					return 'SOURCE_NOT_FOUND';
				case 'IncompatibleSource':
					return 'INCOMPATIBLE_SOURCE';
				case 'IdNotFound':
					return 'ID_NOT_FOUND';
				case 'DuplicateId':
					return 'DUPLICATE_ID';
				case 'MatchNotFound':
					return 'MATCH_NOT_FOUND';
				case 'DuplicateFound':
					return 'DUPLICATE_FOUND';
				case 'Conflict':
					return 'CONFLICT';
				case 'InvalidOperations':
					return 'INVALID_OPERATIONS';
				case 'InvalidQuery':
					return 'INVALID_QUERY';
				case 'InvalidPropertyValue':
					return 'INVALID_PROPERTY_VALUE';
				case 'InvalidFieldValue':
					return 'INVALID_FIELD_VALUE';
				case 'InvalidSampleResolution':
					return 'INVALID_SAMPLE_RESOLUTION';
				case 'OperationNotSupported':
					return 'OPERATION_NOT_SUPPORTED';
				case 'OperationNotActivated':
					return 'OPERATION_NOT_ACTIVATED';
				case 'SourceError':
					return 'SOURCE_ERROR';
				case 'CommunicationError':
					return 'COMMUNICATION_ERROR';
				case 'InternalError':
					return 'INTERNAL_ERROR';
				case 'No document was found':
					return 'NO_DOCUMENT';
				case 'Failed to read document':
					return 'FAILED_TO_READ_DOCUMENT';
				default:
					return 'SOMETHING_WRONG';
				}
			}

			this.handleError = function (error, alert) {
				const status = identifyError(error.status);
				if (status === 'SOMETHING_WRONG') {
					switch (error.status) {
					case 'lockHeldByAnother':
						alert.show('capture-service.capture.alert.locked-by-another-session', { type: 'danger' });
						return;
					case 'lockNotHeld':
						alert.show('capture-service.capture.alert.lock-not-held', { type: 'danger' });
						return;
					default:
						if (alert) {
							alert.show('capture-service.capture.alert.error-status', { type: 'danger', translateValues: { errorStatus: error.status } });
							return;
						}
						return 'CAPTURE_ERROR';
					}
				}
				return status;
			};

			this.handleErrorMessage = function (error) {
				if (error.message.includes(': ')) {
					const errorMessage = error.message.split(': ');
					switch (errorMessage[1]) {
					case 'Source missing':
						return 'SOURCE_MISSING';
					case 'Generic error':
						return 'SOMETHING_WRONG';
					case 'Device not found':
						return 'DEVICE_NOT_FOUND';
					case 'One or more errors occurred':
						return 'SEVERAL_ERRORS';
					case 'USB driver error':
						return 'USB_DRIVER_ERROR';
					case 'Operation is not activated':
						return 'OPERATION_NOT_ACTIVATED';
					default:
						return '';
					}
				}
				return identifyError(error.message);
			};

			this.startModalityCapture = function (modality) {
				modalitiesStatus[modality] = true;
			};
			this.stopCapturingModality = function (modality) {
				modalitiesStatus[modality] = false;
			};

			this.isCaptureRunning = function (modality) {
				return modalitiesStatus[modality];
			};
		}]);
