import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('DeduplicationService', ['$q', '$timeout', 'TransactionsResource', 'AlertService', 'AuthDataHolder', 'AdjudicationCasesResource',
		function ($q, $timeout, TransactionsResource, AlertService, AuthDataHolder, AdjudicationCasesResource) {
			let loadingDisabled = false;
			let isLoading = false;
			let wasLoaded = false;

			let pagesLoaded = 0;
			const maxPagesToLoad = 10;
			const itemsPerPage = 30;
			let hasNextPage = true;

			let data = [];

			function prepareToGetStatistics(fetchResource, filterDataInternal, ...permissionsToCheck) {
				function getCountsPromises() {
					function getFilterToEachStatus() {
						return statuses.map(status => Object.assign({}, filterDataInternal, { status }));
					}

					function transformFiltersToCountPromise() {
						return countFilters.map(filter => fetchResource(filter).$promise);
					}

					function addTotalFilter() {
						return countFilters.unshift({});
					}

					const statuses = angular.copy(filterDataInternal.status);
					const countFilters = getFilterToEachStatus();
					addTotalFilter();
					return [statuses, transformFiltersToCountPromise()];
				}

				function fetchCounts() {
					const deferred = $q.defer();
					$q.all(countsPromises).then((response) => {
						const totalCount = response.shift();
						deferred.resolve({
							statuses,
							counts: statuses.reduce((acc, status, index) => Object.assign(acc, { [status]: response[index].count }), {}),
							filtered: response.reduce((acc, result) => (acc + result.count), 0),
							total: totalCount.count
						});
					});
					return deferred.promise;
				}

				function isFilteredByStatus() {
					return filterDataInternal.status
						&& filterDataInternal.status.length > 0;
				}

				if (!AuthDataHolder.hasAnyAuthority(...permissionsToCheck) || !isFilteredByStatus()) return null;

				const [statuses, countsPromises] = getCountsPromises();
				return () => {
					if (countsPromises) {
						return fetchCounts();
					}
				};
			}

			function setLoadingStates() {
				loadingDisabled = true;
				isLoading = true;
				wasLoaded = true;
			}

			function unsetLoadingStates() {
				$timeout(() => {
					loadingDisabled = !(hasNextPage && pagesLoaded < maxPagesToLoad);
					isLoading = false;
				});
			}

			function alertIfLastPage() {
				if (data.length && hasNextPage && pagesLoaded === maxPagesToLoad) {
					AlertService.show('transactions.limit-reached', { type: 'info' });
				}
			}

			function updatePaginationFlags(responseHeaders) {
				pagesLoaded += 1;
				hasNextPage = responseHeaders('X-Has-Next-Page') === 'true';
				alertIfLastPage();
				unsetLoadingStates();
			}

			this.fetchFromTransactions = function (filterDataInternal, sort) {
				function transactionFilterEnrollWithDuplicateCheckResource(filters) {
					return TransactionsResource.count(angular.extend(
						{ type: 'ENROLL_WITH_DUPLICATE_CHECK' },
						filters
					));
				}

				data = [];
				setLoadingStates();
				filterDataInternal.type = 'ENROLL_WITH_DUPLICATE_CHECK';

				const fetchCounts = prepareToGetStatistics(
					filters => transactionFilterEnrollWithDuplicateCheckResource(filters),
					filterDataInternal,
					'PERMISSION_TRANSACTION_COUNT'
				);

				let counts = null;
				const deferred = $q.defer();
				TransactionsResource.query(angular.extend({
					page: pagesLoaded,
					size: itemsPerPage,
					sort: sort.field,
					reverse: sort.reverse
				}, filterDataInternal), (results, responseHeaders) => {
					data = results;
					if (fetchCounts !== null) {
						fetchCounts()
							.then((countsData) => {
								counts = countsData;
								updatePaginationFlags(responseHeaders);
								deferred.resolve({
									data,
									counts
								});
							});
					} else {
						updatePaginationFlags(responseHeaders);
						deferred.resolve({
							data,
							counts
						});
					}
				});
				return deferred.promise;
			};

			this.fetchFromAdjudication = function (filterDataInternal, sort) {
				function transformRequestId(responseElement) {
					responseElement.requestId = responseElement.id;
					delete responseElement.id;
					return responseElement;
				}

				data = [];
				setLoadingStates();
				const deferred = $q.defer();
				AdjudicationCasesResource.query(angular.extend({
					page: pagesLoaded,
					size: itemsPerPage,
					sort: sort.field,
					reverse: sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					data = value.map(transformRequestId);
					updatePaginationFlags(responseHeaders);
					unsetLoadingStates();
					deferred.resolve({
						data
					});
				});
				return deferred.promise;
			};

			this.getCasesCount = function () {
				return AdjudicationCasesResource.getCount({}).$promise;
			};

			this.setIsLoading = function (newIsLoading) {
				isLoading = newIsLoading;
			};

			this.isLoading = function () {
				return isLoading;
			};

			this.isLoadingDisabled = function () {
				return loadingDisabled;
			};

			this.resetLoadingMoreState = function () {
				loadingDisabled = false;
				pagesLoaded = 0;
				hasNextPage = true;
				data = [];
			};

			this.wasLoaded = function () {
				return wasLoaded;
			};
		}]);
