import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.directive('scrollTop', () => ({
		template: require('../../views/directives/scroll-top.html'),
		restrict: 'E',
		replace: true,
		bindToController: true,
		scope: {
		},
		controller: ['$scope', '$interval', function ($scope, $interval) {
			var footerHeight = 50;
			var btnPadding = 10;
			var bottomHeight = footerHeight + btnPadding;

			var intervalPromise = $interval(() => {
				$scope.scrollY = window.scrollY;
				if (window.scrollY > 0) {
					var scrollHeight = $(document).height();
					var scrollPosition = $(window).height() + $(window).scrollTop();
					var leftToScroll = scrollHeight - scrollPosition;
					var scrollTopElement = document.getElementById('scroll-top');
					if ((leftToScroll - bottomHeight) / scrollHeight <= 0) {
						scrollTopElement.style.bottom = `${footerHeight - leftToScroll + btnPadding}px`;
					} else if (scrollTopElement.style.bottom !== `${btnPadding}px`) {
						scrollTopElement.style.bottom = `${btnPadding}px`;
					}
				}
			}, 300);

			$scope.$on('$destroy', () => {
				$interval.cancel(intervalPromise);
			});

			$scope.scrollToTop = function () {
				$scope.scrollY = 0;
				window.scrollTo(0, 0);
			};
		}]
	}));
