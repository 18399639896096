angular.module('neurotecAbisWebClientApp')
	.controller('TasksCtrl', ['$scope', '$filter', '$translate', 'FilterUtils', 'TasksService', 'SortHelper', 'DatetimePickerService',
		function ($scope, $filter, $translate, FilterUtils, TasksService, SortHelper, DatetimePickerService) {
			$scope.itemsPerPage = 10;
			$scope.totalItems = 0;
			$scope.currentPage = 1;
			$scope.tasks = [];

			var filterDataInternal = {};
			$scope.filterData = {};

			$scope.datetimePickerOptions = { opens: 'right' };
			DatetimePickerService.getDefaultOptions()
				.then((options) => {
					$scope.datetimePickerOptions = { ...options, ...$scope.datetimePickerOptions };
					$scope.datetimePickerOptions.locale.format = 'YYYY-MM-DD HH:mm:ss';
				});

			$scope.statuses = {};

			$translate([
				'admin.tasks.status.SUBMITTED', 'admin.tasks.status.RUNNING',
				'admin.tasks.status.FINISHED', 'admin.tasks.status.CANCELED',
				'admin.tasks.status.ERROR',
			]).then((translations) => {
				$scope.statuses = {
					/* eslint-disable dot-notation */
					SUBMITTED: translations['admin.tasks.status.SUBMITTED'],
					RUNNING: translations['admin.tasks.status.RUNNING'],
					FINISHED: translations['admin.tasks.status.FINISHED'],
					CANCELED: translations['admin.tasks.status.CANCELED'],
					ERROR: translations['admin.tasks.status.ERROR']
					/* eslint-enable dot-notation */
				};
			});

			$scope.taskColour = function (task, colour) {
				switch (task.status) {
				case 'FINISHED':
					return colour === 'success';
				case 'CANCELED':
					return colour === 'warning';
				case 'ERROR':
					return colour === 'danger';
				default:
					return colour === 'info';
				}
			};

			$scope.taskActive = function (task) {
				switch (task.status) {
				case 'RUNNING':
				case 'SUBMITTED':
					return true;
				default:
					return false;
				}
			};

			$scope.sort = SortHelper.create('id', true);

			$scope.pageChanged = function () {
				TasksService.query(angular.extend({
					page: $scope.currentPage - 1,
					size: $scope.itemsPerPage,
					sort: $scope.sort.field,
					reverse: $scope.sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					$scope.tasks = value;
					$scope.totalItems = responseHeaders('X-Total-Count');
				});
			};

			$scope.pageChanged();

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix);
			};

			function isFormValid() {
				return $scope.filterForm.$valid && $scope.dateFieldsValid('createdAt') && $scope.dateFieldsValid('endedAt');
			}

			function copyProcessedFilterData(to) {
				FilterUtils.filterBySingleField($scope.filterData, to, 'userId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'status');
				FilterUtils.filterByDateFields($scope.filterData, to, 'createdAt');
				FilterUtils.filterByDateFields($scope.filterData, to, 'endedAt');
				FilterUtils.filterBySingleField($scope.filterData, to, 'phrase');
			}

			$scope.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					$scope.currentPage = 1;
					$scope.pageChanged();
				}
			};

			$scope.resetFilter = function () {
				$scope.filterData = {};
				$scope.filterForm.$setPristine();
				filterDataInternal = {};
				$scope.currentPage = 1;
				$scope.pageChanged();
			};

			$scope.download = function (mediaType) {
				if (isFormValid()) {
					const filterData = {};
					copyProcessedFilterData(filterData);
					filterData.timeZone = moment.tz.guess();
					const getReport = mediaType === 'PDF' ? TasksService.getReportPdf : TasksService.getReportCsv;
					getReport(angular.extend({
						page: $scope.currentPage - 1,
						size: $scope.itemsPerPage,
						sort: $scope.sort.field,
						reverse: $scope.sort.reverse
					}, filterData), (value) => {
						FileSaver.saveAs(value.document, `tasks_report_${$filter('date')(new Date(), 'yyyyMMdd_HHmmss')}.${mediaType.toLowerCase()}`);
					});
				}
			};

			$scope.stopTask = function (id) {
				TasksService.stop({ id }, () => {
					$scope.pageChanged();
				});
			};

			$scope.sortBy = function (field) {
				$scope.sort.sort(field);
				$scope.currentPage = 1;
				$scope.pageChanged();
			};
		}]);
