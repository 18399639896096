import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('namingService', function () {
		function startsWith(str, prefix) {
			if (!(typeof str === 'string' || str instanceof String)) {
				return false;
			}
			if (typeof str.startsWith === 'function') {
				return str.startsWith(prefix);
			}
			return str.indexOf(prefix) === 0;
		}

		this.minutiaIndexToName = function (index) {
			return `minutia_${index}`;
		};
		this.minutiaNameToIndex = function (name) {
			return parseInt(name.substr(8), 10);
		};
		this.isMinutiaName = function (name) {
			return startsWith(name, 'minutia_');
		};

		this.coreIndexToName = function (index) {
			return `core_${index}`;
		};
		this.coreNameToIndex = function (name) {
			return parseInt(name.substr(5), 10);
		};
		this.isCoreName = function (name) {
			return startsWith(name, 'core_');
		};

		this.deltaIndexToName = function (index) {
			return `delta_${index}`;
		};
		this.deltaNameToIndex = function (name) {
			return parseInt(name.substr(6), 10);
		};
		this.isDeltaName = function (name) {
			return startsWith(name, 'delta_');
		};

		this.doubleCoreIndexToName = function (index) {
			return `doubleCore_${index}`;
		};
		this.doubleCoreNameToIndex = function (name) {
			return parseInt(name.substr(11), 10);
		};
		this.isDoubleCoreName = function (name) {
			return startsWith(name, 'doubleCore_');
		};

		this.getTreeName = function () {
			return 'tree';
		};
		this.isTreeName = function (name) {
			return name === 'tree';
		};
		this.upperSnakeToKebabCase = function (str) {
			return str.replace(/_| /g, '-').toLowerCase();
		};
	});
