import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('EncountersDecisionsMapper', ['AuthDataHolder', function (AuthDataHolder) {
		const mapper = {};

		function combinePastDecisionWithCurrent(assignedDecision, incomingDecision) {
			if (assignedDecision === 'UNDECIDED' || incomingDecision === 'UNDECIDED') {
				return 'UNDECIDED'; // case was marked as 'UNDECIDED' from at least one operator
			}

			return assignedDecision !== null // there was a decision in the past records of adj
				&& assignedDecision !== incomingDecision // decisions don't match resulting in a conflict
				? 'CONFLICT'
				: incomingDecision;
		}

		function getHitByEncounterId(encounterId, encounters) {
			return encounters.find(encounter => encounter.encounterId === encounterId);
		}

		mapper.copyEventHitsStatusesToDecision = function (encounters, decisions, hits) {
			for (let i = 0; i < hits.length; i += 1) {
				const hit = hits[i];
				let decisionIdx = decisions.findIndex(encounter =>
					encounter.hitId === hit.hitId);

				if (decisionIdx === -1) {
					decisionIdx = decisions.findIndex(encounter => getHitByEncounterId(hit.hitId, encounters).subjectId === getHitByEncounterId(encounter.hitId, encounters).subjectId);
				}

				decisions[decisionIdx].type = combinePastDecisionWithCurrent(decisions[decisionIdx].type, hit.type);
			}
		};

		function isEventCreatedByCurrentUser(id) {
			return id === AuthDataHolder.getUserInfo().id;
		}

		function mapDecisionsFromEncounters(encounters) {
			return encounters.map(encounter => ({ hitId: encounter.hitId, type: null }));
		}

		mapper.getUserDecisionTypes = function (encounters, encountersGroup, history) {
			const decisions = mapDecisionsFromEncounters(encountersGroup);
			history.forEach((event) => {
				if (isEventCreatedByCurrentUser(event.userId)) {
					mapper.copyEventHitsStatusesToDecision(encounters, decisions, event.hits);
				}
			});
			return decisions;
		};

		mapper.getCurrentTypes = function (encounters, encountersGroup, history) {
			const decisions = mapDecisionsFromEncounters(encountersGroup);
			history.forEach((event) => {
				mapper.copyEventHitsStatusesToDecision(encounters, decisions, event.hits);
			});
			return decisions;
		};

		return mapper;
	}]);
