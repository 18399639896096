import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('passwordResetCtrl', ['$scope', 'PasswordResouce',
		function ($scope, PasswordResouce) {
			function setSuccessMessage() {
				$scope.alert = {
					type: 'info',
					msg: 'password.messages.success'
				};
			}

			function setFailureMessage() {
				$scope.alert = {
					type: 'danger',
					msg: 'auth.service-unavailable'
				};
			}

			$scope.submit = function () {
				if ($scope.resetPasswordForm.$valid) {
					PasswordResouce.reset({ email: $scope.resetRequest.name }, {}, setSuccessMessage, (data) => {
						if (data.status === -1) {
							setFailureMessage();
						} else {
							setSuccessMessage();
						}
					});
				}
			};
		}]);
