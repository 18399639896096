import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('PalmScenarios', ['Utils',
		function (Utils) {
			const self = this;

			const DEFAULT_PALM_SCENARIO = 'fullPalms';
			const statuses = {
				ok: 'ok',
				capturing: 'capturing',
				queued: 'queued',
				skipped: 'skipped'
			};

			this.getDefaultScenario = function () {
				return angular.copy(DEFAULT_PALM_SCENARIO);
			};

			const scenarios = {
				lowerPalms: {
					queue: [
						[{ position: 'LEFT_LOWER_PALM', status: statuses.queued }],
						[{ position: 'RIGHT_LOWER_PALM', status: statuses.queued }]
					]
				},
				upperPalms: {
					queue: [
						[{ position: 'LEFT_UPPER_PALM', status: statuses.queued }],
						[{ position: 'RIGHT_UPPER_PALM', status: statuses.queued }]
					],
				},
				lowerAndUpperPalms: {
					queue: [
						[{ position: 'LEFT_LOWER_PALM', status: statuses.queued }],
						[{ position: 'RIGHT_LOWER_PALM', status: statuses.queued }],
						[{ position: 'LEFT_UPPER_PALM', status: statuses.queued }],
						[{ position: 'RIGHT_UPPER_PALM', status: statuses.queued }]
					],
				},
				fullPalms: {
					queue: [
						[{ position: 'RIGHT_FULL_PALM', status: statuses.queued }],
						[{ position: 'LEFT_FULL_PALM', status: statuses.queued }],
					],
				}
			};

			const options = Object.keys(scenarios);

			this.getScenarios = function () {
				return scenarios;
			};

			this.getOptions = function () {
				return options;
			};

			this.getCapturerOptionsByDeviceProperties = function (positions) {
				function addLowerAndUpperIfAvailable() {
					if (Utils.inArray('LEFT_UPPER_PALM', positions) && Utils.inArray('RIGHT_LOWER_PALM', positions)) {
						scenarios.options.push('lowerAndUpperPalms');
					}
				}

				function addUpperIfAvailable() {
					if (Utils.inArray('LEFT_UPPER_PALM', positions)) {
						scenarios.options.push('upperPalms');
					}
				}

				function addLowerIfAvailable() {
					if (Utils.inArray('RIGHT_LOWER_PALM', positions)) {
						scenarios.options.push('lowerPalms');
					}
				}

				function addFullIfAvailable() {
					if (Utils.inArray('RIGHT_FULL_PALM', positions)) {
						scenarios.options.push('fullPalms');
					}
				}


				const scenarios = {
					selected: null,
					options: []
				};
				addLowerAndUpperIfAvailable();
				addUpperIfAvailable();
				addLowerIfAvailable();
				addFullIfAvailable();
				if (scenarios.options.length > 0) {
					[scenarios.selected] = scenarios.options;
				}
				return scenarios;
			};

			this.recreateScenario = function (scenarioDto, capturedSteps) {
				for (var i = 0; i < scenarioDto.length; i += 1) {
					var emptyStep = scenarioDto[i];
					var mainEmptyScenarioPosition = emptyStep.position;
					for (var j = 0; j < capturedSteps.length; j += 1) {
						var capturedStep = capturedSteps[j];
						var mainCapturedScenarioPosition = capturedStep.position;
						if (mainEmptyScenarioPosition === mainCapturedScenarioPosition) {
							for (var m = 0; m < emptyStep.length; m += 1) {
								for (var n = 0; n < capturedStep.length; n += 1) {
									if (emptyStep[m].position === capturedStep[n].position) {
										emptyStep[m] = capturedStep[n];
									}
								}
							}
						}
					}
				}
				return scenarioDto;
			};

			this.getStep = function (scenario, position) {
				return scenario.find(step => step[0].position === position);
			};

			this.setStepStatus = function (scenario, position, status) {
				var step = self.getStep(scenario, position);
				for (var i = 0; i < step.length; i += 1) {
					var finger = step[i];
					finger.status = status;
				}
			};
		}]);
