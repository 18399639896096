import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('imageAdjustment', () => ({
		template: require('../../../views/directives/latent-editor/image-adjustment.html'),
		restrict: 'E',
		replace: true,
		scope: {
			adjustments: '='
		}
	}));
