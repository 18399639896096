import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('StatusService', ['$timeout', '$q', 'CapturerService', 'DeviceServerParametersService',
		function ($timeout, $q, CapturerService, DeviceServerParametersService) {
			let isRunning = false;
			let timer;
			let statuses = {
				service: 'STATUS_UNKNOWN',
				devices: {
					faces: {},
					fingers: {},
					irises: {},
					palms: {},
					signature: {},
					document: {}
				}
			};

			this.stop = function () {
				if (isRunning) {
					isRunning = false;
					$timeout.cancel(timer);
				}
			};

			this.initialize = function () {
				this.start();
			};

			this.start = function () {
				if (!isRunning) {
					isRunning = true;
					poll(this.refresh);
				}
			};

			this.getStatuses = function () {
				return statuses;
			};

			this.getServiceStatus = function () {
				return statuses.service;
			};

			this.getCameraStatus = function () {
				if (statuses.devices.faces.cameraAvailable) {
					return 'CAPTURING_READY';
				}
				return 'SOURCE_MISSING';
			};

			this.getIrisScannerStatus = function () {
				if (statuses.devices.irises.scannerAvailable) {
					return 'CAPTURING_READY';
				}
				return 'SOURCE_MISSING';
			};

			this.isServiceInitialized = function () {
				return statuses.service !== 'WAITING_FOR_INITIALIZATION';
			};

			this.isSingleIrisScannerAvailable = function () {
				if (statuses.devices.irises.singleIris) {
					return true;
				}
				return false;
			};

			this.isBothIrisesScannerAvailable = function () {
				if (statuses.devices.irises.bothIrises) {
					return true;
				}
				return false;
			};

			this.isSlapFingerScannerAvailable = function () {
				return statuses.devices.fingers.slap;
			};

			this.getFingerScannerStatus = function () {
				var scanner = statuses.devices.fingers.scannerAvailable;
				if (!scanner) {
					return 'SOURCE_MISSING';
				}
				return 'CAPTURING_READY';
			};

			this.getPalmScannerStatus = function () {
				var scanner = statuses.devices.palms.scannerAvailable;
				if (!scanner) {
					return 'SOURCE_MISSING';
				}
				return 'CAPTURING_READY';
			};

			this.getSignatureScannerStatus = function () {
				var scanner = statuses.devices.signature.scannerAvailable;
				if (!scanner) {
					return 'SOURCE_MISSING';
				}
				return 'CAPTURING_READY';
			};

			this.getDocumentScannerStatus = function () {
				const scanner = statuses.devices.document.scannerAvailable;
				if (!scanner) {
					return 'SOURCE_MISSING';
				}
				return 'CAPTURING_READY';
			};

			this.refresh = function () {
				const deferred = $q.defer();
				DeviceServerParametersService.fetchAndInit()
					.then(() => {
						CapturerService.refreshDevices().then(() => {
							statuses.service = 'STATUS_OK';
							var submodalities = CapturerService.getAvailableSubmodalities();
							statuses.devices.faces = submodalities.devices.faces;
							statuses.devices.fingers = submodalities.devices.fingers;
							statuses.devices.irises = submodalities.devices.irises;
							statuses.devices.palms = submodalities.devices.palms;
							statuses.devices.signature = submodalities.devices.signature;
							statuses.devices.document = submodalities.devices.document;

							deferred.resolve();
							return statuses;
						}, () => {
							statuses = {
								service: 'STATUS_ERROR',
								devices: {
									faces: {},
									fingers: {},
									irises: {},
									palms: {},
									signature: {},
									document: {}
								}
							};

							deferred.reject();
							return statuses;
						});
					})
					.catch((err) => {
						statuses = {
							service: err.status || 'STATUS_ERROR',
							devices: {
								faces: {},
								fingers: {},
								irises: {},
								palms: {},
								signature: {},
								document: {}
							}
						};

						deferred.reject(err);
						return statuses;
					});

				return deferred.promise;
			};

			function poll(call, method) {
				if (method == null) {
					method = 'finally';
				}
				(function refresh() {
					call()[method](() => {
						timer = $timeout(() => {
							refresh();
						}, 5000);
					});
				}());
			}
		}]);
