import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('PainterService', ['Utils', function (Utils) {
		const dangerColor = 'rgba(212, 63, 58, 1)';
		const successColor = 'rgba(52, 144, 52, 1)';
		const hoveredBiometricsTemplate = {
			irises: {
				probeId: [],
				hitId: []
			},
			fingers: {
				probeId: [],
				hitId: []
			},
			palms: {
				probeId: [],
				hitId: []
			}
		};
		let hoveredBiometrics = angular.copy(hoveredBiometricsTemplate);

		this.paintCapturingPositions = function (id, missingPositions, capturingPositions) {
			function paintElement(el, pos) {
				if (!missingPositions[pos] && capturingPositions.indexOf(pos) !== -1) {
					el.style.fill = successColor;
				} else if (missingPositions[pos]) {
					el.style.fill = dangerColor;
				} else {
					el.style.fill = 'rgba(0, 0, 0, 0)';
				}
			}

			Object.keys(missingPositions).forEach((pos) => {
				var positionName = Utils.underscoreToDash(pos);
				var els = document.getElementById(id).getElementsByClassName(positionName);
				Array.from(els).forEach((el) => {
					paintElement(el, pos);
				});
			});
		};

		this.paintMissingPositions = function (missingPositions, capturingPositions = []) {
			function paintElement(el, pos) {
				if (!missingPositions[pos] && capturingPositions.indexOf(pos) !== -1) {
					el.style.fill = successColor;
				} else if (missingPositions[pos]) {
					el.style.fill = dangerColor;
				} else {
					el.style.fill = 'rgba(0, 0, 0, 0)';
				}
			}

			Object.keys(missingPositions).forEach((pos) => {
				var positionName = Utils.underscoreToDash(pos);
				var els = document.getElementsByClassName(positionName);
				Array.from(els).forEach((el) => {
					paintElement(el, pos);
				});
			});
		};

		this.removeBorders = function (className) {
			angular.forEach(document.getElementsByClassName(className), (value) => {
				value.style.border = 'none';
			});
		};

		this.addBorder = function (className, isActive) {
			var color = dangerColor;
			if (isActive) {
				color = successColor;
			}
			var element = document.getElementById(className);
			if (element !== null) {
				element.style.border = `3px solid ${color}`;
			}
		};

		this.getStatusColor = function (status) {
			switch (status) {
			case 'CAPTURING_READY':
			case 'CAPTURING_READY_SCAN_DOCUMENT':
			case 'FINGER_CAPTURING_READY':
			case 'IRIS_CAPTURING_READY':
				return 'alert-info font-bold';
			case 'COMPLETED':
			case 'ABIS_REQUEST_COMPLETED':
			case 'PROCESS_NEXT_STEP':
				return 'alert-success font-bold';
			case 'STARTING':
			case 'IN_PROGRESS':
			case 'RETRYING':
			case 'CONTINUING':
				return 'alert-warning font-bold';
			default:
				// 'IN_PROGRESS' || status === 'ERROR'
				return 'alert-danger font-bold';
			}
		};

		this.shouldIrisHighlight = function (id, isLeftSide) {
			if (isLeftSide) {
				return hoveredBiometrics.irises.probeId.some(elem => elem === id);
			}
			return hoveredBiometrics.irises.hitId.some(elem => elem === id);
		};

		this.shouldFingerHighlight = function (id, isLeftSide) {
			if (isLeftSide) {
				return hoveredBiometrics.fingers.probeId.some(elem => elem === id);
			}
			return hoveredBiometrics.fingers.hitId.some(elem => elem === id);
		};

		this.shouldPalmHighlight = function (id, isLeftSide) {
			if (isLeftSide) {
				return hoveredBiometrics.palms.probeId.some(elem => elem === id);
			}
			return hoveredBiometrics.palms.hitId.some(elem => elem === id);
		};

		this.markIrisAsHovered = function (matchingDetails, biometricIndex) {
			matchingDetails.forEach((elem) => {
				hoveredBiometrics.irises.probeId.push(elem.probeIndex !== undefined ? elem.probeIndex : biometricIndex);
				hoveredBiometrics.irises.hitId.push(elem.hitIndex !== undefined ? elem.hitIndex : biometricIndex);
			});
		};

		this.markFingerAsHovered = function (matchingDetails, biometricIndex) {
			matchingDetails.forEach((elem) => {
				hoveredBiometrics.fingers.probeId.push(elem.probeIndex !== undefined ? elem.probeIndex : biometricIndex);
				hoveredBiometrics.fingers.hitId.push(elem.hitIndex !== undefined ? elem.hitIndex : biometricIndex);
			});
		};

		this.markPalmAsHovered = function (matchingDetails, biometricIndex) {
			matchingDetails.forEach((elem) => {
				hoveredBiometrics.palms.probeId.push(elem.probeIndex !== undefined ? elem.probeIndex : biometricIndex);
				hoveredBiometrics.palms.hitId.push(elem.hitIndex !== undefined ? elem.hitIndex : biometricIndex);
			});
		};

		this.unhoverBiometric = function () {
			hoveredBiometrics = angular.copy(hoveredBiometricsTemplate);
		};
	}]);
