import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('HelpModalCtrl', ['$scope', 'AbisService', '$uibModalStack', function ($scope, AbisService, $uibModalStack) {
		AbisService.getVersionInformation()
			.then((data) => {
				$scope.appInfo = data;
			})
			.catch((err) => {
				throw new Error(err);
			});

		$scope.onLinkClick = function () {
			$uibModalStack.dismissAll();
		};
	}]);
