import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.service('AbisService', ['$q', '$interval', '$rootScope', '$state', '$translate', 'AlertService', 'blockUI', 'NotificationsService', 'SettingsService', 'store', 'SubjectService', 'TransactionsResource', 'BiometricsService', 'ApplicationInfoResource', 'GalleriesService', 'AuthDataHolder', 'TransactionParams',
		function ($q, $interval, $rootScope, $state, $translate, AlertService, blockUI, NotificationsService, SettingsService, store, SubjectService, TransactionsResource, BiometricsService, ApplicationInfoResource, GalleriesService, AuthDataHolder, TransactionParams) {
			var self = this;
			var STORE_NAME = 'abisRequestData';
			var STORE_NAME_GALLERIES = {
				'actions.enroll': `enroll-${STORE_NAME}-galleries`,
				'actions.identify': `identify-${STORE_NAME}-galleries`,
				'actions.verify': `verify-${STORE_NAME}-galleries`,
				'actions.update': `update-${STORE_NAME}-galleries`,
			};
			var defaultPriority = 40;
			var abisRequestData;
			var abisRequestGalleries;
			var stateRequestTypeMap = { // Base types change on settings
				'actions.enroll': 'ENROLL_WITH_DUPLICATE_CHECK', // OR  ENROLL
				'actions.identify': 'IDENTIFY',
				'actions.verify': 'VERIFY',
				'actions.update': 'UPDATE' // OR VERIFY_UPDATE
			};

			function load() {
				abisRequestData = store.get(STORE_NAME);
				abisRequestGalleries = store.get(STORE_NAME_GALLERIES);
				if (!abisRequestData || !abisRequestGalleries) {
					abisRequestData = abisRequestData || {
						type: null,
						priority: defaultPriority,
					};
					abisRequestGalleries = abisRequestGalleries || {
						'actions.enroll': null,
						'actions.identify': null,
						'actions.verify': null,
						'actions.update': null,
					};
				} else {
					Object.entries(abisRequestGalleries).forEach(([actionName, gallery]) => {
						if (!AuthDataHolder.hasAnyGallery(gallery)) {
							abisRequestGalleries[actionName] = null;
						}
					});
				}
			}
			load();

			function save() {
				store.set(STORE_NAME, abisRequestData);
				store.set(STORE_NAME_GALLERIES, abisRequestGalleries);
			}

			function clear() {
				store.remove(STORE_NAME);
				store.remove(STORE_NAME_GALLERIES);
			}

			this.invalidate = function () {
				SubjectService.invalidate();
				BiometricsService.resetMissingFingers();
				BiometricsService.resetMissingIrises();
				clear();
				load();
			};

			function getGalleriesByActionName(actionName) {
				return abisRequestGalleries[actionName];
			}

			this.getRequest = function (actionName = null) {
				return {
					type: abisRequestData.type,
					priority: abisRequestData.priority,
					galleryId: actionName ? getGalleriesByActionName(actionName) : abisRequestGalleries
				};
			};

			this.getRequestTypeReadable = function () {
				this._updatestateRequestTypes();
				var result = null;
				Object.keys(stateRequestTypeMap).forEach((key) => {
					if (stateRequestTypeMap[key] === abisRequestData.type) {
						result = key;
					}
				});
				return result;
			};

			this._updatestateRequestTypes = function () {
				const useDuplicateCheck = SettingsService.getUseDuplicateCheck();
				const verifyDuringUpdate = SettingsService.getVerifyDuringUpdate();
				stateRequestTypeMap['actions.enroll'] = useDuplicateCheck ? 'ENROLL_WITH_DUPLICATE_CHECK' : 'ENROLL';
				stateRequestTypeMap['actions.update'] = verifyDuringUpdate ? 'VERIFY_UPDATE' : 'UPDATE';
			};

			this.getStateRequestTypeMap = function () {
				this._updatestateRequestTypes();
				return stateRequestTypeMap;
			};

			this.setRequestType = function (requestType) {
				if (requestType) {
					abisRequestData.type = requestType;
					save();
				}
			};

			this.setGalleryId = function (actionName, galleryId) {
				abisRequestGalleries[actionName] = galleryId || null;
				save();
			};

			this.getGalleryId = function (actionName) {
				return abisRequestGalleries[actionName];
			};

			this.setRequestTypeFromStateName = function (stateName) {
				this._updatestateRequestTypes();
				this.setRequestType(stateRequestTypeMap[stateName]);
			};

			this.setRequest = function (actionName, requestInfo) {
				abisRequestData.type = requestInfo.type;
				abisRequestData.priority = requestInfo.priority;
				abisRequestGalleries[actionName] = requestInfo.galleryId;
				save();
			};

			this.getPriority = function () {
				return abisRequestData.priority;
			};

			this.setPriority = function (priority) {
				abisRequestData.priority = priority;
				save();
			};

			this.getPriorityOptions = function () {
				return [
					{ value: 20, name: 'forms.request.priority.options.low' },
					{ value: 40, name: 'forms.request.priority.options.normal' },
					{ value: 60, name: 'forms.request.priority.options.high' },
					{ value: 80, name: 'forms.request.priority.options.urgent' }
				];
			};

			this.getDefaultPriority = function () {
				return defaultPriority;
			};

			this.getDto = function () {
				var data = {};
				angular.copy(abisRequestData, data);
				data.params = TransactionParams.getParams(data.type, true);
				data.subject = SubjectService.getDto(true);

				switch (abisRequestData.type) {
				case 'ENROLL':
				case 'ENROLL_WITH_DUPLICATE_CHECK':
					data.galleryId = abisRequestGalleries['actions.enroll'];
					break;
				case 'IDENTIFY':
					data.galleryId = abisRequestGalleries['actions.identify'];
					break;
				case 'VERIFY':
					delete data.subject.biographicData;
					data.subject.encounterId = null;
					data.galleryId = abisRequestGalleries['actions.verify'];
					break;
				case 'UPDATE':
					data.galleryId = abisRequestGalleries['actions.update'];
					data.subject.encounterId = null;
					break;
				case 'VERIFY_UPDATE':
					data.galleryId = abisRequestGalleries['actions.verify'];
					data.subject.encounterId = null;
					break;
				default:
					break;
				}

				return data;
			};

			this.postRequest = function (data) {
				function waitForResponse(transaction) {
					blockUI.message('transactions.waiting-for-response');
					let seconds;
					let blocking = false;
					const interval = $interval(() => {
						seconds = seconds + 1 || 0;
						if (seconds > 10) {
							if (!blocking) {
								blockUI.start({
									message: 'transactions.waiting-for-response',
									buttonMessage: 'transactions.skip-waiting',
									callback: () => {
										NotificationsService.add(transaction.requestId);
										blockUI.reset();
										blockUI.state().buttonMessage = '';
										blockUI.state().callback = () => { };
									}
								});
							}
							blocking = true;
						}
						TransactionsResource.get({ id: transaction.requestId }).$promise
							.then((transaction) => {
								const isFinal = $.inArray(transaction.status, pendingStatuses) === -1;
								if (isFinal) {
									deferred.resolve();
									$interval.cancel(interval);
									blockUI.state().buttonMessage = '';
									blockUI.state().callback = () => { };
									blockUI.reset();
									var scope = $rootScope.$new(true);
									scope.transaction = transaction;
									if ($state.params.previousState && $state.params.previousState.name === 'actions.scan') {
										$state.go('actions.scan', { transactionID: transaction.requestId, previousState: $state.current });
									} else {
										$state.go('actions.transaction', { transactionID: transaction.requestId, previousState: $state.params.previousState || $state.current });
									}
								}
							});
					}, 1000);
				}

				const deferred = $q.defer();
				const pendingStatuses = ['REGISTERED', 'IN_PROGRESS'];
				blockUI.start({
					message: 'transactions.processing'
				});
				TransactionsResource.post(
					data,
					(transaction) => {
						// if ($.inArray(self.getRequest().type, ['IDENTIFY', 'VERIFY']) > -1) {
						if ((self.getRequest().type === 'IDENTIFY' && SettingsService.getWaitForIdentifyResponse())
							|| (self.getRequest().type === 'VERIFY' && SettingsService.getWaitForVerifyResponse() && ($state.params.previousState && $state.params.previousState.name !== 'actions.scan'))
							|| $state.current.name === 'actions.scan') {
							self.invalidate();
							waitForResponse(transaction);
						} else {
							if ($state.params.previousState && $state.params.previousState.name === 'actions.scan') {
								waitForResponse(transaction);
								self.invalidate();
							} else {
								$state.go('actions.home')
									.then(() => {
										const tempType = self.getRequest().type;
										self.invalidate();
										self.setRequestType(tempType);
										AlertService.show('transactions.request-created');
										blockUI.reset();
									});
							}
							deferred.resolve();
							NotificationsService.add(transaction.requestId);
						}
					}, (error) => {
						$state.go($state.params.previousState && $state.params.previousState.name === 'actions.scan' ? 'actions.scan' : 'actions.home')
							.then(() => {
								self.invalidate();
								deferred.reject();
								blockUI.state().buttonMessage = '';
								blockUI.state().callback = () => { };
								blockUI.reset();
								$translate('transactions.request-failed').then((translation) => {
									AlertService.show(`${translation}: ${error.data.message}`, { type: 'danger', msTimeout: 6000, translate: false });
								});
							});
					}
				).$promise.finally(TransactionParams.invalidate);
				return deferred.promise;
			};

			this.getVersionInformation = function () {
				return ApplicationInfoResource.get().$promise;
			};
		}]);
