import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('StoreService', ['$rootScope', '$state', 'AbisService', 'SubjectService', 'store', 'SettingsService',
		function ($rootScope, $state, AbisService, SubjectService, store, SettingsService) {
			this.watch = function () {
				$rootScope.$watch(() => $state.current.name, (currentStateName, oldStateName) => {
					// Handle stores: enroll, identify, verify, update.
					const stateRequestTypeMap = AbisService.getStateRequestTypeMap();
					const isBiometricPage = Object.prototype.hasOwnProperty.call(stateRequestTypeMap, currentStateName);
					const isCapturePage = currentStateName.lastIndexOf('actions.capture', 0) === 0;
					const isLoginPage = currentStateName === 'login';

					if (oldStateName === '') {
						return;
					}
					if (isBiometricPage) {
						const isUpdatePage = stateRequestTypeMap[currentStateName] === 'UPDATE' || stateRequestTypeMap[currentStateName] === 'VERIFY_UPDATE';
						if (AbisService.getRequest().type !== stateRequestTypeMap[currentStateName]) {
							if (isUpdatePage) {
								if ($state.params.subject) {
									SubjectService.setBiographicData($state.params.subject.biographicData);
									SubjectService.setId($state.params.subject.subjectId);
								} else {
									$state.go('actions.home');
								}
							}
							AbisService.setRequestType(stateRequestTypeMap[currentStateName]);
						}
					} else if (!isCapturePage) {
						AbisService.invalidate();

						if (isLoginPage) {
							SettingsService.invalidate();
						}
					}

					if (!AbisService.getRequest().type && isCapturePage) {
						$state.go('actions.home');
					}

					// Handle subjects
					if (['', 'actions.search', 'actions.subject', 'actions.update'].indexOf(currentStateName) === -1) {
						store.remove(':subjects:filterData');
					}

					// Handle cases
					if (['', 'actions.adjudication.cases', 'actions.adjudication.case', 'actions.adjudication.caseHit'].indexOf(currentStateName) === -1) {
						store.remove(':cases:filterData');
						store.remove(':cases:sort');
					}
				});
			};
		}]);
