import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CasesCtrl', ['$scope', '$state', '$timeout', '$translate', 'AuthDataHolder', 'FilterUtils', 'AdjudicationCasesResource', 'SortHelper', 'store', 'ConvertionUtils', 'GalleriesService', 'MultiselectModule',
		function ($scope, $state, $timeout, $translate, AuthDataHolder, FilterUtils, AdjudicationCasesResource, SortHelper, store, ConvertionUtils, GalleriesService, MultiselectModule) {
			const SORT_KEY = ':cases:sort';
			$scope.sort = SortHelper.create('createdAt', true);
			$scope.cnv = ConvertionUtils;

			$scope.firstTimeLoading = true;
			let filterDataInternal = {};
			$scope.filterData = {};

			$scope.hasAnyAuthority = function (authority) {
				return AuthDataHolder.hasAnyAuthority(authority);
			};

			function loadMSModule() {
				$translate([
					'adjudication.case.requestId',
					'adjudication.case.status',
					'adjudication.case.hits',
					'adjudication.case.rank-one-id',
					'adjudication.case.rank-overall-score',
					'adjudication.case.rank-faces-score',
					'adjudication.case.rank-fingers-score',
					'adjudication.case.rank-irises-score',
					'adjudication.case.rank-palms-score',
					'adjudication.case.subject-id',
					'adjudication.case.gallery',
					'adjudication.case.created-at',
					'adjudication.case.started-at',
					'adjudication.case.completed-at',
					'adjudication.case.priority',
					'adjudication.column-visibility'
				]).then((translations) => {
					let msData = [
						{ id: 'requestId', label: translations['adjudication.case.requestId'] },
						{ id: 'status', label: translations['adjudication.case.status'] },
						{ id: 'hitsCount', label: translations['adjudication.case.hits'] },
						{ id: 'firstHitId', label: translations['adjudication.case.rank-one-id'] },
						{ id: 'firstHitOverallScore', label: translations['adjudication.case.rank-overall-score'] },
						{ id: 'firstHitFacesScore', label: translations['adjudication.case.rank-faces-score'] },
						{ id: 'firstHitFingersScore', label: translations['adjudication.case.rank-fingers-score'] },
						{ id: 'firstHitIrisesScore', label: translations['adjudication.case.rank-irises-score'] },
						{ id: 'firstHitPalmsScore', label: translations['adjudication.case.rank-palms-score'] },
						{ id: 'subjectId', label: translations['adjudication.case.subject-id'] },
						{ id: 'createdAt', label: translations['adjudication.case.created-at'] },
						{ id: 'startedAt', label: translations['adjudication.case.started-at'] },
						{ id: 'completedAt', label: translations['adjudication.case.completed-at'] },
						{ id: 'priority', label: translations['adjudication.case.priority'] }
					];
					if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
						msData = [...msData, { id: 'galleryId', label: translations['adjudication.case.gallery'] }];
					}

					const defaultModel = [
						{ id: 'status' },
						{ id: 'hitsCount' },
						{ id: 'firstHitOverallScore' },
						{ id: 'subjectId' },
						{ id: 'createdAt' },
						{ id: 'startedAt' },
						{ id: 'completedAt' },
						{ id: 'priority' }
					];
					const msCustomTexts = {
						buttonDefaultText: translations['adjudication.column-visibility']
					};

					$scope.msModule.moduleData = $scope.msModule.initialize(msData, defaultModel, msCustomTexts, 'cases:columnsToShow', {
						dynamicTitle: false,
						scrollableHeight: '300px',
						scrollable: true
					});
					$scope.isVisibleColumn = $scope.msModule.isVisibleColumn;
				});
			}

			$scope.msModule = MultiselectModule;
			$scope.multiSelectEventsHandler = {
				onSelectionChanged: $scope.msModule.eventsHandler.onSelectionChanged
			};

			function setUpFilters(translations) {
				const storeName = 'casesFilterName';
				const DEFAULT_FILTER_INDEX = 0;
				$scope.searchFilters = [
					{
						name: translations['cases.filter.in-progress'],
						checked: store.get(storeName) === 'in-progress',
						action() {
							store.set(storeName, 'in-progress');
							filterByStatus('ADJUDICATION_IN_PROGRESS');
						}
					},
					{
						name: translations['cases.filter.duplicate'],
						checked: store.get(storeName) === 'duplicate',
						action() {
							store.set(storeName, 'duplicate');
							filterByStatus('DUPLICATE_FOUND');
						}
					},
					{
						name: translations['cases.filter.unique'],
						checked: store.get(storeName) === 'unique',
						action() {
							store.set(storeName, 'unique');
							filterByStatus('OK');
						}
					},
				];

				$scope.firstTimeLoading = false;

				const checkedFilterIdx = $scope.searchFilters.findIndex(f => f.checked);
				if (checkedFilterIdx !== -1) {
					$scope.searchFilters[checkedFilterIdx].action();
				} else {
					$scope.searchFilters[DEFAULT_FILTER_INDEX].checked = true;
					$scope.searchFilters[DEFAULT_FILTER_INDEX].action();
				}
			}

			$scope.statuses = {};
			function loadStatuses() {
				$translate([
					'adjudication.case.status.adjudication-waiting', 'adjudication.case.status.adjudication-in-progress', 'adjudication.case.status.in-progress', 'adjudication.case.status.adjudication-conflict',
					'adjudication.case.status.duplicate', 'adjudication.case.status.unique'
				]).then((translations) => {
					$scope.statuses = {
					/* jshint sub:true */
						ADJUDICATION_WAITING: translations['adjudication.case.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['adjudication.case.status.adjudication-in-progress'],
						IN_PROGRESS: translations['adjudication.case.status.in-progress'],
						ADJUDICATION_CONFLICT: translations['adjudication.case.status.adjudication-conflict'],
						OK: translations['adjudication.case.status.unique'],
						DUPLICATE_FOUND: translations['adjudication.case.status.duplicate']
					/* jshint sub:false */
					};
				});
			}

			function init() {
				$translate([
					'cases.filter.duplicate',
					'cases.filter.unique',
					'cases.filter.in-progress'
				]).then((translations) => {
					setUpFilters(translations);
					loadStatuses();
					loadMSModule();
				});
			}
			init();

			$scope.loadingDisabled = false;
			$scope.wasLoaded = false;
			$scope.loading = false;
			$scope.pagesLoaded = 0;
			$scope.maxPagesToLoad = 10;
			$scope.itemsPerPage = 30;
			$scope.hasNextPage = true;
			$scope.casesCount = 0;
			$scope.cases = [];

			function getCasesCount() {
				AdjudicationCasesResource.getCount({}, (data) => {
					var translationString;
					if (data.count === 0) {
						translationString = 'cases.no-cases';
					} else if (data.count === 1) {
						translationString = 'cases.case-left';
					} else {
						translationString = 'cases.cases-left';
					}
					$scope.casesCount = data.count;
					$translate(translationString, { casesCount: data.count }).then((translation) => {
						$scope.casesLeftMessage = translation;
					});
				});
			}

			$scope.$on('$viewContentLoaded', () => {
				getCasesCount();
			});

			function filterByStatus(status) {
				filterDataInternal = { galleryId: GalleriesService.getDefaultGallery().id };
				$scope.filterData.status = status;
				FilterUtils.filterBySingleField($scope.filterData, filterDataInternal, 'status');
				$scope.resetLoaded();
			}

			$scope.resetLoaded = function () {
				if ($scope.loading) {
					return;
				}

				$scope.loadingDisabled = false;
				$scope.pagesLoaded = 0;
				$scope.cases = [];
				$scope.loadMore();
			};

			$scope.loadMore = function () {
				if ($scope.loadingDisabled) {
					return;
				}

				$scope.loadingDisabled = true;
				$scope.loading = true;
				$scope.wasLoaded = true;

				if (store.get(SORT_KEY)) {
					$scope.sort = SortHelper.create(store.get(SORT_KEY).field, true, store.get(SORT_KEY).reverse);
				}

				if (!AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					delete filterDataInternal.galleryId;
				}

				AdjudicationCasesResource.query(angular.extend({
					page: $scope.pagesLoaded,
					size: $scope.itemsPerPage,
					sort: $scope.sort.field,
					reverse: $scope.sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					Array.prototype.push.apply($scope.cases, value);

					$scope.pagesLoaded += 1;
					$scope.hasNextPage = responseHeaders('X-Has-Next-Page') === 'true';

					$timeout(() => {
						$scope.loadingDisabled = !($scope.hasNextPage && $scope.pagesLoaded < $scope.maxPagesToLoad);
					});

					$scope.loading = false;
				}, () => {
					Array.prototype.push.apply($scope.cases, []);
					$scope.hasNextPage = false;
					$scope.loading = false;
				});
			};

			$scope.updateSelection = function (position, searchFilters) {
				let newIndex;
				angular.forEach(searchFilters, (searchFilter, index) => {
					if (position !== index) {
						searchFilter.checked = false;
					} else {
						searchFilter.checked = true;
						newIndex = index;
					}
				});

				searchFilters[newIndex].action();
			};

			$scope.viewNextCase = function () {
				$state.go('actions.adjudication.case', { nextCase: true });
			};

			$scope.getCaseLinkPayload = function (selectedCase) {
				return { caseId: selectedCase.id };
			};

			$scope.sortBy = function (field) {
				if ($scope.loading) {
					return;
				}

				$scope.sort.sort(field);
				store.set(SORT_KEY, $scope.sort);
				$scope.resetLoaded();
			};
		}]);
