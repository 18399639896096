import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('SettingsService', ['$rootScope', 'store', 'AuthDataHolder',
		function ($rootScope, store, AuthDataHolder) {
			const storeName = ':settings';
			let settings = {};

			const SHOULD_BLUR_IMAGES_TEMPLATE = {
				face: false,
				iris: false,
				finger: false,
				palm: false,
				signature: false,
			};

			const SETTINGS_TEMPLATE = {
				storeSubjectData: '',
				capturerServicePort: '',
				capturerServiceDisplayStatus: '',
				capturerServiceAutoUpdate: '',
				capturerServiceCameraMirrorView: '',
				waitForIdentifyResponse: '',
				waitForVerifyResponse: '',
				shouldBlurImages: angular.copy(SHOULD_BLUR_IMAGES_TEMPLATE),
				useDuplicateCheck: '',
				verifyDuringUpdate: '',
				isCaptureOptionsVisible: {
					Finger: '',
					Iris: '',
					Palm: '',
				},
				preferredGallery: ''
			};

			function save() {
				store.set(storeName, settings);
			}

			function load() {
				var storeSettings = store.get(storeName) || {};
				settings.storeSubjectData = storeSettings.storeSubjectData === true;

				settings.capturerServicePort = storeSettings.capturerServicePort || 8001;
				settings.capturerServiceDisplayStatus = storeSettings.capturerServiceDisplayStatus === undefined ? false : storeSettings.capturerServiceDisplayStatus;
				settings.capturerServiceAutoUpdate = storeSettings.capturerServiceAutoUpdate === undefined ? false : storeSettings.capturerServiceAutoUpdate;
				settings.capturerServiceCameraMirrorView = storeSettings.capturerServiceCameraMirrorView === undefined ? true : storeSettings.capturerServiceCameraMirrorView;

				settings.waitForIdentifyResponse = storeSettings.waitForIdentifyResponse === undefined ? false : storeSettings.waitForIdentifyResponse;
				settings.waitForVerifyResponse = storeSettings.waitForVerifyResponse === undefined ? false : storeSettings.waitForVerifyResponse;
				settings.shouldBlurImages = storeSettings.shouldBlurImages === undefined ? angular.copy(SHOULD_BLUR_IMAGES_TEMPLATE) : storeSettings.shouldBlurImages;

				settings.useDuplicateCheck = storeSettings.useDuplicateCheck === undefined ? true : storeSettings.useDuplicateCheck;

				settings.verifyDuringUpdate = storeSettings.verifyDuringUpdate === undefined ? true : storeSettings.verifyDuringUpdate;
				settings.isCaptureOptionsVisible = {};
				settings.isCaptureOptionsVisible.Finger = !storeSettings.isCaptureOptionsVisible || storeSettings.isCaptureOptionsVisible.Finger === undefined ? true : storeSettings.isCaptureOptionsVisible.Finger;
				settings.isCaptureOptionsVisible.Iris = !storeSettings.isCaptureOptionsVisible || storeSettings.isCaptureOptionsVisible.Iris === undefined ? true : storeSettings.isCaptureOptionsVisible.Iris;
				settings.isCaptureOptionsVisible.Palm = !storeSettings.isCaptureOptionsVisible || storeSettings.isCaptureOptionsVisible.Palm === undefined ? true : storeSettings.isCaptureOptionsVisible.Palm;

				settings.preferredGallery = storeSettings.preferredGallery === undefined
					|| (!storeSettings.preferredGallery || !AuthDataHolder.hasGallery(storeSettings.preferredGallery.id)) ? null : storeSettings.preferredGallery;
			}
			load();

			this.isStoreSubjectData = function () {
				return settings.storeSubjectData;
			};

			this.setStoreSubjectData = function (value) {
				settings.storeSubjectData = value === true;
				save();
			};

			this.getCapturerServicePort = function () {
				return settings.capturerServicePort;
			};

			this.setCapturerServicePort = function (value) {
				function isValidPort(value) {
					function isInt(value) {
						return !isNaN(value) && parseInt(Number(value), 10) === value && !isNaN(parseInt(value, 10));
					}
					if (isInt(value) && value >= 0 && value <= 65535) {
						return true;
					}
					return false;
				}
				if (isValidPort(value)) {
					settings.capturerServicePort = value;
					save();
				}
			};

			this.getCapturerServiceDisplayStatus = function () {
				return settings.capturerServiceDisplayStatus;
			};

			this.setCapturerServiceDisplayStatus = function (value) {
				settings.capturerServiceDisplayStatus = value === true;
				save();
			};

			this.getCapturerServiceAutoUpdate = function () {
				return settings.capturerServiceAutoUpdate;
			};

			this.setCapturerServiceAutoUpdate = function (value) {
				settings.capturerServiceAutoUpdate = value === true;
				save();
			};

			this.getCapturerServiceCameraMirrorView = function () {
				return settings.capturerServiceCameraMirrorView;
			};

			this.setCapturerServiceCameraMirrorView = function (value) {
				settings.capturerServiceCameraMirrorView = value === true;
				save();
			};

			this.getWaitForIdentifyResponse = function () {
				return settings.waitForIdentifyResponse;
			};

			this.setWaitForIdentifyResponse = function (value) {
				settings.waitForIdentifyResponse = value === true;
				save();
			};

			this.getWaitForVerifyResponse = function () {
				return settings.waitForVerifyResponse;
			};

			this.setWaitForVerifyResponse = function (value) {
				settings.waitForVerifyResponse = value === true;
				save();
			};

			this.getShouldBlurImages = function () {
				return settings.shouldBlurImages;
			};

			this.setShouldBlurImages = function (value) {
				settings.shouldBlurImages = value;
				save();
			};

			this.getUseDuplicateCheck = function () {
				const hasEnroll = AuthDataHolder.hasAuthority('PERMISSION_BIOMETRIC_ENROLL');
				const hasEnrollWDC = AuthDataHolder.hasAuthority('PERMISSION_BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK');
				if (hasEnrollWDC) {
					if (hasEnroll) {
						return settings.useDuplicateCheck;
					}
					return true;
				}
				return false;
			};

			this.setUseDuplicateCheck = function (value) {
				settings.useDuplicateCheck = value === true;
				save();
			};

			this.getVerifyDuringUpdate = function () {
				return settings.verifyDuringUpdate;
			};

			this.setVerifyDuringUpdate = function (value) {
				settings.verifyDuringUpdate = value === true;
				save();
			};

			this.setCaptureOptionsVisibility = function (modality, value) {
				settings.isCaptureOptionsVisible[modality] = !!value;
				save();
			};

			this.isCaptureOptionsVisible = function (modality) {
				return settings.isCaptureOptionsVisible[modality];
			};

			this.getPreferredGallery = function () {
				return settings.preferredGallery;
			};

			this.setPreferredGallery = function (gallery) {
				settings.preferredGallery = gallery;
				$rootScope.$broadcast('preferredGallery:updated', gallery);
				save();
			};

			this.invalidate = function (deleteLocalStorage = false) {
				if (deleteLocalStorage) {
					store.remove(storeName);
				}
				settings = angular.copy(SETTINGS_TEMPLATE);
			};
		}]);
