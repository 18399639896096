import { Authority } from '../store/Auth/types';
import { useSelector } from 'react-redux';
import { AppState } from '../store/Store';

const useHasAnyAuthority = () => {
	const authorities = useSelector<AppState>((state) => state.auth.userInfo.authorities) as Authority[];

	const hasAnyAuthority = (requiredAuth: Authority[]) => {
		if (requiredAuth.length === 0) return true;
		for (let i = 0; i < requiredAuth.length; i++) {
			if (authorities.includes(requiredAuth[i])) {
				return true;
			}
		}
		return false;
	};

	return { hasAnyAuthority };
};

export default useHasAnyAuthority;
