import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('PasswordResouce', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/password/:action/:subaction`, null, {
			reset: {
				method: 'POST',
				params: {
					action: 'reset'
				}
			},
			confirm: {
				method: 'POST',
				params: {
					action: 'reset',
					subaction: 'confirm'
				}
			},
			change: {
				method: 'POST',
				params: {
					action: 'change'
				}
			}
		});
	}]);
