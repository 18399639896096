import angular from 'angular';
import * as $ from 'jquery';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.controller('ReportsCtrl', ['$scope', '$translate', '$timeout', 'FilterUtils', 'StatisticsResouce',
		function ($scope, $translate, $timeout, FilterUtils, StatisticsResouce) {
			$scope.adjudicationGroup = [];
			$scope.toggleTranslate = { graph: 'reports.graph', list: 'reports.list' };

			$scope.getObjectFromTabName = function (tabName) {
				return $scope.statistics[tabName.split('-')[1]];
			};
			$scope.opts = {
				activeTab: 'tab-subjects',
			};

			function enableToggleButtons() {
				$('#list-adjudication-toggle').bootstrapToggle();
			}
			$scope.$on('$viewContentLoaded', () => {
				$translate([
					'reports.frames.custom', 'reports.frames.day', 'reports.frames.week', 'reports.frames.month', 'reports.frames.year', 'reports.date', 'forms.from', 'forms.to',
					'reports.count', 'reports.score', 'reports.graph', 'reports.list', 'adjudication.case.status.undecided',
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll', 'transactions.type.identify', 'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update', 'transactions.type.delete',
					'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected',
					'transactions.status.registered', 'adjudication.case.status.in-progress',
					'adjudication.case.status.adjudication-waiting', 'adjudication.case.status.adjudication-in-progress', 'adjudication.case.status.adjudication-conflict',
					'transactions.status.ok', 'adjudication.case.status.duplicate', 'adjudication.case.status.unique',
				])
					.then((translations) => {
						$scope.toggleTranslate.graph = translations['reports.graph'];
						$scope.toggleTranslate.list = translations['reports.list'];
						$('#list-matching-toggle').bootstrapToggle({
							on: translations['reports.graph'],
							off: translations['reports.list']
						});

						$scope.types = {
							/* eslint-disable dot-notation */
							ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
							ENROLL: translations['transactions.type.enroll'],
							IDENTIFY: translations['transactions.type.identify'],
							VERIFY: translations['transactions.type.verify'],
							VERIFY_UPDATE: translations['transactions.type.verify-update'],
							UPDATE: translations['transactions.type.update'],
							DELETE: translations['transactions.type.delete'],
							/* eslint-enable dot-notation */
						};

						$scope.statuses = {
							/* eslint-disable dot-notation */
							REGISTERED: translations['transactions.status.registered'],
							IN_PROGRESS: translations['adjudication.case.status.in-progress'],
							ADJUDICATION_WAITING: translations['adjudication.case.status.adjudication-waiting'],
							ADJUDICATION_IN_PROGRESS: translations['adjudication.case.status.adjudication-in-progress'],
							ADJUDICATION_CONFLICT: translations['adjudication.case.status.adjudication-conflict'],
							REJECTED: translations['transactions.status.rejected'],
							OK: translations['transactions.status.ok'],
							MATCHED: translations['transactions.status.matched'],
							NOT_MATCHED: translations['transactions.status.not-matched'],
							DUPLICATE_FOUND: translations['adjudication.case.status.duplicate'],

							/* eslint-enable dot-notation */
						};

						$scope.decisions = {
							/* eslint-disable dot-notation */
							DUPLICATE: translations['adjudication.case.status.duplicate'],
							UNIQUE: translations['adjudication.case.status.unique'],
							UNDECIDED: translations['adjudication.case.status.undecided']
							/* eslint-enable dot-notation */
						};

						$scope.statusOptions = Object.keys($scope.statuses).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.statuses[current] });
							return acc;
						}, []);
						$scope.decisionsOptions = Object.keys($scope.decisions).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.decisions[current] });
							return acc;
						}, []);
						$scope.typesOptions = Object.keys($scope.types).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.types[current] });
							return acc;
						}, []);

						$scope.tableLabels = {
							score: translations['reports.score'],
							count: translations['reports.count']
						};

						$scope.translationTable = angular.extend({}, $scope.statuses, $scope.types, $scope.decisions);

						function addAxesLabels(options, labelStringY, labelStringX = '') {
							labelStringY = labelStringY ? $scope.tableLabels[labelStringY] : '';
							labelStringX = labelStringX ? $scope.tableLabels[labelStringX] : '';

							const r = angular.copy(options);
							return angular.merge(r, {
								scales: {
									yAxes: [{
										scaleLabel: {
											display: true,
											labelString: labelStringY
										}
									}],
									xAxes: [{
										scaleLabel: {
											display: true,
											labelString: labelStringX
										}
									}]
								}
							});
						}

						const baseOptions = {
							scales: {
								xAxes: [{
									ticks: {
										fontSize: 12,
										autoSkip: false,
									}
								}],
								yAxes: [{
									ticks: {
										beginAtZero: true,
										callback: (value) => {
											if (Number(value) >= 1000) {
												return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
											} else if (Number(value) > 1) {
												return `${value}`;
											}
											return `${value.toFixed(2)}`;
										}
									}
								}]
							},
							tooltips: {
								callbacks: {
									label: (item) => {
										const dataPoint = item.yLabel;
										return `${dataPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
									}
								}
							}
						};

						const termsOptions = {
							scales: {
								yAxes: baseOptions.scales.yAxes
							},
							tooltips: baseOptions.tooltips
						};

						$scope.timeFrames = [
							{
								name: translations['reports.frames.custom'],
								checked: true,
								action() {
									updateSelection(0);

									filterDataInternal = {};
									$scope.filterData = angular.copy($scope.statistic.filters);
									if ($scope.statistic.filterOptions) {
										[$scope.filterData.dateStep.type] = $scope.statistic.filterOptions.dateSteps;
									}

									copyProcessedFilterData(filterDataInternal);
									$scope.statistic.actionFunc();
								}
							},
							{
								name: translations['reports.frames.day'],
								checked: false,
								action() { updateSelection(1); filterInterval('days'); }
							},
							{
								name: translations['reports.frames.week'],
								checked: false,
								action() { updateSelection(2); filterInterval('weeks'); }
							},
							{
								name: translations['reports.frames.month'],
								checked: false,
								action() { updateSelection(3); filterInterval('months'); }
							},
							{
								name: translations['reports.frames.year'],
								checked: false,
								action() { updateSelection(4); filterInterval('years'); }
							}
						];

						$scope.statistics = {
							adjudication: {
								cases: {
									title: 'adjudication-cases',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										rangeFrom: 50,
										rangeTo: 200,
										step: 10,
										statuses: []
									},
									filterOptions: {
										dateSteps: ['days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									options: addAxesLabels(baseOptions, 'count'),
									agregation: [{
										elasticField: 'status',
										order: 1,
										bucketCount: 100
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { notImplementedLoadFunction.call(this, internalFilter); }
								},
								decisions: {
									title: 'adjudication-decisions',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										rangeFrom: 50,
										rangeTo: 200,
										step: 10,
										decisions: [],
										users: []
									},
									filterOptions: {
										dateSteps: ['days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									options: addAxesLabels(baseOptions, 'count'),
									agregation: [{
										elasticField: 'status',
										order: 1,
										bucketCount: 100
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { notImplementedLoadFunction.call(this, internalFilter); }
								}
							},
							biometrics: {
								operations: {
									title: 'hit-distribution',
									options: addAxesLabels(termsOptions, 'count', 'score'),
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										types: []
									},
									filterOptions: {
										dateSteps: ['days', 'weeks', 'months', 'years']
									},
									type: 'typeAggregation',
									agregation: [{
										elasticField: 'matchingResultCount',
										order: 1,
										ranges: (() => {
											const result = [];
											for (let i = 50; i < 200; i += 10) {
												result.push({ from: i, to: i + 10 });
											}
											return result;
										})()
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { notImplementedLoadFunction.call(this, internalFilter); }
								}
							},
							subjects: {
								eligible: {
									title: 'subjects-eligible',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										requestId: null,
										subjectId: null
									},
									filterOptions: {
										dateSteps: ['days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									options: addAxesLabels(baseOptions, 'count'),
									agregation: [{
										elasticField: 'status',
										order: 1,
										bucketCount: 100
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { notImplementedLoadFunction.call(this, internalFilter); }
								},
								duplicate: {
									title: 'subjects-duplicates',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										probeRequestId: null,
										probeSubjectId: null,
										hitRequestId: null,
										hitSubjectId: null,
										fusedScore: null,
										fingerprintsScore: null,
										faceScore: null,
										irisScore: null,
										palmsScore: null,
										adjudicated: false,
									},
									filterOptions: {
										dateSteps: ['days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									options: addAxesLabels(baseOptions, 'count'),
									agregation: [{
										elasticField: 'status',
										order: 1,
										bucketCount: 100
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { notImplementedLoadFunction.call(this, internalFilter); }
								}
							},
							matching: {
								hits: {
									title: 'hit-distribution',
									options: addAxesLabels(termsOptions, 'count', 'score'),
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										adjudicated: null,
										rangeFrom: 50,
										rangeTo: 200,
										step: 10,
									},
									type: 'rangeAggregations',
									agregation: [{
										elasticField: 'matchingResultCount',
										order: 1,
										ranges: (() => {
											const result = [];
											for (let i = 50; i < 200; i += 10) {
												result.push({ from: i, to: i + 10 });
											}
											return result;
										})()
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { loadAggregation.call(this, internalFilter); }
								},
								score: {
									title: 'matching-score',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										adjudicated: null,
										rangeFrom: 50,
										rangeTo: 200,
										step: 10,
									},
									type: 'rangeAggregations',
									options: addAxesLabels(termsOptions, 'count', 'score'),
									agregation: [{
										elasticField: 'matchingResults.score',
										order: 1
									}],
									// eslint-disable-next-line object-shorthand
									actionFunc: function (internalFilter) { loadAggregation.call(this, internalFilter); }
								},
							}
						};

						$scope.onTabChange($scope.opts.activeTab);
					})
					.finally(enableToggleButtons);
			});

			$scope.onValueChange = function () {
				updateSelection(0);
			};

			function updateSelection(position) {
				$scope.timeFrames.forEach((frame, index) => {
					frame.checked = index === position;
				});
			}

			$scope.onTabChange = function (newTabName) {
				updateSelection(0);
				$scope.opts.activeTab = newTabName;
				$scope.statistic = $scope.getObjectFromTabName(newTabName);
				$scope.statistic = $scope.statistic[Object.keys($scope.statistic)[0]];
				$scope.filterData = angular.copy($scope.statistic.filters);
				$scope.statistic.actionFunc();
			};

			let filterDataInternal = {};
			$scope.filterData = {};

			function copyProcessedFilterData(to) {
				FilterUtils.filterByDateFields($scope.filterData, to, 'createdAt');
				FilterUtils.filterBySingleField($scope.filterData, to, 'adjudicated');
				FilterUtils.filterBySingleField($scope.filterData, to, 'step');
				FilterUtils.filterBySingleField($scope.filterData, to, 'rangeFrom');
				FilterUtils.filterBySingleField($scope.filterData, to, 'rangeTo');
			}

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix);
			};

			function filterInterval(timeString) {
				filterDataInternal = {};
				$scope.filterData = angular.copy($scope.statistic.filters);
				if ($scope.statistic.filterOptions) {
					[$scope.filterData.dateStep.type] = $scope.statistic.filterOptions.dateSteps;
				}

				$scope.filterData.createdAtLower = moment().add(-1, timeString);
				$scope.filterData.createdAtUpper = moment();

				copyProcessedFilterData(filterDataInternal);
				$scope.statistic.actionFunc();
			}

			$scope.filter = function () {
				filterDataInternal = {};
				copyProcessedFilterData(filterDataInternal);
				$scope.statistic.actionFunc();
			};

			$scope.resetFilter = function () {
				$scope.filterData = angular.copy($scope.statistic.filters);

				if ($scope.filterForm) {
					$scope.filterForm.$setPristine();
				}
				filterDataInternal = {};
				$scope.statistic.actionFunc();
			};

			function calculateRanges() {
				const result = [];
				for (let i = $scope.filterData.rangeFrom; i < $scope.filterData.rangeTo; i += $scope.filterData.step) {
					// FIXME: Steps validation
					result.push({ from: i, to: i + $scope.filterData.step });
				}
				return result;
			}

			function parseData(obj, data) {
				// Word grouping by two must be done becouse of how char.js handles new lines
				function splitByTwoWords(str) {
					const split = str.split(' ');
					if (split.length < 3) return str;
					const result = Array(Math.floor(split.length / 3 + 1)).fill('');
					for (let i = 0; i < split.length; i += 1) {
						result[Math.floor(i / 2)] += ` ${split[i]}`;
					}
					return result;
				}
				// Handle non translated values eg. numbers, unknown strings.
				function handleDefault(str) {
					str = str.replace(/\.0/g, '').replace(/-/g, ' - ').replace(/_/g, ' ');
					return str;
				}

				data.buckets = data.buckets ? data.buckets : {};
				obj.data = Object.values(data.buckets).map(bucket => bucket.count);
				obj.labels = Object.keys(data.buckets).map(label => ($scope.translationTable[label] ? splitByTwoWords($scope.translationTable[label]) : handleDefault(label)));
				obj.count = data.count;
			}

			function notImplementedLoadFunction() {
				this.data = [];
			}

			function loadAggregation(internalFilter) {
				const context = this;

				const body = {};
				body[context.type] = context.agregation;
				body[context.type][0].ranges = calculateRanges();

				internalFilter = internalFilter || filterDataInternal;
				const finalFilter = angular.copy(context.filters);
				if (internalFilter) {
					Object.keys(internalFilter).forEach((k) => { finalFilter[k] = internalFilter[k]; });
				}
				// Turn moment.js to strings
				Object.keys(finalFilter).forEach((key) => { if (moment.isMoment(finalFilter[key])) { finalFilter[key] = finalFilter[key].format(); } });

				context.loading = false;
				context.error = null;
				context.to = $timeout(() => {
					context.loading = true;
				}, 50);

				return StatisticsResouce.agregate(finalFilter, body, result => parseData(context, result)).$promise
					.catch(() => {
						context.error = 'reports.error';
						context.data = null;
					})
					.finally(() => {
						if (!$timeout.cancel(context.to)) {
							context.loading = false;
						}
					});
			}
		}]);
