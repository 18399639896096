import { NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type AlignSelfProperty = 'alignSelf';
type AlignSelf = `${AlignSelfProperty}${NLayoutViewPorts}`;
export type AlignSelfValues = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
export type AlignSelfProps = { [Property in AlignSelf]: AlignSelfValues } & {
	alignSelf: AlignSelfValues;
};

const getAlignSelfClasses = (suffix?: NLayoutViewPorts): Array<[string, AlignSelfValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-align-self-${suffix.toLowerCase()}-start`, 'flex-start'],
			[`fs-align-self-${suffix.toLowerCase()}-end`, 'flex-end'],
			[`fs-align-self-${suffix.toLowerCase()}-center`, 'center'],
			[`fs-align-self-${suffix.toLowerCase()}-baseline`, 'baseline'],
			[`fs-align-self-${suffix.toLowerCase()}-stretch`, 'stretch'],
		];
	}
	return [
		[`fs-align-self-start`, 'flex-start'],
		[`fs-align-self-end`, 'flex-end'],
		[`fs-align-self-center`, 'center'],
		[`fs-align-self-baseline`, 'baseline'],
		[`fs-align-self-stretch`, 'stretch'],
	];
};

const useAlignSelfClasses = (props: Partial<AlignSelfProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'alignSelf', getAlignSelfClasses),
	});
	return [containerClass, classes];
};

export default useAlignSelfClasses;
