import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('FingerScenarios', ['BiometricsService', 'Utils', 'FingerIdentifier',
		function (BiometricsService, Utils, FingerIdentifier) {
			const self = this;

			const DEFAULT_FINGER_SCENARIO = 'allPlainFingers';
			const statuses = {
				ok: 'ok',
				capturing: 'capturing',
				queued: 'queued',
				skipped: 'skipped'
			};

			this.getDefaultScenario = function () {
				return angular.copy(DEFAULT_FINGER_SCENARIO);
			};

			const identifier = FingerIdentifier.start();
			const scenarios = {
				fourFourTwo: {
					queue: [
						[
							{ position: 'PLAIN_LEFT_FOUR_FINGERS', status: statuses.queued, id: identifier.getIdByPosition('PLAIN_LEFT_FOUR_FINGERS') },
							{ position: 'LEFT_LITTLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_LITTLE_FINGER') },
							{ position: 'LEFT_RING_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_RING_FINGER') },
							{ position: 'LEFT_MIDDLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_MIDDLE_FINGER') },
							{ position: 'LEFT_INDEX_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_INDEX_FINGER') }
						],
						[
							{ position: 'PLAIN_RIGHT_FOUR_FINGERS', status: statuses.queued, id: identifier.getIdByPosition('PLAIN_RIGHT_FOUR_FINGERS') },
							{ position: 'RIGHT_LITTLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_LITTLE_FINGER') },
							{ position: 'RIGHT_RING_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_RING_FINGER') },
							{ position: 'RIGHT_MIDDLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_MIDDLE_FINGER') },
							{ position: 'RIGHT_INDEX_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_INDEX_FINGER') }
						],
						[
							{ position: 'PLAIN_THUMBS', status: statuses.queued, id: identifier.getIdByPosition('PLAIN_THUMBS') },
							{ position: 'LEFT_THUMB', status: statuses.queued, id: identifier.getIdByPosition('LEFT_THUMB') },
							{ position: 'RIGHT_THUMB', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_THUMB') }
						]
					],
					config: {},
					settings: {
						visibleByDefault: true
					}
				},
				allPlainFingers: {
					queue: [
						[{ position: 'LEFT_LITTLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_LITTLE_FINGER') }],
						[{ position: 'LEFT_RING_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_RING_FINGER') }],
						[{ position: 'LEFT_MIDDLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_MIDDLE_FINGER') }],
						[{ position: 'LEFT_INDEX_FINGER', status: statuses.queued, id: identifier.getIdByPosition('LEFT_INDEX_FINGER') }],
						[{ position: 'LEFT_THUMB', status: statuses.queued, id: identifier.getIdByPosition('LEFT_THUMB') }],
						[{ position: 'RIGHT_THUMB', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_THUMB') }],
						[{ position: 'RIGHT_INDEX_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_INDEX_FINGER') }],
						[{ position: 'RIGHT_MIDDLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_MIDDLE_FINGER') }],
						[{ position: 'RIGHT_RING_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_RING_FINGER') }],
						[{ position: 'RIGHT_LITTLE_FINGER', status: statuses.queued, id: identifier.getIdByPosition('RIGHT_LITTLE_FINGER') }]
					],
					config: {},
					settings: {
						visibleByDefault: true
					}
				},
				unknownPlainFingers: {
					queue: [
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }],
						[{ position: 'UNKNOWN', status: statuses.queued, id: identifier.getIdByPosition('UNKNOWN') }]
					],
					config: {},
					settings: {
						visibleByDefault: false
					}
				}
			};

			const options = Object.keys(scenarios);

			this.getScenarios = function () {
				return scenarios;
			};

			this.getOptions = function () {
				return options;
			};

			this.getCapturerOptionsByDeviceProperties = function (positions) {
				function addFourFourTwoIfAvailable() {
					if (Utils.allInArray(BiometricsService.getSlapsPositions(), positions)) {
						options.add('fourFourTwo');
					}
				}

				function addAllPlainIfAvailable() {
					if (Utils.inArray('UNKNOWN', positions)) {
						options.add('unknownPlainFingers');
						options.add('allPlainFingers');
					}
					if (Utils.allInArray(BiometricsService.getAllPlainFingersPositions(), positions)) {
						options.add('allPlainFingers');
					}
				}

				function setDefaultScenario() {
					const scenarioQueue = ['fourFourTwo', 'allPlainFingers', 'unknownPlainFingers'];
					scenarios.selected = scenarioQueue.find(scenario => options.has(scenario));
				}


				const scenarios = {
					selected: null,
					options: []
				};
				const options = new Set();
				addFourFourTwoIfAvailable();
				addAllPlainIfAvailable();
				scenarios.options = [...options];
				setDefaultScenario();
				return scenarios;
			};

			this.getStep = function (scenario, fingerId) {
				return scenario.find(step => step[0].id === fingerId);
			};

			this.setStepStatus = function (scenario, fingerId, status) {
				var step = self.getStep(scenario, fingerId);
				for (var i = 0; i < step.length; i += 1) {
					var finger = step[i];
					finger.status = status;
				}
			};
		}]);
