import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.service('FilterUtils', function () {
		function process(value, transform) {
			if (value == null) {
				return;
			}
			if (typeof value === 'string' && !value) {
				return;
			}
			if (typeof transform === 'function') {
				return transform(value);
			}
			return value;
		}

		function dateTransform(isDateTime) {
			return function (obj) {
				if (obj == null) {
					throw new Error('Got null');
				}
				if (obj instanceof Date || moment.isMoment(obj)) {
					if (isDateTime) {
						return moment(obj).format('YYYY-MM-DDTHH:mm:ss');
					}
					return obj.format('YYYY-MM-DD');
				}
				throw new Error('Unknown date type');
			};
		}

		function dateIsoTransform(obj) {
			if (obj == null) {
				throw new Error('Got null');
			}
			if (obj instanceof Date || moment.isMoment(obj)) {
				return obj.toISOString();
			}
			throw new Error('Unknown date type');
		}

		this.filterBySingleField = function (rawFilterData, processedFilterData, field, transform) {
			var processed = process(rawFilterData[field], transform);
			if (processed != null) {
				processedFilterData[field] = processed;
			}
		};

		this.filterByDateFields = function (rawFilterData, processedFilterData, prefix) {
			this.filterBySingleField(rawFilterData, processedFilterData, `${prefix}Upper`, dateIsoTransform);
			this.filterBySingleField(rawFilterData, processedFilterData, `${prefix}Lower`, dateIsoTransform);
		};

		function transformDates(data, prefix) {
			return {
				[`${prefix}Lower`]: data[prefix].range[0].clone(),
				[`${prefix}Upper`]: data[prefix].range[1].clone()
			};
		}

		function isDatesNullOrEmpty(dateFilter) {
			return !dateFilter
				|| !dateFilter.range
				|| (dateFilter && dateFilter.range && dateFilter.range.some(date => date === null));
		}

		this.filterByDateRanges = function (rawFilterData, processedFilterData, prefix) {
			if (isDatesNullOrEmpty(rawFilterData[prefix])) return;
			const transformedDates = transformDates(rawFilterData, prefix);
			this.filterBySingleField(transformedDates, processedFilterData, `${prefix}Lower`, dateIsoTransform);
			this.filterBySingleField(transformedDates, processedFilterData, `${prefix}Upper`, dateIsoTransform);
		};

		this.filterByDateFieldsEx = function (rawFilterData, processedFilterData, prefix, isDateTime) {
			var upperField = `${prefix}Upper`;
			var lowerField = `${prefix}Lower`;

			if (!isDateTime && rawFilterData[lowerField]) {
				rawFilterData[lowerField].set({
					hour: 0,
					minute: 0,
					second: 0,
					millisecond: 0
				});
			}

			if (!isDateTime && rawFilterData[upperField]) {
				rawFilterData[upperField].set({
					hour: 23,
					minute: 59,
					second: 59,
					millisecond: 999
				});
			}

			var upper = process(rawFilterData[upperField], dateTransform(isDateTime));
			var lower = process(rawFilterData[lowerField], dateTransform(isDateTime));
			if (upper != null || lower != null) {
				if (upper == null) {
					upper = '*';
				}
				if (lower == null) {
					lower = '*';
				}
				processedFilterData[prefix] = `[${lower} TO ${upper}]`;
			}
		};

		function validateDate(lowerDate, upperDate, isDateTime) {
			var upperMillis = null;
			var lowerMillis = null;

			var transform = dateTransform(isDateTime);

			try {
				upperMillis = transform(upperDate);
			} catch (err) {
				// ignore
			}
			try {
				lowerMillis = transform(lowerDate);
			} catch (err) {
				// ignore
			}

			if (upperMillis != null && lowerMillis != null) {
				if (lowerMillis >= upperMillis) {
					return false;
				}
			}

			return true;
		}

		this.dateFieldsValid = function (rawFilterData, prefix, isDateTime = false) {
			var upperDate = rawFilterData[`${prefix}Upper`];
			var lowerDate = rawFilterData[`${prefix}Lower`];
			return validateDate(lowerDate, upperDate, isDateTime);
		};

		this.dateRangeIsValid = function (rawFilterData, prefix, isDateTime = false) {
			if (rawFilterData[prefix] && rawFilterData[prefix].range) {
				const [lowerDate, upperDate] = rawFilterData[prefix].range;
				return validateDate(lowerDate, upperDate, isDateTime);
			}
			return true;
		};

		this.rangeFieldValid = function (rawFilterData, prefix) {
			const from = `${prefix}From`;
			const to = `${prefix}To`;

			return (typeof rawFilterData[from] === 'number')
				&& (typeof rawFilterData[to] === 'number')
				&& rawFilterData[from] < rawFilterData[to];
		};
		this.stepFieldValid = function (filterStep) {
			return (typeof filterStep === 'number')
				&& (filterStep > 0);
		};
	});
