import angular from 'angular';
import BitSet from 'bitset.js';

angular.module('neurotecAbisWebClientApp')
	.service('matchingTreeService', function () {
		this.calculateMST = function (matedMinutiae, pairIndexName, minutiae) {
			function calcDistance(index1, index2) {
				var minutia1 = minutiae[matedMinutiae[index1][pairIndexName]];
				var minutia2 = minutiae[matedMinutiae[index2][pairIndexName]];

				var dx = minutia1.x - minutia2.x;
				var dy = minutia1.y - minutia2.y;

				return dx * dx + dy * dy;
			}

			function getAllEdges() {
				var edges = [];
				for (var i = 0; i < matedMinutiae.length; i += 1) {
					for (var j = i + 1; j < matedMinutiae.length; j += 1) {
						edges.push({
							from: i,
							to: j,
							weight: calcDistance(i, j)
						});
					}
				}
				return edges;
			}

			function calculate(edges) {
				var edgesMST = [];

				if (edges.length === 1) {
					edgesMST.push(edges[0]);
				} else {
					var i;
					var edge;
					var c;

					var max = 0;
					for (i = 0; i < edges.length; i += 1) {
						edge = edges[i];
						c = Math.max(edge.from, edge.to);
						if (c > max) {
							max = c;
						}
					}

					var min = max;
					for (i = 0; i < edges.length; i += 1) {
						edge = edges[i];
						c = Math.min(edge.from, edge.to);
						if (c < min) {
							min = c;
						}
					}

					edges.sort((a, b) => a.weight - b.weight);

					var vnew = new BitSet();
					vnew = vnew.set(min);
					for (var j = 0, count = max + 1; j < count; j += 1) {
						for (i = 0; i < edges.length; i += 1) {
							edge = edges[i];
							// eslint-disable-next-line no-bitwise
							if (vnew.get(edge.from) ^ vnew.get(edge.to)) {
								vnew = vnew.set(edge.from);
								vnew = vnew.set(edge.to);
								edgesMST.push(edge);
								break;
							}
						}
					}
				}

				return edgesMST;
			}

			return calculate(getAllEdges());
		};
	});
