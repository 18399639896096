import angular from 'angular';
import moment from 'moment';
import FileSaver from 'file-saver';

angular.module('neurotecAbisWebClientApp')
	.controller('CaseCtrl', ['$window', '$filter', '$scope', '$state', '$stateParams', '$uibModal', '$translate', '$q', 'AdjudicationCasesResource', 'AuthDataHolder', 'AdjudicationReportsResource', 'AdjudicationService', 'AlertService', 'blockUI', 'AdjudicationResource', 'BiometricsService', 'EncountersResource', 'ParametersService', 'Utils', 'EncountersDecisionsMapper',
		function ($window, $filter, $scope, $state, $stateParams, $uibModal, $translate, $q, AdjudicationCasesResource, AuthDataHolder, AdjudicationReportsResource, AdjudicationService, AlertService, blockUI, AdjudicationResource, BiometricsService, EncountersResource, ParametersService, Utils, EncountersDecisionsMapper) {
			$scope.actionPageName = $stateParams.previousState && $stateParams.previousState.name;
			let flattenSubjects = false;
			const loadedEncountersIds = new Set();
			$scope.subjects = [];
			$scope.canPressDuplicate = false;
			$scope.canPressUnique = false;
			$scope.canPressRelease = true;
			$scope.encountersDecisions = [];
			$scope.encountersHistory = {};
			$scope.isLoading = false;

			$scope.hasAnyAuthority = auth => AuthDataHolder.hasAnyAuthority(auth);

			$scope.getHitByEncounterId = function (encounterId) {
				var { adjudicationCase: { hits } } = $scope;
				for (var i = 0; i < hits.length; i += 1) {
					if (+encounterId === +hits[i].encounterId) {
						return hits[i];
					}
				}
			};

			$scope.getHitBySubjectId = function (subjectId) {
				var { adjudicationCase: { hits } } = $scope;
				for (var i = 0; i < hits.length; i += 1) {
					if (subjectId === hits[i].subjectId) {
						return hits[i];
					}
				}
			};

			$scope.getHitsBySubjectId = function (subjectId) {
				var result = [];
				var { adjudicationCase: { hits } } = $scope;
				for (var i = 0; i < hits.length; i += 1) {
					if (subjectId === hits[i].subjectId) {
						result.push(hits[i]);
					}
				}
				return result;
			};

			function changeStateUrl() {
				$state.transitionTo('actions.adjudication.caseHit', {
					caseId: $scope.caseId,
					hitId: $scope.encounterId
				}, { reload: false, notify: false, location: 'replace' });
			}

			function copyMatchingDetailsToProbe() {
				function deleteOldMatchingDetails(probeBiometric) {
					if (Array.isArray(probeBiometric)) {
						probeBiometric.forEach((elem) => {
							if (elem.matchingDetails) {
								delete elem.matchingDetails;
							}
							if (elem.index !== undefined && elem.index !== null) {
								delete elem.index;
							}
						});
					} else if (probeBiometric.matchingDetails && probeBiometric.index) {
						delete probeBiometric.matchingDetails;
						delete probeBiometric.index;
					}
				}

				const { adjudicationCase: { probe } } = $scope;
				const hit = $scope.getHitByEncounterId($scope.encounterId);

				['faces', 'irises', 'fingers', 'palms', 'signature', 'voice'].forEach((biometric) => {
					if (probe[biometric] && hit[biometric]) {
						deleteOldMatchingDetails(probe[biometric]);
						if (Array.isArray(probe[biometric]) && Array.isArray(hit[biometric])) {
							hit[biometric].forEach((elem) => {
								if (elem.matchingDetails) {
									for (let i = 0; i < elem.matchingDetails.length; i += 1) {
										const match = elem.matchingDetails[i];
										const matchedProbe = probe[biometric][match.probeIndex];
										// eslint-disable-next-line no-continue
										if (!matchedProbe) continue;

										matchedProbe.matchingDetails = [];
										matchedProbe.matchingDetails.push({
											hitIndex: hit[biometric].indexOf(elem),
											score: match.score
										});
									}
								}
								elem.index = hit[biometric].indexOf(elem);
							});
							probe[biometric].forEach((elem) => {
								elem.index = probe[biometric].indexOf(elem);
							});
						} else {
							probe[biometric].index = 0;
							probe[biometric].matchingDetails = hit[biometric].matchingDetails;
						}
					}
				});
			}

			function loadProbe() {
				var deferred = $q.defer();
				AdjudicationResource.get({
					caseId: $scope.caseId,
					encounterId: $scope.adjudicationCase.probe.encounterId
				}, (probe) => {
					$scope.adjudicationCase.probe = probe;
					$scope.showComments = $scope.history && $scope.history.length > 0;
					if ($scope.adjudicationCase.status === 'ADJUDICATION_IN_PROGRESS' && AuthDataHolder.getUserInfo().name === $scope.adjudicationCase.lockedBy) {
						$scope.userIsCaseOwner = true;
						$scope.isCaseOwned = true;
						$scope.showComments = true;
					} else {
						$scope.showResolvedHits = true;
					}

					AdjudicationService
						.getImages($scope.caseId, {
							type: 'probe',
							person: $scope.adjudicationCase.probe
						}, { fingerType: 'TRANSPARENT', palmType: 'ORIGINAL' })
						.then(() => {
							deferred.resolve();
						})
						.catch(() => {
							deferred.reject();
						});
				});
				return deferred.promise;
			}

			function loadHit() {
				var deferred = $q.defer();
				if (!loadedEncountersIds.has($scope.encounterId)) {
					AdjudicationResource.get({
						encounterId: $scope.caseId, target: 'hits', hitId: $scope.encounterId
					}, (data) => {
						loadedEncountersIds.add($scope.encounterId);
						const tempHit = data;
						AdjudicationService
							.getImages($scope.caseId, {
								type: 'hit',
								person: tempHit,
							}, { fingerType: 'TRANSPARENT', palmType: 'ORIGINAL' })
							.then(() => {
								Object.assign($scope.getHitByEncounterId($scope.encounterId), tempHit);
								$scope.hitsSelect.option = $scope.hitsSelect.options.find(hit => hit.encounterId === $scope.encounterId);
								deferred.resolve();
							})
							.catch(() => {
								deferred.reject();
							});
					});
				} else {
					deferred.resolve();
				}
				return deferred.promise;
			}

			function updateModalitiesOptions() {
				if ($scope.encounterId) {
					$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.adjudicationCase.probe, $scope.getHitByEncounterId($scope.encounterId));
				} else {
					$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.adjudicationCase.probe);
				}
			}

			function updateWarningsModel() {
				function hitBadgeOnLockedCase(subject, element) {
					if ($scope.areCommentsMandatory) {
						const decision = $scope.hitsDecisions.find(decision => decision.hitId === subject.hitId);
						return decision.comment === '' && element.touched;
					}
					return false;
				}

				function getBadgeByHit(hit) {
					const badge = {
						icon: '',
						tooltip: '',
					};

					switch (getMasterEncounterDecision(hit.hitId).type) {
					case 'CONFLICT':
						badge.icon = `${icons.conflict} ${classes.warning}`;
						badge.tooltip = tooltipTexts.conflict;
						break;
					case 'DUPLICATE':
						badge.icon = `${icons.duplicate} ${classes.danger}`;
						badge.tooltip = tooltipTexts.duplicate;
						break;
					case 'UNIQUE':
						badge.icon = `${icons.unique} ${classes.success}`;
						badge.tooltip = tooltipTexts.unique;
						break;
					case 'UNDECIDED':
						badge.icon = `${icons.undecided} ${classes.warning}`;
						badge.tooltip = tooltipTexts.undecided;
						break;
					default:
						throw new Error('Horizontal panel: invalidDecision');
					}
					return badge;
				}

				function isWarning(subject) {
					return ['UNDECIDED', 'CONFLICT'].includes(getMasterEncounterDecision(subject.hitId).type);
				}

				const tooltipTexts = {
					conflict: 'adjudication.case.warning.decision-conflict',
					undecided: 'adjudication.case.warning.undecided-decision',
					missing: 'adjudication.case.warning.missing-comment',
					unique: 'adjudication.case.warning.unique-decision',
					duplicate: 'adjudication.case.warning.duplicate-decision'
				};

				const icons = {
					conflict: 'fa fa-exclamation-triangle',
					undecided: 'fa fa-question-circle-o',
					unique: 'fa fa-check-square-o',
					duplicate: 'fa fa-check-square-o'
				};

				const classes = {
					danger: 'danger-icon',
					success: 'success-icon',
					warning: 'warning-icon'
				};

				if ($scope.encountersDecisions.length > 0
					&& $scope.encountersDecisions.some(decision => decision.type !== null)) {
					$scope.hitBadge = {
						hasBadge: $scope.isCaseOwned ? hitBadgeOnLockedCase : () => true,
						getIcon(subject) {
							return $scope.isCaseOwned ? `${icons.conflict} ${classes.warning}` : getBadgeByHit(subject).icon;
						},
						getTooltipText(subject) {
							return $scope.isCaseOwned ? tooltipTexts.missing : getBadgeByHit(subject).tooltip;
						},
						isWarning
					};
				} else {
					$scope.hitBadge = {};
				}
			}

			function changeItem() {
				$scope.isLoading = true;
				blockUI.start('app.loading');
				loadHit()
					.then(() => {
						updateHistory();
						updateComment();
						copyMatchingDetailsToProbe();
						updateModalitiesOptions();
						changeStateUrl();
					})
					.finally(() => {
						blockUI.stop();
						$scope.isLoading = false;
					});
			}

			$scope.changeItemBySubjectId = function (subject) {
				const { subjectId, hitId } = subject;
				$scope.hitsSelect.option = null;
				$scope.currentSubjectNo = $scope.subjects.findIndex(subject =>
					subject.subjectId === subjectId
					&& subject.hitId === hitId);
				$scope.encounterId = $scope.subjects[$scope.currentSubjectNo].hitId;
				changeItem();
			};

			$scope.changeItemByEncounterId = function (selectedEncounterId) {
				if (selectedEncounterId === undefined) return;
				$scope.encounterId = selectedEncounterId;
				const index = $scope.hitsSelect.options.findIndex(option => option.encounterId === selectedEncounterId);
				$scope.hitsSelect.option = $scope.hitsSelect.options[index];
				changeItem();
			};

			$scope.prevEncounter = function () {
				let index = $scope.hitsSelect.options.findIndex(option => option.encounterId === $scope.hitsSelect.option.encounterId) - 1;
				if (index < 0) index = $scope.hitsSelect.options.length - 1;
				$scope.hitsSelect.option = $scope.hitsSelect.options[index];
				$scope.encounterId = $scope.hitsSelect.option.encounterId;
				changeItem();
			};

			$scope.nextEncounter = function () {
				let index = $scope.hitsSelect.options.findIndex(option => option.encounterId === $scope.hitsSelect.option.encounterId) + 1;
				if (index >= $scope.hitsSelect.options.length) index = 0;
				$scope.hitsSelect.option = $scope.hitsSelect.options[index];
				$scope.encounterId = $scope.hitsSelect.option.encounterId;
				changeItem();
			};

			$scope.download = function () {
				AdjudicationReportsResource.get({ id: $scope.caseId, timeZone: moment.tz.guess() }, (value) => {
					FileSaver.saveAs(value.pdf, `adjudication_report_${$filter('date')(new Date(), 'yyyyMMdd_HHmmss')}.pdf`);
				});
			};

			$scope.showHits = function () {
				if ($scope.hitsSelect.options.length > 1) {
					return true;
				}
				return false;
			};

			$scope.hitsSelect = {
				option: null,
				options: []
			};

			function isFinalStatus(status) {
				return ['OK', 'DUPLICATE_FOUND'].includes(status);
			}

			function updateDecisions() {
				$scope.decisionsTypes = $scope.isCaseOwned || isFinalStatus($scope.adjudicationCase.status)
					? $scope.hitsDecisions.map(obj => obj.type) || []
					: $scope.encountersDecisions.map(decision => decision.type) || [];
			}

			$scope.$watch('hitsDecision', (newValue) => {
				if (newValue && !Utils.isObjectEmpty(newValue)) {
					updateDecisions();
				}
			}, true);

			$scope.$watch('currentSubjectNo', (newValue) => {
				if (typeof newValue !== 'undefined') {
					$scope.hitsSelect.options = [];
					const hits = $scope.getHitsBySubjectId($scope.subjects[$scope.currentSubjectNo].subjectId);
					for (let i = 0; i < hits.length; i += 1) {
						if (!flattenSubjects) {
							$scope.hitsSelect.options.push({
								encounterId: hits[i].encounterId,
								score: hits[i].score
							});
						}
					}
					if ($scope.hitsSelect.option === null) {
						[$scope.hitsSelect.option] = $scope.hitsSelect.options;
					}
				}
			}, true);

			function goBackToList() {
				if (AuthDataHolder.hasAnyAuthority('MODIFIER_ADJUDICATION_SUPERVISOR')) {
					return $state.go('actions.deduplication');
				}
				return $state.go('actions.adjudication.cases');
			}

			function goNextOrBackToList() {
				if (AuthDataHolder.hasAnyAuthority('MODIFIER_ADJUDICATION_SUPERVISOR')) {
					return $state.go('actions.deduplication');
				}
				return $state.go('actions.adjudication.case', { nextCase: true }, { reload: true, location: 'replace' });
			}

			function moveToNextHitIfAvailable(cs) {
				function shouldMoveToNextHit() {
					let hitDecision = $scope.hitsDecisions.find(hitDecision => hitDecision.hitId === $scope.encounterId);
					if (!hitDecision) {
						hitDecision = $scope.hitsDecisions[getMasterHitDecisionIndex($scope.getHitByEncounterId($scope.encounterId).subjectId)];
					}

					const isSelectedHitDuplicate = hitDecision.type === 'DUPLICATE';
					const isCaseOwned = AuthDataHolder.getUserInfo().name === cs.lockedBy;
					return cs.status === 'ADJUDICATION_IN_PROGRESS' && isCaseOwned && isSelectedHitDuplicate;
				}

				if (shouldMoveToNextHit()) {
					$scope.nextUnmarkedHit();
				}
			}

			$scope.$on('$viewContentLoaded', () => {
				$scope.isCaseOwned = false;
				$scope.hitsDecisions = [];
				$scope.hitsDecision = {};
				$scope.history = [];
				$scope.showPageslideButton = true;
				$scope.showPageslide = false;
				$scope.modalitiesOptions = {
					showIrises: false,
					showFingers: { common: false, slap: false, unknownCount: 0 },
					showPalms: false,
					showSignature: false
				};
				function initHitsDecisions() {
					$scope.subjects.forEach((subject) => {
						$scope.hitsDecisions.push({
							hitId: subject.hitId,
							type: 'UNDECIDED',
							comment: ''
						});
					});
				}

				function updateSubjects() {
					const { adjudicationCase: { hits } } = $scope;
					for (let i = 0; i < hits.length; i += 1) {
						if (flattenSubjects) {
							$scope.subjects.push({ subjectId: hits[i].subjectId, hitId: hits[i].encounterId });
						} else if (!$scope.subjects.some(hit => hit.subjectId === hits[i].subjectId)) {
							$scope.subjects.push({ subjectId: hits[i].subjectId, hitId: hits[i].encounterId });
						}
					}
					$scope.countNone = $scope.subjects.length;
					$scope.partLen = 100 / $scope.subjects.length;
				}
				function selectHit() {
					const subjectIndex = 0;
					$scope.encounterId = $scope.subjects[subjectIndex].hitId;
					$scope.currentSubjectNo = subjectIndex;
					function findSubjectIndex(hit) {
						if (flattenSubjects) {
							return $scope.subjects.findIndex(subject =>
								subject.subjectId === hit.subjectId
								&& subject.hitId === hit.encounterId);
						}
						return $scope.subjects.findIndex(subject => subject.subjectId === hit.subjectId);
					}

					// Make sure such hitId exists
					for (var i = 0; i < $scope.adjudicationCase.hits.length; i += 1) {
						if (+$stateParams.hitId === +$scope.adjudicationCase.hits[i].encounterId) {
							$scope.encounterId = $stateParams.hitId;
							$scope.currentSubjectNo = findSubjectIndex($scope.adjudicationCase.hits[i]);
						}
					}
					$scope.hitsSelect.option = $scope.hitsSelect.options.find(hit => hit.id === $scope.encounterId);
				}

				function onCase(cs) {
					$scope.caseId = cs.id;
					$scope.adjudicationCase = cs;
					updateSubjects();
					initHitsDecisions();
					if (cs.hits.length > 0) {
						if (cs.transactionParams.matchingMode === 'FUSED') {
							cs.hits.forEach((hit) => {
								if (hit.score >= cs.transactionParams.fusedTopThreshold) {
									let hitIndex = $scope.hitsDecisions.findIndex(hitDecision => hitDecision.hitId === hit.encounterId);
									if (hitIndex === -1) {
										hitIndex = getMasterHitDecisionIndex(hit.subjectId);
									}
									$scope.hitsDecisions[hitIndex].type = 'DUPLICATE';
								}
							});
						}
						selectHit();
						moveToNextHitIfAvailable(cs);
					}

					if ($scope.encounterId) {
						$q.all([loadProbe(), loadHit()]).then(() => {
							copyMatchingDetailsToProbe();
							updateModalitiesOptions();
							updateHistory();
							updateComment();
							$scope.encountersDecisions = getEncountersHistory(angular.copy($scope.hitsDecisions));
							updateWarningsModel();
							changeStateUrl();
						}).finally(() => {
							blockUI.stop();
							$scope.isLoading = false;
						});
					} else {
						loadProbe().then(() => {
							updateModalitiesOptions();
						}).finally(() => {
							blockUI.stop();
							$scope.isLoading = false;
						});
					}
				}
				function onError(error) {
					if (error.status === 404) {
						AlertService.show('adjudication.not-found-next');
					} else if (error.status === 403) {
						// do nothing, intercepter should log user out
					} else {
						AlertService.show('adjudication.error', { type: 'danger' });
					}
					goBackToList();
					blockUI.stop();
					$scope.isLoading = false;
				}

				$q.all([
					ParametersService.getMandatoryCommentsProperty(),
					ParametersService.getGroupEncountersProperty()
				])
					.then((result) => {
						$scope.areCommentsMandatory = result[0][0].value === 'true';
						flattenSubjects = result[1][0].value !== 'true'; // flatten subjects if group-encounters is false
					})
					.finally(() => {
						var isNextCase = $stateParams.nextCase;
						$scope.isLoading = true;
						blockUI.start('app.loading');
						if ($stateParams.caseId) {
							AdjudicationCasesResource.get({ caseId: $stateParams.caseId }, onCase, onError);
						} else if (isNextCase) {
							AdjudicationCasesResource.getNext({}, onCase, onError);
						}
					});
			});

			$scope.isUserSupervisor = function () {
				return AuthDataHolder.hasAnyAuthority('MODIFIER_ADJUDICATION_SUPERVISOR');
			};

			$scope.userCanDownloadReport = function () {
				return AuthDataHolder.hasAnyAuthority('PERMISSION_ADJUDICATION_REPORT');
			};

			$scope.togglePageslide = function () {
				$scope.showPageslide = !$scope.showPageslide;
			};

			$scope.goBack = function () {
				$window.history.back();
			};

			$scope.isLockCaseAvailable = function () {
				if (!$scope.adjudicationCase || !$scope.adjudicationCase.status) return false;

				const isCaseWaiting = $scope.adjudicationCase.status === 'ADJUDICATION_WAITING';
				const isCaseConflictedAndAvailable = $scope.adjudicationCase.status === 'ADJUDICATION_CONFLICT' && $scope.isUserSupervisor();

				return !$scope.isCaseOwned && (isCaseWaiting || isCaseConflictedAndAvailable);
			};

			$scope.lockCase = function () {
				AdjudicationCasesResource.lock({
					caseId: $scope.adjudicationCase.id
				}, () => {
					$scope.adjudicationCase.status = 'ADJUDICATION_IN_PROGRESS';
					$scope.isCaseOwned = true;
					$scope.showComments = true;
					updateDecisions();
					updateWarningsModel();
					$scope.nextUnmarkedHit();
				}, (error) => {
					AlertService.show(error.data.message, { type: 'danger', translate: false });
				});
			};

			$scope.hitByNo = function (index) {
				if ($scope.currentSubjectNo === index) return;
				$scope.hitsSelect.option = null;
				if (index >= 0 && index < $scope.subjects.length) {
					$scope.currentSubjectNo = index;
				}
				$scope.encounterId = $scope.subjects[$scope.currentSubjectNo].hitId;
				$scope.isLoading = true;
				blockUI.start('app.loading'); // TODO: block until face is loaded.
				loadHit().then(() => {
					copyMatchingDetailsToProbe();
					updateModalitiesOptions();
					updateHistory();
					updateComment();
					changeStateUrl();
				}).finally(() => {
					blockUI.stop();
					$scope.isLoading = false;
				});
			};

			$scope.nextUnmarkedHit = function (alertIfLast = true) {
				let currentHitIndex = $scope.hitsDecisions.findIndex(hit =>
					hit.hitId === $scope.encounterId
					&& $scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId($scope.encounterId).subjectId);

				if (currentHitIndex === -1) {
					currentHitIndex = $scope.hitsDecisions.findIndex(hit =>
						$scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId($scope.encounterId).subjectId);
				}
				const potentiallyNextHitIndex = currentHitIndex + 1 >= $scope.hitsDecisions.length ? 0 : currentHitIndex + 1;
				for (let i = 0; i < $scope.hitsDecisions.length; i += 1) {
					const nextHitIndex = (potentiallyNextHitIndex + i) % $scope.hitsDecisions.length;
					if ($scope.hitsDecisions[nextHitIndex].type === 'UNDECIDED') {
						return $scope.hitByNo(nextHitIndex);
					}
				}

				if (alertIfLast) {
					AlertService.show('adjudication.case.hits-reviewed', { type: 'success', translate: true });
				}
			};

			$scope.markHitAsDuplicate = function (alertIfLast = true) {
				$scope.hitsDecision.type = 'DUPLICATE';
				updateFinalActionButtons();
				$scope.nextUnmarkedHit(alertIfLast);
			};

			$scope.markHitAsUnique = function (alertIfLast = true) {
				$scope.hitsDecision.type = 'UNIQUE';
				updateFinalActionButtons();
				$scope.nextUnmarkedHit(alertIfLast);
			};

			$scope.markHitAsUndecided = function () {
				$scope.hitsDecision.type = 'UNDECIDED';
				updateFinalActionButtons();
				$scope.nextUnmarkedHit(false);
			};

			function updateFinalActionButtons() {
				const counts = getCounts();
				updateUniqueAction(counts.none, counts.duplicate, counts.unique);
				updateDuplicateAction(counts.none, counts.duplicate, counts.unique);
				updateReleaseAction(counts.none);
				$scope.countNone = counts.none;
				$scope.countDuplicate = counts.duplicate;
				$scope.countUnique = counts.unique;

				function updateDuplicateAction(countNone, countDuplicate, countUnique) {
					$scope.canPressDuplicate = countNone === 0 && countDuplicate > 0 && countUnique >= 0;
				}

				function updateUniqueAction(countNone, countDuplicate, countUnique) {
					$scope.canPressUnique = countNone === 0 && countDuplicate === 0 && countUnique > 0;
				}

				function updateReleaseAction(countNone) {
					$scope.canPressRelease = countNone > 0;
				}

				function getCounts() {
					let countDuplicate = 0;
					let countUnique = 0;
					let countNone = 0;
					$scope.hitsDecisions.forEach((decision) => {
						if (decision.type === 'DUPLICATE') {
							countDuplicate += 1;
						} else if (decision.type === 'UNIQUE') {
							countUnique += 1;
						} else if (decision.type === 'UNDECIDED') {
							countNone += 1;
						}
					});
					return {
						duplicate: countDuplicate,
						unique: countUnique,
						none: countNone
					};
				}
			}

			const decisionsMapper = EncountersDecisionsMapper;
			function getEncountersHistory(decisionObj) {
				function getLastHistoryEvent() {
					return $scope.adjudicationCase.history[$scope.adjudicationCase.history.length - 1];
				}

				if (isFinalStatus($scope.adjudicationCase.status)) {
					const decisions = $scope.adjudicationCase.hits.map(encounter => ({ hitId: encounter.encounterId, type: null }));
					decisionsMapper.copyEventHitsStatusesToDecision($scope.adjudicationCase.hits, decisions, getLastHistoryEvent().hits);
					return decisions;
				}

				if ($scope.isUserSupervisor()) {
					return decisionsMapper.getCurrentTypes($scope.adjudicationCase.hits, decisionObj, $scope.adjudicationCase.history);
				}

				return decisionsMapper.getUserDecisionTypes($scope.adjudicationCase.hits, decisionObj, $scope.adjudicationCase.history);
			}

			function markIfOneSubject(makeDecision) {
				if ($scope.subjects.length === 1) {
					makeDecision(false);
				}
			}

			function markEncounters() {
				const decisionPayload = [];
				const { adjudicationCase: { hits } } = $scope;
				hits.forEach((hit) => {
					const decisionIdx = $scope.hitsDecisions.findIndex(decision => decision.hitId === hit.encounterId);
					if (decisionIdx !== -1) {
						decisionPayload.push($scope.hitsDecisions[decisionIdx]);
					} else {
						const decision = Object.assign({}, $scope.hitsDecisions.find(hitDecision => $scope.getHitByEncounterId(hitDecision.hitId).subjectId === hit.subjectId));
						decision.hitId = hit.encounterId;
						decisionPayload.push(decision);
					}
				});
				return decisionPayload;
			}

			function validateDecisions(decisions) {
				const areDecisionsValid = !decisions.some(decision => decision.comment === '');
				if (!areDecisionsValid) {
					AlertService.show('adjudication.case.missing-comments', { type: 'danger', translate: true });
					$scope.nextUnmarkedHit(false);
				}
				return areDecisionsValid;
			}

			$scope.markAsDuplicate = function () {
				markIfOneSubject($scope.markHitAsDuplicate);
				const decision = markEncounters();
				if ($scope.areCommentsMandatory && !validateDecisions(decision)) {
					return;
				}

				var scope = $scope.$new(true);
				scope.title = 'comments-modal.title-duplicate';
				scope.placeholder = 'comments-modal.placeholder';
				scope.total = $scope.hitsDecisions.length || 0;
				scope.none = $scope.countNone || 0;
				scope.duplicate = $scope.countDuplicate || 0;
				scope.unique = $scope.countUnique || 0;

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/summary-modal.html'),
					controller: 'SummaryModalCtrl',
					scope,
					windowClass: 'fit-content-modal'
				}).result.then(() => {
					var person = $scope.getHitByEncounterId($scope.encounterId);
					AdjudicationCasesResource.resolve({
						caseId: $scope.adjudicationCase.id
					}, {
						hits: decision,
					}, () => {
						goNextOrBackToList().then(() => {
							AlertService.show('result.duplicate', {
								translateValues: {
									caseId: $scope.adjudicationCase.id,
									person
								}
							});
						});
					}, (error) => {
						AlertService.show(error.data.message, { type: 'danger', translate: false });
					});
				}).catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.markAsUnique = function () {
				markIfOneSubject($scope.markHitAsUnique);
				const decision = markEncounters();
				if ($scope.areCommentsMandatory && !validateDecisions(decision)) {
					return;
				}

				var scope = $scope.$new(true);
				scope.title = 'comments-modal.title-unique';
				scope.placeholder = 'comments-modal.placeholder';
				scope.total = $scope.hitsDecisions.length || 0;
				scope.none = $scope.countNone || 0;
				scope.duplicate = $scope.countDuplicate || 0;
				scope.unique = $scope.countUnique || 0;

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/summary-modal.html'),
					controller: 'SummaryModalCtrl',
					scope,
					windowClass: 'fit-content-modal'
				}).result.then(() => {
					AdjudicationCasesResource.resolve({
						caseId: $scope.adjudicationCase.id
					}, {
						hits: decision,
					}, () => {
						goNextOrBackToList().then(() => {
							AlertService.show('result.unique', {
								translateValues: {
									caseId: $scope.adjudicationCase.id
								}
							});
						});
					}, (error) => {
						AlertService.show(error.data.message, { type: 'danger', translate: false });
					});
				}).catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.releaseCase = function () {
				// if ($scope.isUserSupervisor()) return;
				markIfOneSubject($scope.markHitAsUndecided);
				const decision = markEncounters();
				if ($scope.areCommentsMandatory && !validateDecisions(decision)) {
					return;
				}

				var scope = $scope.$new(true);
				scope.title = 'comments-modal.title-release';
				scope.placeholder = 'comments-modal.placeholder-release';
				scope.total = $scope.hitsDecisions.length || 0;
				scope.none = $scope.countNone || 0;
				scope.duplicate = $scope.countDuplicate || 0;
				scope.unique = $scope.countUnique || 0;

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/summary-modal.html'),
					controller: 'SummaryModalCtrl',
					scope,
					windowClass: 'fit-content-modal'
				}).result.then(() => {
					AdjudicationCasesResource.resolve({
						caseId: $scope.adjudicationCase.id
					}, {
						hits: decision,
					}, () => {
						goNextOrBackToList().then(() => {
							AlertService.show('result.release', {
								translateValues: {
									caseId: $scope.adjudicationCase.id,
								}
							});
						});
					}, (error) => {
						AlertService.show(error.data.message, { type: 'danger', translate: false });
					});
				}).catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.showFaceComparison = function () {
				const scope = $scope.$new(true);
				if (!$scope.adjudicationCase.probe.faces[0] || $scope.adjudicationCase.probe.faces[0].unclickable
					|| !$scope.getHitByEncounterId($scope.encounterId).faces[0] || $scope.getHitByEncounterId($scope.encounterId).faces[0].unclickable) {
					scope.title = 'comparison.face';
					scope.getImageUrl = () => $q(resolve => resolve($scope.adjudicationCase.probe.faces[0] ? $scope.adjudicationCase.probe.faces[0].imageUrl : $scope.getHitByEncounterId($scope.encounterId).faces[0].imageUrl));
					scope.modality = 'face';
					singleImagePreview(scope);
					return;
				}
				scope.probe = {};
				scope.probe.imageUrl = $scope.adjudicationCase.probe.faces[0].imageUrl;
				scope.hit = {};
				scope.hit.imageUrl = $scope.getHitByEncounterId($scope.encounterId).faces[0].imageUrl;
				scope.title = 'comparison.face';

				translateTitle('comparison.face', scope);

				scope.probe.getFaceFeatures = function () {
					return $q((resolve) => {
						AdjudicationResource.getFaceFeatures({
							encounterId: $scope.caseId, modalityId: 0, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.hit.getFaceFeatures = function () {
					return $q((resolve) => {
						AdjudicationResource.getFaceFeatures({
							modalityId: 0, encounterId: $scope.encounterId, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/face-comparison-modal.html'),
					controller: 'FaceComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.showIrisComparison = function (index) {
				var { adjudicationCase: { probe } } = $scope;
				var hit = $scope.getHitByEncounterId($scope.encounterId);
				var scope = $scope.$new(true);
				scope.modality = 'iris';
				scope.probe = probe.irises[index];
				scope.hit = hit.irises[scope.probe.matchingDetails[0].hitIndex];
				$translate([`biometrics.irises.position.${scope.probe.position}`, `biometrics.irises.position.${scope.hit.position}`]).then((translations) => {
					$translate('comparison.finger.modal-title', {
						probePosition: translations[`biometrics.irises.position.${scope.probe.position}`],
						hitPosition: translations[`biometrics.irises.position.${scope.hit.position}`],
						score: scope.probe.matchingDetails[0].score
					}).then((translation) => {
						scope.title = translation;
					});
				});
				showSimpleComparison(scope);
			};

			$scope.showPalmComparison = function (index) {
				const probe = $scope.adjudicationCase.probe.palms[index];
				const [matchingDetails] = probe.matchingDetails;
				const hit = $scope.getHitByEncounterId($scope.encounterId).palms[matchingDetails.hitIndex];
				const probeIndex = index;
				const { hitIndex } = matchingDetails;

				var scope = $scope.$new(true);

				$translate([`biometrics.palms.position.${probe.position}`, `biometrics.palms.position.${hit.position}`]).then((translations) => {
					$translate('comparison.palm.modal-title', {
						probePosition: translations[`biometrics.palms.position.${probe.position}`],
						hitPosition: translations[`biometrics.palms.position.${hit.position}`],
						score: matchingDetails.score
					}).then((translation) => {
						scope.title = translation;
					});
				});

				scope.probe = {};
				scope.probe.getImageUrl = function (type) {
					return $q((resolve, reject) => {
						if (probe.forceImageType === 'NONE') {
							reject({ status: 404 });
						}
						if (type == null) {
							resolve(probe.imageUrl);
						} else {
							switch (type) {
							case 'ORIGINAL':
								resolve(probe.imageUrl);
								return;
							case 'SKELETONIZED':
								AdjudicationResource.getPalmImage({
									encounterId: $scope.caseId, modalityId: probeIndex, type: 'SKELETONIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'BINARIZED':
								AdjudicationResource.getPalmImage({
									encounterId: $scope.caseId, modalityId: probeIndex, type: 'BINARIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							default:
								return reject();
							}
						}
					});
				};
				scope.probe.getFeatures = function (getAllFeatues) {
					return $q((resolve) => {
						getAllFeatues = getAllFeatues || false;
						AdjudicationResource.getPalmFeatures({
							encounterId: $scope.caseId, modalityId: probeIndex, all: getAllFeatues, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.hit = {};
				scope.hit.getImageUrl = function (type) {
					return $q((resolve, reject) => {
						if (hit.forceImageType === 'NONE') {
							reject({ status: 404 });
						}
						if (type == null) {
							resolve(hit.imageUrl);
						} else {
							switch (type) {
							case 'ORIGINAL':
								resolve(hit.imageUrl);
								return;
							case 'SKELETONIZED':
								AdjudicationResource.getPalmImage({
									encounterId: $scope.encounterId, modalityId: hitIndex, type: 'SKELETONIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'BINARIZED':
								AdjudicationResource.getPalmImage({
									encounterId: $scope.encounterId, modalityId: hitIndex, type: 'BINARIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							default:
								return reject();
							}
						}
					});
				};

				scope.hit.getFeatures = function (getAllFeatues) {
					getAllFeatues = getAllFeatues || false;
					return $q((resolve) => {
						AdjudicationResource.getPalmFeatures({
							encounterId: $scope.encounterId, modalityId: hitIndex, all: getAllFeatues, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.getMatchingDetails = function () {
					return $q((resolve) => {
						AdjudicationResource.getPalmMatchingDetails({
							encounterId: $scope.caseId, modalityId: hitIndex, hitId: $scope.encounterId, caseId: $scope.caseId
						}, (palmMatchingDetails) => {
							resolve(palmMatchingDetails.find(palm => palm.probeIndex === probeIndex));
						});
					});
				};

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/palm-comparison-modal.html'),
					controller: 'PalmComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.showSignatureComparison = function () {
				const { probe } = $scope.adjudicationCase;
				const hit = $scope.getHitByEncounterId($scope.encounterId);
				const scope = $scope.$new(true);
				if (!probe.signature || !hit.signature) {
					scope.title = 'comparison.signature';
					scope.getImageUrl = () => $q(resolve => resolve(probe.signature ? probe.signature.imageUrl : hit.signature.imageUrl));
					scope.modality = 'signature';
					singleImagePreview(scope);
					return;
				}
				scope.modality = 'signature';
				scope.probe = probe.signature;
				scope.hit = hit.signature;
				translateTitle('comparison.signature', scope);
				showSimpleComparison(scope);
			};

			$scope.showFingerComparison = function (index) {
				const probe = $scope.adjudicationCase.probe.fingers[index];
				const [matchingDetails] = probe.matchingDetails;
				const hit = $scope.getHitByEncounterId($scope.encounterId).fingers[matchingDetails.hitIndex];
				const probeIndex = index;
				const { hitIndex } = matchingDetails;

				var scope = $scope.$new(true);

				$translate([`biometrics.fingers.position.${probe.position}`, `biometrics.fingers.position.${hit.position}`]).then((translations) => {
					$translate('comparison.finger.modal-title', {
						probePosition: translations[`biometrics.fingers.position.${probe.position}`],
						hitPosition: translations[`biometrics.fingers.position.${hit.position}`],
						score: matchingDetails.score
					}).then((translation) => {
						scope.title = translation;
					});
				});

				scope.probe = { fingerId: probeIndex, encounterId: $scope.adjudicationCase.probe.encounterId };
				scope.probe.getImageUrl = function (type) {
					return $q((resolve, reject) => {
						if (probe.forceImageType === 'NONE') {
							reject({ status: 404 });
						}
						if (type == null) {
							resolve(probe.imageUrl);
						} else {
							switch (type) {
							case 'TRANSPARENT':
								resolve(probe.imageUrl);
								return;
							case 'SKELETONIZED':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.caseId, modalityId: probeIndex, type: 'SKELETONIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'BINARIZED':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.caseId, modalityId: probeIndex, type: 'BINARIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'ORIGINAL':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.caseId, modalityId: probeIndex, type: 'ORIGINAL', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							default:
								return reject();
							}
						}
					});
				};
				scope.probe.getFeatures = function (getAllFeatues) {
					return $q((resolve) => {
						getAllFeatues = getAllFeatues || false;
						AdjudicationResource.getFingerFeatures({
							encounterId: $scope.caseId, modalityId: probeIndex, all: getAllFeatues, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.hit = { fingerId: hitIndex, encounterId: $scope.encounterId };
				scope.hit.getImageUrl = function (type) {
					return $q((resolve, reject) => {
						if (hit.forceImageType === 'NONE') {
							reject({ status: 404 });
						}
						if (type == null) {
							resolve(hit.imageUrl);
						} else {
							switch (type) {
							case 'TRANSPARENT':
								resolve(hit.imageUrl);
								return;
							case 'SKELETONIZED':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.encounterId, modalityId: hitIndex, type: 'SKELETONIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'BINARIZED':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.encounterId, modalityId: hitIndex, type: 'BINARIZED', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							case 'ORIGINAL':
								AdjudicationResource.getFingerImage({
									encounterId: $scope.encounterId, modalityId: hitIndex, type: 'ORIGINAL', caseId: $scope.caseId
								}, (data) => {
									resolve(URL.createObjectURL(data.image));
								});
								return;
							default:
								return reject();
							}
						}
					});
				};

				scope.hit.getFeatures = function (getAllFeatues) {
					getAllFeatues = getAllFeatues || false;
					return $q((resolve) => {
						AdjudicationResource.getFingerFeatures({
							encounterId: $scope.encounterId, modalityId: hitIndex, all: getAllFeatues, caseId: $scope.caseId
						}, (data) => {
							resolve(data);
						});
					});
				};

				scope.getMatchingDetails = function () {
					return $q((resolve) => {
						AdjudicationResource.getFingerMatchingDetails({
							encounterId: $scope.caseId, modalityId: hitIndex, hitId: $scope.encounterId, caseId: $scope.caseId
						}, (fingersMatchingDetails) => {
							resolve(fingersMatchingDetails.find(finger => finger.probeIndex === probeIndex));
						});
					});
				};

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/finger-comparison-modal.html'),
					controller: 'FingerComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			$scope.showSimpleFacePreview = function () {
				var scope = $scope.$new(true);
				if ($scope.getHitByEncounterId($scope.encounterId).faces[0]) {
					scope.title = 'preview.face';
					scope.getImageUrl = () => $q(resolve => resolve($scope.getHitByEncounterId($scope.encounterId).faces[0].imageUrl));
					scope.modality = 'face';
					singleImagePreview(scope);
				}
			};

			$scope.showSimpleIrisPreview = function (index) {
				var scope = $scope.$new(true);
				if ($scope.getHitByEncounterId($scope.encounterId).irises[index]) {
					scope.title = 'preview.iris';
					scope.getImageUrl = () => $q(resolve => resolve($scope.getHitByEncounterId($scope.encounterId).irises[index].imageUrl));
					scope.modality = 'iris';
					singleImagePreview(scope);
				}
			};

			$scope.showSimpleFingerPreview = function (index) {
				var scope = $scope.$new(true);
				if ($scope.getHitByEncounterId($scope.encounterId).fingers[index]) {
					scope.title = 'preview.finger';
					scope.getImageUrl = () => $q(resolve => resolve($scope.getHitByEncounterId($scope.encounterId).fingers[index].imageUrl));
					scope.modality = 'finger';
					singleImagePreview(scope);
				}
			};

			$scope.showSimplePalmPreview = function (index) {
				var scope = $scope.$new(true);
				if ($scope.getHitByEncounterId($scope.encounterId).palms[index]) {
					scope.title = 'preview.palm';
					scope.getImageUrl = () => $q(resolve => resolve($scope.getHitByEncounterId($scope.encounterId).palms[index].imageUrl));
					scope.modality = 'palm';
					singleImagePreview(scope);
				}
			};

			$scope.showSimpleSignaturePreview = function () {
				var scope = $scope.$new(true);
				if ($scope.getHitByEncounterId($scope.encounterId).signature) {
					scope.title = 'preview.signature';
					scope.getImageUrl = () => $q(resolve => resolve($scope.getHitByEncounterId($scope.encounterId).signature.imageUrl));
					scope.modality = 'signature';
					singleImagePreview(scope);
				}
			};

			function singleImagePreview(scope) {
				if (!scope.getImageUrl) { return; }

				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}
				$uibModal.open({
					template: require('../../views/modal/simple-preview-modal.html'),
					controller: 'SimplePreviewModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}

			function canSeeComment(userId) {
				return $scope.isUserSupervisor()
					|| userId === AuthDataHolder.getUserInfo().id;
			}

			function updateComment() {
				let decisionIdx = $scope.hitsDecisions.findIndex(hit =>
					hit.hitId === $scope.encounterId
					&& $scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId($scope.encounterId).subjectId);

				if (decisionIdx === -1) {
					decisionIdx = $scope.hitsDecisions.findIndex(hit =>
						$scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId($scope.encounterId).subjectId);
				}
				$scope.hitsDecision = $scope.hitsDecisions[decisionIdx];
			}

			function updateHistory() {
				const typesToShow = ['UNIQUE', 'DUPLICATE', 'UNDECIDED'];
				const { history } = $scope.adjudicationCase;
				$scope.history = [];

				if (history !== null) {
					history.forEach((historyActivity) => {
						if (typesToShow.includes(historyActivity.type) && canSeeComment(historyActivity.userId)) {
							historyActivity.hits.forEach((hit) => {
								if (hit.hitId === $scope.encounterId) {
									hit.timestamp = historyActivity.timestamp;
									hit.userName = historyActivity.userName;
									$scope.history.push(hit);
								}
							});
						}
					});
				}
			}

			function showSimpleComparison(scope) {
				if ($scope.showPageslide) {
					$scope.togglePageslide();
				}

				$uibModal.open({
					template: require('../../views/modal/simple-comparison-modal.html'),
					controller: 'SimpleComparisonModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}

			function translateTitle(id, scope) {
				$translate(id).then((translation) => {
					scope.title = translation;
				}, (translationId) => {
					scope.title = translationId;
				});
			}

			$scope.showCases = function () {
				return $scope.adjudicationCase.hits.map(hit => ({
					encounterId: hit.encounterId,
					subjectId: hit.subjectId
				}));
			};
			$scope.showDecisions = function () {
				return $scope.hitsDecisions;
			};

			$scope.exportEncounter = function (event, encounterId) {
				event.stopPropagation();
				EncountersResource.export({ encounterId }).$promise
					.then((value) => {
						FileSaver.saveAs(value.nist, `encounter_${encounterId}.nist`);
					});
			};

			function getMasterHitDecisionIndex(subjectId) {
				const hitsWithSubjectId = $scope.adjudicationCase.hits.filter(h => h.subjectId === subjectId);
				return $scope.hitsDecisions.findIndex(hitDecision => hitsWithSubjectId.some(adjHit => adjHit.encounterId === hitDecision.hitId));
			}

			function getMasterEncounterDecision(encounterId) {
				let decisionIdx = $scope.encountersDecisions.findIndex(hit =>
					hit.hitId === encounterId
					&& $scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId(encounterId).subjectId);

				if (decisionIdx === -1) {
					decisionIdx = $scope.encountersDecisions.findIndex(hit =>
						$scope.getHitByEncounterId(hit.hitId).subjectId === $scope.getHitByEncounterId(encounterId).subjectId);
				}
				return $scope.encountersDecisions[decisionIdx];
			}

			$scope.isEncounterStatus = function (status) {
				return $scope.encountersDecisions.length > 0 && getMasterEncounterDecision($scope.encounterId).type === status;
			};

			$scope.invalidateSlideStatusWatcher = function () {
				return $scope.isCaseOwned;
			};
		}]);
