import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('asciiOnly', () => ({
		restrict: 'A',
		require: 'ngModel',
		link(scope, element, attributes, ngModel) {
			function isASCII(str) {
				// eslint-disable-next-line no-control-regex
				return /^[\x00-\x7F]*$/.test(str);
			}
			ngModel.$validators.asciiOnly = function (modelValue) {
				return isASCII(modelValue);
			};
		}
	}));
