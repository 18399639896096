import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('timeline', () => ({
		template: require('../../views/directives/timeline.html'),
		restrict: 'E',
		replace: true,
		scope: {
			eventGroups: '=',
			checkpointGroups: '=',
			series: '=?',
			config: '='
		},
		controllerAs: 'TimelineCtrl',
		bindToController: true,
		controller: ['$scope', '$translate', 'ConvertionUtils', 'TimelineTools',
			function ($scope, $translate, ConvertionUtils, TimelineTools) {
				const self = this;
				self.cnv = ConvertionUtils;
				self.timeline = TimelineTools;
				self.balls = [];

				function setWatchers() {
					$scope.$watch(angular.bind(self, function () {
						return this.config;
					}), self.timeline.updateConfig, true);

					$scope.$watch(angular.bind(self, function () {
						return this.eventGroups;
					}), onElementsChange, true);
					$scope.$watch(angular.bind(self, function () {
						return this.checkpointGroups;
					}), onElementsChange, true);
				}

				function populateBallsData() {
					self.balls = self.timeline.elements.map(() => ({ isOpen: false }));
				}

				function onElementsChange() {
					if ((self.eventGroups && self.eventGroups.length > 0) || (self.checkpointGroups && self.checkpointGroups.length > 0)) {
						self.timeline.onElementsChange(self.eventGroups, self.checkpointGroups, self.series);
						populateBallsData();
					}
				}

				setWatchers();

				self.statuses = {};
				self.types = {};

				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
					'transactions.type.identify', 'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update', 'transactions.type.delete',
					'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
				]).then((translations) => {
					self.types = {
					/* jshint sub:true */
						ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
						ENROLL: translations['transactions.type.enroll'],
						IDENTIFY: translations['transactions.type.identify'],
						VERIFY: translations['transactions.type.verify'],
						VERIFY_UPDATE: translations['transactions.type.verify-update'],
						UPDATE: translations['transactions.type.update'],
						DELETE: translations['transactions.type.delete']
					/* jshint sub:false */
					};

					self.statuses = {
					/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						REJECTED: translations['transactions.status.rejected'],
						OK: translations['transactions.status.ok'],
						MATCHED: translations['transactions.status.matched'],
						NOT_MATCHED: translations['transactions.status.not-matched'],
						DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
					/* jshint sub:false */
					};
				});

				self.isBallFocus = function (index) {
					return self.balls[index] && self.balls[index].isOpen;
				};

				self.setBallFocus = function (isOpen, index) {
					self.balls[index].isOpen = isOpen;
				};
			}]
	}));
