import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ConfUsersCtrl', ['$scope', '$uibModal', '$translate', 'store', 'AuthDataHolder', 'UsersService', 'RolesService', 'GalleriesService', 'CommonValues', 'TaskPollerService', 'ConvertionUtils', 'FilterUtils', 'SortHelper',
		function ($scope, $uibModal, $translate, store, AuthDataHolder, UsersService, RolesService, GalleriesService, CommonValues, TaskPollerService, ConvertionUtils, FilterUtils, SortHelper) {
			const ITEMS_COUNT_STORE_KEY = 'items-per-page';
			$scope.itemsPerPageOptions = [100, 200, 500, 1000];

			$scope.saveItemsPerPageSelection = function () {
				store.set(ITEMS_COUNT_STORE_KEY, $scope.itemsPerPage);
			};

			function initUsersPerPage() {
				function retriveOption() {
					const optionIdx = $scope.itemsPerPageOptions.indexOf(itemsPerPage);
					$scope.itemsPerPage = $scope.itemsPerPageOptions[optionIdx];
				}

				function initDefault() {
					const DEFAULT_OPTION_INDEX = 2;
					$scope.itemsPerPage = $scope.itemsPerPageOptions[DEFAULT_OPTION_INDEX];
					$scope.saveItemsPerPageSelection();
				}

				const itemsPerPage = store.get(ITEMS_COUNT_STORE_KEY);
				if (itemsPerPage !== null) {
					retriveOption();
				} else {
					initDefault();
				}
			}
			initUsersPerPage();

			$scope.totalItems = 0;
			$scope.currentPage = 1;
			$scope.users = [];
			$scope.usersAlerts = [];

			var filterDataInternal = {};
			$scope.filterData = {};
			$scope.sort = SortHelper.create('id', false);

			$scope.states = {};
			$scope.statesAll = {};
			$scope.roles = {};
			$scope.permissions = {};
			$scope.modifiers = {};
			$scope.galleries = {};
			$scope.hasGalleries = false;

			$translate([
				'admin.configuration.users.list.state.ACTIVE',
				'admin.configuration.users.list.state.DISABLED',
				'admin.configuration.users.list.state.DELETED',
				'admin.configuration.users.list.state.SYSTEM',
				'admin.configuration.users.list.state.LDAP'
			]).then((translations) => {
				$scope.states = {
					/* eslint-disable dot-notation */
					ACTIVE: translations['admin.configuration.users.list.state.ACTIVE'],
					DISABLED: translations['admin.configuration.users.list.state.DISABLED']
					/* eslint-enable dot-notation */
				};

				$scope.statesAll = angular.extend(angular.copy($scope.states), {
					/* eslint-disable dot-notation */
					DELETED: translations['admin.configuration.users.list.state.DELETED'],
					SYSTEM: translations['admin.configuration.users.list.state.SYSTEM'],
					LDAP: translations['admin.configuration.users.list.state.LDAP']
					/* eslint-enable dot-notation */
				});
			});

			CommonValues.fillUserPermissions($scope.permissions);
			CommonValues.fillUserModifiers($scope.modifiers);

			RolesService.query((result) => {
				for (var i = 0; i < result.length; i += 1) {
					var entry = result[i];
					$scope.roles[entry.id] = entry.name;
				}
				ConvertionUtils.markDuplicateValuesWithKeys($scope.roles);
			});

			if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
				GalleriesService.getGalleries()
					.then((result) => {
						for (var i = 0; i < result.length; i += 1) {
							var entry = result[i];
							$scope.galleries[entry.id] = entry.name;
						}
						ConvertionUtils.markDuplicateValuesWithKeys($scope.galleries);
						$scope.hasGalleries = result.length > 0;
					});
			}

			$scope.cnv = ConvertionUtils;

			$scope.pageChanged = function () {
				UsersService.query(angular.extend({
					page: $scope.currentPage - 1,
					size: $scope.itemsPerPage,
					sort: $scope.sort.field,
					reverse: $scope.sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					$scope.users = value;
					$scope.totalItems = responseHeaders('X-Total-Count');
				});
			};

			$scope.pageChanged();

			$scope.newUser = function () {
				var scope = $scope.$new(true);
				scope.title = 'admin.configuration.users.new';
				$translate(scope.title).then((translation) => {
					scope.title = translation;
				});
				scope.alerts = [];
				scope.formName = 'newUserForm';
				scope.data = {
					state: 'ACTIVE'
				};
				scope.submitLabel = 'admin.configuration.users.submit';
				$translate(scope.submitLabel).then((translation) => {
					scope.submitLabel = translation;
				});
				scope.submitAction = function (data) {
					var startMsg = 'admin.configuration.users.saving.new';
					var successMsg = 'admin.configuration.users.success.new';
					var successMsgOnList = 'admin.configuration.users.msg.new';
					$translate([startMsg, successMsg, successMsgOnList], { username: data.name }).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							UsersService.save(data, success, error);
							$('.modal').animate({ scrollTop: 0 }, 'slow');
						}, scope.alerts, translations[startMsg], translations[successMsg], () => {
							$scope.usersAlerts.push({ type: 'success', msg: translations[successMsgOnList] });
							$scope.pageChanged();
							scope.$broadcast('userFormClose');
						});
					});
				};
				scope.states = $scope.states;
				scope.roles = Object.entries($scope.roles);
				scope.permissions = Object.entries($scope.permissions);
				scope.modifiers = Object.entries($scope.modifiers);
				scope.galleries = Object.entries($scope.galleries);
				scope.hasGalleries = $scope.hasGalleries;
				scope.passwordRequired = true;
				$uibModal.open({
					template: require('../../../../views/admin/configuration/user-form.html'),
					scope,
					controller: 'UserFormCtrl',
					backdrop: 'static',
					animation: false,
					windowClass: 'modal-admin'
				});
			};

			$scope.deleteUser = function (user) {
				var startMsg = 'admin.configuration.users.saving.delete';
				var successMsg = 'admin.configuration.users.success.delete';
				$translate([startMsg, successMsg], { username: user.name }).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						UsersService.delete({ id: user.id }, success, error);
					}, $scope.usersAlerts, translations[startMsg], translations[successMsg], () => {
						$scope.pageChanged();
					});
				});
			};

			$scope.editUser = function (user) {
				var scope = $scope.$new(true);
				scope.title = 'admin.configuration.users.edit-title';
				$translate(scope.title, { username: user.name }).then((translation) => {
					scope.title = translation;
				});
				scope.alerts = [];
				scope.formName = `editUser${user.id}Form`;
				scope.data = {};
				angular.copy(user, scope.data);
				scope.data.permissions = Object.entries(scope.data.permissions);
				scope.data.roles = Object.entries(scope.data.roles);
				scope.data.modifiers = Object.entries(scope.data.modifiers);
				scope.data.galleries = Object.entries(scope.data.galleries);
				scope.submitLabel = 'admin.configuration.users.save';
				$translate(scope.submitLabel).then((translation) => {
					scope.submitLabel = translation;
				});
				scope.submitAction = function (data) {
					var startMsg = 'admin.configuration.users.saving.update';
					var successMsg = 'admin.configuration.users.success.update';
					$translate([startMsg, successMsg], { username: user.name, userid: user.id }).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							UsersService.save({ id: user.id }, data, success, error);
							$('.modal').animate({ scrollTop: 0 }, 'slow');
						}, scope.alerts, translations[startMsg], translations[successMsg], () => {
							$scope.pageChanged();
						});
					});
				};
				scope.states = $scope.states;
				scope.roles = Object.entries($scope.roles);
				scope.permissions = Object.entries($scope.permissions);
				scope.modifiers = Object.entries($scope.modifiers);
				scope.galleries = Object.entries($scope.galleries);
				scope.hasGalleries = $scope.hasGalleries;
				scope.passwordRequired = false;
				$uibModal.open({
					template: require('../../../../views/admin/configuration/user-form.html'),
					scope,
					controller: 'UserFormCtrl',
					backdrop: 'static',
					windowClass: 'modal-admin'
				});
			};

			function isFormValid() {
				return $scope.filterForm.$valid;
			}

			function copyProcessedFilterData(to) {
				FilterUtils.filterBySingleField($scope.filterData, to, 'name');
				FilterUtils.filterBySingleField($scope.filterData, to, 'state');
				FilterUtils.filterBySingleField($scope.filterData, to, 'email');
				FilterUtils.filterBySingleField($scope.filterData, to, 'fullName');
			}

			$scope.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					$scope.currentPage = 1;
					$scope.pageChanged();
				}
			};

			$scope.resetFilter = function () {
				$scope.filterData = {};
				$scope.filterForm.$setPristine();
				filterDataInternal = {};
				$scope.currentPage = 1;
				$scope.pageChanged();
			};

			$scope.sortBy = function (field) {
				$scope.sort.sort(field);
				$scope.currentPage = 1;
				$scope.pageChanged();
			};
		}]);
