import angular from 'angular';

const blankFace = `${process.env.PUBLIC_URL}/images/blank-face.svg`;
const blankPalm = `${process.env.PUBLIC_URL}/images/blank-palm.svg`;
const blankEye = `${process.env.PUBLIC_URL}/images/blank-eye.svg`;
const blankFinger = `${process.env.PUBLIC_URL}/images/blank-finger.svg`;
const noFingerImage = `${process.env.PUBLIC_URL}/images/no-image-finger.svg`;
const noIrisImage = `${process.env.PUBLIC_URL}/images/no-image-iris.svg`;

angular.module('neurotecAbisWebClientApp')
	.factory('EncounterService', ['EncountersResource', '$q', '$sce', 'AuthDataHolder', function (EncountersResource, $q, $sce, AuthDataHolder) {
		var loader = {};

		loader.irisOrder = ['LEFT', 'RIGHT', 'UNKNOWN'];

		loader.fingerOrder = {
			RIGHT: ['RIGHT_THUMB', 'RIGHT_INDEX_FINGER', 'RIGHT_MIDDLE_FINGER', 'RIGHT_RING_FINGER', 'RIGHT_LITTLE_FINGER'],
			LEFT: ['LEFT_LITTLE_FINGER', 'LEFT_RING_FINGER', 'LEFT_MIDDLE_FINGER', 'LEFT_INDEX_FINGER', 'LEFT_THUMB'],
			SLAP: ['PLAIN_LEFT_FOUR_FINGERS', 'PLAIN_THUMBS', 'PLAIN_RIGHT_FOUR_FINGERS'],
			UNKNOWN: ['UNKNOWN']
		};

		loader.palmOrder = ['RIGHT_FULL_PALM', 'RIGHT_WRITERS_PALM', 'LEFT_FULL_PALM', 'LEFT_WRITERS_PALM', 'RIGHT_LOWER_PALM', 'RIGHT_UPPER_PALM', 'LEFT_LOWER_PALM', 'LEFT_UPPER_PALM'];

		loader.fingerPositions = [...loader.fingerOrder.LEFT, ...loader.fingerOrder.RIGHT, ...loader.fingerOrder.SLAP, ...loader.fingerOrder.UNKNOWN];

		loader.loadFaceImage = function (encounter, encounterId, modalityId) {
			if (!encounter.faces || encounter.faces.length === 0 || 'imageUrl' in encounter.faces[modalityId]) {
				return;
			}

			if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_FACE_IMAGE')) {
				encounter.faces[modalityId].imageUrl = blankFace;
				encounter.faces[modalityId].unclickable = true;
				return;
			}

			EncountersResource.getFaceImage({ encounterId, modalityId }).$promise.then((data) => {
				encounter.faces[modalityId].imageUrl = URL.createObjectURL(data.image);
			}, (response) => {
				if (response.status === 404) {
					encounter.faces[modalityId].image = noFingerImage;
					encounter.faces[modalityId].imageUrl = noFingerImage;
					encounter.faces[modalityId].unclickable = true;
				} else {
					throw new Error(response.statusText);
				}
			});
		};

		loader.loadPalmImage = function (encounter, encounterId, modalityId) {
			if (!encounter.palms || encounter.palms.length === 0 || 'imageUrl' in encounter.palms[modalityId]) {
				return;
			}

			if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_FINGER_IMAGE')) {
				encounter.palms[modalityId].imageUrl = blankPalm;
				encounter.palms[modalityId].unclickable = true;
				return;
			}

			EncountersResource.getPalmImage({ encounterId, modalityId }).$promise
				.then((data) => {
					encounter.palms[modalityId].imageUrl = URL.createObjectURL(data.image);
				}, (response) => {
					if (response.status === 404) {
						encounter.palms[modalityId].image = noFingerImage;
						encounter.palms[modalityId].imageUrl = noFingerImage;
						encounter.palms[modalityId].forceImageType = 'NONE';
						encounter.palms[modalityId].unclickable = true;
					} else {
						throw new Error(response.statusText);
					}
				});
		};

		loader.loadSignatureImage = function (encounter, encounterId) {
			const deferred = $q.defer();
			if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_SIGNATURE_IMAGE')) {
				deferred.resolve();
			} else {
				EncountersResource.getSignatureImage({ encounterId }, (data) => {
					encounter.signature = {};
					encounter.signature = { imageUrl: URL.createObjectURL(data.image) };
					deferred.resolve();
				}, (response) => {
					if (response.status === 404) {
						deferred.resolve();
					} else {
						deferred.reject();
						throw new Error(response.statusText);
					}
				});
			}

			return deferred.promise;
		};

		loader.loadFingerImage = function (encounter, encounterId, modalityId, segmentId) {
			const deferred = $q.defer();
			const endpoint = segmentId === undefined ? EncountersResource.getFingerImage : EncountersResource.getFingerSegmentImage;
			const dataEndpoint = segmentId === undefined ? encounter.fingers[modalityId] : encounter.fingers[modalityId].segments[segmentId];

			if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_FINGER_IMAGE')) {
				dataEndpoint.imageUrl = blankFinger;
				dataEndpoint.unclickable = true;
				return deferred.resolve();
			}

			if (dataEndpoint.imageUrl) {
				return deferred.resolve();
			}
			dataEndpoint.imageUrl = blankFinger;
			endpoint({
				encounterId, modalityId, segmentId, type: 'TRANSPARENT'
			}).$promise
				.then((data) => {
					dataEndpoint.imageUrl = URL.createObjectURL(data.image);
					deferred.resolve();
				}, (response) => {
					if (response.status === 404) {
						dataEndpoint.image = noFingerImage;
						dataEndpoint.imageUrl = noFingerImage;
						dataEndpoint.forceImageType = 'NONE';
						dataEndpoint.unclickable = true;
						deferred.resolve();
					} else {
						deferred.reject();
						throw new Error(response.statusText);
					}
				});
			return deferred.promise;
		};

		loader.createKeysMap = function (keys) {
			var map = new Map();
			keys.forEach((position) => {
				map.set(position, []);
			});
			return map;
		};

		loader.fingersToMap = function (fingersMap, subject) {
			const { fingers } = subject;
			fingers.forEach((finger, index) => {
				finger.modalityId = index;
				finger.positionLookup = finger.position;
				this.appendToMap(subject, fingersMap, finger.position, finger);
				if (finger.segments && finger.segments.length > 0) {
					finger.segments.forEach((segmentFinger, segmentIndex) => {
						segmentFinger.positionLookup = finger.position;
						segmentFinger.modalityId = index;
						segmentFinger.segmentId = segmentIndex;
						this.appendToMap(subject, fingersMap, segmentFinger.position, segmentFinger, segmentIndex);
					});
				}
			});
			return fingersMap;
		};

		loader.sortArrayMap = function (arrayMap) {
			arrayMap.forEach((keyValuePair) => {
				keyValuePair[1].sort((a, b) => b.quality - a.quality);
			});
			return arrayMap;
		};

		loader.appendToMap = function (subject, map, key, value, segmentIndex) {
			let mapValue = map.get(key);
			if (mapValue === undefined) {
				mapValue = [];
			}
			if (value.quality > -1 || value.nfiq !== 'UNKNWON') {
				this.loadFingerImage(subject, subject.encounterId, value.modalityId, value.segmentId);
				this.currentFinger = this.currentFinger || [];
				this.currentFinger[key] = mapValue.length;
				if (value.segmentId !== undefined) {
					this.loadFingerImage(subject, subject.encounterId, value.modalityId).then(() => {
						const parentFinger = this.fingersArrayMap[this.positionsKeys.indexOf(value.positionLookup)][1][value.modalityId];
						this.currentSlapFinger = this.currentSlapFinger || [];
						const prefix = value.position === 'UNKNOWN' ? `${segmentIndex}_` : '';
						this.currentSlapFinger[prefix + value.position] = $sce.trustAsHtml(`<img class="img-responsive" src="${parentFinger.image}" style="background-color: white; width: 200px">`);
					});
				}
			}
			mapValue.push(value);
			map.set(key, mapValue);
		};

		loader.loadFingers = function (subject) {
			return $q((resolve) => {
				subject.fingers = subject.fingers || [];
				this.currentFinger = [];
				this.currentSlapFinger = [];
				subject.fingers.forEach(finger => determineIfModalityFailedToExtract(finger));
				const fingersMap = this.fingersToMap(this.positionsMap, subject);
				this.fingersArrayMap = this.sortArrayMap(Array.from(fingersMap.entries()));
				subject.fingersArrayMap = this.fingersArrayMap;
				subject.fingersMap = this.fingersMap;
				subject.currentFinger = this.currentFinger;
				subject.currentSlapFinger = this.currentSlapFinger;

				this.positionsMap = loader.createKeysMap(this.fingerPositions);
				this.positionsKeys = Array.from(this.positionsMap.keys());
				resolve();
			});
		};

		loader.loadIrises = function (subject, encounterId) {
			function loadIrisImage(encounter, encounterId, modalityId) {
				if ('imageUrl' in encounter.irises[modalityId]) {
					return;
				}

				if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_IRIS_IMAGE')) {
					encounter.irises[modalityId].imageUrl = blankEye;
					encounter.irises[modalityId].unclickable = true;
					return;
				}

				encounter.irises[modalityId].imageUrl = blankEye;

				return EncountersResource.getIrisImage({ encounterId, modalityId }).$promise
					.then((data) => {
						encounter.irises[modalityId].imageUrl = URL.createObjectURL(data.image);
					}, (response) => {
						if (response.status === 404) {
							encounter.irises[modalityId].imageUrl = noIrisImage;
							encounter.irises[modalityId].unclickable = true;
						} else {
							throw new Error(response.statusText);
						}
					});
			}

			subject.irises = subject.irises || [];
			const promises = subject.irises.map((_, i) => loadIrisImage(subject, encounterId, i));

			return $q.all(promises).then(() => {
				for (let i = 0; i < subject.irises.length; i += 1) {
					determineIfModalityFailedToExtract(subject.irises[i]);
				}
				const sortedIrises = [];
				this.irisOrder.forEach((position) => {
					const irises = subject.irises.filter(iris => iris.position === position);
					if (irises.length > 0) {
						sortedIrises.push(...irises);
					}
				});
				subject.irises = sortedIrises;
			});
		};

		function determineIfModalityFailedToExtract(modality) {
			modality.isFailedToExtract = modality.quality === -1;
		}

		loader.loadFaces = function (subject, encounterId) {
			return $q((resolve) => {
				subject.faces = subject.faces || [];
				subject.faces.forEach((value, modalityId) => {
					this.loadFaceImage(subject, encounterId, modalityId);
					determineIfModalityFailedToExtract(value);
				});
				resolve();
			});
		};

		loader.loadPalms = function (subject, encounterId) {
			return $q((resolve) => {
				subject.palms = subject.palms || [];
				subject.palms.forEach((value, modalityId) => {
					this.loadPalmImage(subject, encounterId, modalityId);
					determineIfModalityFailedToExtract(value);
				});
				resolve();
			});
		};

		loader.loadSignature = function (subject, encounterId) {
			return $q((resolve) => {
				this.loadSignatureImage(subject, encounterId)
					.finally(resolve);
			});
		};

		loader.getHit = function (encounter, hit) {
			return EncountersResource.getHit({
				encounterId: encounter,
				modalityId: hit
			}).$promise;
		};

		loader.getHits = function (encounter) {
			return EncountersResource.getHits({
				encounterId: encounter
			}).$promise;
		};

		loader.loadEncounter = function (subject, encounterId) {
			const facePromise = this.loadFaces(subject, encounterId);
			const fingerPromise = this.loadFingers(subject, encounterId);
			const irisesPromise = this.loadIrises(subject, encounterId);
			const palmsPromise = this.loadPalms(subject, encounterId);
			const signaturePromise = this.loadSignature(subject, encounterId);
			return $q.all([facePromise, fingerPromise, irisesPromise, palmsPromise, signaturePromise]);
		};

		loader.positionsMap = loader.createKeysMap(loader.fingerPositions);
		loader.positionsKeys = Array.from(loader.positionsMap.keys());

		return loader;
	}]);
