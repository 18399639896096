import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('OperationParametersTemplates', [
		function () {
			const DEFAULT_PARAMS = {
				lowerThreshold: null,
				topThreshold: null,
				faceThreshold: null,
				fingerThreshold: null,
				irisThreshold: null,
				palmThreshold: null,
				voiceThreshold: null,
				callbackUrl: null,
				maxResults: null,
				fullSearch: null
			};

			const parametersWithoutMaxHits = {
				lowerThreshold: null,
				topThreshold: null,
				faceThreshold: null,
				fingerThreshold: null,
				irisThreshold: null,
				palmThreshold: null,
				voiceThreshold: null,
				callbackUrl: null,
				fullSearch: false
			};
			const ENROLL_WITH_DUPLICATE_CHECK = angular.copy(parametersWithoutMaxHits);
			const VERIFY = angular.copy(parametersWithoutMaxHits);
			const VERIFY_UPDATE = angular.copy(parametersWithoutMaxHits);

			const IDENTIFY = {
				lowerThreshold: null,
				topThreshold: null,
				faceThreshold: null,
				fingerThreshold: null,
				irisThreshold: null,
				palmThreshold: null,
				voiceThreshold: null,
				callbackUrl: null,
				maxResults: null,
				fullSearch: false
			};

			const parametersByType = {
				ENROLL_WITH_DUPLICATE_CHECK,
				VERIFY,
				VERIFY_UPDATE,
				IDENTIFY
			};

			this.getDefaultOptions = function () {
				return angular.copy(DEFAULT_PARAMS);
			};

			this.getParamsByTypeOrDefault = function (type) {
				return angular.copy(parametersByType[type] || DEFAULT_PARAMS);
			};
		}
	]);
