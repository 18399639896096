import angular from 'angular';

const blankFace = `${process.env.PUBLIC_URL}images/blank-face.svg`;

angular.module('neurotecAbisWebClientApp')
	.directive('face', ['$sce', '$translate', 'ParserResource', 'Utils', 'SettingsService', function ($sce, $translate, ParserResource, Utils, SettingsService) { // eslint-disable-line
		return {
			template: require('../../views/directives/face.html'),
			restrict: 'E',
			replace: true,
			scope: {
				face: '=',
				facesScore: '=',
				imgClick: '&',
				clickable: '=',
				showMatchingDetails: '=',
				subjectStatus: '=',
				encounters: '=',
				selectedEncounter: '=',
				encounterChanged: '&',
				encounterPrevChanged: '&',
				encounterNextChanged: '&'
			},
			link(scope, element, attrs) {
				scope.isBlur = SettingsService.getShouldBlurImages().face;

				scope.imageOnError = function () {
					if (scope.face && scope.face.image) {
						if (scope.parsedFace !== null && scope.parsedFace !== undefined) {
							URL.revokeObjectURL(scope.parsedFace);
							scope.parsedFace = null;
						}
						ParserResource.parseImage({ data: scope.face.image }, (result) => {
							scope.parsedFace = URL.createObjectURL(Utils.b64toBlob(result.data));
						});
					}
				};

				scope.hasImgClick = function () {
					return (angular.isDefined(attrs.imgClick) &&
						scope.face != null)
						&& scope.clickable
						&& scope.showMatchingDetails
						&& scope.face.unclickable !== true;
					// scope.face.matchingDetails != null &&
					// scope.face.matchingDetails.length > 0 ;
				};

				scope.imgClickInternal = function () {
					if (scope.hasImgClick()) {
						scope.imgClick();
					}
				};

				/* eslint-disable */
////////////////////////////
////////////////////////////
/////////////
				/* eslint-enable */

				scope.$watch(() => scope.face, (newValue) => {
					/* eslint-disable */
/////////////////////////////
////////////////////
////////////////////////////////////
/////////////////////////////////////////////
///////
//////
//////////////
					/* eslint-enable */

					if (newValue != null && typeof newValue.imageUrl === 'undefined') {
						scope.face.imageUrl = blankFace;
					}
					if (newValue != null) {
						/* eslint-disable */
//////////////////////////////
/////////////////////////////
////////////////////////////
//////////////
						scope.overlay = newValue.isFailedToExtract;
						$translate(['subject.quality']).then((t) => {
							scope.tooltipText = $sce.trustAsHtml(`${newValue.quality ? `<div>${t['subject.quality']}: ${newValue.quality}</div>` : ''}`);
						});
///////////////
					}
				});
				scope.$watch(() => scope.subjectStatus, (newValue) => {
					if (newValue) {
						$translate([`subject.status.${newValue}`]).then((t) => {
							if (newValue) {
								scope.status = t[`subject.status.${newValue}`];
							}
						});
					}
				});
			}
		};
	}]);
