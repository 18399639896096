import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('serviceStatus', () => ({
		template: require('../../../views/directives/admin/service-status.html'),
		restrict: 'E',
		replace: true,
		scope: {
			title: '@',
			services: '='
		},
		controller: ['$scope', 'ConvertionUtils', function ($scope, ConvertionUtils) {
			$scope.alerts = [];
			$scope.statuses = {
				passing: 'Ok'
			};
			$scope.cnv = ConvertionUtils;

			$scope.checkStatus = function (service) {
				switch (service.status) {
				case 'passing':
					return '#28a745';
				case 'warning':
					return '#ffc107';
				default:
				case 'critical':
					return '#dc3545';
				}
			};
		}]
	}));
