import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('matchingMode', () => ({
		template: require('../../../views/directives/admin/matching-mode.html'),
		restrict: 'E',
		scope: {
			mode: '='
		},
		controller: 'matchingModeCtrl',
	}))
	.controller('matchingModeCtrl', ['$scope', function ($scope) {
		function createMatchingMode(mode, title, description) {
			return {
				value: mode,
				title,
				description,
			};
		}

		$scope.modes = {
			FUSED: createMatchingMode('FUSED', 'admin.configuration.parameters.matching-mode.fused.title', 'admin.configuration.parameters.matching-mode.fused.description'),
			FPIR: createMatchingMode('FPIR', 'admin.configuration.parameters.matching-mode.fpir.title', 'admin.configuration.parameters.matching-mode.fpir.description'),
			MODALITY_BASED: createMatchingMode('MODALITY_BASED', 'admin.configuration.parameters.matching-mode.modality-based.title', 'admin.configuration.parameters.matching-mode.modality-based.description'),
			ANOMALOUS: createMatchingMode('ANOMALOUS', 'admin.configuration.parameters.matching-mode.anomalous.title', 'admin.configuration.parameters.matching-mode.anomalous.description')
		};

		$scope.onChange = function (isFpir) {
			$scope.mode = isFpir ? $scope.modes.FPIR.value : $scope.modes.FUSED.value;
		};

		$scope.$watch('mode', (value) => {
			$scope.modeBool = value === $scope.modes.FPIR.value;
		});

		$scope.isFusedOrFpir = function () {
			return $scope.mode === $scope.modes.FUSED.value || $scope.mode === $scope.modes.FPIR.value;
		};
	}]);
