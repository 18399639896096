import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('HotkeyService', ['store',	function (store) {
		var hotkeyErrorMap = {
			switchFront: 'settings.hotkeys.hotkey-switch-front',
			rotateZoomToggle: 'settings.hotkeys.hotkey-rotate-zoom-toggle',
			moveLeft: 'settings.hotkeys.hotkey-move-left',
			moveRight: 'settings.hotkeys.hotkey-move-right',
			moveUp: 'settings.hotkeys.hotkey-move-up',
			moveDown: 'settings.hotkeys.hotkey-move-down'
		};

		var defaultHotkeyBindings = {
			switchFront: 't',
			rotateZoomToggle: 'r',
			moveLeft: 'a',
			moveRight: 'd',
			moveUp: 'w',
			moveDown: 's'
		};
		var hotkeyBindings;

		function load() {
			hotkeyBindings = store.get('hotkeyBindings') ? store.get('hotkeyBindings') : defaultHotkeyBindings;
		}
		load();

		function save() {
			store.set('hotkeyBindings', hotkeyBindings);
		}

		this.getHotkeyErrorValue = function (key) {
			return hotkeyErrorMap[key];
		};

		this.getHotkeyBindings = function () {
			return Object.assign({}, hotkeyBindings);
		};

		this.setHotkeyBindings = function (newBindings) {
			if (newBindings) {
				Object.assign(hotkeyBindings, newBindings);
			}
			save();
		};
	}]);
