import angular from 'angular';
import moment from 'moment';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.service('NotificationsService', ['$interval', '$translate', 'AlertService', 'AuthDataHolder', 'ConvertionUtils', 'store', 'TransactionsResource', function ($interval, $translate, AlertService, AuthDataHolder, ConvertionUtils, store, TransactionsResource) {
		var types = {};
		var statuses = {};

		// TODO: Refactor.
		$translate([
			'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
			'transactions.type.identify', 'transactions.type.verify', 'transactions.type.update', 'transactions.type.delete',
			'transactions.status.registered', 'transactions.status.in-progress',
			'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
			'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
		]).then((translations) => {
			types = {
				/* jshint sub:true */
				ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
				ENROLL: translations['transactions.type.enroll'],
				IDENTIFY: translations['transactions.type.identify'],
				VERIFY: translations['transactions.type.verify'],
				VERIFY_UPDATE: translations['transactions.type.update'],
				DELETE: translations['transactions.type.delete']
				/* jshint sub:false */
			};

			statuses = {
				/* jshint sub:true */
				REGISTERED: translations['transactions.status.registered'],
				IN_PROGRESS: translations['transactions.status.in-progress'],
				ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
				ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
				ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
				REJECTED: translations['transactions.status.rejected'],
				OK: translations['transactions.status.ok'],
				MATCHED: translations['transactions.status.matched'],
				NOT_MATCHED: translations['transactions.status.not-matched'],
				DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
				/* jshint sub:false */
			};
		});

		// TODO: make sure other users can't see current users notifications.
		var storeName = ':notifications';
		var notifications = [];

		var pendingStatuses = ['REGISTERED', 'IN_PROGRESS'];

		function load() {
			notifications = store.get(storeName);
			if (!notifications) {
				notifications = [];
			}
		}
		load();

		function save() {
			store.set(storeName, notifications);
		}

		function clear() {
			store.remove(storeName);
		}

		this.invalidate = function () {
			clear();
			load();
		};

		function check() {
			if (!AuthDataHolder.isAuthenticated()) {
				return;
			}
			function innerCheck(notification) {
				if (!notification.notified) {
					TransactionsResource.get({ id: notification.requestId }, (transaction) => {
						var isFinal = $.inArray(transaction.status, pendingStatuses) === -1;
						if (isFinal) {
							var msg = `${transaction.requestId}# ${transaction.subjectId || ''} ${ConvertionUtils.toReadable(types, transaction.type)} - ${ConvertionUtils.toReadable(statuses, transaction.status)}`;
							AlertService.show(msg, { translate: false, type: 'info' });
							notification.subjectId = transaction.subjectId;
							notification.status = transaction.status;
							notification.notified = true;
							save();
						}
					});
				}
			}
			for (var i = 0; i < notifications.length; i += 1) {
				innerCheck(notifications[i]);
			}
		}

		this.start = function () {
			check();
			$interval(check, 3000);
		};

		this.add = function (requestId) {
			var isAdded = notifications.filter(item => item.requestId === requestId).length;
			if (!isAdded) {
				TransactionsResource.get({ id: requestId }, (transaction) => {
					notifications.unshift({
						requestId: transaction.requestId,
						subjectId: transaction.subjectId,
						type: transaction.type,
						status: transaction.status,
						createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
						notified: false,
						seen: false
					});
					save();
				});
			}
		};

		this.markSeen = function (requestId) {
			const notification = notifications.find(notification => notification.requestId === requestId);
			notification.seen = true;
			save();
		};

		this.get = function () {
			return notifications;
		};
	}]);
