import angular from 'angular';
import moment from 'moment';

angular
	.module('neurotecAbisWebClientApp')
	.service('SubjectService', ['$q', '$rootScope', 'BiometricsService', 'SettingsService', 'store', 'Utils', 'BiographicDataService', '$timeout', 'FingerIdentifier',
		function ($q, $rootScope, BiometricsService, SettingsService, store, Utils, BiographicDataService, $timeout, FingerIdentifier) {
			var self = this;
			var storeName = 'subjectData';
			var subjectData;

			function loadFields() {
				return $((resolve) => {
					BiographicDataService.get().then((result) => {
						self.fields = result;
						resolve(self.fields);
					}, () => {
						$timeout(loadFields, 1000);
					});
				});
			}
			loadFields();

			$rootScope.$watch(() => SettingsService.isStoreSubjectData(), (newValue, oldValue) => {
				if (newValue === true && oldValue === false) {
					store.set(storeName, subjectData);
				} else if (newValue === false && oldValue === true) {
					store.remove(storeName);
				}
			});

			function load() {
				if (SettingsService.isStoreSubjectData()) {
					subjectData = store.get(storeName);
				} else {
					subjectData = null;
				}
				if (!subjectData) {
					subjectData = {
						subjectId: null,
						encounterId: null,
						biographicData: {},
						biometricData: {
							faceImages: [],
							fingerImages: [],
							missingFingers: [],
							irisImages: [],
							missingEyes: [],
							palmImages: [],
							voiceAudios: []
						},
						signatureImage: null
					};
				}
			}
			load();

			function save() {
				if (SettingsService.isStoreSubjectData()) {
					store.set(storeName, subjectData);
				}
			}

			// TODO: Should not be called outside.
			this.save = function () {
				save();
			};

			function clear() {
				store.remove(storeName);
			}

			this.invalidate = function (type) {
				switch (type) {
				case 'fingers':
					subjectData.biometricData.fingerImages = [];
					subjectData.biometricData.missingFingers = [];
					save();
					break;
				case 'faces':
					subjectData.biometricData.faceImages = [];
					save();
					break;
				case 'irises':
					subjectData.biometricData.irisImages = [];
					subjectData.biometricData.missingEyes = [];
					save();
					break;
				case 'palms':
					subjectData.biometricData.palmImages = [];
					save();
					break;
				case 'signature':
					subjectData.signatureImage = null;
					save();
					break;
				case 'voice':
					subjectData.voiceAudios = [];
					save();
					break;
				case 'update':
					subjectData.biometricData.fingerImages = [];
					subjectData.biometricData.missingFingers = [];
					subjectData.biometricData.faceImages = [];
					subjectData.biometricData.irisImages = [];
					subjectData.biometricData.missingEyes = [];
					subjectData.biometricData.palmImages = [];
					subjectData.biometricData.voiceAudios = [];
					subjectData.signatureImage = null;
					subjectData.biographicData = {
						name: null,
						surname: null,
						birthDate: null
					};
					save();
					break;
				default:
					clear();
					load();
				}
			};

			this.getData = function () {
				return subjectData;
			};

			this.getId = function () {
				return subjectData.subjectId;
			};

			this.getEncounterId = function () {
				return subjectData.encounterId;
			};

			this.getModalitiesCounts = function () {
				return {
					facesCount: this.getFacesCount(),
					fingersCount: this.getFingersCount(),
					irisesCount: this.getIrisesCount(),
					palmsCount: this.getPalmsCount(),
					signatureCount: this.getSignatureCount(),
					voiceCount: this.getVoicesCount()
				};
			};

			this.setId = function (id) {
				subjectData.subjectId = id;
				save();
			};

			this.setEncounterId = function (id) {
				subjectData.encounterId = id;
				save();
			};

			this.getBiographicData = function () {
				return subjectData.biographicData;
			};

			this.setBiographicData = function (biographicDataInfo) {
				subjectData.biographicData = angular.copy(biographicDataInfo);
				save();
			};

			this.setBiometricData = function (biometricDataInfo) {
				subjectData.biometricData = biometricDataInfo;
				self.attachFingersIdentifier();
				save();
			};

			this.getFaceData = function () {
				var { biometricData: { faceImages } } = subjectData;
				if (!faceImages.length) {
					return Utils.getBlankBase64Image();
				}
				return faceImages[0].data;
			};

			this.setFaceData = function (base64Image) {
				subjectData.biometricData.faceImages = [];
				var { biometricData: { faceImages } } = subjectData;
				faceImages.push({ data: base64Image });
				save();
			};

			this.getFacesCount = function () {
				var { biometricData: { faceImages } } = subjectData;
				if (faceImages == null) {
					return 0;
				}
				return faceImages.length;
			};

			this.getFingers = function () {
				return subjectData.biometricData.fingerImages;
			};

			this.getFingersCount = function () {
				var steps = subjectData.biometricData.fingerImages;
				var count = 0;
				if (steps != null) {
					for (var i = 0; i < steps.length; i += 1) {
						for (var j = 0; j < steps[i].length; j += 1) {
							if (steps[i][j].status === 'ok') {
								count += 1;
							}
						}
					}
				}
				return count;
			};

			this.removeFingersById = function (fingerId) {
				var steps = subjectData.biometricData.fingerImages;
				for (var j = 0; j < steps.length; j += 1) {
					for (var k = 0; k < steps[j].length; k += 1) {
						if (steps[j][k].id === fingerId) {
							subjectData.biometricData.fingerImages.splice(j, 1);
							save();
							return;
						}
					}
				}
			};

			this.setFingers = function (capturedFingers) {
				var fingerToRemove = BiometricsService.getSlapOrFirstFinger(capturedFingers);
				if (fingerToRemove) {
					self.removeFingersById(fingerToRemove.id);
				}

				subjectData.biometricData.fingerImages.push(capturedFingers);
				save();
			};

			this.attachFingersIdentifier = function () {
				const identifier = FingerIdentifier.start();
				subjectData.biometricData.fingerImages.forEach((step) => {
					step.forEach((finger) => {
						finger.id = identifier.getIdByPosition(finger.position);
					});
				});
			};

			this.setMissingFingers = function (fingersArray) {
				subjectData.biometricData.missingFingers = fingersArray;
			};

			this.getMissingFingers = function () {
				return subjectData.biometricData.missingFingers;
			};

			this.getIrises = function () {
				return subjectData.biometricData.irisImages;
			};

			this.setMissingIrises = function (eyesArray) {
				subjectData.biometricData.missingEyes = eyesArray;
			};

			this.getMissingIrises = function () {
				return subjectData.biometricData.missingEyes;
			};

			this.setIrises = function (irisesInfo) {
				function isNewIrisPair() {
					return irisesInfo.length > 1 && irisesInfo[0].position === 'LEFT';
				}

				function isNewIrisBoth() {
					return irisesInfo[0].position === 'BOTH';
				}

				function isOldIrisBoth() {
					return subjectData.biometricData.irisImages.length > 0 && subjectData.biometricData.irisImages[0].position === 'BOTH';
				}

				if (isNewIrisPair() || isNewIrisBoth() || isOldIrisBoth()) {
					subjectData.biometricData.irisImages = [];
				}

				irisesInfo.forEach((iris) => {
					const index = subjectData.biometricData.irisImages.map(i => i.position).indexOf(iris.position);
					if (index === -1) {
						subjectData.biometricData.irisImages.push({
							position: iris.position,
							data: iris.image
						});
					} else {
						subjectData.biometricData.irisImages[index] = {
							position: iris.position,
							data: iris.image
						};
					}
				});
				save();
			};

			this.removeIrisByPosition = function (irisArray) {
				for (let i = 0; i < subjectData.biometricData.irisImages.length; i += 1) {
					if (irisArray.indexOf(subjectData.biometricData.irisImages[i].position) !== -1) {
						subjectData.biometricData.irisImages.splice(i, 1);
						i -= 1;
					}
				}
			};

			this.removeIrisByIndex = function (index) {
				subjectData.biometricData.irisImages.splice(index, 1);
			};

			this.getIrisesCount = function () {
				var { biometricData: { irisImages } } = subjectData;
				if (irisImages == null) {
					return 0;
				}
				return irisImages.length;
			};

			this.getPalms = function () {
				return subjectData.biometricData.palmImages;
			};

			this.removePalmsByPosition = function (position) {
				var steps = subjectData.biometricData.palmImages;
				for (var j = 0; j < steps.length; j += 1) {
					for (var k = 0; k < steps[j].length; k += 1) {
						if (steps[j][k].position === position) {
							subjectData.biometricData.palmImages.splice(j, 1);
							save();
							return;
						}
					}
				}
			};

			this.setPalms = function (capturedPalms) {
				var positionToRemove = capturedPalms[0].position;
				if (positionToRemove) {
					self.removePalmsByPosition(positionToRemove);
				}
				subjectData.biometricData.palmImages.push(capturedPalms);
				save();
			};

			this.getPalmsCount = function () {
				var { biometricData: { palmImages } } = subjectData;
				if (palmImages == null) {
					return 0;
				}
				return palmImages.length;
			};

			this.getSignature = function () {
				return subjectData.signatureImage;
			};

			this.setSignatureData = function (base64Image) {
				subjectData.signatureImage = base64Image;
				save();
			};

			this.setSignature = function (signatureImage) {
				subjectData.signatureImage = signatureImage;
			};

			this.getSignatureCount = function () {
				var { signatureImage } = subjectData;
				if (signatureImage == null) {
					return 0;
				}
				return 1;
			};

			this.getSignatureData = function () {
				var { signatureImage } = subjectData;
				if (!signatureImage) {
					return Utils.getBlankBase64Image();
				}
				return signatureImage;
			};

			this.setVoiceData = function (base64Image) {
				subjectData.voiceAudios = base64Image;
				save();
			};

			this.setVoice = function (voiceAudios) {
				subjectData.voiceAudios = voiceAudios;
			};

			this.getVoice = function () {
				return subjectData.biometricData.voiceAudios;
			};

			this.getVoicesCount = function () {
				return subjectData.biometricData.voiceAudios.length;
			};

			this.getVoiceData = function () {
				var { voiceAudios } = subjectData;
				if (!voiceAudios) {
					return Utils.getBlankBase64Image();
				}
				return voiceAudios;
			};

			this.getSexOptions = function () {
				return [
					{ value: 'Unspecified', name: 'subject.sex.options.unspecified' },
					{ value: 'Male', name: 'subject.sex.options.m' },
					{ value: 'Female', name: 'subject.sex.options.f' }
				];
			};

			this.getDto = function (useISO) {
				var data = {};
				angular.copy(subjectData, data);

				this.fields.forEach((field) => {
					if (data.biographicData && data.biographicData[field.key]) {
						if (field.type === 'DATE' || field.type === 'DATE_TIME') {
							let f = '';
							if (useISO) {
								f = (field.type === 'DATE_TIME') ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
							} else {
								f = field.viewFormat.replace(/y/g, 'Y').replace(/d/g, 'D');
							}
							data.biographicData[field.key] = moment(data.biographicData[field.key]).format(f);
						} else if (field.type === 'ENUM') {
							const t = Object.entries(field.values);
							const [f] = t.filter(item => item.includes(data.biographicData[field.key]));
							[data.biographicData[field.key]] = f;
						}
					}
				});

				if (this.getFingersCount() > 0) {
					var flatteredFingers = [];
					for (var i = 0; i < data.biometricData.fingerImages.length; i += 1) {
						var step = data.biometricData.fingerImages[i];
						for (var k = 0; k < step.length; k += 1) {
							if (step[k].status === 'ok') {
								flatteredFingers.push({
									position: step[k].position,
									data: step[k].data
								});
							}
						}
					}
					data.biometricData.fingerImages = flatteredFingers;
				}
				if (this.getIrisesCount() > 0) {
					for (var j = 0; j < data.biometricData.irisImages.length; j += 1) {
						if (data.biometricData.irisImages[j].position === 'BOTH') {
							data.biometricData.irisImages.splice(j, 1);
						}
					}
				}
				if (this.getPalmsCount() > 0) {
					var flatteredPalms = [];
					for (var l = 0; l < data.biometricData.palmImages.length; l += 1) {
						var step2 = data.biometricData.palmImages[l];
						for (var m = 0; m < step2.length; m += 1) {
							if (step2[m].status === 'ok') {
								flatteredPalms.push({
									position: step2[m].position,
									data: step2[m].data
								});
							}
						}
					}
					data.biometricData.palmImages = flatteredPalms;
				}
				return data;
			};

			this.hasBiometrics = function () {
				if (this.getFacesCount() +
				this.getFingersCount() +
				this.getIrisesCount() +
				this.getPalmsCount() +
				this.getVoicesCount() > 0) {
					return true;
				}
				return false;
			};
		}]);
