import { Reducer } from 'redux';
import { IAuthData, DefaultAuthData, AuthDataActionTypes } from './types';

export const initialAuthDataState: IAuthData = DefaultAuthData();

const AuthReducer: Reducer<IAuthData, AuthDataActionTypes> = (
	state = initialAuthDataState,
	action: AuthDataActionTypes
) => {
	if (action.type === 'UPDATE_AUTH_DATA') {
		return action.payload;
	}
	if (action.type === 'RESET_AUTH_DATA') {
		return DefaultAuthData();
	}
	return state;
};

export default AuthReducer;
