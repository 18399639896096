import { NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type JustifyContent = 'justify';
type JustifyContentItems = `${JustifyContent}${NLayoutViewPorts}`;
type JustifyContentValues = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
export type JustifyContentProps = { [Property in JustifyContentItems]: JustifyContentValues } & {
	[Property in JustifyContent]: JustifyContentValues;
};

const getJustifyClasses = (suffix?: NLayoutViewPorts): Array<[string, JustifyContentValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-justify-content-${suffix.toLowerCase()}-start`, 'flex-start'],
			[`fs-justify-content-${suffix.toLowerCase()}-end`, 'flex-end'],
			[`fs-justify-content-${suffix.toLowerCase()}-center`, 'center'],
			[`fs-justify-content-${suffix.toLowerCase()}-between`, 'space-between'],
			[`fs-justify-content-${suffix.toLowerCase()}-around`, 'space-around'],
		];
	}
	return [
		[`fs-justify-content-start`, 'flex-start'],
		[`fs-justify-content-end`, 'flex-end'],
		[`fs-justify-content-center`, 'center'],
		[`fs-justify-content-between`, 'space-between'],
		[`fs-justify-content-around`, 'space-around'],
	];
};

const useJustifyContentClasses = (props: Partial<JustifyContentProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'justify', getJustifyClasses),
	});
	return [containerClass, classes];
};

export default useJustifyContentClasses;
