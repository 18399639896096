import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('irises', () => ({
		template: require('../../views/directives/irises.html'),
		restrict: 'E',
		replace: true,
		scope: {
			irises: '=',
			irisesScore: '=',
			imgClick: '=',
			showMatchingDetails: '=',
			noHover: '='
		},
		link(scope) {
			const IRIS_ORDER = ['RIGHT', 'LEFT', 'UNKNOWN'];

			function isIrisesEmpty() {
				return !scope.irises || scope.irises.length === 0;
			}

			function update() {
				scope.irisOrder = [];
				IRIS_ORDER.forEach((position) => {
					if (!isIrisesEmpty()) {
						scope.irises.forEach((iris, index) => {
							if (position === iris.position) {
								scope.irisOrder.push({
									position,
									index
								});
							}
						});
					}
				});
			}

			scope.$watch(() => scope.irises, update);
		}
	}));
