export const AuthorityList = [
	'PERMISSION_ADMINISTRATION_METRICS',
	'PERMISSION_TOOLS_FINGER_EXAMINER',
	'PERMISSION_USER_VIEW',
	'MODIFIER_TRANSACTION_ALL_USERS',
	'PERMISSION_USER_LIST',
	'PERMISSION_ADMINISTRATION_DEVELOPMENT',
	'PERMISSION_HITS_BASE',
	'PERMISSION_ADJUDICATION_COUNT',
	'PERMISSION_TRANSACTION_COUNT',
	'PERMISSION_ENCOUNTER_EXPORT',
	'MODIFIER_ADJUDICATION_SUPERVISOR',
	'PERMISSION_ADMINISTRATION_ROLES',
	'PERMISSION_ADMINISTRATION_TOOLS_RETROSPECTIVE_MODIFICATION',
	'MODIFIER_ADJUDICATION_ALL_USERS',
	'PERMISSION_BIOMETRIC_VERIFY_UPDATE',
	'PERMISSION_ADJUDICATION_VIEW',
	'PERMISSION_ENCOUNTER_IRIS_IMAGE',
	'PERMISSION_ADJUDICATION_LIST',
	'PERMISSION_ADMINISTRATION_TOOLS_NFIQ',
	'PERMISSION_BIOMETRIC_ENROLL',
	'PERMISSION_ENCOUNTER_LIST',
	'PERMISSION_UI_DATA_IMPORT',
	'PERMISSION_USER_MODIFY',
	'PERMISSION_GALLERY_LIST',
	'PERMISSION_ENCOUNTER_FINGER_FEATURES',
	'PERMISSION_ADMINISTRATION_BIOGRAPHIC_FIELDS',
	'PERMISSION_ENCOUNTER_FACE_FEATURES',
	'PERMISSION_ENCOUNTER_PALM_IMAGE',
	'PERMISSION_BIOMETRIC_IDENTIFY',
	'PERMISSION_ADJUDICATION_SOLVE',
	'PERMISSION_BIOMETRIC_VERIFY',
	'PERMISSION_OTHER_LATENT_PRINT',
	'PERMISSION_ENCOUNTER_FACE_IMAGE',
	'PERMISSION_HITS_DETAILS',
	'PERMISSION_ADMINISTRATION_TOOLS_CLEAR',
	'PERMISSION_ENCOUNTER_FINGER_IMAGE',
	'MODIFIER_GALLERY_LIST_ALL',
	'MODIFIER_GALLERY_PARAMETERS_LIST_ALL',
	'PERMISSION_BIOMETRIC_UPDATE',
	'PERMISSION_GALLERY_VIEW',
	'PERMISSION_ENCOUNTER_VIEW',
	'PERMISSION_ADJUDICATION_REPORT',
	'PERMISSION_ADMINISTRATION_TASKS',
	'PERMISSION_GALLERY_MODIFY',
	'PERMISSION_ENCOUNTER_SIGNATURE_IMAGE',
	'PERMISSION_ENCOUNTER_VOICE_AUDIO',
	'PERMISSION_BIOMETRIC_DELETE',
	'PERMISSION_ENCOUNTER_PALM_FEATURES',
	'PERMISSION_GALLERY_DELETE',
	'PERMISSION_TRANSACTION_VIEW',
	'PERMISSION_BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK',
	'PERMISSION_SUBJECT_VIEW',
	'PERMISSION_SUBJECT_LIST',
	'PERMISSION_TRANSACTION_LIST',
	'PERMISSION_ADMINISTRATION_PARAMETERS',
	'PERMISSION_ADMINISTRATION_DASHBOARD',
	'PERMISSION_ADMINISTRATION_LICENSING',
	'PERMISSION_TRANSACTION_REPORT',
	'PERMISSION_BIOMETRIC_SWITCH_PRIMARY',
	'PERMISSION_GALLERY_PARAMETERS_LIST',
	'PERMISSION_GALLERY_PARAMETERS_VIEW',
	'PERMISSION_GALLERY_PARAMETERS_MODIFY',
	'PERMISSION_GALLERY_PARAMETERS_DELETE',
] as const;

export type Authority = typeof AuthorityList[number];
export const UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA';
export const RESET_AUTH_DATA = 'RESET_AUTH_DATA';

export interface IUserInfo {
	authorities: Authority[];
	email: string;
	id: number;
	userName: string;
}

export interface IAuthData {
	accessToken: string;
	tokenType: string;
	refreshToken: string;
	validUntil: number;
	userInfo: IUserInfo;
}

export const DefaultAuthData = (): IAuthData => {
	return {
		accessToken: '',
		refreshToken: '',
		tokenType: '',
		validUntil: 0,
		userInfo: {
			authorities: [],
			email: '',
			id: 0,
			userName: '',
		},
	};
};

export interface UpdateAuthDataAction {
	type: typeof UPDATE_AUTH_DATA;
	payload: IAuthData;
}

export interface ResetAuthDataAction {
	type: typeof RESET_AUTH_DATA;
}

export type AuthDataActionTypes = UpdateAuthDataAction | ResetAuthDataAction;
