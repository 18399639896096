import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('DeviceServerParametersResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/parameters/device-server/`, { property: '@property' }, {
			getParameters: {
				method: 'GET',
				isArray: true,
				transformResponse(parametersJson) {
					const parameters = JSON.parse(parametersJson);
					if (Array.isArray(parameters)) {
						return parameters.map((parameter) => {
							const parametersResponse = { name: parameter.name };
							try {
								parametersResponse.value = JSON.parse(parameter.value);
							} catch (error) {
								parametersResponse.value = parameter.value;
							}
							return parametersResponse;
						});
					}
					return parameters;
				}
			},
			setParameters: {
				method: 'POST',
			},
		});
	}]);
