import { addToClassesFun, extractClassesFun, isSpanValue, SpanItems } from '../types/NLibrary';

export const objToClasses: addToClassesFun = (prop, accessor, toStringFun) => {
	if (prop && !isSpanValue(prop)) {
		const p = prop[accessor] ?? null;
		if (p !== null) {
			return toStringFun(p);
		}
	}
	return null;
};

export const mixedToClasses: addToClassesFun = (prop, accessor, toStringFun) => {
	if (prop) {
		if (isSpanValue(prop)) {
			return toStringFun(prop);
		}

		const p = prop[accessor] ?? null;
		if (p !== null) {
			return toStringFun(p);
		}
	}
	return null;
};

export const useExtractColProps: extractClassesFun = (props, accessor, prefix, toClasses) => {
	const viewPorts: SpanItems[] = ['sm', 'md', 'lg', 'xl'];
	const classes: string[] = [];

	const className = toClasses(props.xs, accessor, (layout) =>
		typeof layout === 'boolean' ? prefix : `${prefix}-${layout}`
	);
	if (className !== null) classes.push(className);
	viewPorts.forEach((viewPort) => {
		const prop = props[viewPort];
		const className = toClasses(prop, accessor, (layout) =>
			typeof layout === 'boolean' ? `${prefix}-${viewPort}` : `${prefix}-${viewPort}-${layout}`
		);
		if (className !== null) classes.push(className);
	});
	return classes;
};

export default useExtractColProps;
