import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('RoleFormCtrl', ['$scope', '$translate', function ($scope, $translate) {
		const roleIdPattern = /^[A-Z_]*$/;

		let roleIdInvalidChars = 'admin.configuration.roles.errors.invalid-chars';
		let roleIdInvalidConvention = 'admin.configuration.roles.errors.invalid-convention';

		$translate([
			roleIdInvalidChars, roleIdInvalidConvention
		]).then((translations) => {
			({ [roleIdInvalidChars]: roleIdInvalidChars } = translations);
			({ [roleIdInvalidConvention]: roleIdInvalidConvention } = translations);
		});

		function isValidRoleId() {
			const element = document.forms[$scope.formName].elements.id;
			if ($scope.data.id) {
				if (!roleIdPattern.test($scope.data.id)) {
					element.setCustomValidity(roleIdInvalidChars);
					return false;
				} else if ($scope.data.id.charAt(0) === '_' || $scope.data.id.charAt($scope.data.id.length - 1) === '_') {
					element.setCustomValidity(roleIdInvalidConvention);
					return false;
				}
			}
			element.setCustomValidity('');
			return true;
		}

		$scope.submitForm = function () {
			$scope.payload = {};
			Object.assign($scope.payload, $scope.data);
			function transformData() {
				['permissions', 'modifiers'].forEach((selectable) => {
					if ($scope.data[selectable]) {
						$scope.payload[selectable] = $scope.data[selectable].map(elem => elem[0]);
					}
				});
			}

			if (isValidRoleId() && $scope[$scope.formName].$valid) {
				transformData();
				$scope.submitAction($scope.payload);
			}
		};

		$scope.$on('roleFormClose', () => {
			$scope.$close();
		});
	}]);
