import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.controller('LocalesEditorCtrl', ['$scope', '$translate', function ($scope, $translate) {
		$scope.editing = false;
		$scope.translationsDefault = null;
		$scope.translationsTarget = null;
		$scope.translationsTargetFilename = 'filename';
		$scope.translationsDefaultFilename = 'filename';
		$scope.templateItems = null;
		$scope.missingKeys = null;
		$scope.currentMissingKey = null;
		$scope.hasFlatKeysOnly = null;
		$scope.alerts = [];

		$scope.uploadTranslationsDefault = function (ev) {
			$scope.alerts = [];
			uploadFilePromise(ev).then((data) => {
				$scope.translationsDefault = {};
				$scope.translationsDefaultFilename = data.filename;
				$scope.hasFlatKeysOnly = true;
				objectMap($scope.translationsDefault, data.result, undefined, true);
			}).catch((error) => {
				$scope.alerts.push({ type: 'danger', msg: `[${error.filename}] ${error.message}` });
			});
		};

		$scope.createTranslationsTarget = function () {
			$scope.alerts = [];
			$scope.translationsTarget = {};
			$scope.translationsTargetFilename = 'locale-xy_XY.json';
			objectMap($scope.translationsTarget, {});
		};

		$scope.uploadTranslationsTarget = function (ev) {
			$scope.alerts = [];
			uploadFilePromise(ev).then((data) => {
				$scope.translationsTarget = {};
				$scope.translationsTargetFilename = data.filename;
				objectMap($scope.translationsTarget, data.result);
			}).catch((error) => {
				$scope.alerts.push({ type: 'danger', msg: `[${error.filename}] ${error.message}` });
			});
		};

		$scope.dragoverHandler = function (ev) {
			ev.preventDefault();
		};

		$scope.startEditing = function () {
			$scope.editing = true;
			updateView();
		};

		$scope.dotsToDashes = function (str) {
			return str.replace(/\./g, '-');
		};

		$scope.gotoPreviousMissingKey = function () {
			var len = $scope.missingKeys.length;
			if ($scope.currentMissingKey - 1 < 0) {
				$scope.currentMissingKey = len;
			} else {
				$scope.currentMissingKey -= 1;
			}
			document.getElementById(`js-missing-key-${$scope.dotsToDashes($scope.missingKeys[$scope.currentMissingKey])}`).scrollIntoView();
		};

		$scope.gotoNextMissingKey = function () {
			var len = $scope.missingKeys.length;
			if ($scope.currentMissingKey + 1 > len) {
				$scope.currentMissingKey = 0;
			} else {
				$scope.currentMissingKey += 1;
			}
			document.getElementById(`js-missing-key-${$scope.dotsToDashes($scope.missingKeys[$scope.currentMissingKey])}`).scrollIntoView();
		};

		$scope.gotoTop = function () {
			window.scrollTo(0, 0);
			$scope.currentMissingKey = -1;
		};

		$scope.onInput = function (ev) {
			var key = ev.name;
			var { value } = ev;
			var index = $scope.missingKeys.indexOf(key);

			$scope.translationsTarget[key] = value;
			var isMissing = index !== -1;
			if ((value === '' && !isMissing) || (value !== '' && isMissing)) {
				updateMissigKeys();
			}
		};

		$scope.$watch(() => window.scrollY, (newValue) => {
			$scope.scrollY = newValue;
		});

		$scope.downloadTranslation = function () {
			var text = generateTranslation();
			var filename = 'translation';
			var blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
			FileSaver.saveAs(blob, `${filename}.json`);
		};

		function generateTranslation() {
			function namespace(obj, nsString, nsValue) {
				var parts = nsString.split('.');
				var parent = obj;
				var i;
				for (i = 0; i < parts.length; i += 1) {
					if (typeof parent[parts[i]] === 'undefined') {
						parent[parts[i]] = i === parts.length - 1 ? nsValue : {};
					}
					parent = parent[parts[i]];
				}
				return obj;
			}

			var updatedTranslation = {};

			// NOTE: use translationsTarget if one wants to preserve old keys
			// for (var key in translationsTarget) {
			if ($scope.hasFlatKeysOnly) {
				// web admin locales
				updatedTranslation = $scope.translationsTarget;
			} else {
				// web client locales
				Object.keys($scope.translationsDefault).forEach((key) => {
					// NOTE: in case of nsString as string with dots e.g: 'Canceling...'
					// and single level translation files, don't namespace at all
					namespace(updatedTranslation, key, $scope.translationsTarget[key]);
				});
			}
			return JSON.stringify(updatedTranslation, null, 4);
		}

		function updateMissigKeys() {
			$scope.missingKeys = [];
			Object.keys($scope.translationsDefault).forEach((key) => {
				if (!$scope.translationsTarget[key]) {
					$scope.missingKeys.push(key);
				}
			});
			var message = 'admin.tools.locales-editor.missing-translations';
			$translate(message, { count: $scope.missingKeys.length }).then((translation) => {
				$scope.missingKeysMessage = translation;
			});
			$scope.currentMissingKey = -1;
		}

		function updateView() {
			if ($scope.translationsDefault && $scope.translationsTarget) {
				$scope.templateItems = [];
				updateMissigKeys();
				Object.keys($scope.translationsDefault).forEach((mapItem) => {
					$scope.templateItems.push({
						key: mapItem,
						translationsDefault: $scope.translationsDefault[mapItem],
						translationsTarget: $scope.translationsTarget[mapItem] === undefined ? '' : $scope.translationsTarget[mapItem],
						missingKeys: $scope.missingKeys
					});
				});
			}
		}

		function objectMap(keyValuePairs, nodes, path, isDefault) {
			path = path || [];
			Object.keys(nodes).forEach((node) => {
				var currPath = path.slice();
				currPath.push(node);
				if (typeof nodes[node] === 'string') {
					keyValuePairs[currPath.join('.')] = nodes[node];
				} else if (typeof nodes[node] === 'object') {
					// if default locale file contains only flat keys, it might contain dots, so keep flag to track it.
					if (isDefault) {
						$scope.hasFlatKeysOnly = false;
					}
					objectMap(keyValuePairs, nodes[node], currPath, isDefault);
				}
			});
		}

		function uploadFilePromise(ev) {
			return new Promise(((resolve, reject) => {
				var f = null;
				var fr = null;
				ev.preventDefault();
				var dt = ev.dataTransfer;
				function resolveLoaded(e) {
					var parsedResult;
					try {
						parsedResult = $.parseJSON(e.currentTarget.result);
						resolve({
							filename: f.name,
							result: parsedResult
						});
					} catch (error) {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject({
							filename: f.name,
							message: error.message
						});
					}
				}
				if (dt && dt.items) {
					// drag & drop
					for (var i = 0; i < dt.items.length; i += 1) {
						if (dt.items[i].kind === 'file') {
							f = dt.items[i].getAsFile();
							fr = new FileReader();
							fr.onloadend = resolveLoaded;
							fr.readAsText(f);
						}
					}
				} else {
					// browse...
					var input = ev.target;
					[f] = input.files;
					fr = new FileReader();
					fr.onloadend = resolveLoaded;
					fr.readAsText(f);
				}
			}));
		}
	}]);
