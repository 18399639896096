import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('resolution', () => ({
		template: require('../../../views/directives/latent-editor/resolution.html'),
		restrict: 'E',
		replace: true,
		scope: {
			resolution: '=',
			global: '='
		}
	}));
