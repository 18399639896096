import React from 'react';
import './App.css';

const App: React.FC = () => {
	return (
		<div ng-app="neurotecAbisWebClientApp">
			<div translate-cloak="app.client" ui-view=""></div>
		</div>
	);
};

export default App;
