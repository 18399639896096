import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('UserFormCtrl', ['$scope', '$translate', function ($scope, $translate) {
		var USERNAME_MIN_LENGTH = 4;
		var USERNAME_MAX_LENGTH = 64;
		var PASSWORD_MIN_LENGTH = 8;

		// eslint-disable-next-line no-useless-escape
		var usernamePattern = /^[a-zA-Z0-9_\-\.]*$/;
		var passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;

		var usernameTooShort = 'admin.configuration.users.requirements.username-too-short';
		var usernameTooLong = 'admin.configuration.users.requirements.too-long';
		var usernameInvalidChars = 'admin.configuration.users.requirements.invalid_chars';
		var passwordTooShort = 'admin.configuration.users.requirements.pwd-too-short';
		var passwordWeak = 'admin.configuration.users.requirements.pwd-weak';
		var passwordsDoNotMatch = 'admin.configuration.users.requirements.pwd-no-match';
		var permissionMissing = 'admin.configuration.users.requirements.permissions-missing';

		$translate([
			usernameTooShort, usernameTooLong, usernameInvalidChars
		], {
			minLength: USERNAME_MIN_LENGTH, maxLength: USERNAME_MAX_LENGTH
		}).then((translations) => {
			/* eslint-disable prefer-destructuring */
			usernameTooShort = translations[usernameTooShort];
			usernameTooLong = translations[usernameTooLong];
			usernameInvalidChars = translations[usernameInvalidChars];
			/* eslint-enable prefer-destructuring */
		});

		$translate([
			passwordTooShort, passwordWeak, passwordsDoNotMatch
		], { minLength: PASSWORD_MIN_LENGTH }).then((translations) => {
			/* eslint-disable prefer-destructuring */
			passwordTooShort = translations[passwordTooShort];
			passwordWeak = translations[passwordWeak];
			passwordsDoNotMatch = translations[passwordsDoNotMatch];
			/* eslint-enable prefer-destructuring */
		});

		$translate([
			permissionMissing,
		]).then((translations) => {
			/* eslint-disable prefer-destructuring */
			permissionMissing = translations[permissionMissing];
			/* eslint-enable prefer-destructuring */
		});

		function isValidName() {
			var element = document.forms[$scope.formName].elements.name;
			if ($scope.data.name) {
				if ($scope.data.name.length < USERNAME_MIN_LENGTH) {
					element.setCustomValidity(usernameTooShort);
					return false;
				} else if ($scope.data.name.length > USERNAME_MAX_LENGTH) {
					element.setCustomValidity(usernameTooLong);
					return false;
				} else if (!usernamePattern.test($scope.data.name)) {
					element.setCustomValidity(usernameInvalidChars);
					return false;
				}
			}
			element.setCustomValidity('');
			return true;
		}

		function isValidPassword() {
			var element = document.forms[$scope.formName].elements.password;
			if ($scope.data.password) {
				if ($scope.data.password.length < PASSWORD_MIN_LENGTH) {
					element.setCustomValidity(passwordTooShort);
					return false;
				} else if (!passwordPattern.test($scope.data.password)) {
					element.setCustomValidity(passwordWeak);
					return false;
				}
			}
			element.setCustomValidity('');
			return true;
		}

		function isValidRepeatPassword() {
			var element = document.forms[$scope.formName].elements.repeatPassword;
			if ($scope.passwordRequired || $scope.data.password) {
				if (element.value !== $scope.data.password) {
					element.setCustomValidity(passwordsDoNotMatch);
					return false;
				}
			}
			element.setCustomValidity('');
			return true;
		}

		function isPermissionSet() {
			const elementPermissions = document.forms[$scope.formName].elements.permissions;
			const elementRoles = document.forms[$scope.formName].elements.roles;
			let element = null;
			elementPermissions.setCustomValidity('');
			elementRoles.setCustomValidity('');
			if ((!$scope.data.roles || !$scope.data.roles.length) &&
				(!$scope.data.permissions || !$scope.data.permissions.length)) {
				element = $scope.advancedVisible === true ? elementPermissions : elementRoles;
				element.setCustomValidity(permissionMissing);
				return false;
			}
			return true;
		}

		$scope.advancedVisible = ($scope.data.permissions && $scope.data.permissions.length) || ($scope.data.modifiers && $scope.data.modifiers.length);

		$scope.submitUserForm = function () {
			$scope.payload = {};
			Object.assign($scope.payload, $scope.data);
			function refactorData() {
				['permissions', 'roles', 'modifiers', 'galleries'].forEach((selectable) => {
					if ($scope.data[selectable]) {
						const refactored = [];
						$scope.data[selectable].forEach((elem) => {
							refactored.push(elem[0]);
						});
						$scope.payload[selectable] = refactored;
					}
				});
			}

			if (isValidName() && isValidPassword() && isValidRepeatPassword() && $scope[$scope.formName].$valid && isPermissionSet()) {
				refactorData();
				$scope.submitAction($scope.payload);
			}
		};

		$scope.$on('userFormClose', () => {
			$scope.$close();
		});
	}]);
