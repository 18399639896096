import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('AuthService', ['$q', '$timeout', 'AuthDataHolder', 'OAuth2Resource', 'AuthResource', function ($q, $timeout, AuthDataHolder, OAuth2Resource, AuthResource) {
		this.login = function (username, password) {
			var deferred = $q.defer();
			AuthDataHolder.invalidate();

			function getUserInfo() {
				AuthResource.getUser((userInfo) => {
					var hasAccess = false;
					for (var i = 0; i < userInfo.authorities.length; i += 1) {
						hasAccess = userInfo.authorities[i].indexOf('MODIFIER_') !== 0 &&
								userInfo.authorities[i].indexOf('PERMISSION_ADMINISTRATION_') !== 0;
						if (hasAccess) {
							break;
						}
					}
					if (hasAccess) {
						AuthDataHolder.setUserInfo(userInfo);
						deferred.resolve();
					} else {
						AuthDataHolder.invalidate();
						deferred.reject('auth.access-violation');
					}
				}, () => {
					AuthDataHolder.invalidate();
					deferred.reject('auth.failed-to-get-user-info');
				});
			}

			function login() {
				deferred.notify('auth.logging-in');
				OAuth2Resource.token({
					// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
					username,
					password,
					grant_type: 'password'
					// jscs:enable requireCamelCaseOrUpperCaseIdentifiers
				}, (response) => {
					AuthDataHolder.authenticate(response);
					if (AuthDataHolder.isAuthenticated()) {
						getUserInfo();
					} else {
						deferred.reject('auth.unexpected-error');
					}
				}, (httpResponse) => {
					if (httpResponse.data.error === 'invalid_grant') {
						deferred.reject('auth.invalid-credentials');
					} else if (httpResponse.status === -1 || httpResponse.status === 502 || httpResponse.status === 504) {
						deferred.reject('auth.auth-service-unavailable');
					} else {
						if (httpResponse.data.error_description) {
							// eslint-disable-next-line no-console
							console.error(`Authentication failed: ${httpResponse.data.error_description}`);
						}
						deferred.reject('auth.unexpected-response');
					}
				});
			}

			$timeout(() => {
				deferred.notify('auth.checking-availability');

				AuthResource.ping(() => {
					login();
				}, (httpResponse) => {
					if (httpResponse.status !== -1 && httpResponse.status !== 502 && httpResponse.status !== 504) {
						login();
					} else {
						deferred.reject('auth.service-unavailable');
					}
				});
			});

			return deferred.promise;
		};

		this.renew = function () {
			return $q((resolve, reject) => {
				OAuth2Resource.token({
					// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
					grant_type: 'refresh_token',
					refresh_token: AuthDataHolder.getRefreshToken()
					// jscs:enable requireCamelCaseOrUpperCaseIdentifiers
				}, (response) => {
					AuthDataHolder.authenticate(response);
					if (AuthDataHolder.isAuthenticated()) {
						resolve();
					} else {
						reject('auth.unexpected-reauth-error');
					}
				}, (httpResponse) => {
					if (httpResponse.status === -1 || httpResponse.status === 502 || httpResponse.status === 504) {
						reject('auth.auth-service-unavailable');
					} else {
						if (httpResponse.data.error_description) {
							// eslint-disable-next-line no-console
							console.error(`Reauthentication failed: ${httpResponse.data.error_description}`);
						}
						reject('auth.unexpected-response');
					}
				});
			});
		};

		this.logout = function () {
			return $q((resolve) => {
				// TODO actually invalidate tokens when Spring implements revocation
				// https://github.com/spring-projects/spring-security-oauth/issues/146
				AuthDataHolder.invalidate();
				resolve();
			});
		};
	}]);
