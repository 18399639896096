import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('LicensingCtrl', ['$scope', '$http', 'AuthDataHolder',
		function ($scope, $http, AuthDataHolder) {
			$scope.src = '/npgadmin/';
			$scope.available = null;

			$http.get($scope.src).then(() => {
				$scope.available = true;
			}, () => {
				$scope.available = false;
			});

			window.licensingIFrameOnLoad = function (iframe) {
				iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
				iframe.contentWindow.NPGADMIN_ADDITIONAL_HEADERS = function () {
					return {
						// eslint-disable-next-line quote-props
						'Authorization': `${AuthDataHolder.getTokenType()} ${AuthDataHolder.getAccessToken()}`
					};
				};
			};
		}]);
