import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.factory('AuthResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/auth/:action`, null, {
			ping: {
				method: 'GET',
				timeout: 3000,
				params: {
					action: 'ping'
				}
			},
			getUser: {
				method: 'GET',
				params: {
					action: 'user'
				}
			}
		});
	}]);
