import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('AdjudicationReportsResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/adjudication/cases/:id/report`, { id: '@id' }, {
			get: {
				headers: {
					Accept: 'application/pdf'
				},
				responseType: 'arraybuffer',
				transformResponse(data) {
					var response = {};
					if (data) {
						response.pdf = new Blob([data], {
							type: 'application/pdf'
						});
					}
					return response;
				}
			}
		});
	}])

	.factory('AdjudicationCasesResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/adjudication/cases/:caseId/:action`, { caseId: '@caseId', action: '@action' }, {
			lock: {
				method: 'POST',
				params: {
					action: 'lock'
				}
			},
			resolve: {
				method: 'POST',
				params: {
					action: 'resolve'
				}
			},
			getNext: {
				method: 'GET',
				params: {
					action: 'next'
				}
			},
			getCount: {
				method: 'GET',
				params: {
					action: 'count'
				}
			},
			query: {
				isArray: true
			}
		});
	}])
	.factory('AdjudicationResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		function responseImage(data) {
			var response = {};
			if (data) {
				response.image = new Blob([data], {
					type: 'image/png'
				});
			}
			return response;
		}

		return $resource(`${BaseUrlService.getBaseManagementUrl()}/encounters/:encounterId/:target/:hitId/:modality/:modalityId/:action`, {
			caseId: '@caseId', modalityId: '@modalityId', encounterId: '@encounterId'
		}, {
			getFaceImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'faces',
					action: 'image'
				},
				transformResponse: responseImage
			},
			getFaceFeatures: {
				method: 'GET',
				params: {
					modality: 'faces',
					action: 'features'
				}
			},
			getFingerImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'fingers',
					action: 'image'
				},
				transformResponse: responseImage
			},
			getFingerFeatures: {
				method: 'GET',
				params: {
					modality: 'fingers',
					action: 'features'
				}
			},
			getIrisImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'irises',
					action: 'image'
				},
				transformResponse: responseImage
			},
			getPalmImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'palms',
					action: 'image'
				},
				transformResponse: responseImage
			},
			getPalmFeatures: {
				method: 'GET',
				params: {
					modality: 'palms',
					action: 'features'
				}
			},
			getSignatureImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'signature',
					action: 'image'
				},
				transformResponse: responseImage
			},
			getFingerMatchingDetails: {
				method: 'GET',
				isArray: true,
				params: {
					modality: 'fingers',
					target: 'hits',
					action: 'matching-details'
				}
			},
			getPalmMatchingDetails: {
				method: 'GET',
				isArray: true,
				params: {
					modality: 'palms',
					target: 'hits',
					action: 'matching-details'
				}
			}
		});
	}]);
