import { NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type Direction = 'direction';
type DirectionItems = `${Direction}${NLayoutViewPorts}`;
type DirectionValues = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type DirectionProps = { [Property in DirectionItems]: DirectionValues } & {
	[Property in Direction]: DirectionValues;
};

const getDirectionsClasses = (suffix?: NLayoutViewPorts): Array<[string, DirectionValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-flex-${suffix.toLowerCase()}-row`, 'row'],
			[`fs-flex-${suffix.toLowerCase()}-row-reverse`, 'row-reverse'],
			[`fs-flex-${suffix.toLowerCase()}-column`, 'column'],
			[`fs-flex-${suffix.toLowerCase()}-column-reverse`, 'column-reverse'],
		];
	}
	return [
		[`fs-flex-row`, 'row'],
		[`fs-flex-row-reverse`, 'row-reverse'],
		[`fs-flex-column`, 'column'],
		[`fs-flex-column-reverse`, 'column-reverse'],
	];
};

const useDirectionClasses = (props: Partial<DirectionProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'direction', getDirectionsClasses),
	});
	return [containerClass, classes];
};

export default useDirectionClasses;
