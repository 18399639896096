import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('GalleriesResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/galleries/:action/:id`, { galleryId: '@id', action: '@action' }, {
			getGalleries: {
				method: 'GET',
				isArray: true
			},
			updateGallery: {
				method: 'PUT'
			},
			getGalleriesDefaultParameters: {
				method: 'GET',
				isArray: true,
				params: {
					action: 'parameters'
				}
			},
			addGalleryDefaultParameters: {
				method: 'POST',
				params: {
					action: 'parameters'
				}
			},
			updateGalleryDefaultParameters: {
				method: 'PUT',
				params: {
					action: 'parameters'
				}
			},
			deleteGalleryDefaultParameters: {
				method: 'DELETE',
				params: {
					action: 'parameters'
				}
			},
			getSizes: {
				method: 'GET',
				isArray: false,
				params: {
					action: 'galleries-sizes'
				}
			}
		});
	}]);
