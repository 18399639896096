import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CaptureSignatureCtrl', ['$scope', '$timeout', 'AbisService', 'AlertService', 'PainterService', 'StatusService', 'SubjectService', 'Utils', 'CapturerService', 'ParserResource', 'CapturePageService', 'AuthDataHolder', 'SettingsService',
		function ($scope, $timeout, AbisService, AlertService, PainterService, StatusService, SubjectService, Utils, CapturerService, ParserResource, CapturePageService, AuthDataHolder, SettingsService) {
			let device;
			$scope.isFinalStatus = CapturerService.isFinalStatus;
			$scope.parsedSignature = '';
			$scope.isCapturing = false;

			$scope.isBlur = SettingsService.getShouldBlurImages().signature;

			function revokeAndNullUrl(url) {
				if (url !== null && url !== undefined) {
					URL.revokeObjectURL(url);
					url = null;
				}
				return url;
			}

			function previewFrame(base64Image) {
				$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);

				if (base64Image !== 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
					if (typeof base64Image === 'string') {
						$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(base64Image, 'image/x-ms-bmp'));
					} else if (base64Image.size === 0) {
						$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(Utils.getBlankBase64Image(), 'image/x-ms-bmp'));
					} else {
						$scope.frameUrl = URL.createObjectURL(base64Image);
					}
				}
			}

			StatusService.refresh()
				.catch((err) => {
					if (err.message) {
						AlertService.show(err.message, { type: 'danger', translate: true });
					}
				});
			$scope.$on('$viewContentLoaded', () => {
				$scope.actionPageName = AbisService.getRequestTypeReadable();
				$scope.$watch(() => SubjectService.getSignatureData(), (newImage) => {
					$timeout(() => previewFrame($scope.parsedSignature || newImage), 50);
				});
				$scope.$watch(() => CapturerService.getDevice('Signature'), (newDevice) => {
					device = newDevice;
				}, true);
				$scope.$watch(() => StatusService.getSignatureScannerStatus(), (newValue) => {
					$scope.status = newValue;
				}, true);
			});

			function invalidate() {
				$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
				SubjectService.invalidate('signature');
			}

			$scope.imageOnError = () => {
				if ($scope.frameUrl !== null && $scope.frameUrl !== undefined) {
					$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
					$scope.frameUrl = null;
				}
				if ($scope.parsedSignature !== '') {
					$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob($scope.parsedSignature, 'image/x-ms-bmp'));
				} else {
					const imageData = SubjectService.getSignatureData();
					ParserResource.parseImage({ data: imageData }, (result) => {
						$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(result.data, 'image/x-ms-bmp'));
						$scope.parsedSignature = result.data;
					});
				}
			};

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			$scope.startCapture = function () {
				CapturerService.refreshDevices().then(() => {
					if ($scope.status !== 'SOURCE_MISSING') {
						invalidate();
						CapturerService.registerAndLockDevice(device.id)
							.then((deviceId) => {
								CapturerService.setConfiguration(deviceId, {})
									.then(() => {
										capture(device.id);
									}, () => {
										AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
									});
							}, (error) => {
								CapturerService.handleError(error, AlertService);
							});
					}
				});
				function capture(deviceId) {
					$scope.isCapturing = true;
					CapturerService.capture(deviceId)
						.then((result) => {
							CapturerService.download(deviceId, result.captureIds[0]).then((result) => {
								$scope.isCapturing = false;
								$scope.status = 'PROCESS_NEXT_STEP';
								$scope.imageData = result.sensorData;
								SubjectService.setSignatureData(result.sensorData);
								previewFrame(result.sensorData);
							}, () => {
								$scope.status = 'CAPTURE_ERROR';
							});
						}, (error) => {
							$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
							$scope.isCapturing = false;
							if (error.message) {
								$scope.errorMessage = CapturerService.handleErrorMessage(error);
							}
							$scope.status = CapturerService.handleError(error);
						}).finally(() => {
							CapturerService.unlock(device.id);
						});

					function preview(deviceId, etag) {
						CapturerService.previewImage(deviceId, etag).then((response) => {
							if (CapturerService.isFinalStatus($scope.status)) {
								return;
							}
							previewFrame(response.image);
							preview(deviceId, response.etag);
						}, () => {
							if (!CapturerService.isFinalStatus($scope.status)) {
								$timeout(() => {
									preview(deviceId, '');
								}, 100);
							}
						});
					}
					$scope.status = 'STARTING';
					preview(deviceId, '');
				}
			};

			$scope.biometricsPage = function () {
				CapturePageService.goBack();
			};

			$scope.stopCapture = function (clearData) {
				if (clearData) {
					invalidate();
				}
				if (device !== null) {
					CapturerService.cancel(device.id)
						.then(() => {
							$scope.status = 'CANCELED';
						})
						.catch((response) => {
							if (response.status !== 'lockNotHeld') {
								throw new Error(response.status);
							}
						})
						.finally(() => {
							CapturerService.unlock($scope.device.id);
						});
				}
			};

			$scope.importImage = function () {
				function setImportData(data, notImage) {
					SubjectService.setSignatureData($scope.importImageFile.content);
					if ($scope.parsedSignature !== '') {
						$scope.parsedSignature = '';
					}
					if (notImage) {
						$scope.parsedSignature = data;
					}

					$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
					$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(data, 'image/x-ms-bmp'));
					AlertService.show('biometrics.import.imported', { type: 'success' });
				}
				if ($scope.importImageFile.type.match(/^image\//)) {
					setImportData($scope.importImageFile.content, false);
				} else {
					const data = $scope.importImageFile.content;
					ParserResource.parseImage({ data }, (result) => {
						setImportData(result.data, true);
					}, () => {
						AlertService.show('forms.invalid-image', { type: 'danger' });
					});
				}
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.removeImage = function () {
				invalidate();
			};
		}]);
