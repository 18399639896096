import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('passwordConfirmCtrl', ['$scope', 'PasswordResouce', '$state', 'PasswordValidation',
		function ($scope, PasswordResouce, $state, PasswordValidation) {
			const { email, token } = $state.params;
			const { getPasswordValidationErrors, getPasswordRequirmentStatus } = PasswordValidation;

			$scope.getPasswordValidationErrors = getPasswordValidationErrors;
			$scope.getPasswordRequirmentStatus = getPasswordRequirmentStatus;

			$scope.showFields = true;
			$scope.showRequirements = false;
			$scope.error = false;

			if (!email || !token) {
				$scope.alert = {
					msg: 'password.messages.invalid-link',
					type: 'danger'
				};
				$scope.showFields = false;
			}

			function setAlertMessage() {
				$scope.alert = {
					type: 'info',
					msg: 'password.messages.processing'
				};
			}

			$scope.submit = function () {
				const { confirmRequest, confirmForm } = $scope;
				$scope.error = getPasswordValidationErrors(confirmRequest.password);
				if ($scope.error.length > 0) { return; }
				if (confirmForm.$valid) {
					if (confirmRequest.password === confirmRequest.oldPassword) {
						setAlertMessage();
						$scope.showFields = false;
						PasswordResouce.confirm({ email, token }, { password: confirmRequest.password }, () => {
							$scope.alert = {
								type: 'success',
								msg: 'password.messages.completed'
							};
						}, (error) => {
							$scope.showFields = true;
							const { data } = error;
							$scope.alert = {
								type: 'danger',
								msg: data.message ? data.message : 'password.message.error'
							};
						});
					}
				}
			};
		}]);
