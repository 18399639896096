import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.controller('FingerComparisonModalCtrl', ['$rootScope', '$filter', '$scope', '$q', '$uibModalInstance', '$translate', '$sce', '$window', 'namingService', 'ConvertionUtils', 'drawingService', 'matchingTreeService', 'store', 'HotkeyService', 'hotkeys', 'Utils', 'ExaminersResource', 'AlertService', 'AuthDataHolder', 'ImageService',
		function ($rootScope, $filter, $scope, $q, $uibModalInstance, $translate, $sce, $window, namingService, ConvertionUtils, drawingService, matchingTreeService, store, HotkeyService, hotkeys, Utils, ExaminersResource, AlertService, AuthDataHolder, ImageService) {
			$scope.isObjectEmpty = obj => Utils.isObjectEmpty(obj);
			var stage;
			let mouseDown = false;
			let callbackHit;
			let callbackProbe;
			let mouseMoveListener;
			let minutiaeQuadTree;
			let coresQuadTree;
			let deltasQuadTree;
			let doubleCoresQuadTree;

			$scope.getAllFeatures = false;
			$scope.wheelMode = 'zoom';
			$scope.wheelStep = 'default';
			$scope.frontFinger = 'hit';
			$scope.overlapped = false;
			$scope.lockMode = false;
			$scope.minutiaInfo = {};

			$scope.isCountingRidges = false;
			$scope.ridgeCount = { probe: '', hit: '' };
			$scope.selectedMinutiae = { probe: [], hit: [] };

			var zoomFactor;
			var rotationStep;

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.$watch('wheelStep', (newValue) => {
				if (newValue === 'precision') {
					zoomFactor = 1.005;
					rotationStep = 0.1;
				} else {
					zoomFactor = 1.05;
					rotationStep = 2;
				}
			});

			function onResize() {
				$scope.reset();
			}
			$window.addEventListener('resize', onResize);
			$scope.$on('$destroy', () => $window.removeEventListener('resize', onResize));

			function getFeaturesByName(name) {
				if (name === 'probe') {
					return $scope.probe.features;
				} else if (name === 'hit') {
					return $scope.hit.features;
				}
			}

			function getMTStrokeCommandByName(name) {
				if (name === 'probe') {
					return 'index1';
				} else if (name === 'hit') {
					return 'index2';
				}
			}

			function moveShapeToFront(shape) {
				shape.parent.setChildIndex(shape, shape.parent.numChildren - 1);
			}

			$scope.$watch('frontFinger', (newValue, oldValue) => {
				if (newValue !== oldValue) {
					moveShapeToFront(stage.getChildByName(newValue));
					stage.update();
				}
			});

			// HOTKEYS----------------------------------------------------------------------
			var bindings = HotkeyService.getHotkeyBindings();
			function handleHotKeyCallback(hotkey) {
				var currentFront = null;
				if (hotkey === bindings.switchFront) {
					if ($scope.frontFinger === 'hit') {
						$scope.frontFinger = 'probe';
					} else {
						$scope.frontFinger = 'hit';
					}
				} else if (hotkey === bindings.rotateZoomToggle) {
					if ($scope.wheelMode === 'zoom') {
						$scope.wheelMode = 'rotation';
					} else {
						$scope.wheelMode = 'zoom';
					}
				} else if (hotkey === bindings.moveLeft) {
					currentFront = stage.getChildByName($scope.frontFinger);
					currentFront.x -= 1;
					stage.update();
				} else if (hotkey === bindings.moveRight) {
					currentFront = stage.getChildByName($scope.frontFinger);
					currentFront.x += 1;
					stage.update();
				} else if (hotkey === bindings.moveUp) {
					currentFront = stage.getChildByName($scope.frontFinger);
					currentFront.y -= 1;
					stage.update();
				} else if (hotkey === bindings.moveDown) {
					currentFront = stage.getChildByName($scope.frontFinger);
					currentFront.y += 1;
					stage.update();
				} else {
					// Do nothing
				}
			}

			function setHotkeys() {
				function setHotkeySwitchFront(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Switch front fingerprint.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyRotateZoomToggle(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Toggle rotation or zoom',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveLeft(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image left.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveRight(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image right.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveUp(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image up.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}
				function setHotkeyMoveDown(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image down.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				if (bindings) {
					setHotkeySwitchFront(bindings.switchFront);
					setHotkeyRotateZoomToggle(bindings.rotateZoomToggle);
					setHotkeyMoveUp(bindings.moveUp);
					setHotkeyMoveDown(bindings.moveDown);
					setHotkeyMoveLeft(bindings.moveLeft);
					setHotkeyMoveRight(bindings.moveRight);
				}
			}
			//------------------------------------------------------------------------------

			function changeVisibility(container, check, visibility) {
				for (var i = 0; i < container.numChildren; i += 1) {
					var child = container.getChildAt(i);
					if (check(child.name)) {
						child.visible = visibility;
					} else if (child instanceof window.createjs.Container) {
						changeVisibility(child, check, visibility);
					}
				}
			}

			function changeImage(finger, imageName) {
				var container = finger.getChildByName('image');
				var foundOne = false;
				for (var i = 0; i < container.numChildren; i += 1) {
					var child = container.getChildAt(i);
					if (child.name === imageName) {
						child.visible = true;
						foundOne = true;
					} else {
						child.visible = false;
					}
				}

				if (foundOne || imageName === 'NONE') {
					container.updateCache();
				} else {
					return getFingerImageUrl($scope[finger.name], imageName).then(() => {
						const image = new Image();
						image.onload = function () {
							const bitmap = new window.createjs.Bitmap(image);
							bitmap.name = imageName;
							container.addChild(bitmap);
							container.updateCache();
							container.parent.updateCache();
							stage.update();
						};
						image.src = $scope[finger.name].imageUrl[imageName];
					});
				}
			}

			function changeFilter(fingerName) {
				var image = stage.getChildByName(fingerName).getChildByName('image');

				var { color } = $scope[`${fingerName}Options`];
				var r = parseInt(color.substr(1, 2), 16);
				var g = parseInt(color.substr(3, 2), 16);
				var b = parseInt(color.substr(5, 2), 16);

				var a = $scope[`${fingerName}Options`].alpha;
				var matrix = new window.createjs.ColorMatrix().adjustContrast($scope[`${fingerName}Options`].contrast).adjustBrightness($scope[`${fingerName}Options`].brightness);

				image.filters = [
					new window.createjs.ColorFilter(1, 1, 1, a, r, g, b),
					new window.createjs.ColorMatrixFilter(matrix)
				];

				image.updateCache();
			}

			function combineWithFeaturesMaster(options, key) {
				return options.drawFeaturesMaster && options[key];
			}

			function drawingOptionsChanged(name, newValue, oldValue) {
				if (oldValue == null) {
					oldValue = {};
				}
				if (newValue.image !== oldValue.image) {
					changeImage(stage.getChildByName(name), newValue.image);
				}
				if (newValue.color !== oldValue.color || newValue.alpha !== oldValue.alpha || newValue.brightness !== oldValue.brightness || newValue.contrast !== oldValue.contrast) {
					changeFilter(name);
				}
				if (newValue.overlayColor !== oldValue.overlayColor) {
					drawingService.getMTStrokeCommands()[getMTStrokeCommandByName(name)].style = drawingService.getDefaultDarkerHexColor(newValue.overlayColor);
					drawFeatures(stage.getChildByName(name), getFeaturesByName(name), newValue.overlayColor);
				}
				if (newValue.drawMatchingTree !== oldValue.drawMatchingTree) {
					changeVisibility(stage.getChildByName(name), namingService.isTreeName, newValue.drawMatchingTree);
				}
				if (combineWithFeaturesMaster(newValue, 'drawMinutiae') !== combineWithFeaturesMaster(oldValue, 'drawMinutiae')) {
					changeVisibility(stage.getChildByName(name), namingService.isMinutiaName, combineWithFeaturesMaster(newValue, 'drawMinutiae'));
				}
				if (combineWithFeaturesMaster(newValue, 'drawCores') !== combineWithFeaturesMaster(oldValue, 'drawCores')) {
					changeVisibility(stage.getChildByName(name), namingService.isCoreName, combineWithFeaturesMaster(newValue, 'drawCores'));
				}
				if (combineWithFeaturesMaster(newValue, 'drawDeltas') !== combineWithFeaturesMaster(oldValue, 'drawDeltas')) {
					changeVisibility(stage.getChildByName(name), namingService.isDeltaName, combineWithFeaturesMaster(newValue, 'drawDeltas'));
				}
				if (combineWithFeaturesMaster(newValue, 'drawDoubleCores') !== combineWithFeaturesMaster(oldValue, 'drawDoubleCores')) {
					changeVisibility(stage.getChildByName(name), namingService.isDoubleCoreName, combineWithFeaturesMaster(newValue, 'drawDoubleCores'));
				}

				stage.getChildByName(name).updateCache();
			}

			function genDefaultDrawingOptions() {
				return {
					image: 'TRANSPARENT',
					color: '#000000',
					alpha: 1,
					brightness: 0,
					contrast: 0,
					drawMatchingTree: true,
					drawFeaturesMaster: true,
					drawMinutiae: true,
					drawCores: false,
					drawDeltas: false,
					drawDoubleCores: false,
					overlayColor: '#FF0000'
				};
			}

			function initDrawingOptions(name) {
				$scope[`${name}Options`] = genDefaultDrawingOptions();
				$scope.$watch(`${name}Options`, (newValue, oldValue) => {
					if (newValue !== oldValue) {
						const update = (resetVisibility = false) => {
							if (resetVisibility) {
								oldValue = {};
							}
							drawingOptionsChanged(name, newValue, oldValue);
							stage.update();
							store.set(`${name}Options`, Object.assign(angular.copy($scope[`${name}Options`]), { drawCores: false, drawDeltas: false, drawDoubleCores: false }));
						};
						const { features } = $scope[name];
						let getAllFeatures = false;
						if (newValue.drawCores && !oldValue.drawCores) {
							getAllFeatures = true;
						} else if (newValue.drawDeltas && !oldValue.drawDeltas) {
							getAllFeatures = true;
						} else if (newValue.drawDoubleCores && !oldValue.drawDoubleCores) {
							getAllFeatures = true;
						}
						if (getAllFeatures) {
							if (features.cores || features.deltas || features.doubleCores) {
								getAllFeatures = false;
							}
						}

						if (getAllFeatures) {
							$scope[name].getFeatures(true).then((r) => {
								$scope[name].features = r;
								const finger = stage.getChildByName(name);
								const img = finger.getChildAt(0);
								const tree = finger.getChildAt(1);

								finger.removeAllChildren();
								finger.addChild(img);
								finger.addChild(tree);

								drawFeatures(finger, $scope[name].features, newValue.overlayColor);

								if (name === 'probe') {
									callbackProbe = createFingerActions({
										features: $scope[name].features,
										container: finger,
										options: newValue
									}, {
										features: $scope.hit.features,
										container: stage.getChildByName('hit'),
										options: $scope.hitOptions
									}, {
										array: $scope.matchingDetails.matedMinutiae,
										self: 'index1',
										other: 'index2'
									});
								} else if (name === 'hit') {
									callbackHit = createFingerActions({
										features: $scope[name].features,
										container: finger,
										options: newValue
									}, {
										features: $scope.probe.features,
										container: stage.getChildByName('probe'),
										options: $scope.probeOptions
									}, {
										array: $scope.matchingDetails.matedMinutiae,
										self: 'index2',
										other: 'index1'
									});
								}
								document.getElementById('canvas').removeEventListener('mousemove', mouseMoveListener);
								mouseMoveListener = document.getElementById('canvas').addEventListener('mousemove', () => { callbackProbe(); callbackHit(); });
								update(true);
							});
						} else {
							update();
						}
					}
				}, true);
			}

			function initCustomDrawingOptions() {
				const defaultDrawingOptions = genDefaultDrawingOptions();
				const overlayOverWrite = { overlayColor: defaultDrawingOptions.overlayColor }; // To handle overlay of older options data model
				if (store.get('probeOptions')) {
					Object.assign($scope.probeOptions, defaultDrawingOptions, store.get('probeOptions'), overlayOverWrite);
				}
				if (store.get('hitOptions')) {
					Object.assign($scope.hitOptions, defaultDrawingOptions, store.get('hitOptions'), overlayOverWrite);
				}

				// Override image settings in case finger has no images
				// FIXME: Think of a way to restore last image type user preference after preview of finger with no images
				if ($scope.probe.forceImageType === 'NONE') {
					$scope.probeOptions.image = 'NONE';
					$scope.probeOptions.hasNoImage = true;
				} else {
					$scope.probeOptions.image = 'TRANSPARENT';
					delete $scope.probeOptions.hasNoImage;
				}
				if ($scope.hit.forceImageType === 'NONE') {
					$scope.hitOptions.image = 'NONE';
					$scope.hitOptions.hasNoImage = true;
				} else {
					$scope.hitOptions.image = 'TRANSPARENT';
					delete $scope.hitOptions.hasNoImage;
				}
			}

			initDrawingOptions('probe');
			initDrawingOptions('hit');
			initCustomDrawingOptions();

			function enableWheelActions() {
				function mouseWheel(event) {
					let scale;
					let rotation;
					if (event.deltaY < 0) {
						scale = zoomFactor;
						rotation = rotationStep;
					} else {
						scale = 1 / zoomFactor;
						rotation = -rotationStep;
					}

					let child;
					let local;
					let scrolledChild;
					let childImg;
					for (var i = stage.numChildren - 1; i >= 0; i -= 1) {
						child = stage.getChildAt(i);
						local = child.globalToLocal(stage.mouseX, stage.mouseY);
						childImg = child.getChildByName('image');
						if (($scope.wheelMode === 'zoom' && $scope.lockMode) || childImg.hitArea.hitTest(local.x, local.y)) {
							scrolledChild = child.name;
							if (!$scope.lockMode) {
								$scope.frontFinger = scrolledChild;
							}
							child.regX = local.x;
							child.regY = local.y;
							child.x = stage.mouseX;
							child.y = stage.mouseY;
							if ($scope.wheelMode === 'zoom') {
								child.scaleY *= scale;
								child.scaleX = child.scaleY;
							} else {
								child.rotation += rotation;
							}

							if (!($scope.wheelMode === 'zoom' && $scope.lockMode)) {
								break;
							}
						}
					}

					$scope.$digest();
					stage.update();
				}

				stage.canvas.addEventListener('dblclick', () => {
					$scope.$apply((scope) => {
						if (scope.wheelMode === 'zoom') {
							scope.wheelMode = 'rotation';
						} else {
							scope.wheelMode = 'zoom';
						}
					});
				});

				const canvasElem = document.getElementById('canvas');
				canvasElem.addEventListener('wheel', (event) => {
					event.stopPropagation();
					mouseWheel(event);
				}, { capture: false, passive: true });
			}

			function resizeCanvas(canvas) {
				var parent = $(canvas).parent();
				canvas.width = parent.width();
				canvas.height = parent.height();
			}

			function init() {
				var canvas = document.getElementById('canvas');
				resizeCanvas(canvas);

				if (stage) {
					stage.removeAllChildren();
					stage.update();
				}

				stage = new window.createjs.Stage(canvas);
				minutiaeQuadTree = new $rootScope.jsQuadtree.QuadTree(new $rootScope.jsQuadtree.Box(0, 0, 1000, 1000));
				coresQuadTree = new $rootScope.jsQuadtree.QuadTree(new $rootScope.jsQuadtree.Box(0, 0, 1000, 1000));
				deltasQuadTree = new $rootScope.jsQuadtree.QuadTree(new $rootScope.jsQuadtree.Box(0, 0, 1000, 1000));
				doubleCoresQuadTree = new $rootScope.jsQuadtree.QuadTree(new $rootScope.jsQuadtree.Box(0, 0, 1000, 1000));
				stage.snapToPixelEnabled = true;

				enableWheelActions();
			}

			$scope.overlap = function () {
				var probe = stage.getChildByName('probe');
				var hit = stage.getChildByName('hit');
				$scope.frontFinger = 'hit';

				var positionOld = probe.localToGlobal(0, 0);
				probe.regX = 0;
				probe.regY = 0;

				probe.scaleX = probe.scaleY;

				var positionNew = probe.localToGlobal(0, 0);
				probe.x += positionOld.x - positionNew.x;
				probe.y += positionOld.y - positionNew.y;
				hit.x += positionOld.x - positionNew.x;
				hit.y += positionOld.y - positionNew.y;

				hit.regX = $scope.matchingDetails.centerX;
				hit.regY = $scope.matchingDetails.centerY;
				hit.scaleX = probe.scaleX;
				hit.scaleY = probe.scaleY;
				hit.rotation = probe.rotation + ConvertionUtils.radiansToDegress($scope.matchingDetails.rotation);
				var compoundTranslationX = ($scope.matchingDetails.centerX + $scope.matchingDetails.translationX) * Math.cos(ConvertionUtils.degreesToRadians(probe.rotation)) - ($scope.matchingDetails.centerY + $scope.matchingDetails.translationY) * Math.sin(ConvertionUtils.degreesToRadians(probe.rotation));
				var compoundTranslationY = ($scope.matchingDetails.centerX + $scope.matchingDetails.translationX) * Math.sin(ConvertionUtils.degreesToRadians(probe.rotation)) + ($scope.matchingDetails.centerY + $scope.matchingDetails.translationY) * Math.cos(ConvertionUtils.degreesToRadians(probe.rotation));
				hit.x = probe.x + (compoundTranslationX) * hit.scaleX;
				hit.y = probe.y + (compoundTranslationY) * hit.scaleY;

				$scope.overlapped = true;

				stage.update();
			};

			$scope.reset = function () {
				var halfCanvasWidth = stage.canvas.width / 2;

				var probe = stage.getChildByName('probe');
				var hit = stage.getChildByName('hit');

				var probeBounds = probe.getChildByName('image').getBounds();
				var hitBounds = hit.getChildByName('image').getBounds();

				var scaleProbe = Math.min(halfCanvasWidth / probeBounds.width, stage.canvas.height / probeBounds.height);
				var scaleCandidate = Math.min(halfCanvasWidth / hitBounds.width, stage.canvas.height / hitBounds.height);

				probe.regX = 0;
				probe.regY = 0;
				hit.regX = 0;
				hit.regY = 0;

				probe.scaleX = scaleProbe;
				probe.scaleY = scaleProbe;
				hit.scaleX = scaleCandidate;
				hit.scaleY = scaleCandidate;

				probe.rotation = 0;
				hit.rotation = 0;

				probe.x = (halfCanvasWidth - probeBounds.width * scaleProbe) / 2 - probeBounds.x * scaleProbe;
				probe.y = (stage.canvas.height - probeBounds.height * scaleProbe) / 2 - probeBounds.y * scaleProbe;
				hit.x = halfCanvasWidth + (halfCanvasWidth - hitBounds.width * scaleCandidate) / 2 - hitBounds.x * scaleCandidate;
				hit.y = (stage.canvas.height - hitBounds.height * scaleCandidate) / 2 - hitBounds.y * scaleCandidate;

				$scope.overlapped = false;

				stage.update();
			};

			function getFingerImageUrl(finger, type) {
				if (finger.imageUrl == null) {
					finger.imageUrl = {};
				}

				function inflate(value) {
					const INFLATE_RATIO = 2.2;
					return value * INFLATE_RATIO;
				}

				return finger.getImageUrl(type).then((imageUrl) => {
					finger.imageUrl[type] = imageUrl;
				}, (response) => {
					if (response.status === 404) {
						var maxX = Math.max(...finger.features.minutiae.map(obj => obj.x));
						var maxY = Math.max(...finger.features.minutiae.map(obj => obj.y));
						var canvas = document.createElement('canvas');
						canvas.width = inflate(maxX);
						canvas.height = maxY;
						finger.imageUrl[type] = canvas.toDataURL('image/png', 0.1);
						finger.forceImageType = 'NONE';
					} else {
						throw new Error('Internal error');
					}
				});
			}

			function getFingerFeatures(finger, allfeatures) {
				return finger.getFeatures(allfeatures).then((features) => {
					finger.features = features;
				});
			}

			function getMatchingDetails() {
				return $scope.getMatchingDetails().then((matchingDetails) => {
					$scope.matchingDetails = matchingDetails;
				});
			}


			function createFinger(finger) {
				const deferred = $q.defer();
				const container = new window.createjs.Container();

				const imageContainer = new window.createjs.Container();
				imageContainer.name = 'image';

				const name = 'TRANSPARENT';

				const image = new Image();
				image.onload = function () {
					const trimmedBounds = ImageService.getImageBoundsWithoutAlpha(image, this.width, this.height);
					let imageBounds;
					if (trimmedBounds.width !== 0 || trimmedBounds.height !== 0) {
						finger.hasImage = true;
						imageBounds = {
							x: trimmedBounds.left.x,
							y: trimmedBounds.top.y,
							width: trimmedBounds.width,
							height: trimmedBounds.height,
						};
					} else {
						finger.hasImage = false;
						const processedBounds = ImageService.getImageBoundsFromMinutia(finger.features.minutiae);
						imageBounds = {
							x: processedBounds.left.x,
							y: processedBounds.top.y,
							width: processedBounds.width,
							height: processedBounds.height,
						};
					}

					var bitmap = new window.createjs.Bitmap(this);
					bitmap.name = name;
					imageContainer.hitArea = drawingService.createRectangleHitArea(imageBounds);
					imageContainer.addChild(bitmap);
					imageContainer.cache(0, 0, bitmap.image.width, bitmap.image.height);
					container.addChild(imageContainer);
					deferred.resolve(container);
					stage.update();
				};
				image.src = finger.imageUrl[name];

				return deferred.promise;
			}

			function drawFeatures(container, features, color) {
				let i;
				let shape;
				const DEFAULT_MINUTIA_COLOR = drawingService.getDefaultMinutiaColor();
				if (features.minutiae != null) {
					for (i = 0; i < features.minutiae.length; i += 1) {
						const minutiaColor = drawingService.getMinutiaColor(i, $scope.matchingDetails.matedMinutiae, container.name);
						if (container.getChildByName(namingService.minutiaIndexToName(i)) === null) {
							shape = drawingService.createMinutia(features.minutiae[i], i, minutiaColor === DEFAULT_MINUTIA_COLOR ? color : minutiaColor);
							shape.name = namingService.minutiaIndexToName(i);
							container.addChild(shape);
						} else {
							redrawMinutia(container, i, minutiaColor === DEFAULT_MINUTIA_COLOR ? color : minutiaColor);
						}
					}
				}

				if (features.cores != null) {
					for (i = 0; i < features.cores.length; i += 1) {
						const coreColor = drawingService.getDefaultDarkerHexColor(color);
						if (container.getChildByName(namingService.coreIndexToName(i)) === null) {
							shape = drawingService.createCore(features.cores[i], coreColor);
							shape.name = namingService.coreIndexToName(i);
							container.addChild(shape);
						} else {
							const core = $scope[container.name].features.cores[i];
							shape = container.getChildByName(namingService.coreIndexToName(i));
							shape.graphics.clear();
							drawingService.drawCore(shape.graphics, core, null, coreColor);
						}
					}
				}

				if (features.deltas != null) {
					for (i = 0; i < features.deltas.length; i += 1) {
						const deltaColor = drawingService.getDefaultDarkerHexColor(color);
						if (container.getChildByName(namingService.deltaIndexToName(i)) === null) {
							shape = drawingService.createDelta(features.deltas[i], deltaColor);
							shape.name = namingService.deltaIndexToName(i);
							container.addChild(shape);
						} else {
							const delta = $scope[container.name].features.deltas[i];
							shape = container.getChildByName(namingService.deltaIndexToName(i));
							shape.graphics.clear();
							drawingService.drawDelta(shape.graphics, delta, null, deltaColor);
						}
					}
				}

				if (features.doubleCores != null) {
					for (i = 0; i < features.doubleCores.length; i += 1) {
						let doubleCoreColor = drawingService.getDefaultDarkerHexColor(color);
						doubleCoreColor = doubleCoreColor === DEFAULT_MINUTIA_COLOR ? color : doubleCoreColor;
						if (container.getChildByName(namingService.doubleCoreIndexToName(i)) === null) {
							shape = drawingService.createDoubleCore(features.doubleCores[i], doubleCoreColor);
							shape.name = namingService.doubleCoreIndexToName(i);
							container.addChild(shape);
						} else {
							const doubleCore = $scope[container.name].features.doubleCores[i];
							shape = container.getChildByName(namingService.doubleCoreIndexToName(i));
							shape.graphics.clear();
							drawingService.drawDoubleCore(shape.graphics, doubleCore, null, doubleCoreColor);
						}
					}
				}
			}

			function redrawMinutiaWithNeighbors(features, container, index, width, color, neighborWidth, neighborColor) {
				const shapeContainer = container.getChildByName(namingService.minutiaIndexToName(index));
				const minutia = features.minutiae[index];

				const shape = shapeContainer.getChildByName(index);
				shape.graphics.clear();

				drawingService.drawMinutia(shape.graphics, minutia, width, color || shape.color);
				for (let j = 0; j < minutia.neighbors.length; j += 1) {
					const neighborContainer = container.getChildByName(namingService.minutiaIndexToName(minutia.neighbors[j].index));
					const neighborMinutia = features.minutiae[minutia.neighbors[j].index];

					moveShapeToFront(neighborContainer);

					const neighborShape = neighborContainer.getChildByName(minutia.neighbors[j].index);
					neighborShape.graphics.clear();
					drawingService.drawMinutia(neighborShape.graphics, neighborMinutia, neighborWidth, neighborColor || neighborShape.color);
				}

				moveShapeToFront(shapeContainer);
			}

			function findPoint(pointArray, childName) {
				return pointArray.find(point => point.data.parent === childName);
			}

			let mouseOveredMinutiaId = '';
			let mouseOveredCoreId = '';
			let mouseOveredDeltaId = '';
			let mouseOveredDoubleCoreId = '';
			function createFingerActions(self, other, matedMinutiae) {
				function minutiaMouseEventInternal(index, width, color, neighborWidth, neighborColor) {
					redrawMinutiaWithNeighbors(
						self.features, self.container, index, width,
						color, neighborWidth,
						neighborColor
					);
					if (matedMinutiae.array) {
						for (var i = 0; i < matedMinutiae.array.length; i += 1) {
							var matedMinutia = matedMinutiae.array[i];
							if (matedMinutia[matedMinutiae.self] === index) {
								redrawMinutiaWithNeighbors(
									other.features, other.container, matedMinutia[matedMinutiae.other], width,
									color, neighborWidth,
									neighborColor
								);
								break;
							}
						}
					}

					for (let i = 0; i < stage.numChildren; i += 1) {
						stage.children[i].updateCache();
					}
					stage.update();
				}

				let targetMinutia = '';
				function minutiaMouseover(id, parentName) {
					if (!targetMinutia) {
						targetMinutia = self.features.minutiae[id];
						$scope.minutiaInfo = {
							name: Utils.capitalizeFirstLetter(parentName),
							index: id,
							angle: Math.trunc(ConvertionUtils.radiansToDegress(targetMinutia.angle)),
							bifurcation: targetMinutia.bifurcation
						};
						$scope.$digest();
						minutiaMouseEventInternal(id, 1, '#2bbd7e', 1, 'orange');
					}
				}

				function minutiaMouseout(id) {
					$scope.minutiaInfo = {};
					$scope.$digest();
					minutiaMouseEventInternal(id);
					targetMinutia = null;
				}

				function coreMouseEventInternal(index, width, color) {
					const shape = self.container.getChildByName(namingService.coreIndexToName(index));
					const core = self.features.cores[index];

					moveShapeToFront(shape);
					shape.graphics.clear();
					drawingService.drawCore(shape.graphics, core, width, color || drawingService.getDefaultDarkerHexColor(self.options.overlayColor));

					self.container.updateCache();
					stage.update();
				}

				let drewCore = false;
				function coreMouseover(index) {
					if (!drewCore) {
						coreMouseEventInternal(index, 2);
						drewCore = true;
					}
				}

				function coreMouseout(index) {
					coreMouseEventInternal(index);
					drewCore = false;
				}

				function deltaMouseEventInternal(index, width, color) {
					const shape = self.container.getChildByName(namingService.deltaIndexToName(index));
					const delta = self.features.deltas[index];

					moveShapeToFront(shape);

					shape.graphics.clear();
					drawingService.drawDelta(shape.graphics, delta, width, color || drawingService.getDefaultDarkerHexColor(self.options.overlayColor));

					self.container.updateCache();
					stage.update();
				}

				function deltaMouseover(index) {
					deltaMouseEventInternal(index, 2);
				}

				function deltaMouseout(index) {
					deltaMouseEventInternal(index);
				}

				function doubleCoreMouseEventInternal(index, width, color) {
					const shape = self.container.getChildByName(namingService.doubleCoreIndexToName(index));

					moveShapeToFront(shape);

					shape.graphics.clear();
					drawingService.drawDoubleCore(shape.graphics, width, color || drawingService.getDefaultDarkerHexColor(self.options.overlayColor));

					self.container.updateCache();
					stage.update();
				}

				function doubleCoreMouseover(index) {
					doubleCoreMouseEventInternal(index, 2);
				}

				function doubleCoreMouseout(index) {
					doubleCoreMouseEventInternal(index);
				}

				let i;
				let shape;
				let shapesContainer;
				let currentFeatureElem;
				if (self.features.minutiae != null) {
					for (i = 0; i < self.features.minutiae.length; i += 1) {
						shapesContainer = self.container.getChildByName(namingService.minutiaIndexToName(i));
						shape = shapesContainer.getChildByName(i);
						currentFeatureElem = self.features.minutiae[i];
						shape.hitArea = drawingService.createMinutiaHitArea(currentFeatureElem);
						minutiaeQuadTree.insert(new $rootScope.jsQuadtree.Circle(currentFeatureElem.x, currentFeatureElem.y, 4, { parent: self.container.name, minutiaName: i }));
					}
				}

				if (self.features.cores != null) {
					for (i = 0; i < self.features.cores.length; i += 1) {
						shape = self.container.getChildByName(namingService.coreIndexToName(i));
						currentFeatureElem = self.features.cores[i];
						shape.hitArea = drawingService.createCoreHitArea(self.features.cores[i]);
						coresQuadTree.insert(new $rootScope.jsQuadtree.Box(currentFeatureElem.x + 10, currentFeatureElem.y + 10, 20, 20, { parent: self.container.name, coreName: i }));
					}
				}

				if (self.features.deltas != null) {
					for (i = 0; i < self.features.deltas.length; i += 1) {
						shape = self.container.getChildByName(namingService.deltaIndexToName(i));
						currentFeatureElem = self.features.deltas[i];
						shape.hitArea = drawingService.createDeltaHitArea(self.features.deltas[i]);
						deltasQuadTree.insert(new $rootScope.jsQuadtree.Box(currentFeatureElem.x + 10, currentFeatureElem.y + 10, 20, 20, { parent: self.container.name, deltaName: i }));
					}
				}

				if (self.features.doubleCores != null) {
					for (i = 0; i < self.features.doubleCores.length; i += 1) {
						shape = self.container.getChildByName(namingService.doubleCoreIndexToName(i));
						currentFeatureElem = self.features.doubleCores[i];
						shape.hitArea = drawingService.createDoubleCoreHitArea();
						doubleCoresQuadTree.insert(new $rootScope.jsQuadtree.Circle(currentFeatureElem.x, currentFeatureElem.y, 10, { parent: self.container.name, doubleCoreName: i }));
					}
				}

				function pressMove() {
					if ($scope.lockMode) {
						for (let i = 0; i < stage.children.length; i += 1) {
							stage.children[i].x = stage.mouseX;
							stage.children[i].y = stage.mouseY;
						}
					} else {
						const child = stage.getChildAt(stage.numChildren - 1);
						child.x = stage.mouseX;
						child.y = stage.mouseY;
					}
					stage.update();
				}

				function identifyElementType(local, child) {
					// Minutiae
					if ($scope[`${self.container.name}Options`].drawMinutiae && child.name === self.container.name) {
						const points = minutiaeQuadTree.query(new $rootScope.jsQuadtree.Circle(local.x, local.y, 4));
						const point = findPoint(points, child.name);
						if (point) {
							mouseOveredMinutiaId = point.data.minutiaName;
							minutiaMouseover(point.data.minutiaName, point.data.parent);
						} else if (mouseOveredMinutiaId !== '' && !point && child.name === self.container.name && !$scope.isCountingRidges) {
							minutiaMouseout(mouseOveredMinutiaId);
							mouseOveredMinutiaId = '';
						}
					}

					// Double Cores
					if ($scope[`${self.container.name}Options`].drawDoubleCores && child.name === self.container.name) {
						const points = doubleCoresQuadTree.query(new $rootScope.jsQuadtree.Circle(local.x, local.y, 10));
						const point = findPoint(points, child.name);
						if (point && mouseOveredDoubleCoreId === '' && mouseOveredMinutiaId === '') {
							mouseOveredDoubleCoreId = point.data.doubleCoreName;
							doubleCoreMouseover(point.data.doubleCoreName);
						} else if ((!point || mouseOveredMinutiaId !== '') && mouseOveredDoubleCoreId !== '') {
							doubleCoreMouseout(mouseOveredDoubleCoreId);
							mouseOveredDoubleCoreId = '';
						}
					}

					// Cores
					if ($scope[`${self.container.name}Options`].drawCores && child.name === self.container.name) {
						const points = coresQuadTree.query(new $rootScope.jsQuadtree.Box(local.x, local.y, 20, 20));
						const point = findPoint(points, child.name);
						if (!!point && mouseOveredCoreId === '' && mouseOveredMinutiaId === '' && mouseOveredDoubleCoreId === '') {
							mouseOveredCoreId = point.data.coreName;
							coreMouseover(point.data.coreName);
						} else if ((!point || mouseOveredMinutiaId !== '' || mouseOveredDoubleCoreId !== '') && mouseOveredCoreId !== '') {
							coreMouseout(mouseOveredCoreId);
							mouseOveredCoreId = '';
						}
					}

					// Deltas
					if ($scope[`${self.container.name}Options`].drawDeltas && child.name === self.container.name) {
						const points = deltasQuadTree.query(new $rootScope.jsQuadtree.Box(local.x, local.y, 20, 20)); // TODO: Deltas need specific triangle class with its 'intersects' and 'contains' methods
						const point = findPoint(points, child.name);
						if (!!point && mouseOveredDeltaId === '' && mouseOveredMinutiaId === '' && mouseOveredDoubleCoreId === '' && mouseOveredCoreId === '') {
							mouseOveredDeltaId = point.data.deltaName;
							deltaMouseover(point.data.deltaName);
						} else if ((!point || mouseOveredMinutiaId !== '' || mouseOveredCoreId !== '' || mouseOveredDoubleCoreId !== '') && mouseOveredDeltaId !== '') {
							deltaMouseout(mouseOveredDeltaId);
							mouseOveredDeltaId = '';
						}
					}
				}

				return () => {
					let child;
					let local;
					for (var i = stage.numChildren - 1; i >= 0; i -= 1) {
						child = stage.getChildAt(i);
						local = child.globalToLocal(stage.mouseX, stage.mouseY);
						if (child.getChildByName('image').hitArea.hitTest(local.x, local.y)) {
							if (!mouseDown && !$scope.isCountingRidges) {
								identifyElementType(local, child);
							} else if (mouseDown) {
								pressMove();
							}
							break;
						}
					}
				};
			}

			function draw() {
				$q.all([
					createFinger($scope.probe),
					createFinger($scope.hit)
				]).then((person) => {
					var probe = person[0];
					probe.name = 'probe';
					var hit = person[1];
					hit.name = 'hit';

					if ($scope.matchingDetails.matedMinutiae) {
						var edgesMST = matchingTreeService.calculateMST($scope.matchingDetails.matedMinutiae, 'index1', $scope.probe.features.minutiae);
						var probeTree = drawingService.createMatchingTree(edgesMST, $scope.matchingDetails.matedMinutiae, 'index1', $scope.probe.features.minutiae, drawingService.getDefaultDarkerHexColor($scope.probeOptions.overlayColor));
						probeTree.name = namingService.getTreeName();
						probe.addChild(probeTree);

						var hitTree = drawingService.createMatchingTree(edgesMST, $scope.matchingDetails.matedMinutiae, 'index2', $scope.hit.features.minutiae, drawingService.getDefaultDarkerHexColor($scope.hitOptions.overlayColor));
						hitTree.name = namingService.getTreeName();
						hit.addChild(hitTree);
					}

					drawFeatures(probe, $scope.probe.features, $scope.probeOptions.overlayColor);
					drawFeatures(hit, $scope.hit.features, $scope.hitOptions.overlayColor);

					const probeBounds = probe.getBounds();
					const hitBounds = hit.getBounds();
					probe.cache(probeBounds.x, probeBounds.y, probeBounds.width, probeBounds.height, 3);
					hit.cache(hitBounds.x, hitBounds.y, hitBounds.width, hitBounds.height, 3);

					callbackProbe = createFingerActions({
						features: $scope.probe.features,
						container: probe,
						options: $scope.probeOptions
					}, {
						features: $scope.hit.features,
						container: hit,
						options: $scope.hitOptions
					}, {
						array: $scope.matchingDetails.matedMinutiae,
						self: 'index1',
						other: 'index2'
					});

					callbackHit = createFingerActions({
						features: $scope.hit.features,
						container: hit,
						options: $scope.hitOptions
					}, {
						features: $scope.probe.features,
						container: probe,
						options: $scope.probeOptions
					}, {
						array: $scope.matchingDetails.matedMinutiae,
						self: 'index2',
						other: 'index1'
					});

					mouseMoveListener = document.getElementById('canvas').addEventListener('mousemove', () => { callbackProbe(); callbackHit(); }, {
						capture: false,
						passive: true
					});

					stage.addChild(probe);
					stage.addChild(hit);
					moveShapeToFront(stage.getChildByName($scope.frontFinger));
					drawingOptionsChanged('hit', $scope.hitOptions, {});
					drawingOptionsChanged('probe', $scope.probeOptions, {});
				}).finally(() => {
					setHotkeys();
					$scope.reset();
					$(window).on('resize', onWindowResize);
				});
			}

			function onWindowResize() {
				resizeCanvas(stage.canvas);
				stage.update();
			}

			function invalidateRidgeCount(container) {
				const ridgeCountLine = container.getChildByName('ridgeCountLine');
				if (ridgeCountLine !== null) {
					ridgeCountLine.graphics.clear();
				}
				$scope.ridgeCount[container.name] = '-';
			}

			function redrawMinutia(container, index, color, width) {
				const shapeContainer = container.getChildByName(namingService.minutiaIndexToName(index));
				const minutia = $scope[container.name].features.minutiae[index];

				const shape = shapeContainer.getChildByName(index);
				shape.color = color || shape.color;
				shape.graphics.clear();

				drawingService.drawMinutia(shape.graphics, minutia, width, color || shape.color);
			}

			function drawRidgeCountLine(container, indexFrom, indexTo, color, width) {
				if (container.getChildByName('ridgeCountLine') === null) {
					const ridgeCountLineShape = new window.createjs.Shape();
					ridgeCountLineShape.name = 'ridgeCountLine';
					container.addChild(ridgeCountLineShape);
				}

				const { features } = $scope[container.name];
				const shape = container.getChildByName('ridgeCountLine');
				const minutiaFrom = features.minutiae[indexFrom];
				const minutiaTo = features.minutiae[indexTo];

				shape.graphics.clear();

				drawingService.drawLine(shape.graphics, minutiaFrom, minutiaTo, color, width);
				moveShapeToFront(shape);
			}

			function calculateRidges(name, fingerIndex, point1Index, point2Index) {
				const minutia1 = $scope[name].features.minutiae[point1Index];
				const minutia2 = $scope[name].features.minutiae[point2Index];
				ExaminersResource.CountRidges({
					encounterId: $scope[name].encounterId,
					fingerId: $scope[$scope.frontFinger].fingerId,
					point1: {
						x: minutia1.x,
						y: minutia1.y
					},
					point2: {
						x: minutia2.x,
						y: minutia2.y
					},
				}, (response) => {
					$scope.ridgeCount[name] = response.count;
				}, (error) => {
					AlertService.show(error.data.message, { type: 'danger', translate: true });
				});
			}

			function countRidgesInternal(child, index, color, width) {
				if ($scope.selectedMinutiae[child.name].some(minutia => minutia === index)) { // pressed again
					const minutiaColor = drawingService.getMinutiaColor(index, $scope.matchingDetails.matedMinutiae, child.name);
					$scope.selectedMinutiae[child.name] = $scope.selectedMinutiae[child.name].filter(minutia => minutia !== index);
					invalidateRidgeCount(child);
					redrawMinutia(child, index, minutiaColor);
				} else { // first time
					$scope.selectedMinutiae[child.name].push(index);
					invalidateRidgeCount(child);
					redrawMinutia(child, index, color, width);
				}

				if ($scope.selectedMinutiae[child.name].length === 2) { // need to draw the line
					drawRidgeCountLine(child, $scope.selectedMinutiae[child.name][0], $scope.selectedMinutiae[child.name][1], color, width);
					calculateRidges(child.name, index, $scope.selectedMinutiae[child.name][0], $scope.selectedMinutiae[child.name][1]);
				}

				if ($scope.selectedMinutiae[child.name].length > 2) { // need to disable last selected minutia
					for (let i = 0; i < $scope.selectedMinutiae[child.name].length - 2; i += 1) {
						invalidateRidgeCount(child);
						const minutiaColor = drawingService.getMinutiaColor($scope.selectedMinutiae[child.name][i], $scope.matchingDetails.matedMinutiae, child.name);
						redrawMinutia(child, $scope.selectedMinutiae[child.name][i], minutiaColor);
					}
					$scope.selectedMinutiae[child.name].splice(0, $scope.selectedMinutiae[child.name].length - 2);
					drawRidgeCountLine(child, $scope.selectedMinutiae[child.name][0], $scope.selectedMinutiae[child.name][1], color, width);
					calculateRidges(child.name, index, $scope.selectedMinutiae[child.name][0], $scope.selectedMinutiae[child.name][1]);
				}

				child.updateCache();
				stage.update();
			}

			$translate(['comparison.finger.wheel.step.on', 'comparison.finger.wheel.step.off',
				'comparison.finger.lock-mode.on', 'comparison.finger.lock-mode.off',
			]).then((translations) => {
				$scope.comparison = {
					wheelStep: {
						off: translations['comparison.finger.wheel.step.off'],
						on: translations['comparison.finger.wheel.step.on']
					},
					lockMode: {
						on: translations['comparison.finger.lock-mode.on'],
						off: translations['comparison.finger.lock-mode.off']
					},
				};
			});

			$scope.hasFingerImage = function () {
				return $scope[$scope.frontFinger].hasImage;
			};

			$scope.onChangeWheelStep = function (wheelStepBool) {
				$scope.wheelStep = wheelStepBool ? 'precision' : 'default';
			};

			$translate(['comparison.finger.tooltip-disabled-count']).then((translates) => {
				$scope.tooltipText = $sce.trustAsHtml(`<div>${translates['comparison.finger.tooltip-disabled-count']}</div>`);
			});

			$uibModalInstance.rendered.then(() => {
				init();
				const checkIfAllFeaturesNeeded = options => options.drawCores || options.drawDeltas || options.drawDoubleCores;
				$q.all([
					getFingerFeatures($scope.probe, checkIfAllFeaturesNeeded($scope.probeOptions)), getFingerFeatures($scope.hit, checkIfAllFeaturesNeeded($scope.hitOptions))
				]).then(() => {
					$q.all([
						getFingerImageUrl($scope.probe, 'TRANSPARENT'), getFingerImageUrl($scope.hit, 'TRANSPARENT'),
						getMatchingDetails()
					]).then(() => {
						function assignCoordinatesToChild(child, local) {
							child.regX = local.x;
							child.regY = local.y;
							child.x = stage.mouseX;
							child.y = stage.mouseY;
						}

						draw();
						document.getElementById('canvas').addEventListener('mousedown', () => {
							let local;
							const oldChild = stage.getChildAt(stage.numChildren - 1);
							let selectedChild = oldChild;
							let childImg;
							for (let i = stage.numChildren - 1; i >= 0; i -= 1) {
								selectedChild = stage.getChildAt(i);
								local = selectedChild.globalToLocal(stage.mouseX, stage.mouseY);
								childImg = selectedChild.getChildByName('image');
								if (childImg.hitArea.hitTest(local.x, local.y)) {
									assignCoordinatesToChild(selectedChild, local);
									mouseDown = true;
									if (!$scope.lockMode) {
										break;
									}
								} else if ($scope.lockMode) {
									assignCoordinatesToChild(selectedChild, local);
								} else {
									selectedChild = oldChild;
								}
							}
							if (!$scope.lockMode) {
								moveShapeToFront(selectedChild);
								$scope.$apply((scope) => {
									scope.frontFinger = selectedChild.name;
								});
							}
						});
						document.getElementById('canvas').addEventListener('mouseup', () => {
							mouseDown = false;
						});
						document.getElementById('canvas').addEventListener('click', () => {
							if ($scope.isCountingRidges) {
								let local;
								let child;
								let points;
								let point;
								for (let i = stage.numChildren - 1; i >= 0; i -= 1) {
									child = stage.getChildAt(i);
									local = child.globalToLocal(stage.mouseX, stage.mouseY);
									if (child.getChildByName('image').hitArea.hitTest(local.x, local.y) && $scope[child.name].hasImage) {
										points = minutiaeQuadTree.query(new $rootScope.jsQuadtree.Circle(local.x, local.y, 4));
										point = findPoint(points, child.name);
										if (point) {
											countRidgesInternal(child, point.data.minutiaName, '#2bbd7e', 2);
											break;
										}
									}
								}
							}
						});
					});
				});
			});

			$uibModalInstance.closed.then(() => {
				$(window).off('resize', onWindowResize);
			});

			$scope.countRidges = function () {
				if (!AuthDataHolder.hasAnyAuthority('PERMISSION_TOOLS_FINGER_EXAMINER')) {
					return;
				}

				if ($scope.isCountingRidges) {
					let container = stage.getChildByName('probe');
					for (let i = 0; i < $scope.selectedMinutiae.probe.length; i += 1) {
						invalidateRidgeCount(container);
						const minutiaColor = drawingService.getMinutiaColor($scope.selectedMinutiae.probe[i], $scope.matchingDetails.matedMinutiae, 'probe');
						redrawMinutia(container, $scope.selectedMinutiae.probe[i], minutiaColor);
						container.updateCache();
					}
					container = stage.getChildByName('hit');
					for (let i = 0; i < $scope.selectedMinutiae.hit.length; i += 1) {
						invalidateRidgeCount(container);
						const minutiaColor = drawingService.getMinutiaColor($scope.selectedMinutiae.hit[i], $scope.matchingDetails.matedMinutiae, 'hit');
						redrawMinutia(container, $scope.selectedMinutiae.hit[i], minutiaColor);
						container.updateCache();
					}
					stage.update();
					$scope.selectedMinutiae = { probe: [], hit: [] };
				}
				$scope.isCountingRidges = !$scope.isCountingRidges;
			};

			$scope.getProbeExportImageUrl = function () {
				return $scope.probe.getImageUrl('ORIGINAL');
			};
			$scope.getHitExportImageUrl = function () {
				return $scope.hit.getImageUrl('ORIGINAL');
			};
		}]);
