import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('HomeCtrl', ['$scope', 'AuthDataHolder',
		function ($scope, AuthDataHolder) {
			$scope.isHomeUrl = true;

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};
		}]);
