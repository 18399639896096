import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('AlertService', ['$translate', '$injector', '$q', function ($translate, $injector, $q) {
		var types = ['success', 'danger', 'warning', 'info'];

		function createAlert(type, message) {
			return {
				type,
				message
			};
		}

		function addAlert(alert) {
			$injector.get('toastr')[alert.type](alert.message);
		}

		function translateAlert(translationId, params, alert) {
			var deferred = $q.defer();
			$translate(translationId, params).then((translation) => {
				alert.message = translation;
				deferred.resolve(alert);
			});
			return deferred.promise;
		}

		this.show = function (msg, options) {
			options = options || {};
			options.translate = options.translate === true || options.translate == null;
			options.type = types.indexOf(options.type) !== -1 ? options.type : 'success';
			options.type = options.type === 'danger' ? 'error' : options.type; // toastr adapter
			options.translateValues = options.translateValues || {};

			if (options.translate) {
				translateAlert(msg, options.translateValues, createAlert(options.type, null)).then((alert) => {
					addAlert(alert);
				});
			} else {
				addAlert(createAlert(options.type, msg));
			}
		};
	}]);

