import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ExportUtils', ['$filter', '$window', function ($filter, $window) {
		this.downloadModality = function (getModality, fileName) {
			function createElement(blob) {
				const link = document.createElement('a');
				const date = $filter('date')(new Date(), 'yyyyMMdd_HHmmss');
				const name = `${fileName}${date}.png`;

				link.href = blob;
				link.download = name;

				document.body.appendChild(link);
				return link;
			}

			function destroyElement(link) {
				document.body.removeChild(link);
			}

			getModality
				.then((blob) => {
					const link = createElement(blob);
					// eslint-disable-next-line no-undef
					link.dispatchEvent(new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: $window
					}));
					destroyElement(link);
				});
		};
	}]);
