angular.module('neurotecAbisWebClientApp')
	.service('EncounterToSubjectConverter', ['$q', 'SubjectService', 'Utils', 'EncountersResource', function ($q, SubjectService, Utils, EncountersResource) {
		const self = this;

		function modalityToImagesData(encounterModality) {
			return $q.all(encounterModality.map(item => Utils.blobURLtoB64(item.imageUrl)));
		}

		function getLimbs(limbs, encounterId, endpoint) {
			function getPromises() {
				return limbs.map((_limb, index) =>
					endpoint({ encounterId, modalityId: index, type: 'ORIGINAL' }).$promise);
			}

			async function toLimb(result, idx) {
				return {
					position: limbs[idx].position,
					data: await Utils.blobToB64(result.value.image)
				};
			}

			function toLimbs(results) {
				return results.reduce((promises, result, idx) => {
					if (result.status === 'fulfilled') {
						promises.push(toLimb(result, idx));
					}
					return promises;
				}, []);
			}

			const deferred = $q.defer();
			const promises = getPromises();
			Promise.allSettled(promises).then((results) => {
				const limbsPromises = toLimbs(results);
				$q.all(limbsPromises).then(deferred.resolve);
			});
			return deferred.promise;
		}

		function getFingers(encounter) {
			const endpoint = EncountersResource.getFingerImage;
			return getLimbs(encounter.fingers, encounter.encounterId, endpoint);
		}

		function getPalms(encounter) {
			const endpoint = EncountersResource.getPalmImage;
			return getLimbs(encounter.palms, encounter.encounterId, endpoint);
		}

		self.encounterBiometricsToSubject = function (encounter) {
			function toFaceObj(data) {
				return {
					data
				};
			}

			function toIrisObj(data, position) {
				return {
					position,
					data
				};
			}

			function toLimbObj(rawLimb) {
				return [{
					data: rawLimb.data,
					position: rawLimb.position,
					status: 'ok',
					isImported: true
				}];
			}

			const deferred = $q.defer();
			let biometricData = {};
			$q.all([
				modalityToImagesData(encounter.faces),
				modalityToImagesData(encounter.irises),
				getFingers(encounter),
				getPalms(encounter),
			]).then((modalitiesData) => {
				biometricData = {
					faceImages: modalitiesData[0].map(toFaceObj) || [],
					irisImages: modalitiesData[1].map((data, idx) => toIrisObj(data, encounter.irises[idx].position)) || [],
					fingerImages: modalitiesData[2].map(data => toLimbObj(data)) || [],
					palmImages: modalitiesData[3].map(data => toLimbObj(data)) || [],
				};
				deferred.resolve(biometricData);
			});

			return deferred.promise;
		};

		this.encounterToSubjectService = async function (encounter) {
			SubjectService.invalidate();
			SubjectService.setId(encounter.subjectId);
			SubjectService.setEncounterId(encounter.encounterId);
			SubjectService.setBiographicData(encounter.biographicData);

			const biometricData = await self.encounterBiometricsToSubject(encounter);
			SubjectService.setBiometricData(biometricData);

			if (encounter.signature && encounter.signature.imageUrl) {
				const signature = await Utils.blobURLtoB64(encounter.signature.imageUrl);
				SubjectService.setSignature(signature);
			}
		};
	}]);
