import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('SummaryModalCtrl', ['$scope', '$uibModalInstance', '$translate', function ($scope, $uibModalInstance, $translate) {
		$scope.perform = function () {
			$scope.$close('ok');
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.keyPress = function (event) {
			if (event.keyCode === 13 && event.shiftKey !== true) {
				$scope.perform();
			}
		};

		$translate([
			'adjudication.case.status.duplicate', 'adjudication.case.status.unique', 'adjudication.case.status.undecided'
		]).then((translations) => {
			$scope.statuses = {
				/* jshint sub:true */
				UNIQUE: translations['adjudication.case.status.unique'],
				DUPLICATE: translations['adjudication.case.status.duplicate'],
				UNDECIDED: translations['adjudication.case.status.undecided']
				/* jshint sub:false */
			};
		});
	}]);
