import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('scorePanel', () => ({
		template: require('../../views/directives/score-panel.html'),
		restrict: 'E',
		replace: true,
		scope: {
			scores: '=',
		},
		controller: ['$scope', 'ParametersService', function ($scope, ParametersService) {
			$scope.allowedModalities = new Set();
			ParametersService.getParameter('mmabis.management.extraction-modalities').then((data) => {
				$scope.allowedModalities = new Set(data);
			});

			$scope.iconClass = 'img-responsive pointer-events-none score-icon';
		}]
	}));
