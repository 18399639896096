import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('GalleriesService', ['$q', 'GalleriesResource', 'AuthDataHolder', function ($q, GalleriesResource, AuthDataHolder) {
		const self = this;
		const DEFAULT_GALLERY = {
			description: 'Global gallery',
			id: 'default',
			name: 'Default',
			state: 'ACTIVE'
		};
		const ALL_GALLERIES_OPTION = {
			description: 'All galleries',
			id: 'any',
			name: 'All',
			state: 'ACTIVE'
		};
		const ALL_GALLERIES_KEYS = ['any', 'all', null]; // valid keywords for filtering

		/* On top of fetched galleries there can be unshifted options like 'Global' or 'All'
		while default selected should be the first available gallery.

		All is only added as an option on specific methods for search
		 */
		const GALLERIES_START_INDEX = 1;

		this.getGalleries = function () {
			return GalleriesResource.getGalleries({ galleryId: '*' }).$promise;
		};

		this.getAvailableGalleries = function () {
			const defer = $q.defer();
			GalleriesResource.getGalleries({ galleryId: '*' }).$promise
				.then((galleries) => {
					const filteredGalleries = galleries.filter(gallery => gallery.state === 'ACTIVE'
																		&& AuthDataHolder.hasAnyGallery(gallery.id));
					defer.resolve(filteredGalleries);
				})
				.catch(() => defer.reject([]));
			return defer.promise;
		};

		this.getGallerySizes = function () {
			const defer = $q.defer();
			$q.all([
				GalleriesResource.getGalleries({ galleryId: '*' }).$promise,
				GalleriesResource.getSizes().$promise
			])
				.then(([galleries, sizes]) => {
					const gallerySizes = galleries.map(gallery => ({
						id: gallery.id,
						name: gallery.name,
						size: sizes[gallery.id]
					}));
					const defaultGallery = this.getDefaultGallery();
					gallerySizes.unshift({
						id: defaultGallery.id,
						name: defaultGallery.name,
						size: sizes[defaultGallery.id],
					});
					defer.resolve(gallerySizes);
				})
				.catch(() => defer.reject([]));
			return defer.promise;
		};

		this.isDefaultGallery = function (galleryObj) {
			return galleryObj.id === DEFAULT_GALLERY.id;
		};

		this.isAll = function (galleryObj) {
			return ALL_GALLERIES_KEYS.includes(galleryObj.id);
		};

		this.returnIfNotDefault = function (gallery) {
			return !this.isDefaultGallery(gallery) && gallery;
		};

		this.getDefaultGallery = function () {
			return angular.copy(DEFAULT_GALLERY);
		};

		this.isDefaultId = function (galleryId) {
			return galleryId === DEFAULT_GALLERY.id;
		};

		this.getAllGalleriesOption = function () {
			return angular.copy(ALL_GALLERIES_OPTION);
		};

		this.getBeginningGalleryIndexForFiltering = function () {
			const DEFAULT_GALLERY = 1;
			return angular.copy(GALLERIES_START_INDEX + DEFAULT_GALLERY);
		};

		this.getBeginningGalleryIndex = function () {
			return angular.copy(GALLERIES_START_INDEX);
		};

		this.getGalleryNameById = function (galleriesStr, galleries) {
			if (!AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST') || !galleriesStr) { return ''; }

			let gallery = [galleriesStr];

			const isGalleryAll = self.getAllGalleriesOption().id === galleriesStr[0].id;
			if (isGalleryAll) {
				gallery = [galleries[0]];
			}

			const isMultiple = galleriesStr.includes(',');
			if (isMultiple) {
				gallery = galleriesStr.split(',');
			}

			return gallery.map(extractedId => galleries.find(g => g.id === extractedId).name).join(', ');
		};

		this.toRequestString = function (galleries) {
			return galleries.map(g => g.id).join(',');
		};

		this.fromRequestString = function (galleriesStr, galleries) {
			let gallery = [galleriesStr];

			const isGalleryAll = self.getAllGalleriesOption().id === galleriesStr[0].id;
			if (isGalleryAll) {
				gallery = [galleries[0]];
			}

			const isMultiple = galleriesStr.includes(',');
			if (isMultiple) {
				gallery = galleriesStr.split(',');
			}

			return galleries.filter(g => gallery.some(extractedId => extractedId === g.id));
		};

		this.getGalleriesDefaultParameters = function () {
			return GalleriesResource.getGalleriesDefaultParameters({ galleryId: '*' }).$promise;
		};

		this.save = (...params) => GalleriesResource.save(...params).$promise;
		this.updateGallery = (...params) => GalleriesResource.updateGallery(...params).$promise;
		this.delete = (...params) => GalleriesResource.delete(...params).$promise;
		this.addParameters = (...params) => GalleriesResource.addGalleryDefaultParameters(...params).$promise;
		this.updateParameters = (...params) => GalleriesResource.updateGalleryDefaultParameters(...params).$promise;
		this.deleteParameters = (...params) => GalleriesResource.deleteGalleryDefaultParameters(...params).$promise;
	}]);
