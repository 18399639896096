import angular from 'angular';

const yaw = `${process.env.PUBLIC_URL}/images/face-view/yaw.svg`;
const arrow = `${process.env.PUBLIC_URL}/images/face-view/arrow.svg`;
const roll = `${process.env.PUBLIC_URL}/images/face-view/roll.svg`;

angular.module('neurotecAbisWebClientApp')
	.service('FaceViewService', ['ParserResource', 'Utils', '$timeout', function (ParserResource, Utils, $timeout) {
		var self = this;
		var canvas;
		var ctx;
		var oldFrame = null;
		var yawArrow = new Image();
		var moveArrow = new Image();
		var rollArrow = new Image();

		var focusBody = null;
		var focusTimeOut = null;

		function facePolygon(ctx, x, y, width, height, yaw) {
			ctx.moveTo(x, y);
			ctx.lineTo(x + width, y);

			if (yaw < 0) {
				ctx.lineTo(x + width - (width / 5 * yaw) / 45, y + height / 2);
			}

			ctx.lineTo(x + width, y + height);
			ctx.lineTo(x, y + height);

			if (yaw > 0) {
				ctx.lineTo(x - (width / 5 * yaw) / 45, y + height / 2);
			}

			ctx.lineTo(x, y);
		}

		this.init = function (canvasId) {
			canvas = document.getElementById(canvasId);
			ctx = canvas.getContext('2d');
			yawArrow.src = yaw;
			moveArrow.src = arrow;
			rollArrow.src = roll;
		};

		this.clear = function () {
			ctx.setTransform(1, 0, 0, 1, 0, 0);
			ctx.clearRect(0, 0, canvas.width, canvas.height);
		};

		this.refresh = function (frameUrl, icaoRect, icaoWarnings, frameB64) {
			var rect = icaoRect;
			var warnings = icaoWarnings;
			var frameImage = new Image();
			frameImage.src = frameUrl;

			if (frameB64 !== null && frameB64 !== undefined) {
				frameImage.onerror = function () {
					if (oldFrame !== null && oldFrame !== undefined) {
						URL.revokeObjectURL(oldFrame);
						oldFrame = null;
					}

					ParserResource.parseImage({ data: frameB64 }, (result) => {
						oldFrame = URL.createObjectURL(Utils.b64toBlob(result.data, 'image/x-ms-bmp'));
						self.refresh(oldFrame, icaoRect, icaoWarnings, frameB64);
					});
				};
			}

			frameImage.onload = function () {
				canvas.width = frameImage.width;
				canvas.height = frameImage.height;
				ctx.setTransform(1, 0, 0, 1, 0, 0);
				ctx.clearRect(0, 0, frameImage.width, frameImage.height);
				var offsetX = (canvas.width - frameImage.width) / 2;
				var offsetY = (canvas.height - frameImage.height) / 2;
				ctx.drawImage(frameImage, offsetX > 0 ? offsetX : 0, offsetY > 0 ? offsetY : 0);
				ctx.save();
				ctx.restore();

				if (focusBody) {
					// Focus region
					ctx.save();
					ctx.beginPath();
					ctx.fillStyle = 'rgb(69, 153, 69)';
					ctx.arc(focusBody.x + focusBody.width / 2, focusBody.y + focusBody.height / 2, 5, 0, 2 * Math.PI);
					ctx.fill();
					ctx.beginPath();
					ctx.setLineDash([12, 3, 3]);
					ctx.lineWidth = '3';
					ctx.strokeStyle = 'rgb(38, 38, 38)';
					ctx.rect(focusBody.x, focusBody.y, focusBody.width, focusBody.height);
					ctx.stroke();
					ctx.beginPath();
					ctx.lineWidth = '2';
					ctx.strokeStyle = 'rgb(69, 153, 69)';
					ctx.rect(focusBody.x, focusBody.y, focusBody.width, focusBody.height);
					ctx.stroke();
					ctx.restore();
				}

				if (warnings && warnings.indexOf('FACE_NOT_DETECTED') === -1) {
					var x = rect.X;
					var y = rect.Y;
					var width = rect.Width;
					var height = rect.Height;
					var yaw = rect.Yaw;
					var roll = rect.Roll;
					var padding = 5;

					ctx.translate(x + width / 2, y + height / 2);
					ctx.rotate(roll * Math.PI / 180);

					// face rectangle
					ctx.beginPath();
					ctx.strokeStyle = 'green';
					ctx.lineWidth = 1;
					facePolygon(ctx, -width / 2, -height / 2, width, height, yaw);
					ctx.stroke();
					ctx.closePath();
					ctx.save();
					ctx.restore();

					ctx.setLineDash([5, 3]);
					ctx.beginPath();
					ctx.strokeStyle = 'white';
					ctx.lineWidth = 1;
					facePolygon(ctx, -rect.TokenWidth / 2, -rect.TokenHeight / 2, rect.TokenWidth, rect.TokenHeight, 0);
					ctx.stroke();
					ctx.closePath();
					ctx.save();
					ctx.restore();
					ctx.setLineDash([]);

					if (width > 0) {
						if (warnings.indexOf('PITCH_DOWN') > -1) {
							// south
							ctx.save();
							ctx.translate(-yawArrow.height / 2, -height / 2 + yawArrow.height / 2 + padding);
							ctx.rotate(-90 * Math.PI / 180);
							ctx.drawImage(yawArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('PITCH_UP') > -1) {
							// north
							ctx.save();
							ctx.translate(-yawArrow.height / 2, height / 2 - yawArrow.height / 2 - padding);
							ctx.scale(-1, 1);
							ctx.rotate(90 * Math.PI / 180);
							ctx.drawImage(yawArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('YAW_RIGHT') > -1) {
							// east
							var yawLeftOffset = yaw < 0 ? (width / 5 * yaw) / 45 : 0;
							ctx.save();
							ctx.translate(width / 2 + padding - yawLeftOffset - yawArrow.width / 2 - padding, -yawArrow.height / 2);
							ctx.drawImage(yawArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('YAW_LEFT') > -1) {
							// west
							var yawRightOffset = yaw > 0 ? (width / 5 * yaw) / 45 : 0;
							ctx.save();
							ctx.translate(-width / 2 - padding - yawRightOffset + yawArrow.width / 2 + padding, -yawArrow.height / 2);
							ctx.scale(-1, 1);
							ctx.drawImage(yawArrow, 0, 0);
							ctx.restore();
						}

						if (warnings.indexOf('TOO_SOUTH') > -1) {
							// south
							ctx.save();
							ctx.translate(-moveArrow.height / 2, -height / 2 - padding);
							ctx.rotate(-90 * Math.PI / 180);
							ctx.drawImage(moveArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('TOO_NORTH') > -1) {
							// north
							ctx.save();
							ctx.translate(-moveArrow.height / 2, height / 2 + padding);
							ctx.scale(-1, 1);
							ctx.rotate(90 * Math.PI / 180);
							ctx.drawImage(moveArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('TOO_EAST') > -1) {
							// east
							var yawEastOffset = yaw < 0 ? (width / 5 * yaw) / 45 : 0;
							ctx.save();
							ctx.translate(width / 2 + padding - yawEastOffset, -moveArrow.height / 2);
							ctx.drawImage(moveArrow, 0, 0);
							ctx.restore();
						}
						if (warnings.indexOf('TOO_WEST') > -1) {
							// west
							var yawWestOffset = yaw > 0 ? (width / 5 * yaw) / 45 : 0;
							ctx.save();
							ctx.translate(-width / 2 - padding - yawWestOffset, -moveArrow.height / 2);
							ctx.scale(-1, 1);
							ctx.drawImage(moveArrow, 0, 0);
							ctx.restore();
						}

						if (warnings.indexOf('ROLL_RIGHT') > -1) {
							ctx.save();
							ctx.translate(-width / 2 - rollArrow.width / 2, -height / 2 - rollArrow.height / 2);
							ctx.drawImage(rollArrow, 0, 0);
							ctx.restore();
						}

						if (warnings.indexOf('ROLL_LEFT') > -1) {
							ctx.save();
							ctx.translate(width / 2 + rollArrow.width / 2, -height / 2 - rollArrow.height / 2);
							ctx.scale(-1, 1);
							ctx.drawImage(rollArrow, 0, 0);
							ctx.restore();
						}
					}
				}
			};
		};

		this.setFocusPoint = function (fb) {
			focusBody = fb;
			$timeout.cancel(focusTimeOut);
			focusTimeOut = $timeout(() => { focusBody = null; }, 1000);
		};
	}]);
