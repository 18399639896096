import angular from 'angular';

angular.module('neurotecAbisWebClientApp')

	.factory('ImageResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/latent-print/process`, null, {
			sendImg: {
				method: 'POST'
			}
		});
	}])

	.factory('MinutiaResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/latent-print/minutia`, null, {
			sendImg: {
				method: 'POST'
			}
		});
	}])

	.factory('FingerImageResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/latent-print/imageupload`, null, {
			sendFingerImage: {
				method: 'POST'
			}
		});
	}])

	.factory('MinutiaDataResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/latent-print/minutiadata`, null, {
			sendMinutiaData: {
				method: 'POST'
			}
		});
	}]);
