import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('LogsCtrl', ['$scope', '$timeout', 'ElasticsearchService', 'FilterUtils', 'ConvertionUtils', 'DatetimePickerService',
		function ($scope, $timeout, ElasticsearchService, FilterUtils, ConvertionUtils, DatetimePickerService) {
			$scope.cnv = ConvertionUtils;

			/* Dropdowns & enabled/disabled checking */

			$scope.apps = {
				'authorization-service': 'Authorization service',
				'management-service': 'Management service',
				'matching-service': 'Matching service',
				'image-processing-service': 'Image processing service'
			};

			$scope.disabled = null;
			$scope.nodes = [];
			ElasticsearchService.search({
				size: 0,
				aggs: {
					nodes: {
						terms: {
							field: 'HOSTNAME.keyword',
							order: { _key: 'asc' },
							size: 100
						}
					}
				}
			}, (resp) => {
				var nodes = resp.aggregations.nodes.buckets;
				for (var i = 0; i < nodes.length; i += 1) {
					$scope.nodes.push(nodes[i].key);
				}
				if ($scope.nodes.length > 0) {
					[$scope.node] = $scope.nodes;
				}
			}).$promise.catch((response) => {
				if (response.status === 404) {
					$scope.disabled = true;
				}
			}).finally(() => {
				if ($scope.disabled == null) {
					$scope.disabled = false;
				}
			});

			/* Filtering */

			var filterDataInternal = {};
			$scope.filterData = {};

			$scope.datetimePickerOptions = {};
			DatetimePickerService.getDefaultOptions()
				.then((options) => {
					$scope.datetimePickerOptions = options;
					$scope.datetimePickerOptions.timePicker = true;
					$scope.datetimePickerOptions.locale.format = 'YYYY-MM-DD hh:mm A';
				});

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix, true);
			};

			function isFormValid() {
				return $scope.filterForm.$valid && $scope.dateFieldsValid('timestamp');
			}

			function copyProcessedFilterData(to) {
				FilterUtils.filterBySingleField($scope.filterData, to, 'app');
				FilterUtils.filterBySingleField($scope.filterData, to, 'node');
				FilterUtils.filterByDateFields($scope.filterData, to, 'timestamp');
				FilterUtils.filterBySingleField($scope.filterData, to, 'requestId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'subjectId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'message');
				FilterUtils.filterBySingleField($scope.filterData, to, 'withExceptions');
			}

			$scope.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					fetchLog();
				}
			};

			$scope.resetFilter = function () {
				$scope.filterData = {};
				$scope.filterForm.$setPristine();
				filterDataInternal = {};
				fetchLog();
			};

			/* Fetching */

			function isFastQuery(filterData) {
				if (filterData == null) {
					filterData = filterDataInternal;
				}
				// eslint-disable-next-line no-restricted-syntax
				for (var key in filterData) {
					if (Object.prototype.hasOwnProperty.call(filterData, key) && filterData[key] != null) {
						switch (key) {
						case 'app':
						case 'node':
							break;
						default:
							return false;
						}
					}
				}
				return true;
			}

			function fetchLog() {
				$scope.scrollId = null;

				var body = {
					query: {
						bool: {
							must: []
						}
					}
				};

				if (filterDataInternal.app != null) {
					var app = {
						term: { 'app.keyword': filterDataInternal.app }
					};
					body.query.bool.must.push(app);
				}
				if (filterDataInternal.node != null) {
					var node = {
						term: { 'HOSTNAME.keyword': filterDataInternal.node }
					};
					body.query.bool.must.push(node);
				}
				if (filterDataInternal.timestampLower != null || filterDataInternal.timestampUpper != null) {
					var timestamp = {
						range: {
							'@timestamp': {}
						}
					};
					if (filterDataInternal.timestampLower != null) {
						timestamp.range['@timestamp'].gte = filterDataInternal.timestampLower;
					}
					if (filterDataInternal.timestampUpper != null) {
						timestamp.range['@timestamp'].lte = filterDataInternal.timestampUpper;
					}
					body.query.bool.must.push(timestamp);
				}
				if (filterDataInternal.requestId != null) {
					var requestId = {
						term: { request_id: filterDataInternal.requestId }
					};
					body.query.bool.must.push(requestId);
				}
				if (filterDataInternal.subjectId != null) {
					var subjectId = {
						term: { 'subject_id.keyword': filterDataInternal.subjectId }
					};
					body.query.bool.must.push(subjectId);
				}
				if (filterDataInternal.message != null) {
					var message = {
						simple_query_string: {
							query: filterDataInternal.message,
							fields: [
								'message',
								'stack_trace'
							],
							default_operator: 'and'
						}
					};
					body.query.bool.must.push(message);
				}
				if (filterDataInternal.withExceptions === true) {
					var withExceptions = {
						exists: {
							field: 'stack_trace'
						}
					};
					body.query.bool.must.push(withExceptions);
				}

				if (isFastQuery()) {
					body.sort = [
						{ '@timestamp': 'desc' },
						{
							offset: {
								order: 'desc',
								unmapped_type: 'long'
							}
						}
					];
					body.size = 50;
					return ElasticsearchService.search(body, (resp) => {
						$scope.log = resp.hits.hits.reverse();
					}).$promise;
				}
				body.sort = [
					'@timestamp',
					{
						offset: {
							unmapped_type: 'long'
						}
					}
				];
				body.size = 100;
				return ElasticsearchService.search({
					scroll: '1m',
				}, body, (resp) => {
					$scope.log = resp.hits.hits;
					$scope.scrollId = resp['_scroll_id']; // eslint-disable-line dot-notation
				}).$promise;
			}

			/* Scrolling */

			var scrolling = false;

			$scope.scroll = function () {
				if ($scope.scrollId != null) {
					if (scrolling) {
						return;
					}
					scrolling = true;

					ElasticsearchService.scroll({
						scroll: '1m',
						scroll_id: $scope.scrollId
					}, (resp) => {
						if (resp.hits.hits.length === 0) {
							$scope.scrollId = null;
							return;
						}
						for (var i = 0; i < resp.hits.hits.length; i += 1) {
							$scope.log.push(resp.hits.hits[i]);
						}
					}, () => {
						$scope.scrollId = null;
					}).$promise['finally'](() => { // eslint-disable-line dot-notation
						scrolling = false;
					});
				}
			};

			/* Polling */

			var controllerAlive;
			$scope.$on('$viewContentLoaded', () => {
				controllerAlive = true;

				fetchLog()['finally'](function poll() { // eslint-disable-line dot-notation
					if ($scope.disabled) {
						return;
					}
					$timeout(() => {
						if (controllerAlive) {
							if (isFastQuery()) {
								fetchLog()['finally'](poll); // eslint-disable-line dot-notation
							} else {
								poll();
							}
						}
					}, 5000);
				});
			});
			$scope.$on('$destroy', () => {
				controllerAlive = false;
			});

			/* Auto trigger */

			function valueChanged(newValue, oldValue) {
				if (newValue != null && newValue !== oldValue && isFormValid()) {
					var filterDataTemp = {};
					copyProcessedFilterData(filterDataTemp);
					if (isFastQuery(filterDataTemp)) {
						$scope.filter();
					}
				}
			}

			$scope.$watch('filterData.app', valueChanged);
			$scope.$watch('filterData.node', valueChanged);
		}]);
