import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('keyboard', ['$document', function ($document) {
		return {
			scope: {
				keysToHandle: '<keyboard',
				ngDisabled: '='
			},
			link(scope) {
				let keyHandlers = {};
				let isLoaded = false;

				function registerKeyHandlers() {
					Object.entries(scope.keysToHandle).forEach(([keyName, callback]) => {
						keyHandlers[keyName] = callback;
					});
				}

				function bindKeys() {
					$document.on('keydown', handleKeyPress);
				}

				function deregisterKeyDown() {
					$document.off('keydown', handleKeyPress);
				}

				function handleKeyPress(event) {
					const keyHandler = keyHandlers[event.originalEvent.code];
					if (keyHandler && !scope.ngDisabled) {
						event.preventDefault();
						scope.$apply(() => {
							keyHandler(event);
						});
					}
				}

				scope.$on('$destroy', () => {
					deregisterKeyDown();
				});

				scope.$watch('ngDisabled', (isDisabled) => {
					if (!isDisabled && !isLoaded) {
						keyHandlers = {};
						deregisterKeyDown();
						registerKeyHandlers();
						bindKeys();
						isLoaded = true;
					} else if (isDisabled) {
						deregisterKeyDown();
						isLoaded = false;
					}
				});
			}
		};
	}]);
