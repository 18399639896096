import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('EncountersResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		function responseImage(data) {
			var response = {};
			if (data) {
				response.image = new Blob([data], {
					type: 'image/png'
				});
			}
			return response;
		}
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/encounters/:encounterId/:modality/:modalityId/:item/:segmentId/:segmentItem`, {
			encounterId: '@encounterId',
			modality: '@modality',
			modalityId: '@modalityId',
			item: '@item',
			segmentId: '@segmentId',
			segmentItem: '@segmentItem'
		}, {
			export: {
				params: {
					modality: 'export',
					type: 'ANSI_NIST'
				},
				headers: {
					Accept: 'application/octet-stream'
				},
				responseType: 'arraybuffer',
				transformResponse(data) {
					var response = {};
					if (data) {
						response.nist = new Blob([data], {
							type: 'application/octet-stream'
						});
					}
					return response;
				}
			},
			get: {
				method: 'GET'
			},
			getExport: {
				method: 'GET',
				params: {
					modality: 'export'
				}
			},
			getIrises: {
				method: 'GET',
				params: {
					modality: 'irises'
				},
				isArray: true
			},
			getIris: {
				method: 'GET',
				params: {
					modality: 'irises'
				}
			},
			getIrisImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'irises',
					item: 'image'
				},
				transformResponse: responseImage
			},
			getPalms: {
				method: 'GET',
				params: {
					modality: 'palms'
				},
				isArray: true
			},
			getPalm: {
				method: 'GET',
				params: {
					modality: 'palms'
				}
			},
			getPalmImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'palms',
					item: 'image'
				},
				transformResponse: responseImage
			},
			getPalmFeatures: {
				method: 'GET',
				params: {
					modality: 'palms',
					item: 'features'
				}
			},
			getSignatureImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'signature',
					item: 'image'
				},
				transformResponse: responseImage
			},
			getFingers: {
				method: 'GET',
				params: {
					modality: 'fingers'
				},
				isArray: true
			},
			getFinger: {
				method: 'GET',
				params: {
					modality: 'fingers'
				}
			},
			getFingerImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'fingers',
					item: 'image'
				},
				transformResponse: responseImage
			},
			getFingerFeatures: {
				method: 'GET',
				params: {
					modality: 'fingers',
					item: 'features'
				}
			},
			getFingerSegments: {
				method: 'GET',
				params: {
					modality: 'fingers',
					item: 'segments'
				}
			},
			getFingerSegmentImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'fingers',
					item: 'segments',
					segmentItem: 'image'
				},
				transformResponse: responseImage
			},
			getFingerSegmentFeatures: {
				method: 'GET',
				params: {
					modality: 'fingers',
					item: 'segments',
					segmentItem: 'features'
				}
			},
			getFaces: {
				method: 'GET',
				params: {
					modality: 'faces'
				},
				isArray: true
			},
			getFace: {
				method: 'GET',
				params: {
					modality: 'faces'
				}
			},
			getFaceImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					modality: 'faces',
					item: 'image'
				},
				transformResponse: responseImage
			},
			getFaceFeatures: {
				method: 'GET',
				params: {
					modality: 'faces',
					item: 'features'
				}
			},
			getHit: {
				method: 'GET',
				params: {
					modality: 'hits'
				}
			},
			getHits: {
				method: 'GET',
				isArray: true,
				params: {
					modality: 'hits'
				}
			},
			getFingerMatchingDetails: {
				method: 'GET',
				params: {
					modality: 'hits',
					item: 'fingers',
					segmentItem: 'matching-details'
				},
				isArray: true
			},
			getPalmMatchingDetails: {
				method: 'GET',
				params: {
					modality: 'hits',
					item: 'palms',
					segmentItem: 'matching-details'
				},
				isArray: true
			}
		});
	}])

	.factory('ParserResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/parser/:target`, {}, {
			parseNist: {
				method: 'POST',
				params: {
					target: 'nist'
				}
			},
			parseImage: {
				method: 'POST',
				params: {
					target: 'image'
				}
			}
		});
	}])
	.factory('TransactionsResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/transactions/:id`, { id: '@id' }, {
			get: {
				method: 'GET'
			},
			count: {
				method: 'GET',
				params: {
					id: 'count',
					f: 'b' // Prevent some ad-blockers from blocking count?type pattern
				}
			},
			post: {
				method: 'POST'
			},
			query: {
				isArray: true
			}
		});
	}])

	.factory('ReportsResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		function parseResponseData(data, headers) {
			const response = {};
			if (data) {
				response.document = new Blob([data], {
					type: headers()['content-type']
				});
			}
			return response;
		}
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/transactions/:id/report`, { id: '@id' }, {
			getReportCsv: {
				method: 'GET',
				headers: {
					Accept: 'text/csv'
				},
				responseType: 'arraybuffer',
				transformResponse: parseResponseData
			},
			getReportPdf: {
				method: 'GET',
				headers: {
					Accept: 'application/pdf'
				},
				responseType: 'arraybuffer',
				transformResponse: parseResponseData
			}
		});
	}])

	.factory('SubjectsResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/subjects/:id`, { id: '@id' }, {
			get: {
				method: 'GET'
			},
			post: {
				method: 'POST'
			},
			query: {
				isArray: true
			}
		});
	}])

	.factory('UsersResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/users`, {}, {
			get: {
				method: 'GET',
				isArray: true
			}
		});
	}])
	.factory('BiographicDataResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/biographic-data/:item`, {}, {
			get: {
				method: 'GET',
				params: {
					item: 'fields'
				},
				isArray: true
			}
		});
	}])

	.factory('ApplicationInfoResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/version`, {}, {
			get: {
				method: 'GET'
			}
		});
	}]);
