import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('BiometricsService', ['store', function (store) {
		var self = this;
		var validQualities = ['POOR', 'FAIR', 'GOOD', 'VERY_GOOD', 'EXCELLENT'];
		var preferedQuality = ['GOOD', 'VERY_GOOD', 'EXCELLENT'];

		var slapFingersPositionsMap = {
			PLAIN_RIGHT_FOUR_FINGERS: ['RIGHT_INDEX_FINGER', 'RIGHT_MIDDLE_FINGER', 'RIGHT_RING_FINGER', 'RIGHT_LITTLE_FINGER'],
			PLAIN_LEFT_FOUR_FINGERS: ['LEFT_LITTLE_FINGER', 'LEFT_RING_FINGER', 'LEFT_MIDDLE_FINGER', 'LEFT_INDEX_FINGER'],
			PLAIN_THUMBS: ['LEFT_THUMB', 'RIGHT_THUMB']
		};

		var allFingersPositionsMap = {
			PLAIN_RIGHT_FOUR_FINGERS: ['RIGHT_INDEX_FINGER', 'RIGHT_MIDDLE_FINGER', 'RIGHT_RING_FINGER', 'RIGHT_LITTLE_FINGER'],
			PLAIN_LEFT_FOUR_FINGERS: ['LEFT_LITTLE_FINGER', 'LEFT_RING_FINGER', 'LEFT_MIDDLE_FINGER', 'LEFT_INDEX_FINGER'],
			PLAIN_THUMBS: ['LEFT_THUMB', 'RIGHT_THUMB'],
			LEFT_LITTLE_FINGER: ['LEFT_LITTLE_FINGER'],
			LEFT_RING_FINGER: ['LEFT_RING_FINGER'],
			LEFT_MIDDLE_FINGER: ['LEFT_MIDDLE_FINGER'],
			LEFT_INDEX_FINGER: ['LEFT_INDEX_FINGER'],
			LEFT_THUMB: ['LEFT_THUMB'],
			RIGHT_THUMB: ['RIGHT_THUMB'],
			RIGHT_INDEX_FINGER: ['RIGHT_INDEX_FINGER'],
			RIGHT_MIDDLE_FINGER: ['RIGHT_MIDDLE_FINGER'],
			RIGHT_RING_FINGER: ['RIGHT_RING_FINGER'],
			RIGHT_LITTLE_FINGER: ['RIGHT_LITTLE_FINGER'],
			UNKNOWN: ['UNKNOWN']
		};

		var eyesPositionsMap = {
			BOTH: ['LEFT', 'RIGHT']
		};

		this.getSlapsPositions = function () {
			var slaps = [];
			Object.keys(slapFingersPositionsMap).forEach((slap) => {
				if (Object.prototype.hasOwnProperty.call(slapFingersPositionsMap, slap)) {
					slaps.push(slap);
				}
			});
			return slaps;
		};

		this.isSlapPosition = function (position) {
			return Object.prototype.hasOwnProperty.call(slapFingersPositionsMap, position);
		};

		this.getSlapOrFirstFinger = function (fingers) {
			if (fingers.length > 1) {
				for (var i = 0; i < fingers.length; i += 1) {
					if (self.isSlapPosition(fingers[i].position)) {
						return fingers[i];
					}
				}
			}
			return fingers[0];
		};

		this.arePositionsMissing = function (slapFingersPositionsMap, fingersMissing) {
			for (var i = 0; i < slapFingersPositionsMap.length; i += 1) {
				if (fingersMissing.indexOf(slapFingersPositionsMap[i]) === -1) {
					return false;
				}
			}
			return true;
		};

		this.getBothEyesPositions = function () {
			return eyesPositionsMap.BOTH;
		};

		this.getAllPlainFingersPositions = function () {
			return slapFingersPositionsMap.PLAIN_RIGHT_FOUR_FINGERS.concat(slapFingersPositionsMap.PLAIN_LEFT_FOUR_FINGERS, slapFingersPositionsMap.PLAIN_THUMBS);
		};

		this.getPositions = function (position) {
			return allFingersPositionsMap[position].slice();
		};

		/** Checks if score is in preferred quality range.
		 *
		 * Accepts all NFIQ v2 scores.
		 */
		function isNfiq2xPreferedQuality(quality) {
			return preferedQuality.indexOf(validQualities[getNfiqScoreQualityLevel(quality)]) !== -1;
		}

		this.isPreferedQuality = function (quality) {
			if (typeof quality === 'string') {
				return isNfiq2xPreferedQuality(+quality);
			}
			return isNfiq2xPreferedQuality(quality);
		};

		this.hasFingersPreferedQuality = function (fingers, missingFingers) {
			for (var i = 0; i < fingers.length; i += 1) {
				var isMissingFinger = missingFingers.indexOf(fingers[i].position) > -1;
				if (!self.isSlapPosition(fingers[i].position) && !isMissingFinger) {
					if (preferedQuality.indexOf(fingers[i].quality) === -1) {
						return false;
					}
				}
			}
			return true;
		};

		function getNfiqScoreQualityLevel(score) {
			for (let i = 0; i < 5; i += 1) {
				const rangeMax = (i + 1) * 20;
				if (score <= rangeMax) {
					return i;
				}
			}
			return 0;
		}

		this.getQualityLevel = function (quality) {
			if (typeof quality === 'string') {
				return validQualities.indexOf(quality);
			}
			return getNfiqScoreQualityLevel(quality);
		};

		this.isFailedToGetQuality = function (score) {
			const QUALITY_UNKNOWN_CODE = 254;
			const QUALITY_FAILED_CODE = 255;
			return score === QUALITY_UNKNOWN_CODE || score === QUALITY_FAILED_CODE;
		};

		/** Checks if quality is NFIQ v2
		 *
		 * Accepts all NFIQ v2 scores.
		 */
		this.isNfiq2 = function (score) {
			return score > 5;
		};

		this.setSlapNameForMissingFingers = function (fingers) {
			function getPlain() {
				const slaps = new Set();
				fingers.forEach((finger) => {
					switch (finger.position) {
					case 'PLAIN_LEFT_FOUR_FINGERS':
					case 'PLAIN_RIGHT_FOUR_FINGERS':
					case 'PLAIN_THUMBS':
						slaps.add(finger.position);
						break;
					default:
					}
				});
				return slaps;
			}

			function getPositions(fingers) {
				const result = [];
				fingers.forEach(finger => result.push(finger.position));
				return result;
			}

			function getMissing(expectedPositions, actualPositions) {
				const missing = [];
				expectedPositions.forEach((position) => {
					const index = actualPositions.indexOf(position);
					if (index === -1) {
						missing.push(position);
					}
				});
				return missing;
			}

			function getMissingFingersBySlapPosition(slapPosition) {
				const fingerPositions = slapFingersPositionsMap[slapPosition];
				const originalPositions = getPositions(fingers);
				return getMissing(fingerPositions, originalPositions);
			}
			const slaps = [...getPlain()];
			const missing = new Set();
			slaps.forEach((slap) => {
				const missingFingers = getMissingFingersBySlapPosition(slap);
				missingFingers.forEach(f => missing.add(f));
			});
			return missing;
		};

		var missingBiometricsModule = (function () {
			var missingPositionsData;

			(function load() {
				missingPositionsData = store.get('missingPositionsData');
				if (!missingPositionsData) {
					missingPositionsData = {
						missingFingers: [],
						missingIrises: []
					};
				}
			}());

			function save() {
				store.set('missingPositionsData', missingPositionsData);
			}

			//			function clear() {
			//				store.remove('missingPositionsData');
			//			}

			var setMissingFingers = function (fingersInfo) {
				missingPositionsData.missingFingers = [];
				angular.forEach(fingersInfo, (isMissing, fingerPosition) => {
					if (isMissing) {
						missingPositionsData.missingFingers.push(fingerPosition);
					}
				});
				save();
			};

			var resetMissingFingers = function () {
				missingPositionsData.missingFingers = [];
				save();
			};

			var getMissingFingersMap = function () {
				var mf = missingPositionsData.missingFingers;
				var missingFingers = {};
				self.getAllPlainFingersPositions().forEach((fingerPosition) => {
					if (mf.indexOf(fingerPosition) !== -1) {
						missingFingers[fingerPosition] = true;
					} else {
						missingFingers[fingerPosition] = false;
					}
				});
				return missingFingers;
			};

			var getMissingFingersPositions = function () {
				return missingPositionsData.missingFingers;
			};

			var setMissingIrises = function (irisesInfo) {
				missingPositionsData.missingIrises = [];
				angular.forEach(irisesInfo, (isMissing, irisPosition) => {
					if (isMissing) {
						missingPositionsData.missingIrises.push(irisPosition);
					}
				});
				save();
			};

			var resetMissingIrises = function () {
				missingPositionsData.missingIrises = [];
				save();
			};

			var getMissingIrisesMap = function () {
				var mf = missingPositionsData.missingIrises;
				var missingIrises = {};
				self.getBothEyesPositions().forEach((irisPoisition) => {
					if (mf.indexOf(irisPoisition) !== -1) {
						missingIrises[irisPoisition] = true;
					} else {
						missingIrises[irisPoisition] = false;
					}
				});
				return missingIrises;
			};

			var getMissingIrisesPositions = function () {
				return missingPositionsData.missingIrises;
			};

			var getModalitiesOptions = function (modalities, comparisonModalities = null) {
				if (comparisonModalities !== null) {
					return {
						showIrises: !!modalities.irises?.length || !!comparisonModalities.irises?.length,
						showFingers: {
							common: !!modalities.fingers?.length || !!comparisonModalities.fingers?.length,
							slap: modalities.fingers?.some(finger => self.isSlapPosition(finger.position))
								|| comparisonModalities.fingers?.some(finger => self.isSlapPosition(finger.position)),
							unknownCount: Math.max(
								modalities.fingers?.filter(finger => finger.position === 'UNKNOWN').length,
								comparisonModalities.fingers?.filter(finger => finger.position === 'UNKNOWN').length
							)
						},
						showPalms: !!modalities.palms?.length || !!comparisonModalities.palms?.length,
						showSignature: !!(((modalities.signaturesCount && modalities.signaturesCount > 0) || (comparisonModalities.signaturesCount && comparisonModalities.signaturesCount > 0))
							|| (modalities.signature && (modalities.signature.image || modalities.signature.imageUrl)) || (comparisonModalities.signature && (comparisonModalities.signature.image || comparisonModalities.signature.imageUrl)))
					};
				}
				return {
					showIrises: !!modalities.irises?.length,
					showFingers: {
						common: !!modalities.fingers?.length,
						slap: modalities.fingers?.some(finger => self.isSlapPosition(finger.position)),
						unknownCount: modalities.fingers?.filter(finger => finger.position === 'UNKNOWN').length
					},
					showPalms: !!modalities.palms?.length,
					showSignature: !!((modalities.signaturesCount && modalities.signaturesCount > 0)
						|| (modalities.signature && (modalities.signature.image || modalities.signature.imageUrl)))
				};
			};

			return {
				setMissingFingers,
				resetMissingFingers,
				getMissingFingersMap,
				getMissingFingersPositions,
				setMissingIrises,
				resetMissingIrises,
				getMissingIrisesMap,
				getMissingIrisesPositions,
				getModalitiesOptions
			};
		}());
		this.setMissingFingers = missingBiometricsModule.setMissingFingers;
		this.resetMissingFingers = missingBiometricsModule.resetMissingFingers;
		this.getMissingFingersMap = missingBiometricsModule.getMissingFingersMap;
		this.getMissingFingersPositions = missingBiometricsModule.getMissingFingersPositions;
		this.setMissingIrises = missingBiometricsModule.setMissingIrises;
		this.resetMissingIrises = missingBiometricsModule.resetMissingIrises;
		this.getMissingIrisesMap = missingBiometricsModule.getMissingIrisesMap;
		this.getMissingIrisesPositions = missingBiometricsModule.getMissingIrisesPositions;
		this.getModalitiesOptions = missingBiometricsModule.getModalitiesOptions;
	}]);
