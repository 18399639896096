import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('choices', () => ({
		template: require('../../../views/directives/latent-editor/choices.html'),
		restrict: 'E',
		replace: true,
		scope: {
			choices: '='
		}
	}));
