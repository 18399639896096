import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('PasswordValidation', () => {
		const validator = {};

		validator.getPasswordValidationErrors = function (password) {
			if (!password) return [];
			const errors = [];
			if (password.length < 8) errors.push('too-short');
			const lowerCase = new RegExp('^.*[a-z]');
			const upperCase = new RegExp('^.*[A-Z]');
			const numberCase = new RegExp('^.*[0-9]');
			if (!lowerCase.exec(password)) {
				errors.push('one-lower-case');
			}
			if (!upperCase.exec(password)) {
				errors.push('one-upper-case');
			}
			if (!numberCase.exec(password)) {
				errors.push('one-digit');
			}
			return errors;
		};

		validator.getPasswordRequirmentStatus = function (requirment, password) {
			if (!password) return 'incompleted';
			const errors = this.getPasswordValidationErrors(password);
			return errors.indexOf(requirment) === -1 ? 'completed' : 'incompleted';
		}.bind(validator);

		return validator;
	});
