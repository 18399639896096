import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('SubjectPageData', [
		function () {
			const savedData = {
				transactions: null,
				transactionsPager: null,
				events: null,
				eventsPagers: null
			};

			this.getData = function () {
				return savedData;
			};

			this.getTransactionsData = function () {
				return { transactions: savedData.transactions, pager: savedData.transactionsPager };
			};

			this.getEventsData = function () {
				return { events: savedData.events, pager: savedData.eventsPagers };
			};

			this.setTransactions = function (transactions, pager) {
				savedData.transactions = transactions;
				savedData.transactionsPager = pager;
			};

			this.setEvents = function (events, pager) {
				savedData.events = events;
				savedData.eventsPagers = pager;
			};

			this.invalidate = function (data) {
				switch (data) {
				case 'transactions':
					savedData.transactions = null;
					savedData.transactionsPager = null;
					break;
				case 'events':
					savedData.events = null;
					savedData.eventsPagers = null;
					break;
				default:
					savedData.transactions = null;
					savedData.transactionsPager = null;
					savedData.events = null;
					savedData.eventsPagers = null;
				}
			};

			this.isTransactionsSaved = function () {
				return savedData.transactions !== null;
			};

			this.isEventsSaved = function () {
				return savedData.events !== null;
			};
		}]);
