import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('sidebar', () => ({
		template: require('../../../views/directives/admin/sidebar.html'),
		restrict: 'E',
		replace: true,
		scope: {},
		controller: ['$scope', '$state', 'AuthDataHolder', function ($scope, $state, AuthDataHolder) {
			var currentName = '';

			$scope.isOpened = function (name) {
				return currentName === name || $state.includes(name);
			};

			$scope.toggle = function (name) {
				if (name === currentName) {
					currentName = '';
				} else {
					currentName = name;
				}
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};
		}]
	}));
