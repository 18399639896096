import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ParametersService', ['ParametersResource', '$q', 'AuthDataHolder', function (ParametersResource, $q, AuthDataHolder) {
		this._generateDefaultParameterValue = (dataProcessor = this.defaultDataProcessor) => ({
			isLoading: false,
			value: null,
			promise: null,
			dataProcessor
		});

		this.defaultDataProcessor = data => data[0].value;
		this.arrayDataProcessor = data => data[0].value.split(',').map(s => s.toLowerCase());

		this.parameters = {
			'mmabis.management.generate-subject-id': this._generateDefaultParameterValue(),
			'mmabis.management.extraction-modalities': this._generateDefaultParameterValue(this.arrayDataProcessor)
		};

		this._getParameter = parameterName => ParametersResource.get({ names: parameterName }).$promise;

		this._loadParameter = (parameterName) => {
			const parameter = this.parameters[parameterName];
			parameter.isLoading = true;
			parameter.promise = this._getParameter(parameterName);
			parameter.promise.then((data) => {
				parameter.isLoading = false;
				if (parameter.dataProcessor !== null) {
					parameter.value = parameter.dataProcessor(data);
				} else {
					parameter.value = data;
				}
			}).catch(() => {
				this.parameters[parameter] = this._generateDefaultParameterValue(parameter.dataProcessor);
			});
		};

		this._checkAndLoadParameter = (parameterName) => {
			if (!AuthDataHolder.isAuthenticated() ||
				!Object.keys(this.parameters).includes(parameterName)) {
				return false;
			}

			const parameter = this.parameters[parameterName];
			if (parameter.value === null && parameter.isLoading === false) {
				this._loadParameter(parameterName);
			}
			return true;
		};

		this.getParameter = (parameterName) => {
			const dataCanBeAccesed = this._checkAndLoadParameter(parameterName);
			const requestPromise = $q((resolve, reject) => {
				if (!dataCanBeAccesed) {
					reject();
				}
				const parameter = this.parameters[parameterName];
				if (parameter.isLoading) {
					parameter.promise.then(d => resolve(parameter.dataProcessor(d)), reject).catch(reject);
				} else {
					resolve(parameter.value);
				}
			});
			return requestPromise;
		};

		this.getMandatoryCommentsProperty = function () {
			return ParametersResource.query({
				names: [
					'adjudication-hit-comments-mandatory'
				]
			}).$promise;
		};

		this.getGroupEncountersProperty = function () {
			return ParametersResource.query({
				names: [
					'group-encounters'
				]
			}).$promise;
		};
	}]);
