import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('multiselectButtons', ['BiographicDataService', function () {
		return {
			template: require('../../views/directives/multiselect-buttons.html'),
			restrict: 'E',
			replace: true,
			scope: {
				title: '@',
				data: '=',
				getElements: '&',
				onChange: '&'
			},
			controllerAs: 'MSButtonsCtrl',
			bindToController: true,
			controller: [function () {
				const self = this;

				const getElements = () => self.getElements()();

				self.selectAllElements = function () {
					function markAllElementsAs(value) {
						getElements().forEach(({ key }) => {
							self.data[key] = value;
						});
					}
					markAllElementsAs(!self.allElementsSelected());
				};

				self.allElementsSelected = function () {
					function allObjectsAreInitialized() {
						return Array.isArray(getElements())
						&& self.data;
					}

					if (!allObjectsAreInitialized()) return false;
					return !getElements().some(({ key }) => !self.data[key]);
				};
			}]
		};
	}]);
