import { NLayoutViewPorts } from '../types/NLibrary';
import useClassNames, { ClassesProduct, ConditionalClasses } from './useClassNames';

type AlignContentProperty = 'alignContent';
type AlignContent = `${AlignContentProperty}${NLayoutViewPorts}`;
type AlignContentValues = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
export type AlignContentProps = { [Property in AlignContent]: AlignContentValues } & {
	[Property in AlignContentProperty]: AlignContentValues;
};

interface PropsToClassesType {
	<TProps, TBase extends keyof TProps, TKeys extends keyof TProps>(
		props: TProps,
		base: TBase,
		getClasses: (suffix?: NLayoutViewPorts) => Array<[string, TProps[TKeys & TBase]]>
	): ConditionalClasses;
}

const isPropertyOfProps = <TProps>(prop: unknown, props: TProps): prop is keyof TProps => {
	return props[prop as keyof TProps] !== undefined;
};

export const propsToClasses: PropsToClassesType = (props, base, getClasses) => {
	const classes: ConditionalClasses = {};
	const viewPorts: NLayoutViewPorts[] = ['Sm', 'Md', 'Lg', 'Xl'];
	const classesPropertyPairs = getClasses();
	classesPropertyPairs.forEach((classPropertyPair) => {
		classes[classPropertyPair[0]] = props[base] === classPropertyPair[1];
	});

	viewPorts.forEach((viewPort) => {
		const classesPropertyPairs = getClasses(viewPort);
		const prop = `${base}${viewPort}` as unknown;
		if (isPropertyOfProps(prop, props)) {
			classesPropertyPairs.forEach((classPropertyPair) => {
				classes[classPropertyPair[0]] = props[prop] === classPropertyPair[1];
			});
		}
	});
	return classes;
};

const getAlignContentClasses = (suffix?: NLayoutViewPorts): Array<[string, AlignContentValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-align-content-${suffix.toLowerCase()}-start`, 'flex-start'],
			[`fs-align-content-${suffix.toLowerCase()}-end`, 'flex-end'],
			[`fs-align-content-${suffix.toLowerCase()}-center`, 'center'],
			[`fs-align-content-${suffix.toLowerCase()}-around`, 'space-around'],
			[`fs-align-content-${suffix.toLowerCase()}-stretch`, 'stretch'],
		];
	}
	return [
		['fs-align-content-start', 'flex-start'],
		['fs-align-content-end', 'flex-end'],
		['fs-align-content-center', 'center'],
		['fs-align-content-around', 'space-around'],
		['fs-align-content-stretch', 'stretch'],
	];
};

const useAlignContentClasses = (props: Partial<AlignContentProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'alignContent', getAlignContentClasses),
		'fs-align-content-stretch': !props.alignContent,
	});
	return [containerClass, classes];
};

export default useAlignContentClasses;
