import React from 'react';
import useAlignContentClasses, { AlignContentProps } from '../../hooks/useAlignContentClasses';
import useAlignItemsClasses, { AlignItemsProps } from '../../hooks/useAlignItemsClasses';
import useClassNames from '../../hooks/useClassNames';
import useDirectionClasses, { DirectionProps } from '../../hooks/useDirectionClasses';
import useJustifyContentClasses, { JustifyContentProps } from '../../hooks/useJustifyContentClasses';
import useSpacingClasses, { SpacingProps } from '../../hooks/useSpacingClasses';
import useFlexWrapClasses, { FlexWrapProps } from '../../hooks/useWrapClasses';
import { NLayoutColWidth, NLayoutPropsWithChildren, SpanItems } from '../../types/NLibrary';
import useExtractColProps, { mixedToClasses } from '../../hooks/useExtractColProps';

export type NRowProps = NLayoutPropsWithChildren &
	Partial<AlignItemsProps> &
	Partial<AlignContentProps> &
	Partial<SpacingProps> &
	Partial<DirectionProps> &
	Partial<JustifyContentProps> &
	Partial<FlexWrapProps> &
	Partial<SpanProps>;

type SpanObjProperty = 'cols';
export type SpanProps = {
	[Property in SpanItems]: NLayoutColWidth | { [Property in SpanObjProperty]: NLayoutColWidth };
};

const NRow: React.FC<NRowProps> = ({
	component: Component = 'div',
	alignContent,
	alignContentSm,
	alignContentMd,
	alignContentLg,
	alignContentXl,
	alignItems,
	alignItemsSm,
	alignItemsMd,
	alignItemsLg,
	alignItemsXl,
	spacing,
	spacingSm,
	spacingMd,
	spacingLg,
	spacingXl,
	direction,
	directionSm,
	directionMd,
	directionLg,
	directionXl,
	justify,
	justifySm,
	justifyMd,
	justifyLg,
	justifyXl,
	wrap,
	wrapSm,
	wrapMd,
	wrapLg,
	wrapXl,
	xs,
	sm,
	md,
	lg,
	xl,
	classes,
	children,
	...props
}: NRowProps) => {
	const [containerClass] = useClassNames(
		useAlignContentClasses({ alignContent, alignContentSm, alignContentMd, alignContentLg, alignContentXl })[0],
		useAlignItemsClasses({ alignItems, alignItemsSm, alignItemsMd, alignItemsLg, alignItemsXl })[0],
		useSpacingClasses({ spacing, spacingSm, spacingMd, spacingLg, spacingXl })[0],
		useDirectionClasses({ direction, directionSm, directionMd, directionLg, directionXl })[0],
		useJustifyContentClasses({ justify, justifySm, justifyMd, justifyLg, justifyXl })[0],
		useFlexWrapClasses({ wrap, wrapSm, wrapMd, wrapLg, wrapXl })[0],
		useExtractColProps({ xs, sm, md, lg, xl }, 'cols', 'fs-row-cols', mixedToClasses),
		classes || [],
		'fs-row',
		'clearfix' // solves issue with bs3 container (safe to remove once out of bs3)
	);
	return (
		<Component className={containerClass} {...props}>
			{children}
		</Component>
	);
};

export default NRow;
