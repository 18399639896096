import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('MetricsCtrl', ['$scope', '$timeout', '$q', '$state', 'GraphiteService', 'FilterUtils',
		function ($scope, $timeout, $q, $state, GraphiteService, FilterUtils) {
			$scope.disabled = null;
			$scope.filterData = {};
			let filterDataInternal = {};
			$scope.datePickerOptions = {
				timePicker: true,
				timePicker24Hour: true,
				locale: {
					format: 'MM/DD/YYYY HH:mm'
				}
			};

			GraphiteService.get().$promise.catch((response) => {
				if (response.status === 404) {
					$scope.disabled = true;
				}
			}).finally(() => {
				if ($scope.disabled == null) {
					$scope.disabled = false;
				}
			});

			$scope.nodes = [];
			$scope.proxy = {
				node: null
			};

			$scope.title = null;
			var nodeFilter = null;
			var graphTemplates = [];

			function scaleLabel(value, unit, binaryScale, decimalDigits) {
				if (unit == null) {
					unit = 'B';
				}
				if (binaryScale == null) {
					binaryScale = true;
				}
				if (decimalDigits == null) {
					decimalDigits = 0;
				}

				var k = binaryScale ? 1024 : 1000;
				var m = k * (binaryScale ? 1024 : 1000);
				var g = m * (binaryScale ? 1024 : 1000);
				var t = g * (binaryScale ? 1024 : 1000);

				if (value > t) {
					return `${Number(value / t).toFixed(decimalDigits)} ${binaryScale ? 'Ti' : 'T'}${unit}`;
				} else if (value >= g) {
					return `${Number(value / g).toFixed(decimalDigits)} ${binaryScale ? 'Gi' : 'G'}${unit}`;
				} else if (value >= m) {
					return `${Number(value / m).toFixed(decimalDigits)} ${binaryScale ? 'Mi' : 'M'}${unit}`;
				} else if (value >= k) {
					return `${Number(value / k).toFixed(decimalDigits)} ${binaryScale ? 'Ki' : 'k'}${unit}`;
				}
				return `${Number(value).toFixed(0)} ${unit}`;
			}

			if ($state.current.data.target === 'hardware') {
				$scope.title = 'admin.metrics.hardware.title';
				graphTemplates = [
					{
						title: 'admin.metrics.hardware.cpu',
						id: 'alias(offset(scale(%s.cpu.percent-idle, -1), 100), "usage")',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return `${Number(label).toFixed(2)} %`;
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}, {
						title: 'admin.metrics.hardware.load',
						id: 'aliasByMetric(%s.load.load.*)',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											beginAtZero: true
										}
									}]
								}
							}
						}
					}, {
						title: 'admin.metrics.hardware.memory',
						id: 'alias(sumSeries(%s.memory.memory-{buffered,cached,free}), "available")',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return scaleLabel(label);
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}, {
						title: 'admin.metrics.hardware.disk',
						id: 'aliasSub(%s.df-*.df_complex-free, ".+\\.df-(.+)\\..+", "\\1")',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return scaleLabel(label);
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}, {
						title: 'admin.metrics.hardware.disk-traffic',
						id: 'aliasByMetric(sumSeriesWithWildcards(%s.disk-{sd,md,hd,vd}[a-z0-9].disk_octets.*, 2))',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return scaleLabel(label, 'B/s');
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}, {
						title: 'admin.metrics.hardware.network-traffic',
						id: 'aliasByMetric(sumSeriesWithWildcards(%s.interface-{eth,en}*.if_octets.*, 2))',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return scaleLabel(label * 8, 'bps', false);
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}
				];
			} else if ($state.current.data.target === 'biometric') {
				$scope.title = 'admin.metrics.transactions.title';
				nodeFilter = function (nodes) {
					var queryToNode = {};
					for (var i = 0; i < nodes.length; i += 1) {
						var node = nodes[i];
						queryToNode[`${node.id}.statsd.derive-performTask`] = node;
					}
					return GraphiteService.expand({
						query: Object.keys(queryToNode),
						leavesOnly: 1
					}).$promise.then((response) => {
						var filtered = [];
						for (var i = 0; i < response.results.length; i += 1) {
							filtered.push(queryToNode[response.results[i]]);
						}
						return filtered;
					}, () => []);
				};
				graphTemplates = [
					{
						title: 'admin.metrics.transactions.throughput',
						id: 'aliasSub(scaleToSeconds(%s.statsd.gauge-performTask_*-count,1), ".+-performTask_(.+)-count.+", "\\1")',
						chart: {
							colors: [{
								backgroundColor: '#9BB4D8'
							}, {
								backgroundColor: '#ECBD97'
							}, {
								backgroundColor: '#9ECAA7'
							}, {
								backgroundColor: '#E59193'
							}, {
								backgroundColor: '#A8A7A9'
							}, {
								backgroundColor: '#B4A5CC'
							}, {
								backgroundColor: '#C89193'
							}, {
								backgroundColor: '#C89193'
							}],
							options: {
								scales: {
									yAxes: [{
										stacked: true,
										ticks: {
											callback(label) {
												return `${Number(label).toFixed(1)} tx/s`;
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					},
					{
						title: 'admin.metrics.transactions.duration',
						id: 'aliasSub(%s.statsd.latency-performTask_*-average, ".+-performTask_(.+)-average", "\\1")',
						chart: {
							options: {
								scales: {
									yAxes: [{
										ticks: {
											callback(label) {
												return `${Number(label).toFixed(2)} s`;
											},
											beginAtZero: true
										}
									}]
								}
							}
						}
					}
				];
			}

			var chartDefaults = {
				colors: [{
					backgroundColor: 'rgba(57, 106, 177, 0.5)'
				}, {
					backgroundColor: 'rgba(218, 124, 48, 0.5)'
				}, {
					backgroundColor: 'rgba(62, 150, 81, 0.5)'
				}, {
					backgroundColor: 'rgba(204, 37, 41, 0.5)'
				}, {
					backgroundColor: 'rgba(83, 81, 84, 0.5)'
				}, {
					backgroundColor: 'rgba(107, 76, 154, 0.5)'
				}, {
					backgroundColor: 'rgba(146, 36, 40, 0.5)'
				}, {
					backgroundColor: 'rgba(148, 139, 61, 0.5)'
				}],
				options: {
					animation: false,
					legend: {
						display: true,
						position: 'bottom'
					},
					scales: {
						xAxes: [{
							type: 'time',
							time: {
								tooltipFormat: 'MM/DD/YYYY HH:mm',
								displayFormats: {
									millisecond: 'HH:mm:ss.SSS',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm',
									day: 'MM/DD/YYYY',
								}
							},
							scaleLabel: {
								display: true,
								labelString: 'Time'
							}
						}]
					}
				}
			};

			$scope.graphs = [];
			var { graphs } = $scope;

			function parse(graph, response) {
				var { chart } = graph;
				chart.data = [];
				chart.series = [];
				for (var i = 0; i < response.length; i += 1) {
					var metric = response[i];
					var points = [];
					chart.data.push(points);
					chart.series.push(metric.target);
					for (var j = 0; j < metric.datapoints.length; j += 1) {
						points.push({
							x: moment.unix(metric.datapoints[j][1]),
							y: metric.datapoints[j][0] ?? 0
						});
					}
				}
			}

			function fetch(graph, filterOptions = null) {
				return GraphiteService.render(angular.extend({
					from: '-1h',
					maxDataPoints: 60,
					target: graph.id
				}, filterOptions), (response) => {
					parse(graph, response);
				}).$promise;
			}

			function fetchAndCleanup(graph) {
				return fetch(graph).then(() => {
					if (graph.chart.data.length === 0) {
						graphs.splice(graphs.indexOf(graph), 1);
					}
				});
			}

			GraphiteService.find({
				query: 'mmabis.*'
			}, (nodes) => {
				var promise;
				if (nodeFilter != null) {
					promise = nodeFilter(nodes);
				} else {
					promise = $q.resolve(nodes);
				}
				promise.then((nodes) => {
					$scope.nodes = nodes;
					if (nodes.length > 0) {
						[$scope.proxy.node] = nodes;
					}
				});
			});

			$scope.$watch('proxy.node', (node) => {
				if (node != null) {
					graphs.length = 0;
					for (var i = 0; i < graphTemplates.length; i += 1) {
						var template = graphTemplates[i];
						var graph = {
							title: template.title,
							// eslint-disable-next-line no-undef
							id: sprintf(template.id, node.id),
							chart: angular.merge({}, chartDefaults, template.chart)
						};
						graphs.push(graph);
						fetchAndCleanup(graph);
					}
				}
			});

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix, true);
			};

			function mapAndCopyProcessedFilterData(to) {
				to.from = moment($scope.filterData.createdAtLower).utc().format('HH:mm_YYYYMMDD');
				to.until = moment($scope.filterData.createdAtUpper).utc().format('HH:mm_YYYYMMDD');
			}

			function isDateIntervalNotEmpty() {
				return $scope.filterData.createdAtLower
				&& $scope.filterData.createdAtUpper;
			}

			$scope.onValueChange = function () {
				if (!$scope.dateFieldsValid('createdAt')
					|| !isDateIntervalNotEmpty()) return;

				$scope.pollEnabled = false;
				filterDataInternal = {};
				mapAndCopyProcessedFilterData(filterDataInternal);
				graphs.forEach(graph => fetch(graph, filterDataInternal));
			};

			$scope.toggleLivePolling = function () {
				if ($scope.pollEnabled) {
					$scope.pollEnabled = false;
				} else {
					$scope.pollEnabled = true;
					$scope.filterData = {};
					poll();
				}
			};

			$scope.pollEnabled = true;
			$scope.$on('$destroy', () => {
				$scope.pollEnabled = false;
			});

			function poll() {
				(function refresh() {
					if ($scope.disabled) {
						return;
					}
					if ($scope.pollEnabled) {
						const promises = [];
						for (let i = 0; i < graphs.length; i += 1) {
							promises.push(fetch(graphs[i]));
						}
						$q.all(promises)['finally']($timeout(refresh, 10000)); // eslint-disable-line dot-notation
					}
				}());
			}
			poll();
		}]);
