import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('StatisticsResouce', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/statistics/:action/:subaction`, null, {
			agregate: {
				method: 'POST',
				params: {
					action: 'aggregation'
				}
			}
		});
	}]);
