import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('IcaoStatusService', [function () {
		const DEFAULT_WARNINGS = {
			FACE_NOT_DETECTED: {
				invalid: false,
				skipped: false
			},
			EXPRESSION: {
				invalid: false,
				skipped: false
			},
			DARK_GLASSES: {
				invalid: false,
				skipped: false
			},
			BLINK: {
				invalid: false,
				skipped: false
			},
			MOUTH_OPEN: {
				invalid: false,
				skipped: false
			},
			LOOKING_AWAY: {
				invalid: false,
				skipped: false
			},
			RED_EYE: {
				invalid: false,
				skipped: false
			},
			FACE_DARKNESS: {
				invalid: false,
				skipped: false
			},
			UNNATURAL_SKIN_TONE: {
				invalid: false,
				skipped: false
			},
			WASHED_OUT: {
				invalid: false,
				skipped: false
			},
			PIXELATION: {
				invalid: false,
				skipped: false
			},
			SKIN_REFLECTION: {
				invalid: false,
				skipped: false
			},
			GLASSES_REFLECTION: {
				invalid: false,
				skipped: false
			},
			ROLL_LEFT: {
				invalid: false,
				skipped: false
			},
			ROLL_RIGHT: {
				invalid: false,
				skipped: false
			},
			YAW_LEFT: {
				invalid: false,
				skipped: false
			},
			YAW_RIGHT: {
				invalid: false,
				skipped: false
			},
			PITCH_UP: {
				invalid: false,
				skipped: false
			},
			PITCH_DOWN: {
				invalid: false,
				skipped: false
			},
			TOO_NEAR: {
				invalid: false,
				skipped: false
			},
			TOO_FAR: {
				invalid: false,
				skipped: false
			},
			TOO_NORTH: {
				invalid: false,
				skipped: false
			},
			TOO_SOUTH: {
				invalid: false,
				skipped: false
			},
			TOO_EAST: {
				invalid: false,
				skipped: false
			},
			TOO_WEST: {
				invalid: false,
				skipped: false
			},
			SHARPNESS: {
				invalid: false,
				skipped: false
			},
			BACKGROUND_UNIFORMITY: {
				invalid: false,
				skipped: false
			},
			GRAYSCALE_DENSITY: {
				invalid: false,
				skipped: false
			},
			SATURATION: {
				invalid: false,
				skipped: false
			},
			COMPRESSION_ARTIFACTS: {
				invalid: false,
				skipped: false
			},
			MOTION_BLUR: {
				invalid: false,
				skipped: false
			},
			OCCLUSION: {
				invalid: false,
				skipped: false
			},
			RESOLUTION: {
				invalid: false,
				skipped: false
			},
			UNDEREXPOSURE: {
				invalid: false,
				skipped: false
			},
			OVEREXPOSURE: {
				invalid: false,
				skipped: false
			},
			HEAVY_FRAME: {
				invalid: false,
				skipped: false
			},
			LIVENESS: {
				invalid: false,
				skipped: false
			},
		};
		let warnings = {};
		// used to store information whether or not statuses are default to prevent showing them
		let isPristine = true;

		this.getIcaoStatuses = function () {
			return warnings;
		};

		this.updateIcao = function (newWarnings) {
			Object.keys(warnings).forEach((warning) => { warnings[warning].invalid = false; });
			newWarnings.forEach((warning) => {
				if (!warnings[warning]) {
					// eslint-disable-next-line no-console
					console.warn(`Warning ${warning} is not supported`);
				} else {
					warnings[warning].invalid = true;
				}
			});

			isPristine = false;

			return warnings;
		};

		this.isPristine = function () {
			return isPristine;
		};

		this.invalidate = function () {
			warnings = angular.copy(DEFAULT_WARNINGS);
			isPristine = true;
		};
	}]);
