import React from 'react';
import useAlignSelfClasses, { AlignSelfProps } from '../../hooks/useAlignSelfClasses';
import useClassNames from '../../hooks/useClassNames';
import useSpacingClasses, { SpacingProps } from '../../hooks/useSpacingClasses';
import {
	NLayoutColOffset,
	NLayoutColOrder,
	NLayoutColWidth,
	NLayoutPropsWithChildren,
	ViewPorts,
} from '../../types/NLibrary';
import useExtractColProps, { mixedToClasses, objToClasses } from '../../hooks/useExtractColProps';

export type NColProps = NLayoutPropsWithChildren & Partial<SpacingProps> & Partial<AlignSelfProps> & Partial<SpanProps>;
type SpanItems = 'xs' | ViewPorts;
export type SpanProps = {
	[Property in SpanItems]:
		| NLayoutColWidth
		| { span: NLayoutColWidth; order?: NLayoutColOrder; offset?: NLayoutColOffset };
};

const NCol: React.FC<NColProps> = ({
	component: Component = 'div',
	spacing,
	spacingSm,
	spacingMd,
	spacingLg,
	spacingXl,
	classes,
	children,
	alignSelf,
	alignSelfSm,
	alignSelfMd,
	alignSelfLg,
	alignSelfXl,
	xs,
	sm,
	md,
	xl,
	lg,
	...props
}: NColProps) => {
	const [containerClass] = useClassNames(
		useSpacingClasses({ spacing, spacingSm, spacingMd, spacingLg, spacingXl })[0],
		useAlignSelfClasses({ alignSelf, alignSelfSm, alignSelfMd, alignSelfLg, alignSelfXl }),
		useExtractColProps({ xs, sm, md, lg, xl }, 'span', 'fs-col', mixedToClasses),
		useExtractColProps({ xs, sm, md, lg, xl }, 'order', 'fs-order', objToClasses),
		useExtractColProps({ xs, sm, md, lg, xl }, 'offset', 'fs-offset', objToClasses),
		classes || [],
		'fs-col'
	);
	return (
		<Component className={containerClass} {...props}>
			{children}
		</Component>
	);
};

export default NCol;
