import angular from 'angular';

angular.module('neurotecAbisWebClientApp').directive('stickyFooter', () => ({
	template: require('../../views/directives/sticky-footer.html'),
	restrict: 'E',
	replace: true,
	scope: {},
	controller: [
		'$scope',
		'$uibModal',
		'SettingsService',
		'StatusService',
		function ($scope, $uibModal, SettingsService, StatusService) {
			$scope.$watch(
				() => StatusService.getServiceStatus(),
				(newValue) => {
					$scope.status = newValue;
				}
			);

			$scope.$watch(
				() => SettingsService.getCapturerServiceDisplayStatus(),
				(newValue) => {
					$scope.capturerServiceDisplayStatus = newValue;
				}
			);

			/* eslint-disable */
//////////////////////////
//////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
////////////
			/* eslint-enable */
		}
	]
}));
