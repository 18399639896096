import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.directive('listBox', () => ({
		template: require('../../../views/directives/admin/list-box.html'),
		restrict: 'E',
		replace: true,
		require: '^form',
		scope: {
			formName: '=',
			formInputName: '@',
			listLabel: '@',
			selectedListLabel: '@',
			data: '=',
			selectedData: '=',
		},
		controller: 'listBoxCtrl',
	}))
	.filter(
		'customOrderBy',
		() =>
			function (items, field, reverse = false) {
				if (items === undefined) return;
				items.sort((a, b) => (a[field] > b[field] ? 1 : -1));
				if (reverse) items.reverse();
				return items;
			}
	)
	.controller('listBoxCtrl', [
		'$scope',
		'$attrs',
		function ($scope, $attrs) {
			$scope.inputRequired = Object.hasOwnProperty.call($attrs, 'required');

			$scope.isEmpty = function (array) {
				return array === undefined || array.length == 0;
			};

			$scope.init = function () {
				$scope.originalData = [];
				$scope.originalData = $scope.data;
				if (!$scope.isEmpty($scope.selectedData)) {
					const temp = [];
					$scope.selectedData.forEach(elem => temp.push(elem[1]));
					$scope.selectedData = [];
					$scope.originalData.forEach((elem) => {
						if (temp.indexOf(elem[0]) !== -1) {
							$scope.selectedData.push(elem);
						}
					});
					$scope.data = $scope.data.filter(elem => !$scope.selectedData.some(e => e[0] === elem[0]));
				}
				$scope.selectedToRemoveData = [];
				$scope.highlightedData = [];
			};
			$scope.init();

			$scope.selectData = function () {
				if ($scope.highlightedData === undefined || $scope.highlightedData.length === 0) return;
				if ($scope.selectedData === undefined) {
					$scope.selectedData = [];
				}
				$scope.originalData.forEach((elem) => {
					if ($scope.highlightedData.indexOf(elem[0]) !== -1) {
						$scope.selectedData.push(elem);
					}
				});
				$scope.data = $scope.data.filter(elem => !$scope.selectedData.some(e => elem[0] === e[0]));
			};

			$scope.removeData = function () {
				if ($scope.selectedToRemoveData === undefined || $scope.selectedToRemoveData.length === 0) return;
				$scope.originalData.forEach((elem) => {
					if ($scope.selectedToRemoveData.indexOf(elem[0]) !== -1) {
						$scope.data.push(elem);
					}
				});

				$scope.selectedData = $scope.selectedData.filter(elem => !$scope.data.some(e => elem[0] === e[0]));
			};

			$scope.selectAll = function () {
				if ($scope.data === undefined || $scope.data.length === 0) return;
				$scope.highlightedData = [];
				$scope.selectedToRemoveData = [];
				$scope.selectedData = [];
				$scope.selectedData = $scope.originalData;
				$scope.data = [];
			};

			$scope.removeAll = function () {
				if ($scope.selectedData === undefined || $scope.selectedData.length === 0) return;
				$scope.highlightedData = [];
				$scope.selectedToRemoveData = [];
				$scope.data = [];
				$scope.data = $scope.originalData;
				$scope.selectedData = [];
			};

			$scope.isInputRequired = function () {
				return $scope.inputRequired && $scope.isEmpty($scope.selectedData);
			};
		},
	]);
