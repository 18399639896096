import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('notifications', () => ({
		template: require('../../views/directives/notifications.html'),
		restrict: 'E',
		replace: true,
		scope: {},
		controller: ['$filter', '$scope', '$translate', '$uibModal', 'ConvertionUtils', 'NotificationsService', 'ReportsResource', '$state',
			function ($filter, $scope, $translate, $uibModal, ConvertionUtils, NotificationsService, ReportsResource, $state) {
				$scope.cnv = ConvertionUtils;

				$scope.types = {};
				$scope.statuses = {};

				// TODO: Refactor.
				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
					'transactions.type.identify', 'transactions.type.verify', 'transactions.type.update', 'transactions.type.delete',
					'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
				]).then((translations) => {
					$scope.types = {
						/* jshint sub:true */
						ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
						ENROLL: translations['transactions.type.enroll'],
						IDENTIFY: translations['transactions.type.identify'],
						VERIFY: translations['transactions.type.verify'],
						VERIFY_UPDATE: translations['transactions.type.update'],
						DELETE: translations['transactions.type.delete']
						/* jshint sub:false */
					};

					$scope.statuses = {
						/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						REJECTED: translations['transactions.status.rejected'],
						OK: translations['transactions.status.ok'],
						MATCHED: translations['transactions.status.matched'],
						NOT_MATCHED: translations['transactions.status.not-matched'],
						DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
						/* jshint sub:false */
					};
				});


				$scope.$watch(() => NotificationsService.get(), (newValue) => {
					$scope.notifications = newValue;
				});

				$scope.seeAll = function (event) {
					event.stopPropagation();
				};

				$scope.clearNotifications = function (event) {
					event.stopPropagation();
					NotificationsService.invalidate();
				};

				$scope.markSeen = function (requestId) {
					NotificationsService.markSeen(requestId);
				};

				$scope.moreTransactionInfo = function (event, requestId) {
					$state.go('actions.transaction', { transactionID: requestId });
				};
			}
		]
	}));
