import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('editorWindow', () => ({
		template: require('../../../views/directives/latent-editor/editor-window.html'),
		restrict: 'E',
		replace: true,
		scope: {
			editor: '=',
			coordinates: '='
		}
	}));
