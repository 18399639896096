import React from 'react';

export interface NLayoutProps<Component extends React.ElementType = React.ElementType> {
	component?: Component;
	classes?: string | string[];
}

export type NLayoutPropsWithChildren<Component extends React.ElementType = React.ElementType> = React.PropsWithChildren<
	NLayoutProps<Component>
> &
	React.HTMLProps<Component>;

export type ViewPorts = 'sm' | 'md' | 'lg' | 'xl';
export type NLayoutViewPorts = Capitalize<ViewPorts>;

export type NLayoutColWidth = true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type NLayoutSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type NLayoutColOrder = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'first' | 'last';
export type NLayoutColOffset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type SpanItems = 'xs' | ViewPorts;
export type SpanValues = string | boolean | number;

export const isSpanValue = (elem: unknown): elem is SpanValues => {
	return typeof elem === 'boolean' || typeof elem === 'string' || typeof elem === 'number';
};

export interface extractClassesFun {
	<
		TValues extends string | boolean | number,
		TObj extends Record<string, TValues>,
		T extends Partial<Record<SpanItems, TValues | TObj>>
	>(
		props: T,
		accessor: keyof TObj,
		prefix: string,
		toClass: addToClassesFun
	): string[];
}

export interface addToClassesFun {
	<
		TValues extends string | boolean | number,
		TObj extends Record<string, TValues>,
		TProp extends SpanValues | Partial<TObj> | undefined
	>(
		prop: TProp,
		accessor: keyof TObj,
		toStringFun: (prop1: SpanValues) => string
	): string | null;
}
