angular.module('neurotecAbisWebClientApp')
	.service('ChartOptionsService', ['ChartOptions', function (ChartOptions) {
		this.getDashboardOptions = function (subjects) {
			function roundToDecimals(value, decimals) {
				return parseFloat(value).toFixed(decimals);
			}

			function changePieOptions() {
				const defaultPieOptions = options.pieOptions();
				options.pieOptions = function () {
					defaultPieOptions.tooltips.callbacks = {
						label(tooltipItem, data) {
							const value = subjects.dataRaw[tooltipItem.index];
							const label = data.labels[tooltipItem.index];
							return `${label}: ${+roundToDecimals(subjects.dataRaw[tooltipItem.index] / subjects.count * 100, 2)}% (${value})`;
						}
					};
					return defaultPieOptions;
				};
			}

			const options = ChartOptions;
			changePieOptions();
			return options;
		};

		this.getMetricsOptions = function (handleDownloadLink) {
			function changeChartOptions() {
				Object.assign(chartOptions, {
					title: {
						display: true,
						text: ''
					},
					animation: {
						onComplete: handleDownloadLink
					},
					legend: {
						display: true,
						position: 'bottom'
					},
					layout: {
						padding: {
							left: 10,
							right: 0,
							top: 10,
							bottom: 10
						}
					},
					tooltips: {
						enabled: true,
						position: 'nearest'
					}
				});

				chartOptions.scales.xAxes[0].ticks.min = 1;
				chartOptions.scales.xAxes[0].ticks.barShowStroke = false;

				chartOptions.scales.yAxes[0].ticks.min = 1;
				chartOptions.scales.yAxes[0].ticks.barShowStroke = false;
			}

			function changeTermOptions() {
				termsOptions.animation = {
					onComplete: handleDownloadLink
				};
			}

			const chartOptions = ChartOptions.chartOptions();
			const termsOptions = ChartOptions.termsOptions();

			changeChartOptions();
			changeTermOptions();

			return {
				termsOptions: () => termsOptions,
				chartOptions: () => chartOptions,
			};
		};
	}]);
