import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('BiographicFlags', () => {
		const flagFactory = {};

		flagFactory.flags = {
			HIDE_SUBJECT_FIELD: {
				name: 'admin.configuration.biographic-fields.flags.HIDE_SUBJECT_FIELD.name',
				description: 'admin.configuration.biographic-fields.flags.HIDE_SUBJECT_FIELD.description',
				section: 'FIELD',
				inverted: true,
			},
			SEARCH_COLUMN: {
				name: 'admin.configuration.biographic-fields.flags.SEARCH_COLUMN.name',
				description: 'admin.configuration.biographic-fields.flags.SEARCH_COLUMN.description',
				section: 'SEARCH',
				inverted: false,
			},
			SEARCH_FIELD: {
				name: 'admin.configuration.biographic-fields.flags.SEARCH_FIELD.name',
				description: 'admin.configuration.biographic-fields.flags.SEARCH_FIELD.description',
				section: 'SEARCH',
				inverted: false,
			},
		};

		flagFactory.addFlags = function (field) {
			const flags = [];
			if (field.flags) {
				for (let i = 0; i < field.flags.length; i += 1) {
					flags.push([field.flags[i], true]);
				}
			}
			const defaultFlags = Object.entries(this.flags);
			const resultFlags = [];
			for (let i = 0; i < defaultFlags.length; i += 1) {
				const entries = flags.filter(item => item[0] === defaultFlags[i][0]);
				if (entries.length === 0) {
					resultFlags.push([defaultFlags[i][0], false]);
				} else {
					resultFlags.push(entries[0]);
				}
			}
			field.flags = resultFlags;
		};

		flagFactory.reduce = function (flags) {
			return flags.filter(item => item[1]).map(item => item[0]);
		};

		return flagFactory;
	});
