import angular from 'angular';
import FileSaver from 'file-saver';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.controller('TransactionsCtrl', ['$scope', '$filter', '$timeout', '$translate', '$q', '$state', 'AuthDataHolder', 'AlertService', 'FilterUtils', 'ReportsResource', 'TransactionsResource', 'SortHelper', 'ConvertionUtils', 'FilterDataHolder', 'GalleriesService', 'SettingsService', 'Utils',
		function ($scope, $filter, $timeout, $translate, $q, $state, AuthDataHolder, AlertService, FilterUtils, ReportsResource, TransactionsResource, SortHelper, ConvertionUtils, FilterDataHolder, GalleriesService, SettingsService, Utils) {
			$scope.sort = SortHelper.create('requestId', true);
			$scope.cnv = ConvertionUtils;

			$scope.loadingDisabled = false;
			$scope.loading = false;
			$scope.pagesLoaded = 0;
			$scope.maxPagesToLoad = 10;
			$scope.itemsPerPage = 30;
			$scope.hasNextPage = true;
			$scope.firstTimeLoading = true;

			const FILTER_NAME = 'transactions';

			$scope.transactions = [];
			const retainFilter = $state.previous.includes('transaction');
			if (!retainFilter) {
				FilterDataHolder.remove(FILTER_NAME);
			}
			$scope.filterData = FilterDataHolder.get(FILTER_NAME);

			$scope.galleries = [];
			$scope.selectedGallery = {};
			$scope.galleryStartIndex = 0;
			// broadcast from 'settings-service.js' -> setPreferredGallery()
			$scope.$on('preferredGallery:updated', (_event, newValue) => {
				if ($scope.selectedGallery !== newValue && newValue !== '') {
					$scope.selectedGallery = newValue;
					$scope.onGalleryChoose();
					$scope.filter();
				}
			}, true);

			$scope.datetimePickerOptions = {
				format: 'YYYY-MM-DD HH:mm:ss',
				showClear: true,
				allowInputToggle: true
			};

			$scope.searchFilters = [];
			$scope.changeValue = function () {
				if (!$scope.loading) { // blocks firing when 'All' filter action() method clears filterData
					$scope.updateSelection(0, $scope.searchFilters, false, true);
				}
			};

			var filterDataInternal = {};
			$translate([
				'transactions.filter',
				'transactions.filter.custom',
				'transactions.filter.last-hour',
				'transactions.filter.last-day',
				'transactions.filter.last-week',
				'transactions.filter.last-month',
				'transactions.filter.any'
			]).then((translations) => {
				$scope.searchFilters = [
					{
						name: translations['transactions.filter.custom'],
						checked: false,
						action() {
							$scope.filterData = FilterDataHolder.get(FILTER_NAME);
							$scope.filterForm.$setPristine();
							filterDataInternal = {};
							if (Utils.isObjectEmpty($scope.filterData)) {
								$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex] || GalleriesService.getDefaultGallery();
								$scope.onGalleryChoose();
							}
							$scope.transactions = [];
							$scope.wasLoaded = false;
							if (Object.getOwnPropertyNames($scope.filterData).length !== 0) {
								$scope.filter();
							}
						}
					},
					{
						name: translations['transactions.filter.last-hour'],
						checked: false,
						action() {
							filterInterval('hours', translations['transactions.filter.last-hour']);
						}
					},
					{
						name: translations['transactions.filter.last-day'],
						checked: false,
						action() {
							filterInterval('days', translations['transactions.filter.last-day']);
						}
					},
					{
						name: translations['transactions.filter.last-week'],
						checked: false,
						action() {
							filterInterval('weeks', translations['transactions.filter.last-week']);
						}
					},
					{
						name: translations['transactions.filter.last-month'],
						checked: false,
						action() {
							filterInterval('months', translations['transactions.filter.last-month']);
						}
					},
					{
						name: translations['transactions.filter.any'],
						checked: false,
						action() {
							$scope.updateFields = false;
							$scope.filterData = {};
							$scope.filterForm.$setPristine();
							$scope.sort.reset();

							if ($scope.galleries.length !== 0) {
								$scope.selectedGallery = GalleriesService.getAllGalleriesOption();
								$scope.filterData.galleryId = $scope.selectedGallery.id;
							}

							filterDataInternal = {};
							FilterDataHolder.remove(FILTER_NAME);
							copyProcessedFilterData(filterDataInternal);
							$scope.resetLoaded();
						}
					}
				];

				function moveFilterToModels() {
					$scope.selectedGallery = $scope.galleries.find(gallery => gallery.id === $scope.filterData.galleryId) || null;
				}

				function identifyLastFilter() {
					function filter(id) {
						$scope.updateSelection(id, $scope.searchFilters);
					}

					// 'Custom' filter
					const criteria = ['subjectId', 'requestId', 'type', 'priority', 'status', 'userId', 'adjudicated'];
					for (let i = 0; i < criteria.length; i += 1) {
						if ($scope.filterData[criteria[i]]) {
							filter(0);
							return;
						}
					}

					// filtered by time
					if ($scope.filterData.createdAt.range && $scope.filterData.createdAt.range.length > 0) {
						if (moment($scope.filterData.createdAt.range[0]).add(1, 'months').unix() === moment($scope.filterData.createdAt.range[1]).unix()) {
							filter(4);
							return;
						} else if (moment($scope.filterData.createdAt.range[0]).add(1, 'weeks').unix() === moment($scope.filterData.createdAt.range[1]).unix()) {
							filter(3);
							return;
						} else if (moment($scope.filterData.createdAt.range[0]).add(1, 'days').unix() === moment($scope.filterData.createdAt.range[1]).unix()) {
							filter(2);
							return;
						} else if (moment($scope.filterData.createdAt.range[0]).add(1, 'hours').unix() === moment($scope.filterData.createdAt.range[1]).unix()) {
							filter(1);
							return;
						}
					}

					if ($scope.filterData.galleryId === GalleriesService.getAllGalleriesOption().id) {
						// filtered by 'Any'
						filter(5);
					} else {
						// Custom filter, ex. galleryId: 'not preffered gallery', createdAt: 'YYYY-MM-DD'
						filter(0);
					}
				}

				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					GalleriesService.getAvailableGalleries()
						.then((galleries) => {
							$scope.galleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
							if ($scope.galleries.length !== 0) {
								$scope.galleries.unshift(
									GalleriesService.getAllGalleriesOption(),
									GalleriesService.getDefaultGallery(),
								);

								$scope.galleryStartIndex = GalleriesService.getBeginningGalleryIndexForFiltering();
							}

							$scope.firstTimeLoading = false;
							if (retainFilter) {
								moveFilterToModels();
								identifyLastFilter();
							} else {
								$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex];
								$scope.onGalleryChoose();
								$scope.updateSelection(5, $scope.searchFilters);
							}
						});
				} else {
					$scope.galleries = [];

					$scope.firstTimeLoading = false;
					if (retainFilter) {
						moveFilterToModels();
						identifyLastFilter();
					} else {
						$scope.updateSelection(5, $scope.searchFilters);
					}
				}
			});

			$scope.onGalleryChoose = function () {
				if ($scope.selectedGallery) {
					$scope.filterData.galleryId = $scope.selectedGallery.id;
				}
			};

			$scope.updateSelection = function (position, searchFilters, invalidateFormFields = false, silentUpdate = false) {
				let newIndex;
				angular.forEach(searchFilters, (searchFilter, index) => {
					if (position !== index) {
						searchFilter.checked = false;
					} else {
						if (invalidateFormFields) {
							FilterDataHolder.remove(FILTER_NAME);
						}

						searchFilter.checked = true;
						newIndex = index;
					}
				});
				if (!silentUpdate) {
					searchFilters[newIndex].action();
				}
			};

			$scope.datetimePickerOptions = {
				format: 'YYYY-MM-DD HH:mm:ss',
				showClear: true,
				allowInputToggle: true
			};

			$scope.dateRangeOptions = {
				showDropdowns: true,
				timePicker24Hour: true,
				autoUpdateInput: false,
				alwaysShowCalendars: true,
				opens: 'left',
				drops: 'bottom',
			};

			$scope.types = {};
			$scope.statuses = {};
			let typesMap = [];

			$translate([
				'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
				'transactions.type.identify', 'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update', 'transactions.type.delete',
				'transactions.status.registered', 'transactions.status.in-progress',
				'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
				'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
			]).then((translations) => {
				$scope.types = {
				/* jshint sub:true */
					ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
					ENROLL: translations['transactions.type.enroll'],
					IDENTIFY: translations['transactions.type.identify'],
					VERIFY: translations['transactions.type.verify'],
					VERIFY_UPDATE: translations['transactions.type.verify-update'],
					UPDATE: translations['transactions.type.update'],
					DELETE: translations['transactions.type.delete']
				/* jshint sub:false */
				};

				$scope.statuses = {
				/* jshint sub:true */
					REGISTERED: translations['transactions.status.registered'],
					IN_PROGRESS: translations['transactions.status.in-progress'],
					ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
					ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
					ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
					REJECTED: translations['transactions.status.rejected'],
					OK: translations['transactions.status.ok'],
					MATCHED: translations['transactions.status.matched'],
					NOT_MATCHED: translations['transactions.status.not-matched'],
					DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
				/* jshint sub:false */
				};

				typesMap = Object.entries($scope.types).map(([key, name]) => ({ key, name }));
			});

			$scope.getFilterTypes = function () {
				return typesMap;
			};

			$scope.loadMore = function () {
				if ($scope.loadingDisabled) {
					return;
				}

				$scope.loadingDisabled = true;
				$scope.loading = true;
				$scope.wasLoaded = true;

				var countsPromises;
				if (AuthDataHolder.hasAnyAuthority('PERMISSION_TRANSACTION_COUNT')) {
					countsPromises = $q.all([
						TransactionsResource.count().$promise,
						TransactionsResource.count(filterDataInternal).$promise
					]);
				}

				TransactionsResource.query(angular.extend({
					page: $scope.pagesLoaded,
					size: $scope.itemsPerPage,
					sort: $scope.sort.field,
					reverse: $scope.sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					Array.prototype.push.apply($scope.transactions, value);

					if (AuthDataHolder.hasAnyAuthority('PERMISSION_TRANSACTION_COUNT')) {
						countsPromises.then((response) => {
							$translate('transactions.counts', {
								total: response[0].count,
								filtered: response[1].count,
								showing: $scope.transactions.length
							}).then((translation) => {
								$scope.countsMessage = translation;
							});
						});
					}

					$scope.pagesLoaded += 1;
					$scope.hasNextPage = responseHeaders('X-Has-Next-Page') === 'true';

					$timeout(() => {
						$scope.loadingDisabled = !($scope.hasNextPage && $scope.pagesLoaded < $scope.maxPagesToLoad);
					});

					if ($scope.transactions.length && $scope.hasNextPage && $scope.pagesLoaded === $scope.maxPagesToLoad) {
						AlertService.show('transactions.limit-reached', { type: 'info' });
					}
				}).$promise.finally(() => {
					$scope.loading = false;
					$scope.updateFields = true;
				});
			};

			$scope.resetLoaded = function () {
				if ($scope.loading) {
					return;
				}

				$scope.loadingDisabled = false;
				$scope.pagesLoaded = 0;
				$scope.transactions = [];
				$scope.loadMore();
			};

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateRangeIsValid($scope.filterData, prefix, true);
			};

			function isFormValid() {
				return $scope.filterForm.$valid
					&& $scope.dateFieldsValid('createdAt')
					&& $scope.dateFieldsValid('startedAt')
					&& $scope.dateFieldsValid('completedAt');
			}

			function isFilterByGalleryAvailable() {
				return AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')
				&& $scope.galleries.length > $scope.galleryStartIndex
				&& $scope.selectedGallery.id !== GalleriesService.getAllGalleriesOption().id;
			}

			function copyProcessedFilterData(to) {
				function transformKeyValueToArray(types) {
					return Object.keys(types).reduce((acc, key) => {
						if (types[key]) {
							acc.push(key);
						}
						return acc;
					}, []);
				}

				if (isFilterByGalleryAvailable()) {
					FilterUtils.filterBySingleField($scope.filterData, to, 'galleryId');
				}

				FilterUtils.filterBySingleField($scope.filterData, to, 'subjectId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'requestId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'type', transformKeyValueToArray);
				FilterUtils.filterBySingleField($scope.filterData, to, 'priority');
				FilterUtils.filterBySingleField($scope.filterData, to, 'status');
				FilterUtils.filterByDateRanges($scope.filterData, to, 'createdAt');
				FilterUtils.filterByDateRanges($scope.filterData, to, 'startedAt');
				FilterUtils.filterByDateRanges($scope.filterData, to, 'completedAt');
				FilterUtils.filterBySingleField($scope.filterData, to, 'userId');
				FilterUtils.filterBySingleField($scope.filterData, to, 'adjudicated');
			}

			$scope.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					FilterDataHolder.add(FILTER_NAME, $scope.filterData);
					$scope.resetLoaded();
				}
			};

			function filterInterval(timeString, label) {
				filterDataInternal = {};
				$scope.filterData = {};
				$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex] || GalleriesService.getDefaultGallery();
				$scope.onGalleryChoose();
				$scope.filterData.createdAt = {
					range: [
						moment().add(-1, timeString),
						moment()
					],
					label
				};
				copyProcessedFilterData(filterDataInternal);
				FilterDataHolder.remove(FILTER_NAME);
				FilterDataHolder.add(FILTER_NAME, $scope.filterData);
				$scope.resetLoaded();
			}

			$scope.resetFilter = function () {
				FilterDataHolder.remove(FILTER_NAME);
				$scope.filterData = {};
				$scope.filterForm.$setPristine();
				filterDataInternal = {};

				$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex] || GalleriesService.getDefaultGallery();
				$scope.updateSelection(0, $scope.searchFilters, true, false);

				$scope.sort.reset();
				$scope.resetLoaded();
			};

			$scope.download = function (mediaType) {
				if (isFormValid()) {
					const filterData = {};
					copyProcessedFilterData(filterData);
					filterData.timeZone = moment.tz.guess();
					const getReport = mediaType === 'PDF' ? ReportsResource.getReportPdf : ReportsResource.getReportCsv;
					getReport(angular.extend({
						sort: $scope.sort.field,
						reverse: $scope.sort.reverse
					}, filterData), (value) => {
						FileSaver.saveAs(value.document, `transactions_report_${$filter('date')(new Date(), 'yyyyMMdd_HHmmss')}.${mediaType.toLowerCase()}`);
					});
				}
			};

			$scope.hasAnyAuthority = auth => AuthDataHolder.hasAnyAuthority(auth);

			$scope.isTransactionLinkActive = $scope.hasAnyAuthority('PERMISSION_TRANSACTION_VIEW');
			$scope.getTransactionLinkPayload = function (transaction) {
				return { transactionID: transaction.requestId, previousState: $state.current };
			};

			$scope.sortBy = function (field) {
				if ($scope.loading) {
					return;
				}

				$scope.sort.sort(field);
				$scope.resetLoaded();
			};

			$scope.getGalleryNameById = galleryId => GalleriesService.getGalleryNameById(galleryId, $scope.galleries);

			$scope.isGalleriesPresent = function () {
				return $scope.galleries.length > $scope.galleryStartIndex;
			};
		}]);
