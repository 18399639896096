import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('adjudicationIcon', () => ({
		template: require('../../views/directives/icons/adjudication.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('enrollIcon', () => ({
		template: require('../../views/directives/icons/enroll.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('identifyIcon', () => ({
		template: require('../../views/directives/icons/identify.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('verifyIcon', () => ({
		template: require('../../views/directives/icons/verify.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('passportIcon', () => ({
		template: require('../../views/directives/icons/passport.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('searchIcon', () => ({
		template: require('../../views/directives/icons/search.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('updateIcon', () => ({
		template: require('../../views/directives/icons/update.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('transactionsIcon', () => ({
		template: require('../../views/directives/icons/transactions.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('latentIcon', () => ({
		template: require('../../views/directives/icons/latent.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('positionIndicator', () => ({
		template: require('../../views/directives/icons/position-indicator.html'),
		restrict: 'E',
		replace: true,
		scope: {
			circleContainer: '@'
		}
	}))
	.directive('compareIcon', () => ({
		template: require('../../views/directives/icons/compare.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('deleteUserIcon', () => ({
		template: require('../../views/directives/icons/user-times.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('editUserIcon', () => ({
		template: require('../../views/directives/icons/user-edit.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('plusUserIcon', () => ({
		template: require('../../views/directives/icons/user-plus.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}))
	.directive('deduplicationIcon', () => ({
		template: require('../../views/directives/icons/deduplication.html'),
		restrict: 'E',
		replace: true,
		scope: {}
	}));
