import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('usernameTypeahead', [function () {
		return {
			template: require('../../views/directives/username-typeahead.html'),
			restrict: 'E',
			replace: true,
			require: '^form',
			scope: {
				inputName: '@',
				labelName: '@',
				inputId: '@',
				inputNgModel: '=',
				noResultsLabel: '@',
				inputClass: '@',
				labelClass: '@',
				onChange: '&'
			},
			controller: 'typeAheadCtrl',
			link: (scope, elem, attr, formCtrl) => {
				scope.formCtrl = formCtrl;
				scope.$watch('formCtrl[inputName].$pristine', () => {
					if (formCtrl[scope.inputName].$pristine) {
						scope.selectedData = null;
						scope.noResults = false;
					}
				});
			}
		};
	}])
	.controller('typeAheadCtrl', ['$scope', 'UsersResource', function ($scope, UsersResource) {
		$scope.$watch('inputNgModel', (newValue) => {
			if (newValue !== undefined && $scope.selectedData === undefined) {
				UsersResource.get({ id: newValue }).$promise.then((users) => {
					[$scope.selectedData] = users;
				});
			}
		});

		$scope.getUsernames = function (val) {
			return UsersResource.get({ name: val }).$promise.then((users) => {
				if (!users) {
					$scope.noResults = false;
				}
				return users;
			});
		};

		function validateUsername() {
			if (typeof $scope.selectedData === 'object' || $scope.selectedData === '') {
				$scope.formCtrl.$setValidity($scope.inputName, true);
				$scope.noResults = false;
			} else {
				$scope.formCtrl.$setValidity($scope.inputName, false);
			}
		}

		$scope.selectionChanged = function () {
			$scope.inputNgModel = $scope.selectedData ? $scope.selectedData.id : '';
			validateUsername();
			$scope.onChange();
		};
	}]);
