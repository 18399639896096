import angular from 'angular';
import FileSaver from 'file-saver';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.controller('FaceComparisonModalCtrl', ['$filter', '$scope', '$timeout', '$uibModalInstance', '$q', 'ConvertionUtils', 'drawingService', 'nudgedService', 'SettingsService',
		function ($filter, $scope, $timeout, $uibModalInstance, $q, ConvertionUtils, drawingService, nudgedService, SettingsService) {
			$scope.isBlur = SettingsService.getShouldBlurImages().face;

			var stage;
			var zoomFactor = 1.05;

			$scope.overlapSlider = {
				value: 1,
				max: 100,
				min: 1
			};

			$scope.options = {
				cropImage: 'yes',
				comparisonMode: 'sideBySide'
			};

			function initCanvas() {
				var canvas = document.getElementById('canvas');
				resizeCanvas(canvas);

				stage = new window.createjs.Stage(canvas);
				stage.enableMouseOver();

				enableWheelActions();
			}

			function enableWheelActions() {
				function mouseWheel(event) {
					var scale;
					if (event.wheelDelta > 0 || event.detail < 0) {
						scale = zoomFactor;
					} else {
						scale = 1 / zoomFactor;
					}

					for (var i = stage.numChildren - 1; i >= 0; i -= 1) {
						var child = stage.getChildAt(i);
						var local = child.globalToLocal(stage.mouseX, stage.mouseY);
						child.regX = local.x;
						child.regY = local.y;
						child.x = stage.mouseX;
						child.y = stage.mouseY;
						child.scaleY *= scale;
						child.scaleX = child.scaleY;
					}

					stage.update();
				}

				stage.canvas.addEventListener('mousewheel', mouseWheel);
				stage.canvas.addEventListener('DOMMouseScroll', mouseWheel);
			}

			function resizeCanvas(canvas) {
				var parent = $(canvas).parent();
				canvas.width = parent.width();
				canvas.height = parent.height();
			}

			function onWindowResize() {
				resizeCanvas(stage.canvas);
				stage.update();
			}

			function loadFeatures() {
				var deferred = $q.defer();

				function loadFeature(person) {
				// person must have getFaceFeatures()
					return person.getFaceFeatures().then((features) => {
						person.features = features;
					});
				}

				$q.all([
					loadFeature($scope.probe),
					loadFeature($scope.hit)
				]).then(deferred.resolve);

				return deferred.promise;
			}

			function registerTile(imagesContainer, image, x, y, width, height, isTileEven) {
				var originalImageBitmap = new window.createjs.Bitmap(image);
				var originalImageContainer = new window.createjs.Container();
				originalImageContainer.hitArea = drawingService.createRectangleHitArea({
					x, y, width, height
				});
				originalImageContainer.on('click', (event) => {
					if ($scope.options.comparisonMode !== 'tile') {
						return;
					}
					var target = event.currentTarget;
					if (target.alpha === 1) {
						target.alpha = 0;
					} else {
						target.alpha = 1;
					}

					imagesContainer.updateCache();
					stage.update();
				});
				if (isTileEven) {
					originalImageContainer.name = 'EVEN';
				} else {
					originalImageContainer.name = 'ODD';
				}
				originalImageContainer.addChild(originalImageBitmap);
				originalImageContainer.cache(x, y, width, height);
				imagesContainer.addChild(originalImageContainer);
			}

			function createTiles(imagesContainer, image, width, height, columns, rows) {
				var tileWidth = width / columns;
				var tileHeight = height / rows;

				var col;
				var row;
				for (col = 0; col < columns; col += 1) {
					for (row = 0; row < rows; row += 1) {
						var x = col * tileWidth;
						var y = row * tileHeight;
						var isTileEven = false;
						if ((col + row) % 2 === 0) {
							isTileEven = true;
						}
						registerTile(imagesContainer, image, x, y, tileWidth, tileHeight, isTileEven);
					}
				}
			}

			function addImages() {
				var deferred = $q.defer();

				function addImage(container, person, pattern) {
					if (pattern == null) {
						pattern = 'default';
					}

					var deferred = $q.defer();
					var image = new Image();

					var imageContainer = new window.createjs.Container();
					imageContainer.name = 'image';
					container.addChild(imageContainer);

					var tiledImageContainer = new window.createjs.Container();
					tiledImageContainer.name = 'TILED';
					imageContainer.addChild(tiledImageContainer);

					image.src = person.imageUrl;
					image.onload = function () {
						if (pattern === 'tiled') {
							createTiles(tiledImageContainer, image, image.width, image.height, 16, 12);
							tiledImageContainer.cache(0, 0, image.width, image.height);
						} else { // 'default'
							var imageBitmap = new window.createjs.Bitmap(image);
							imageContainer.addChild(imageBitmap);
							imageContainer.cache(0, 0, imageBitmap.image.width, imageBitmap.image.height);
						}

						deferred.resolve(container);
					};

					return deferred.promise;
				}

				var probeContainer = new window.createjs.Container();
				probeContainer.name = 'probe';
				var hitContainer = new window.createjs.Container();
				hitContainer.name = 'hit';
				stage.addChild(probeContainer, hitContainer);

				$q.all([
					addImage(probeContainer, $scope.probe),
					addImage(hitContainer, $scope.hit, 'tiled')
				]).then(deferred.resolve);

				return deferred.promise;
			}

			function maskImage(image, rect, scale) {
				if (scale == null) {
					scale = 1;
				}

				var shape = new window.createjs.Shape().graphics.beginFill('#000000').drawRect(rect.x * scale, rect.y * scale, rect.width * scale, rect.height * scale);
				var mask = new window.createjs.Shape(shape);
				image.mask = mask;
			}

			function getImageByPersonName(personName) {
				return stage.getChildByName(personName).getChildByName('image');
			}

			function getDisplayBoundsByPersonName(personName) {
				function getFaceBoundsByPersonName(personName) {
					if (personName === 'probe') {
						return $scope.probe.features.boundingRect;
					} else if (personName === 'hit') {
						return $scope.hit.features.boundingRect;
					}
					return null;
				}

				var boundingRect = getFaceBoundsByPersonName(personName);
				if ($scope.options.cropImage === 'yes' && boundingRect != null) {
					return getInflatedBounds(boundingRect);
				}
				return getImageByPersonName(personName).getBounds();
			}

			function changeMask(image, bounds) {
				maskImage(image, {
					x: bounds.x,
					y: bounds.y,
					width: bounds.width,
					height: bounds.height
				});
			}

			function updateOverlayModeSlider() {
				var minValue;
				var maxValue;
				var currValue;
				if ($scope.options.comparisonMode === 'horizontal' || $scope.options.comparisonMode === 'vertical') {
					var imageBounds = stage.getChildByName('hit').getChildByName('image').getChildByName('TILED').getBounds();
					minValue = 1;
					maxValue = $scope.options.comparisonMode === 'horizontal' ? imageBounds.width : imageBounds.height;
					if ($scope.options.comparisonMode === 'horizontal') {
						currValue = Math.round(($scope.hit.features.leftEyeCenter.x + $scope.hit.features.rightEyeCenter.x) / 2);
					} else {
						currValue = Math.round($scope.hit.features.noseTip.y);
					}
				} else if ($scope.options.comparisonMode === 'blend') {
					minValue = 1;
					maxValue = 100;
					currValue = 50;
				}
				function changeUIOverlaySlider(minValue, maxValue, currValue) {
					$scope.overlapSlider.min = minValue;
					$scope.overlapSlider.max = maxValue;
					$scope.overlapSlider.value = currValue;
					// Slider issues workaround: https://github.com/angular/angular.js/issues/6726
					$timeout(() => {
						angular.element('.slider-input').val($scope.overlapSlider.value);
					}, 50);
				}
				changeUIOverlaySlider(minValue, maxValue, currValue);
			}

			function removeFilters() {
				var imageCropped = stage.getChildByName('hit').getChildByName('image').getChildByName('TILED');
				imageCropped.filters = [];
				imageCropped.updateCache();
			}

			function drawingOptionsChanged(newValue, oldValue) {
				if (oldValue == null) {
					oldValue = {};
				}

				function localToLocal(imageFrom, imageTo, x, y) {
					var global = imageFrom.localToGlobal(x, y);
					return imageTo.globalToLocal(global.x, global.y);
				}

				removeFilters();

				if (newValue.comparisonMode === 'sideBySide') {
					$scope.showSideBySide();
					changeMask(getImageByPersonName('hit'), getDisplayBoundsByPersonName('hit'));
					changeMask(getImageByPersonName('probe'), getDisplayBoundsByPersonName('probe'));
				} else {
					$scope.showOverlap();
					var probeBounds = getDisplayBoundsByPersonName('probe');
					var hitLocal = localToLocal(getImageByPersonName('probe'), getImageByPersonName('hit'), probeBounds.x, probeBounds.y);
					changeMask(getImageByPersonName('probe'), getDisplayBoundsByPersonName('probe'));
					changeMask(getImageByPersonName('hit'), {
						x: hitLocal.x,
						y: hitLocal.y,
						width: probeBounds.width * getSRT().scale,
						height: probeBounds.height * getSRT().scale
					});
				}
				var container = stage.getChildByName('hit').getChildByName('image').getChildByName('TILED');
				var i;
				var child;
				if (newValue.comparisonMode === 'tile') {
					for (i = 0; i < container.numChildren; i += 1) {
						child = container.getChildAt(i);
						if (child.name === 'EVEN') {
							child.alpha = 0;
						}
					}
					container.updateCache();
					stage.update();
				} else {
					for (i = 0; i < container.numChildren; i += 1) {
						child = container.getChildAt(i);
						child.alpha = 1;
					}
					container.updateCache();
					stage.update();
				}
				updateOverlayModeSlider();

				stage.update();
			}

			function getInflatedBounds(bounds) {
				var width = bounds.width / 2;
				var { height } = bounds;
				var x = -width / 1.5;
				var y = -height / 1.5;

				return {
					x: bounds.x + x,
					y: bounds.y + y,
					width: bounds.width + width,
					height: bounds.height + height
				};
			}

			function getSRT(scale) {
				if (scale == null) {
					scale = 1;
				}
				function matrix(person) {
					return [
						[person.features.leftEyeCenter.x * scale, person.features.leftEyeCenter.y * scale],
						[person.features.rightEyeCenter.x * scale, person.features.rightEyeCenter.y * scale],
						[person.features.noseTip.x * scale, person.features.noseTip.y * scale],
						[person.features.mouthCenter.x * scale, person.features.mouthCenter.y * scale]
					];
				}

				var domain = matrix($scope.probe);
				var range = matrix($scope.hit);

				var trans = nudgedService.estimateTSR(domain, range);

				return {
					scale: trans.getScale(),
					rotation: ConvertionUtils.radiansToDegress(trans.getRotation()),
					translation: {
						x: trans.getTranslation()[0],
						y: trans.getTranslation()[1]
					}
				};
			}

			$scope.showSideBySide = function () {
				var halfCanvasWidth = stage.canvas.width / 2;

				var probe = stage.getChildByName('probe');
				var probeBounds = getDisplayBoundsByPersonName('probe');
				var probeScale = Math.min(halfCanvasWidth / probeBounds.width, stage.canvas.height / probeBounds.height);

				var hit = stage.getChildByName('hit');
				var hitBounds = getDisplayBoundsByPersonName('hit');
				var hitScale = Math.min(halfCanvasWidth / hitBounds.width, stage.canvas.height / hitBounds.height);

				probe.regX = 0;
				probe.regY = 0;
				hit.regX = 0;
				hit.regY = 0;

				probe.scaleX = probeScale;
				probe.scaleY = probeScale;
				hit.scaleX = hitScale;
				hit.scaleY = hitScale;

				getImageByPersonName('probe').rotation = 0;
				getImageByPersonName('hit').rotation = 0;

				probe.x = (halfCanvasWidth - probeBounds.width * probeScale) / 2 - probeBounds.x * probeScale;
				probe.y = (stage.canvas.height - probeBounds.height * probeScale) / 2 - probeBounds.y * probeScale;
				hit.x = halfCanvasWidth + (halfCanvasWidth - hitBounds.width * hitScale) / 2 - hitBounds.x * hitScale;
				hit.y = (stage.canvas.height - hitBounds.height * hitScale) / 2 - hitBounds.y * hitScale;
			};

			$scope.showOverlap = function () {
				var probe = stage.getChildByName('probe');
				var hit = stage.getChildByName('hit');
				var probeBounds = probe.getChildByName('image').getBounds();
				var scaleProbe = Math.min(stage.canvas.width / probeBounds.width, stage.canvas.height / probeBounds.height);

				probe.regX = 0;
				probe.regY = 0;
				hit.regX = 0;
				hit.regY = 0;

				probe.x = stage.canvas.width / 2 - probeBounds.width * scaleProbe / 2 - $scope.probe.features.boundingRect.width * scaleProbe / 2;
				probe.y = stage.canvas.height / 2 - probeBounds.height * scaleProbe / 2;
				hit.x = 0;
				hit.y = 0;

				probe.scaleX = scaleProbe;
				probe.scaleY = scaleProbe;
				hit.scaleX = scaleProbe;
				probe.scaleY = scaleProbe;

				var trans = getSRT();
				hit.scaleX = (1 / trans.scale) * probe.scaleX;
				hit.scaleY = (1 / trans.scale) * probe.scaleX;
				hit.x -= (trans.translation.x * 1 / trans.scale) * probe.scaleX - probe.x;
				hit.y -= (trans.translation.y * 1 / trans.scale) * probe.scaleX - probe.y;
				getImageByPersonName('probe').rotation = trans.rotation;

				stage.update();
			};

			function changeAlphaMaskFilter(newValue) {
				if (newValue != null && $scope.options.comparisonMode !== 'sideBySide') {
					var imageOriginal = stage.getChildByName('hit').getChildByName('image').getChildByName('TILED');
					var imageBounds = imageOriginal.getBounds();

					imageOriginal.filters = [];

					var box = new window.createjs.Shape();
					box.graphics.beginFill('#000000');
					if ($scope.options.comparisonMode === 'horizontal') {
						box.graphics.drawRect(0, 0, newValue, imageBounds.height);
						box.cache(0, 0, newValue, imageBounds.height);
						imageOriginal.filters.push(new window.createjs.AlphaMaskFilter(box.cacheCanvas));
					} else if ($scope.options.comparisonMode === 'vertical') {
						box.graphics.drawRect(0, 0, imageBounds.width, newValue);
						box.cache(0, 0, imageBounds.width, newValue);
						imageOriginal.filters.push(new window.createjs.AlphaMaskFilter(box.cacheCanvas));
					} else if ($scope.options.comparisonMode === 'blend') {
						imageOriginal.filters.push(new window.createjs.ColorFilter(1, 1, 1, newValue / 100));
					} else if ($scope.options.comparisonMode === 'tile') {
						imageOriginal.filters.push(new window.createjs.ColorFilter(1, 1, 1, 0));
					}

					imageOriginal.updateCache();
					stage.update();
				}
			}

			$uibModalInstance.rendered.then(() => {
				var deferred = $q.defer();

				initCanvas();
				$(window).on('resize', onWindowResize);
				loadFeatures().then(() => {
				// addImage uses features
					addImages().then(() => {
						drawingOptionsChanged($scope.options, null);
						$scope.$watch('options', drawingOptionsChanged, true);
						$scope.$watch('overlapSlider.value', changeAlphaMaskFilter, true);
					});
				});

				return deferred.promise;
			});

			$uibModalInstance.closed.then(() => {
				$(window).off('resize', onWindowResize);
			});

			$scope.download = function (type) {
				if (type === 'png') {
					var canvas = document.getElementById('canvas');
					canvas.toBlob((data) => {
						var date = $filter('date')(new Date(), 'yyyyMMdd_HHmmss');
						FileSaver.saveAs(data, `face_comparison_${date}.png`);
					});
				}
			};

			$scope.getImageUrl = function (modality) {
				return () => $q((resolve) => {
					resolve(modality.imageUrl);
				});
			};
		}]);
