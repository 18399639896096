import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.controller('SimpleComparisonModalCtrl', ['$scope', '$uibModalInstance', '$q', 'SettingsService', function ($scope, $uibModalInstance, $q, SettingsService) {
		$scope.isBlur = SettingsService.getShouldBlurImages()[$scope.modality] || false;

		function resizeCanvas(canvas) {
			var parent = $(canvas).parent();
			canvas.width = parent.width();
			canvas.height = parent.height();
		}

		function resizeImages() {
			const nextImageOffset = { x: 0, y: 0 };
			const probe = stage.getChildByName('probe');
			const hit = stage.getChildByName('hit');
			const probeImage = probe
				.getChildByName('image');
			const hitImage = hit
				.getChildByName('image');

			const probeImageBounds = probeImage.getBounds();
			const hitImageBounds = hitImage.getBounds();

			const imageContainerWidth = stage.canvas.width / 2;
			const imageContainerHeight = stage.canvas.height;
			const separatorWidth = 5;

			const xRatio = (imageContainerWidth - separatorWidth) / probeImageBounds.width;
			const yRatio = imageContainerHeight / hitImageBounds.height;
			const scale = Math.min(xRatio, yRatio);

			probeImage.scaleX = scale;
			probeImage.scaleY = scale;
			hitImage.scaleX = scale;
			hitImage.scaleY = scale;

			probeImage.x = nextImageOffset.x;
			nextImageOffset.x = imageContainerWidth + separatorWidth;
			probeImage.y = (imageContainerHeight - hitImageBounds.height * scale) / 2;

			hitImage.x = nextImageOffset.x;
			hitImage.y = (imageContainerHeight - hitImageBounds.height * scale) / 2;
		}

		function onWindowResize() {
			resizeCanvas(stage.canvas);
			resizeImages();
			stage.update();
		}

		let stage;
		function initCanvas() {
			const canvas = document.getElementById('canvas');
			resizeCanvas(canvas);

			stage = new window.createjs.Stage(canvas);
		}

		function addImages() {
			const deferred = $q.defer();

			function addImage(container, person) {
				const deferred = $q.defer();
				const image = new Image();

				const imageContainer = new window.createjs.Container();
				imageContainer.name = 'image';
				container.addChild(imageContainer);

				image.src = person.imageUrl;
				image.onload = function () {
					const imageBitmap = new window.createjs.Bitmap(image);
					imageContainer.addChild(imageBitmap);
					imageContainer.cache(imageBitmap.x, imageBitmap.y, image.width, imageBitmap.image.height);
					deferred.resolve(container);
				};

				return deferred.promise;
			}

			const probeContainer = new window.createjs.Container();
			probeContainer.name = 'probe';
			const hitContainer = new window.createjs.Container();
			hitContainer.name = 'hit';
			stage.addChild(probeContainer, hitContainer);

			$q.all([
				addImage(probeContainer, $scope.probe),
				addImage(hitContainer, $scope.hit)
			]).then(deferred.resolve);

			return deferred.promise;
		}

		$uibModalInstance.rendered.then(() => {
			const deferred = $q.defer();
			initCanvas();
			addImages()
				.then(onWindowResize);

			$(window).on('resize', onWindowResize);

			return deferred.promise;
		});

		$scope.getImageUrl = function (modality) {
			return () => $q((resolve) => {
				resolve(modality.imageUrl);
			});
		};
	}]);
