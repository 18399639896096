import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('MultiselectModule', ['store', 'Utils',
		function (store, Utils) {
			let msData;
			let msCustomTexts;
			let msModel = [];
			let storeName = '';
			let shouldSave = true;

			let msExtraSettings = {
				dynamicTitle: false
			};

			function saveModel() {
				if (shouldSave) {
					store.set(storeName, msModel);
				}
			}

			const events = {
				onSelectionChanged: saveModel
			};

			function setMsData(data) {
				msData = data;
				return msData;
			}

			function getModelFromData(data) {
				let model;
				if (Utils.isObject(data) && !Array.isArray(data)) {
					if (Object.hasOwnProperty.call(data, 'id')) {
						return { id: data.id };
					}

					model = {};
					Object.keys(data).forEach((key) => {
						model[key] = getModelFromData(data[key]);
					});
				} else if (Array.isArray(data)) {
					model = data.map(group => getModelFromData(group));
				}
				return model;
			}

			function getMsModel(defaultModel) {
				if (!shouldSave) {
					msModel = defaultModel || getModelFromData(msData);
					return defaultModel;
				}

				var columnsToShow = store.get(storeName);
				if (!columnsToShow) {
					msModel = defaultModel || getModelFromData(msData);
					saveModel();
					return msModel;
				}
				msModel = columnsToShow;
				return columnsToShow;
			}

			function getMsExtraSettings() {
				return msExtraSettings;
			}

			function getMsCustomTexts() {
				return msCustomTexts;
			}

			function getListOfColumnsFromGroups(groups, indexes) {
				if (indexes.length === 0) return groups;

				let list = groups;
				indexes.forEach((index) => {
					if (list[index]) list = list[index];
				});
				return list;
			}

			function getSelectedModel(...indexes) {
				return getListOfColumnsFromGroups(msModel, indexes);
			}

			function getSelectedData(...indexes) {
				return getListOfColumnsFromGroups(msData, indexes);
			}

			function isVisibleColumn(...indexes) {
				if (!multiSelectModule.isInitialized) return false;

				function checkIfHasColumn(columns) {
					return columns.some(column => column.id === columnName);
				}

				const [columnName] = indexes.splice(indexes.length - 1);
				const list = getListOfColumnsFromGroups(msModel, indexes);
				return checkIfHasColumn(list);
			}

			const multiSelectModule = {
				data: [],
				model: [],
				selected: null,
				getSelected: () => {},
				getSelectedOptions: () => {},
				events: null,
				extraSettings: {},
				customTexts: {},
				isInitialized: false,
			};

			function initialize(data, defaultModel, customText, storageName = null, extraSettings) {
				shouldSave = !!storageName;
				storeName = storageName;
				msExtraSettings = extraSettings || msExtraSettings;
				msCustomTexts = customText;
				multiSelectModule.data = setMsData(data);
				multiSelectModule.model = getMsModel(defaultModel);
				multiSelectModule.getSelected = getSelectedModel;
				multiSelectModule.getSelectedOptions = getSelectedData;
				multiSelectModule.extraSettings = getMsExtraSettings();
				multiSelectModule.customTexts = getMsCustomTexts();
				multiSelectModule.isInitialized = true;

				return multiSelectModule;
			}

			return {
				initialize,
				multiSelectModule,
				isVisibleColumn,
				eventsHandler: events
			};
		}
	]);
