import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('nullIfMin', () => ({
		restrict: 'A',
		require: 'ngModel',
		scope: {
			ngModel: '='
		},
		link(scope, _element, attributes, ngModelCtrl) {
			scope.$watch(() => ngModelCtrl.$viewValue, (model) => {
				if (model === attributes.min) {
					scope.ngModel = null;
				}
			});
		}
	}));
