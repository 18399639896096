import angular from 'angular';
import FileSaver from 'file-saver';

angular.module('neurotecAbisWebClientApp')
	.controller('SettingsModalCtrl', ['$scope', '$translate', '$timeout', '$q', 'AlertService', 'CapturerService', 'HotkeyService', 'LanguagesService', 'SettingsService', 'StatusService', 'PasswordValidation', 'PasswordResouce', 'CaptureLogResource', 'ScenarioService', 'GalleriesService', 'ConvertionUtils', 'AuthDataHolder', 'DSParametersSettingsService', 'SubjectService',
		function ($scope, $translate, $timeout, $q, AlertService, CapturerService, HotkeyService, LanguagesService, SettingsService, StatusService, PasswordValidation, PasswordResouce, CaptureLogResource, ScenarioService, GalleriesService, ConvertionUtils, AuthDataHolder, DSParametersSettingsService, SubjectService) {
			$scope.opt = {
				activeTab: 'tab-configuration'
			};
			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.isCaptureOptionsTabActive = function (...tabs) {
				return tabs.some(tab => tab === $scope.opt.activeTab);
			};

			function onTabOpen() {
				$timeout(() => {
					$scope.$broadcast('rzSliderForceRender');
				});
			}

			$scope.setActiveTab = function (tab) {
				$scope.opt.activeTab = tab;
				onTabOpen();
			};

			$scope.hasFingers = () => (SubjectService.getFingersCount() > 0);
			$scope.hasPalms = () => (SubjectService.getPalmsCount() > 0);

			function initVariables() {
				$scope.settings = {};
				$scope.deviceSelectedScenarios = {};
				$scope.scenariosSelected = {};
				$scope.devices = {};
				$scope.capturerServicePort = SettingsService.getCapturerServicePort();
				$scope.capturerServiceDisplayStatus = SettingsService.getCapturerServiceDisplayStatus();
				$scope.capturerServiceAutoUpdate = SettingsService.getCapturerServiceAutoUpdate();
				$scope.capturerServiceCameraMirrorView = SettingsService.getCapturerServiceCameraMirrorView();
				$scope.waitForIdentifyResponse = SettingsService.getWaitForIdentifyResponse();
				$scope.waitForVerifyResponse = SettingsService.getWaitForVerifyResponse();
				$scope.shouldBlurImages = SettingsService.getShouldBlurImages();
				$scope.storeSubjectData = SettingsService.isStoreSubjectData();
				$scope.isFingersCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Finger');
				$scope.isIrisesCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Iris');
				$scope.isPalmsCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Palm');

				const { getPasswordValidationErrors, getPasswordRequirmentStatus } = PasswordValidation;
				$scope.getPasswordValidationErrors = getPasswordValidationErrors;
				$scope.getPasswordRequirmentStatus = getPasswordRequirmentStatus;

				$scope.showNewPasswordValidation = false;
			}

			$scope.clearLocalStorage = function () {
				window.localStorage.clear();
			};

			$scope.languages = {
				options: LanguagesService.getLanguageOptions(),
				selected: LanguagesService.getPreferredLanguage()
			};

			function initGalleriesIfAvailable() {
				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					GalleriesService.getAvailableGalleries().then((galleries) => {
						const convertedGalleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
						$scope.galleries = {
							options: convertedGalleries,
							selected: SettingsService.getPreferredGallery() || convertedGalleries[0] || GalleriesService.getDefaultGallery()
						};
					});
				} else {
					$scope.galleries = {
						options: [],
						selected: ''
					};
				}
			}

			function initLocalizationSettings() {
				$scope.languages = {
					options: LanguagesService.getLanguageOptions(),
					selected: LanguagesService.getPreferredLanguage()
				};
			}

			function initDeviceServerConfigSettings() {
				$scope.isParametersLoading = DSParametersSettingsService.isParametersLoading;
				$scope.isDeviceServerInitialized = StatusService.isServiceInitialized;
				$scope.showDeviceServerParametersError = false;
				$scope.facesPagingConfig = {
					currentIcaoParametersPage: 1,
					itemsPerPage: 5
				};
			}

			function init() {
				initVariables();
				initGalleriesIfAvailable();
				initLocalizationSettings();
				initDeviceServerConfigSettings();
			}
			init();

			$scope.clearLocalStorage = function () {
				window.localStorage.clear();
			};

			$scope.saveDevices = function () {
				CapturerService.setDeviceByModality('Face', $scope.settings.cameraId);
				CapturerService.setDeviceCaptureFormat($scope.settings.cameraId, $scope.settings.cameraFormat);

				CapturerService.setDeviceByModality('Finger', $scope.settings.fingerScannerId, $scope.scenariosSelected.fingerScannerScenario);
				ScenarioService.setSelected($scope.scenariosSelected.fingerScannerScenario);
				CapturerService.setDeviceByModality('Palm', $scope.settings.palmScannerId, $scope.scenariosSelected.palmScannerScenario);
				ScenarioService.setSelected($scope.scenariosSelected.palmScannerScenario, 'Palm');

				CapturerService.setDeviceByModality('Iris', $scope.settings.irisScannerId, $scope.scenariosSelected.irisScannerScenario);
				CapturerService.setDeviceByModality('Signature', $scope.settings.signatureScannerId);
				CapturerService.setDeviceByModality('Document', $scope.settings.documentScannerId);

				SettingsService.setCapturerServiceCameraMirrorView($scope.capturerServiceCameraMirrorView);
				SettingsService.setCaptureOptionsVisibility('Finger', $scope.isFingersCaptureOptionsVisible);
				SettingsService.setCaptureOptionsVisibility('Iris', $scope.isIrisesCaptureOptionsVisible);
				SettingsService.setCaptureOptionsVisibility('Palm', $scope.isPalmsCaptureOptionsVisible);

				AlertService.show('settings.saved');
			};

			function saveConfig() {
				if ($scope.capturerServicePort !== SettingsService.getCapturerServicePort()) {
					$scope.needPageReload = true;
				}
				SettingsService.setCapturerServicePort($scope.capturerServicePort);
				SettingsService.setCapturerServiceDisplayStatus($scope.capturerServiceDisplayStatus);
				SettingsService.setCapturerServiceAutoUpdate($scope.capturerServiceAutoUpdate);
			}

			$scope.saveGeneral = function () {
				// Biometrics
				SettingsService.setWaitForIdentifyResponse($scope.waitForIdentifyResponse);
				SettingsService.setWaitForVerifyResponse($scope.waitForVerifyResponse);
				SettingsService.setShouldBlurImages($scope.shouldBlurImages);

				// Local Storage
				SettingsService.setStoreSubjectData($scope.storeSubjectData);

				// Language
				LanguagesService.setPreferredLanguage($scope.languages.selected);

				// Gallery
				SettingsService.setPreferredGallery($scope.galleries.selected);
				$timeout(() => { // race condition
					AlertService.show('settings.saved');
				}, 100);
			};

			function resetDevicesIfInvalid() {
				$scope.devices.cameras = $scope.devices.cameras || [];
				$scope.devices.fingerScanners = $scope.devices.fingerScanners || [];
				$scope.devices.irisScanners = $scope.devices.irisScanners || [];
				$scope.devices.palmScanners = $scope.devices.palmScanners || [];
				$scope.devices.signatureScanners = $scope.devices.signatureScanners || [];
				$scope.devices.documentScanners = $scope.devices.documentScanners || [];
			}

			function getEveryModalityDevices() {
				$scope.devices.cameras = CapturerService.getDevices('Face');
				$scope.devices.fingerScanners = CapturerService.getDevices('Finger');
				$scope.devices.irisScanners = CapturerService.getDevices('Iris');
				$scope.devices.palmScanners = CapturerService.getDevices('Palm');
				$scope.devices.signatureScanners = CapturerService.getDevices('Signature');
				$scope.devices.documentScanners = CapturerService.getDevices('Document');
			}

			function getSelectedDevices() {
				const selectedFingerScanner = $scope.devices.fingerScanners.length > 0 ? CapturerService.getDevice('Finger') : {};
				const selectedCamera = $scope.devices.cameras.length > 0 ? CapturerService.getDevice('Face') : {};
				const selectedIrisScanner = $scope.devices.irisScanners.length > 0 ? CapturerService.getDevice('Iris') : {};
				const selectedPalmScanner = $scope.devices.palmScanners.length > 0 ? CapturerService.getDevice('Palm') : {};
				const selectedSignatureScanner = $scope.devices.signatureScanners.length > 0 ? CapturerService.getDevice('Signature') : {};
				const selectedDocumentScanner = $scope.devices.documentScanners.length > 0 ? CapturerService.getDevice('Document') : {};
				return {
					getFingerScanner: () => selectedFingerScanner,
					getCamera: () => selectedCamera,
					getIrisScanner: () => selectedIrisScanner,
					getPalmScanner: () => selectedPalmScanner,
					getSignatureScanner: () => selectedSignatureScanner,
					getDocumentScanner: () => selectedDocumentScanner
				};
			}

			function extractSelectedDevicesId(selectedDevices) {
				$scope.settings.fingerScannerId = selectedDevices.getFingerScanner().id;
				$scope.settings.cameraId = selectedDevices.getCamera().id;
				$scope.settings.irisScannerId = selectedDevices.getIrisScanner().id;
				$scope.settings.palmScannerId = selectedDevices.getPalmScanner().id;
				$scope.settings.signatureScannerId = selectedDevices.getSignatureScanner().id;
				$scope.settings.documentScannerId = selectedDevices.getDocumentScanner().id;
			}

			function extractSelectedScenarios(selectedDevices) {
				if (selectedDevices.getFingerScanner().scenarios && selectedDevices.getFingerScanner().scenarios.options) {
					$scope.deviceSelectedScenarios.fingerScanner = selectedDevices.getFingerScanner().scenarios.options;
				}
				$scope.deviceSelectedScenarios.irisScanner = selectedDevices.getIrisScanner().scenarios;
				if ($scope.deviceSelectedScenarios.irisScanner !== undefined) {
					$scope.scenariosSelected.irisScannerScenario = selectedDevices.getIrisScanner().scenarios.selected;
				}
				if (selectedDevices.getPalmScanner().scenarios && selectedDevices.getPalmScanner().scenarios.options) {
					$scope.deviceSelectedScenarios.palmScanner = selectedDevices.getPalmScanner().scenarios.options;
				}
			}

			function extractCameraFormat(selectedDevices) {
				if ($scope.settings.cameraId) {
					$scope.settings.cameraFormat = selectedDevices.getCamera().selectedFormat !== undefined
						? selectedDevices.getCamera().selectedFormat
						: selectedDevices.getCamera().defaultFormat;
				}
			}

			$scope.refreshDevices = function () {
				resetDevicesIfInvalid();

				CapturerService.refreshDevices().then(
					() => {
						getEveryModalityDevices();
						const selectedDevices = getSelectedDevices();
						extractSelectedDevicesId(selectedDevices);
						extractSelectedScenarios(selectedDevices);
						extractCameraFormat(selectedDevices);
					},
					() => {
						$scope.settings.fingerScannerId = null;
						$scope.settings.cameraId = null;
						$scope.settings.irisScannerId = null;
						$scope.devices.fingerScanners = [];
						$scope.devices.cameras = [];
						$scope.devices.irisScanners = [];
						$scope.devices.palmScanners = [];
						$scope.devices.signatureScanners = [];
						$scope.devices.documentScanners = [];
						$scope.deviceSelectedScenarios.fingerScanner = null;
						$scope.deviceSelectedScenarios.palmScanner = null;
						$scope.deviceSelectedScenarios.irisScanner = null;
					}
				);

				$scope.scenariosSelected.fingerScannerScenario = ScenarioService.getSelected();
				if (!$scope.deviceSelectedScenarios.fingerScanner) $scope.deviceSelectedScenarios.fingerScanner = [];
				$scope.deviceSelectedScenarios.fingerScanner = ScenarioService.getOptions();

				$scope.scenariosSelected.palmScannerScenario = ScenarioService.getSelected('Palm');
				if (!$scope.deviceSelectedScenarios.palmScanner) $scope.deviceSelectedScenarios.palmScanner = [];
				$scope.deviceSelectedScenarios.palmScanner = ScenarioService.getOptions('Palm');

				$scope.capturerServicePort = SettingsService.getCapturerServicePort();

				$scope.updateDeviceLogs();
				StatusService.refresh(); // FIXME: dirty hack to update footer status display when auto update turned off
			};

			$scope.$watch('settings.fingerScannerId', (newFingerScannerId) => {
				if (newFingerScannerId !== undefined && newFingerScannerId !== null) {
					var selectedFingerScanner = CapturerService.getDevice('Finger', newFingerScannerId);
					$scope.deviceSelectedScenarios.fingerScanner = selectedFingerScanner.scenarios.options;
					$scope.scenariosSelected.fingerScannerScenario = selectedFingerScanner.scenarios.selected;
				}
			});

			$scope.$watch('settings.irisScannerId', (newIrisScannerId) => {
				if (newIrisScannerId !== undefined && newIrisScannerId !== null) {
					var selectedIrisScanner = CapturerService.getDevice('Iris', newIrisScannerId);
					$scope.deviceSelectedScenarios.irisScanner = selectedIrisScanner.scenarios;
					$scope.scenariosSelected.irisScannerScenario = selectedIrisScanner.scenarios.selected;
				}
			});

			$scope.$watch('settings.cameraId', (newCameraId) => {
				if (newCameraId !== undefined && newCameraId !== null) {
					const selectedCamera = $scope.getCamera(newCameraId);
					if (selectedCamera) {
						$scope.settings.cameraFormat = selectedCamera.selectedFormat !== undefined ? selectedCamera.selectedFormat : selectedCamera.defaultFormat;
					}
				}
			});

			$scope.getCamera = function (id) {
				const cameras = $scope.devices.cameras.filter(camera => camera.id === id);
				if (cameras.length > 0) return cameras[0];
				return null;
			};

			$scope.getCameraFormats = function (id) {
				const camera = $scope.getCamera(id);
				if (camera && camera.formats) {
					return camera.formats;
				}
				return [];
			};

			$scope.saveHotkeys = function () {
				var duplicateKeys = [];
				Object.keys($scope.currentBindings).forEach((key) => {
					var lastIndex = Object.values($scope.currentBindings).lastIndexOf($scope.currentBindings[key]);
					var firstIndex = Object.values($scope.currentBindings).indexOf($scope.currentBindings[key]);
					if (firstIndex > -1 && firstIndex !== lastIndex) {
						duplicateKeys.push(HotkeyService.getHotkeyErrorValue(key));
					}
				});

				if (duplicateKeys.length > 0) {
					duplicateKeys.unshift('settings.hotkeys.hotkeys-duplicate-error');
					$translate(duplicateKeys).then((translations) => {
						var errorMessage = Object.values(translations)[0];
						errorMessage += ': ';

						for (var i = 1; i < Object.values(translations).length - 1; i += 1) {
							errorMessage += Object.values(translations)[i];
							errorMessage += ', ';
						}

						errorMessage += Object.values(translations)[Object.values(translations).length - 1];
						errorMessage += '.';
						AlertService.show(errorMessage, { type: 'danger', translate: false });
					});
				} else {
					HotkeyService.setHotkeyBindings($scope.currentBindings);
					AlertService.show('settings.saved');
				}
			};

			$scope.refreshHotkeys = function () {
				$scope.currentBindings = HotkeyService.getHotkeyBindings();
			};

			function passwordErrorToTranslationOrDefault(errorMsg) {
				const dynamicPwdMap = [
					['Password should be at least', 'password.messages.too-short'],
					['Password should contain at least one lowercase', 'password.messages.does-not-meet-requirements'],
					['Old password cannot be empty', 'password.messages.old-empty'],
					['Old password is invalid', 'password.messages.current-bad']
				];
				let translation = 'password.messages.unknown-error';
				dynamicPwdMap.forEach(([keyStart, translationStr]) => {
					if (errorMsg.includes(keyStart)) {
						translation = translationStr;
					}
				});
				return translation;
			}

			$scope.changePassword = function () {
				const { passwordChange } = $scope;
				const errors = $scope.getPasswordValidationErrors(passwordChange.new);
				if (errors.length > 0) return;
				if (!passwordChange.new || !passwordChange.old || !passwordChange.confirm) return;
				if (passwordChange.new !== passwordChange.confirm) return;
				PasswordResouce.change({ oldPassword: passwordChange.old, password: passwordChange.new }, () => {
					AlertService.show('password.messages.completed');
					$scope.passwordChange = {};
					$scope.showNewPasswordValidation = false;
				}, (error) => {
					const passwordTranslation = passwordErrorToTranslationOrDefault(error.data.message);
					$translate(passwordTranslation)
						.then(data => AlertService.show(data, { type: 'danger', translate: false }));
				});
			};

			$scope.updateDeviceLogs = function () {
				const deferred = $q.defer();
				if (StatusService.getServiceStatus() !== 'STATUS_ERROR') {
					CaptureLogResource.get({ count: 50 }, (response) => {
						if (response.logs.length === 0) {
							response.logs.push({ value: $translate.instant('settings.no-logs') });
						}
						$scope.deviceLogs = response;
						deferred.resolve();
					});
				} else {
					deferred.resolve();
				}
				return deferred.promise;
			};

			function updateLogsView() {
				$scope.updateDeviceLogs()
					.then($scope.scrollDown);
			}

			$scope.$watch(() => StatusService.getServiceStatus(), updateLogsView);

			$scope.getDeviceServerStatus = function () {
				return StatusService.getServiceStatus();
			};

			$scope.downloadDeviceLogs = function () {
				CaptureLogResource.all((response) => {
					let formatedText = '';
					response.logs.forEach((log) => { formatedText += `${log.date} ${log.value}`; });
					const blob = new Blob([formatedText], { type: 'text/plain', endings: 'native' });
					FileSaver.saveAs(blob, `ds_logs_${new Date().toISOString()}.txt`);
				});
			};

			$scope.scrollDown = function () {
				// TODO FIX THIS HACK AND SCROLL AFTER VIEW UPDATE TICK.
				$timeout(() => {
					const element = document.getElementById('deviceLogs');
					element.scrollTop = element.scrollHeight;
				}, 200);
			};

			$scope.$watch(() => StatusService.getStatuses(), (newStatuses) => {
				if (newStatuses.service === 'STATUS_OK') {
					$scope.refreshDevices();
				}
			}, true);
			$scope.refreshDevices();
			$scope.refreshHotkeys();

			$scope.isNumberType = function (parameter) {
				return parameter.type === 'number'
					&& !$scope.isRangeSliderType(parameter);
			};
			$scope.isRangeSliderType = function (parameter) {
				return parameter.min !== undefined
					&& parameter.max !== undefined;
			};

			$scope.validate = function (param) {
				param.edit = (param.savedValue !== null ? param.value !== param.savedValue : param.value !== param.defaultValue);
			};

			$scope.onSliderChange = function (param) {
				$scope.validate(param);
			};

			$scope.saveOptions = function () {
				if ($scope.isCaptureOptionsTabActive('tab-configuration')) {
					saveConfig();
				} else {
					DSParametersSettingsService.saveParameters();
				}
				AlertService.show('settings.saved');
			};

			$scope.confirmationMessage = false;
			$scope.resetDeviceParameters = function () {
				DSParametersSettingsService.resetDeviceParameters()
					.then($scope.toggleDialog);
			};

			$scope.toggleDialog = function () {
				$scope.confirmationMessage = !$scope.confirmationMessage;
			};

			$scope.findIndexByPage = function (index, itemsPerPage, currentPage) {
				return index + (itemsPerPage * currentPage - itemsPerPage);
			};

			$scope.getPagedParameter = function (obj, index) {
				const relativeIndex = $scope.findIndexByPage(index, $scope.facesPagingConfig.itemsPerPage, $scope.facesPagingConfig.currentIcaoParametersPage);
				return obj[relativeIndex];
			};

			$scope.getDeviceServerParameters = function () {
				$scope.showDeviceServerParametersError = false;
				DSParametersSettingsService.getParameters()
					.then((res) => {
						$scope.deviceServerParametersSettings = res;
					})
					.catch(() => {
						$scope.showDeviceServerParametersError = true;
					});
			};

			$scope.discardParameterChanges = function (index, container) {
				DSParametersSettingsService.discardParameterChanges(index, container);
				$scope.validate(container[index]);
			};

			$scope.setDefaultParameter = function (index, container) {
				DSParametersSettingsService.setDefaultParameter(index, container);
				$scope.validate(container[index]);
			};

			$scope.getSliderOptions = function (parameter, index) {
				return {
					floor: parameter.min,
					ceil: parameter.max,
					disabled: parameter.isDisabled && parameter.isDisabled(),
					id: `slider-${index}`,
					onChange: $scope.onSliderChange(parameter)
				};
			};

			$scope.translationsMap = new Map([
				['Nfiq10', 'settings.capture-options.fingers.options.nfiq-quality-algorithm.nfiq'],
				['Nfiq20', 'settings.capture-options.fingers.options.nfiq-quality-algorithm.nfiq2'],
				['Nfiq21', 'settings.capture-options.fingers.options.nfiq-quality-algorithm.nfiq21']
			]);
		}]);
