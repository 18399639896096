import { combineReducers, createStore, compose } from 'redux';

import AuthReducer from './Auth/reducer';
const rootReducer = combineReducers({
	auth: AuthReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers: typeof compose = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, composeEnhancers());
export default store;
