import { NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type AlignItemsProperty = 'alignItems';
type AlignItems = `${AlignItemsProperty}${NLayoutViewPorts}`;
export type AlignItemsValues = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
export type AlignItemsProps = { [Property in AlignItems]: AlignItemsValues } & {
	alignItems: AlignItemsValues;
};

const getAlignItemsClasses = (suffix?: NLayoutViewPorts): Array<[string, AlignItemsValues]> => {
	if (suffix !== undefined) {
		return [
			[`fs-align-items-${suffix.toLowerCase()}-start`, 'flex-start'],
			[`fs-align-items-${suffix.toLowerCase()}-end`, 'flex-end'],
			[`fs-align-items-${suffix.toLowerCase()}-center`, 'center'],
			[`fs-align-items-${suffix.toLowerCase()}-baseline`, 'baseline'],
			[`fs-align-items-${suffix.toLowerCase()}-stretch`, 'stretch'],
		];
	}
	return [
		['fs-align-items-start', 'flex-start'],
		['fs-align-items-end', 'flex-end'],
		['fs-align-items-center', 'center'],
		['fs-align-items-baseline', 'baseline'],
		['fs-align-items-stretch', 'stretch'],
	];
};

const useAlignItemsClasses = (props: Partial<AlignItemsProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'alignItems', getAlignItemsClasses),
		'fs-align-items-stretch': !props.alignItems,
	});
	return [containerClass, classes];
};

export default useAlignItemsClasses;
