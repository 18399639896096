import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ConfRolesCtrl', ['$scope', '$uibModal', '$translate', 'RolesService', 'CommonValues', 'TaskPollerService', 'ConvertionUtils',
		function ($scope, $uibModal, $translate, RolesService, CommonValues, TaskPollerService, ConvertionUtils) {
			$scope.rolesAlerts = [];

			$scope.roles = [];

			$scope.permissions = {};
			$scope.modifiers = {};

			function fetchRoles() {
				RolesService.query((result) => {
					$scope.roles = result;
				});
			}
			fetchRoles();

			CommonValues.fillUserPermissions($scope.permissions);
			CommonValues.fillUserModifiers($scope.modifiers);

			$scope.cnv = ConvertionUtils;

			$scope.newRole = function () {
				var scope = $scope.$new(true);
				scope.title = 'admin.configuration.roles.new';
				$translate(scope.title).then((translation) => {
					scope.title = translation;
				});
				scope.alerts = [];
				scope.formName = 'newRoleForm';
				scope.data = {};
				scope.submitLabel = 'admin.configuration.roles.new-submit';
				$translate(scope.submitLabel).then((translation) => {
					scope.submitLabel = translation;
				});
				scope.submitAction = function (data) {
					var startMsg = 'admin.configuration.roles.saving.new';
					var successMsg = 'admin.configuration.roles.success.new';
					var successMsgOnList = 'admin.configuration.roles.msg.new';
					$translate([startMsg, successMsg, successMsgOnList], { roleid: data.id }).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							RolesService.save(data, success, error);
						}, scope.alerts, translations[startMsg], translations[successMsg], () => {
							$scope.rolesAlerts.push({ type: 'success', msg: translations[successMsgOnList] });
							fetchRoles();
							scope.$broadcast('roleFormClose');
						});
					});
				};
				scope.permissions = Object.entries($scope.permissions);
				scope.modifiers = Object.entries($scope.modifiers);
				$uibModal.open({
					template: require('../../../../views/admin/configuration/role-form.html'),
					scope,
					controller: 'RoleFormCtrl',
					backdrop: 'static',
					animation: false
				});
			};

			$scope.deleteRole = function (role) {
				var startMsg = 'admin.configuration.roles.saving.delete';
				var successMsg = 'admin.configuration.roles.success.delete';
				$translate([startMsg, successMsg], { roleid: role.id }).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						RolesService.delete({ id: role.id }, success, error);
					}, $scope.rolesAlerts, translations[startMsg], translations[successMsg], () => {
						fetchRoles();
					});
				});
			};

			$scope.editRole = function (role) {
				var scope = $scope.$new(true);
				scope.title = 'admin.configuration.roles.edit-role';
				$translate(scope.title, { roleid: role.id }).then((translation) => {
					scope.title = translation;
				});
				scope.alerts = [];
				scope.formName = `editRole${role.id}Form`;
				scope.data = {};
				angular.copy(role, scope.data);
				scope.data.permissions = Object.entries(scope.data.permissions);
				scope.data.modifiers = Object.entries(scope.data.modifiers);
				scope.submitLabel = 'admin.configuration.roles.save-changes';
				$translate(scope.submitLabel).then((translation) => {
					scope.submitLabel = translation;
				});
				scope.submitAction = function (data) {
					var startMsg = 'admin.configuration.roles.saving.update';
					var successMsg = 'admin.configuration.roles.success.update';
					$translate([startMsg, successMsg], { roleid: role.id }).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							RolesService.save({ id: data.id }, data, success, error);
						}, scope.alerts, translations[startMsg], translations[successMsg], () => {
							fetchRoles();
						});
					});
				};
				scope.permissions = Object.entries($scope.permissions);
				scope.modifiers = Object.entries($scope.modifiers);
				scope.edit = true;
				$uibModal.open({
					template: require('../../../../views/admin/configuration/role-form.html'),
					scope,
					controller: 'RoleFormCtrl',
					backdrop: 'static'
				});
			};
		}]);
