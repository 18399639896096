import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('LoginCtrl', ['$rootScope', '$scope', '$state', 'AuthService', 'BiographicDataService', 'LoginRedirectService', 'DSParametersSettingsService', 'AuthDataHolder',
		function ($rootScope, $scope, $state, AuthService, BiographicDataService, LoginRedirectService, DSParametersSettingsService, AuthDataHolder) {
			$scope.loginRequest = {};

			switch ($rootScope.logoutReason) {
			case 'SESSION_EXPIRED':
				$scope.alert = {
					type: 'warning',
					msg: 'login.message.sessionExpired'
				};
				break;
			case 'LOST_CONNECTION':
				$scope.alert = {
					type: 'danger',
					msg: 'login.message.connectionLost'
				};
				break;
			case 'LOST_AUTHORITIES':
				$scope.alert = {
					type: 'danger',
					msg: 'login.message.roleChanged'
				};
				break;
			case 'LOST_SESSION':
				$scope.alert = {
					type: 'danger',
					msg: 'login.message.sessionLost'
				};
				break;
			default:
				$scope.alert = {
					type: 'info',
					msg: ''
				};
			}
			if ($rootScope.logoutReason != null) {
				$rootScope.logoutReason = null;
			}

			$scope.login = function () {
				if ($scope.loginForm.$valid) {
					AuthService.login($scope.loginRequest.name, $scope.loginRequest.password).then(() => {
						const redirect = LoginRedirectService.getRedirectParams();
						BiographicDataService.get();
						if (!redirect.state) {
							$state.reload();

							if (AuthDataHolder.hasAnyAuthority(['PERMISSION_ADMINISTRATION_PARAMETERS'])) {
								DSParametersSettingsService.checkForUpdate();
							}
						} else {
							LoginRedirectService.clear();
							$state.go(redirect.state.name, redirect.params);
						}
					}, (message) => {
						$scope.alert = { type: 'danger', msg: message };
					}, (message) => {
						$scope.alert = { type: 'info', msg: message };
					});
				}
			};
		}]);
