import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('FilterDataHolder', function () {
		this.filters = {};
		this.add = function (name, filter) {
			this.filters[name] = filter;
		};
		this.remove = function (name) {
			if (Object.prototype.hasOwnProperty.call(this.filters, name)) {
				delete this.filters[name];
			}
		};
		this.get = function (name) {
			if (Object.prototype.hasOwnProperty.call(this.filters, name)) {
				return this.filters[name];
			}
			return {};
		};
	});
