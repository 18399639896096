import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.filter('underToLowerCaseDash', () => function (input) {
		if (input) {
			return input.replace(/_/g, '-').toLowerCase();
		}
	})
	.filter('parseBirthDate', () => function (input) {
		if (!input) {
			return;
		}
		var withoutDashes = input.replace(new RegExp('-', 'g'), '');
		var date = moment(withoutDashes, 'YYYYMMDD').format('YYYY-MM-DD');
		return date;
	});
