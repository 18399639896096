import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('TasksService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		function parseResponseData(data, headers) {
			const response = {};
			if (data) {
				response.document = new Blob([data], {
					type: headers()['content-type']
				});
			}
			return response;
		}
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/tasks/:id/:action`, null, {
			getSilent: {
				method: 'GET',
				ignoreLoadingBar: true
			},
			stop: {
				method: 'POST',
				params: {
					action: 'stop'
				}
			},
			getReportCsv: {
				method: 'GET',
				params: {
					action: 'report'
				},
				headers: {
					Accept: 'text/csv'
				},
				responseType: 'arraybuffer',
				transformResponse: parseResponseData
			},
			getReportPdf: {
				method: 'GET',
				params: {
					action: 'report'
				},
				headers: {
					Accept: 'application/pdf'
				},
				responseType: 'arraybuffer',
				transformResponse: parseResponseData
			}
		});
	}])
	.factory('UsersService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/users/:id`);
	}])
	.factory('UsernamesService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/users`, {}, {
			get: {
				method: 'GET',
				isArray: true
			}
		});
	}])
	.factory('RolesService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/roles/:id`);
	}])
	.factory('UtilitiesService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/utilities/:action/:subaction`, null, {
			clearTransactions: {
				method: 'POST',
				params: {
					action: 'clear'
				}
			},
			reapplyMatchingRules: {
				method: 'POST',
				params: {
					action: 'reapply',
					subaction: 'matching-rules'
				}
			},
			calculateNfiq: {
				method: 'POST',
				params: {
					action: 'calculate',
					subaction: 'nfiq'
				}
			}
		});
	}])
	.factory('AdminParametersService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/parameters`);
	}])
	.factory('MonitoringService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/monitoring/:info`, null, {
			getSilent: {
				method: 'GET',
				ignoreLoadingBar: true
			}
		});
	}])
	.factory('GraphiteService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseGraphiteUrl()}/:api/:action`, null, {
			find: {
				method: 'GET',
				isArray: true,
				params: {
					api: 'metrics',
					action: 'find'
				}
			},
			expand: {
				method: 'GET',
				params: {
					api: 'metrics',
					action: 'expand'
				}
			},
			render: {
				method: 'GET',
				isArray: true,
				params: {
					api: 'render',
					format: 'json'
				}
			}
		});
	}])
	.factory('ElasticsearchService', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseElasticsearchUrl()}/:param1/:param2`, null, {
			search: {
				method: 'POST',
				params: {
					param1: 'logstash-*',
					param2: '_search'
				}
			},
			scroll: {
				method: 'POST',
				params: {
					param1: '_search',
					param2: 'scroll'
				}
			}
		});
	}])
	.factory('BiographicResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/biographic-data/:item`, null, {
			get: {
				method: 'GET',
				params: {
					item: 'fields'
				},
				isArray: true
			},
			set: {
				method: 'POST',
				params: {
					item: 'fields'
				}
			}
		});
	}]);
