import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ConfGalleriesParametersCtrl', ['$q', '$scope', '$translate', 'AuthDataHolder', 'GalleriesService', 'TaskPollerService',
		function ($q, $scope, $translate, AuthDataHolder, GalleriesService, TaskPollerService) {
			var selectedID = 0;

			const GLOBAL_GALLERY_NAME = 'Global';
			const GLOBAL_GALLERY_ID = 'default';

			$scope.galleriesParameters = [];
			$scope.galleriesParametersAlerts = [];

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.opts = { activeTab: 'tab-duplicate-check' };
			$scope.matchingModesTabToModel = {
				'tab-duplicate-check': $scope.duplicateCheck,
				'tab-identification': $scope.identification,
				'tab-verification': $scope.verification,
			};

			$scope.changeTab = function (newTab) {
				$scope.opts.activeTab = newTab;
				$scope.$broadcast('reCalcViewDimensions');
			};

			GalleriesService.getGalleriesDefaultParameters()
				.then((results) => {
					results.forEach((galleryParameters) => {
						$scope.galleriesParameters.push({
							data: galleryParameters,
							deleted: false,
							isNew: false
						});
					});
					$scope.galleryParameters = [];
					[$scope.galleryParameters.data] = results;
					$scope.galleryParameters.isNew = false;
					$scope.duplicateCheckParameters = $scope.galleryParameters.data.duplicateCheckParameters;
					$scope.identifyParameters = $scope.galleryParameters.data.identifyParameters;
					$scope.verifyParameters = $scope.galleryParameters.data.verifyParameters;
					$scope.additionalParameters = $scope.galleryParameters.data.additionalParameters !== null ? $scope.galleryParameters.data.additionalParameters : [];
					selectedID = 0;
				});

			$scope.addGalleryParameters = function () {
				const galleryParametersTmp = {
					data: null,
					isNew: true
				};
				$scope.galleriesParameters.push(galleryParametersTmp);
				selectedID = $scope.galleriesParameters.push() - 1;
				$scope.galleryParameters = $scope.galleriesParameters[selectedID];
				$scope.duplicateCheckParameters = null;
				$scope.identifyParameters = null;
				$scope.verifyParameters = null;
				$scope.additionalParameters = [];
			};

			$scope.setGalleryParameters = function (galleryParameters, index) {
				$scope.galleryParameters = galleryParameters;
				$scope.duplicateCheckParameters = $scope.galleryParameters.data.duplicateCheckParameters;
				$scope.identifyParameters = $scope.galleryParameters.data.identifyParameters;
				$scope.verifyParameters = $scope.galleryParameters.data.verifyParameters;
				$scope.additionalParameters = $scope.galleryParameters.data.additionalParameters !== null ? $scope.galleryParameters.data.additionalParameters : [];
				selectedID = index;
			};

			$scope.selectGalleryParameters = function (index) {
				$scope.setGalleryParameters($scope.galleriesParameters[index], index);
			};

			$scope.isActive = function (index) {
				return index === selectedID;
			};

			function isValidGalleryParametersSelector(gallerySelector) {
				return !(gallerySelector === GLOBAL_GALLERY_NAME || gallerySelector === GLOBAL_GALLERY_ID);
			}

			function updateGalleryParameters() {
				const startMsg = 'admin.configuration.galleries-parameters.saving.update';
				const successMsg = 'admin.configuration.galleries-parameters.success.update';
				const reservedGalleryName = 'admin.configuration.galleries-parameters.failure.update';
				$scope.galleryParameters.data.duplicateCheckParameters = $scope.duplicateCheckParameters;
				$scope.galleryParameters.data.identifyParameters = $scope.identifyParameters;
				$scope.galleryParameters.data.verifyParameters = $scope.verifyParameters;
				$scope.galleryParameters.data.additionalParameters = $scope.additionalParameters;
				$translate([startMsg, successMsg, reservedGalleryName], { galleryId: $scope.galleryParameters.data.gallerySelector }).then((translations) => {
					if (!isValidGalleryParametersSelector($scope.galleryParameters.data.gallerySelector) || !$scope.duplicateCheckForm.$valid || !$scope.identificationForm.$valid || !$scope.verificationForm.$valid) {
						$scope.galleriesParametersAlerts.push({ type: 'danger', msg: translations[reservedGalleryName] });
						return;
					}

					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.updateParameters({ galleryId: $scope.galleryParameters.data.gallerySelector }, $scope.galleryParameters.data, success, error);
					}, $scope.galleriesParametersAlerts, translations[startMsg], translations[successMsg], () => {
						$scope.galleryParameters.isNew = false;
					});
				});
			}

			function addGalleryParameters() {
				const startMsg = 'admin.configuration.galleries-parameters.saving.new';
				const successMsg = 'admin.configuration.galleries-parameters.success.new';
				const reservedGalleryName = 'admin.configuration.galleries-parameters.failure.new';
				$scope.galleryParameters.data.duplicateCheckParameters = $scope.duplicateCheckParameters;
				$scope.galleryParameters.data.identifyParameters = $scope.identifyParameters;
				$scope.galleryParameters.data.verifyParameters = $scope.verifyParameters;
				$scope.galleryParameters.data.additionalParameters = $scope.additionalParameters;
				$translate([startMsg, successMsg, reservedGalleryName], { galleryId: $scope.galleryParameters.data.gallerySelector }).then((translations) => {
					if ((!isValidGalleryParametersSelector($scope.galleryParameters.data.gallerySelector) || !$scope.duplicateCheckForm.$valid
						|| !$scope.identificationForm.$valid || !$scope.verificationForm.$valid) && $scope.additionalParameters.length === 0) {
						$scope.galleriesParametersAlerts.push({ type: 'danger', msg: translations[reservedGalleryName] });
						return;
					}

					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.addParameters($scope.galleryParameters.data, success, error);
					}, $scope.galleriesParametersAlerts, translations[startMsg], translations[successMsg], () => {
						$scope.galleryParameters.isNew = false;
					});
				});
			}

			$scope.saveGalleryParameters = function () {
				if (!$scope.galleryParameters.isNew) {
					updateGalleryParameters();
				} else {
					addGalleryParameters();
				}
			};

			$scope.deleteGalleryParameters = function (galleryParameters) {
				var startMsg = 'admin.configuration.galleries-parameters.saving.delete';
				var successMsg = 'admin.configuration.galleries-parameters.success.delete';
				var indexToDelete = $scope.galleriesParameters.indexOf(galleryParameters);
				$translate([startMsg, successMsg], { galleryId: galleryParameters.data.gallerySelector }).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.deleteParameters({ galleryId: galleryParameters.data.gallerySelector }, success, error);
					}, $scope.galleriesParametersAlerts, translations[startMsg], translations[successMsg], () => {
						galleryParameters.data = null;
						galleryParameters.deleted = true;
						$scope.galleriesParameters.splice(indexToDelete, 1);
						const selectedIndex = Math.min(selectedID, $scope.galleriesParameters.length - 1);
						$scope.selectGalleryParameters(selectedIndex);
					});
				});
			};

			$scope.discardGalleryParametersChanges = function (galleryParameters) {
				$scope.galleryParameters.data = null;
				$scope.galleryParameters.isNew = false;
				$scope.galleriesParameters.splice($scope.galleriesParameters.indexOf(galleryParameters), 1);
			};

			$scope.newGalleryParameters = function () {
				$scope.galleriesParameters.push({
					edit: {
						state: 'ACTIVE'
					}
				});
			};

			$scope.getClassIfModeMatchesModality = function (mode, modality) {
				const modes = {
					FUSED: 'FUSED',
					FPIR: 'FPIR',
					MODALITY_BASED: 'MODALITY_BASED',
					ANOMALOUS: 'ANOMALOUS'
				};
				const activeClass = 'parameter-active';
				switch (modality) {
				case 'fused':
					return (mode === modes.FUSED || mode === modes.ANOMALOUS || mode === modes.FPIR) ? activeClass : '';
				case 'face':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'iris':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'finger':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'palm':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				default:
					return '';
				}
			};

			$scope.isModeFpir = function (value) {
				return value === 'FPIR';
			};

			$scope.addParameter = function (parameters) {
				parameters.push({ name: '', value: '' });
			};

			$scope.removeParameter = function (parameters, index) {
				parameters.splice(index, 1);
			};
		}]);
