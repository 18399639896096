import ReactDOM from 'react-dom';
import React from 'react';
import angular from 'angular';

import HomeView from './views/Home';
import FaceIcon from './icons/face.svg'
import FingerIcon from './icons/finger.svg'
import ScoreIcon from './icons/score.svg'
import FusedIcon from './icons/fused.svg'
import VoiceIcon from './icons/voice.svg'
import ModalityBasedIcon from './icons/modality-based.svg'
import AnomalousIcon from './icons/anomalous.svg'
import AdminIcon from './icons/admin.svg'

import Store from './store/Store';
import { Provider } from 'react-redux';

function toBindings(propTypes) {
	return propTypes.reduce((bindings, prop) => {
		bindings[prop] = '<';
		return bindings;
	}, {});
}

function toProps(propTypes, controller) {
	return propTypes.reduce((props, key) => {
		props[key] = controller[key];
		return props;
	}, {});
}

export function reactToAngularComponent(Component, bindingNames = []) {
	return {
		bindings: toBindings(bindingNames),
		controller: ['$element', function ($element) {
			this.$onChanges = () => {
				const props = toProps(bindingNames, this);
				ReactDOM.render(
					<Provider store={Store}>
						<Component {...props} />
					</Provider>,
					$element[0]
				);
			};
			this.$onDestroy = () => ReactDOM.unmountComponentAtNode($element[0]);
		}],
	};
}

const RegisterReactComponents = (module) => {
	angular.module(module).component('homeView', reactToAngularComponent(HomeView));
	angular.module(module).component('faceIcon', reactToAngularComponent(FaceIcon, ['className']));
	angular.module(module).component('fingerIcon', reactToAngularComponent(FingerIcon, ['className']));
	angular.module(module).component('scoreIcon', reactToAngularComponent(ScoreIcon, ['className']));
	angular.module(module).component('fusedIcon', reactToAngularComponent(FusedIcon, ['className']));
	angular.module(module).component('voiceIcon', reactToAngularComponent(VoiceIcon, ['className']));
	angular.module(module).component('modalityBasedIcon', reactToAngularComponent(ModalityBasedIcon, ['className']));
	angular.module(module).component('anomalousIcon', reactToAngularComponent(AnomalousIcon, ['className']));
	angular.module(module).component('adminIcon', reactToAngularComponent(AdminIcon, ['className']));
};
export default RegisterReactComponents;
