import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ImageService', [function () {
		this.getImageBoundsWithoutAlpha = function (image, width, height) {
			const tempCanvas = document.createElement('canvas');
			tempCanvas.width = width;
			tempCanvas.height = height;
			const tempContext = tempCanvas.getContext('2d');
			tempContext.drawImage(image, 0, 0);
			const pixels = tempContext.getImageData(0, 0, width, height);

			const bounds = {
				top: { x: null, y: null },
				left: { x: null, y: null },
				right: { x: null, y: null },
				bottom: { x: null, y: null }
			};

			let x;
			let y;
			for (let i = 0; i < pixels.data.length; i += 4) {
				if (pixels.data[i + 3] !== 0) {
					x = (i / 4) % width;
					y = Math.floor((i / 4) / width);

					if (bounds.top.x === null && bounds.top.y === null) {
						bounds.top.x = x;
						bounds.top.y = y;
					}

					if ((bounds.left.x === null && bounds.left.y === null) || x < bounds.left.x) {
						bounds.left.x = x;
						bounds.left.y = y;
					}

					if ((bounds.right.x === null && bounds.right.y === null) || bounds.right.x < x) {
						bounds.right.x = x;
						bounds.right.y = y;
					}

					if ((bounds.bottom.x === null && bounds.bottom.y === null) || bounds.bottom.y < y) {
						bounds.bottom.x = x;
						bounds.bottom.y = y;
					}
				}
			}

			bounds.width = bounds.right.x - bounds.left.x;
			bounds.height = bounds.bottom.y - bounds.top.y;

			return bounds;
		};

		this.getImageBoundsFromMinutia = function (minutiae) {
			let topMost = angular.copy(minutiae[0]);
			let rightMost = angular.copy(minutiae[0]);
			let bottomMost = angular.copy(minutiae[0]);
			let leftMost = angular.copy(minutiae[0]);

			minutiae.forEach((minutia) => {
				topMost = minutia.y > topMost.y ? minutia : topMost;
				rightMost = minutia.x > rightMost.x ? minutia : rightMost;
				bottomMost = minutia.y < bottomMost.y ? minutia : bottomMost;
				leftMost = minutia.x < leftMost.x ? minutia : leftMost;
			});

			return {
				top: topMost,
				right: rightMost,
				left: leftMost,
				bottom: bottomMost,
				width: rightMost.x - leftMost.x,
				height: bottomMost.y - topMost.y
			};
		};
	}]);
