import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('circlePager', () => ({
		template: require('../../../views/directives/admin/circle-pager.html'),
		restrict: 'E',
		replace: true,
		scope: {
			circles: '=',
			currentPage: '=',
			onClick: '&'
		}
	}));
