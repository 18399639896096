import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('Base64Tools', () => {
		var base64Tools = {};

		base64Tools.arrayBufferToBase64String = function (arrayBuffer) {
			var bytes = new Uint8Array(arrayBuffer);

			var binaryString = '';
			for (var i = 0; i < bytes.byteLength; i += 1) {
				binaryString += String.fromCharCode(bytes[i]);
			}

			return window.btoa(binaryString);
		};

		// based on http://stackoverflow.com/a/16245768
		base64Tools.base64StringToBlob = function (base64String, contentType, sliceSize) {
			contentType = contentType || 'application/octet-stream';
			sliceSize = sliceSize || 512;

			var byteCharacters = atob(base64String);
			var byteArrays = [];

			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);

				var byteNumbers = new Array(slice.length);
				for (var i = 0; i < slice.length; i += 1) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				var byteArray = new Uint8Array(byteNumbers);

				byteArrays.push(byteArray);
			}

			var blob = new Blob(byteArrays, { type: contentType });
			return blob;
		};

		return base64Tools;
	});
