import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('demographicData', ['BiographicDataService', function () {
		return {
			template: require('../../views/directives/demographic-data.html'),
			restrict: 'E',
			replace: true,
			scope: {
				person: '=',
				otherPerson: '=',
				pairColumns: '=?', // number of tables
				fields: '=',
				galleries: '='
			},
			controllerAs: 'bioDataCtrl',
			bindToController: true,
			link: (_scope, _element, attrs, ctrl) => {
				ctrl.noAdditionalFields = Object.hasOwnProperty.call(attrs, 'noAdditionalFields');
			},
			controller: ['$scope', 'JaroWinkler', 'GalleriesService', 'BiographicDataService', 'Utils', function ($scope, JaroWinkler, GalleriesService, BiographicDataService, Utils) {
				const self = this;
				self.pairColumns = self.pairColumns !== undefined ? self.pairColumns : 1;
				self.columnContent = {};

				self.hasKey = BiographicDataService.hasKey;

				function initFieldsGroup() {
					function getAdditionalFields() {
						const fields = [];
						fields.push({ key: 'subjectId' });
						fields.push({ key: 'encounterId' });
						if (self.galleries.length > 1) {
							fields.push({ key: 'galleryId' });
						}
						return fields;
					}

					function populateGroups() {
						const chunkSize = Math.ceil((fieldsCopy.length) / (self.pairColumns || 1));
						for (let i = 0, j = fieldsCopy.length; i < j; i += chunkSize) {
							self.fieldsGroup.push(fieldsCopy.slice(i, i + chunkSize));
						}
					}

					self.fieldsGroup = [];
					const fieldsCopy = angular.copy(self.fields);
					const additionalFields = getAdditionalFields();
					fieldsCopy.unshift(...additionalFields);
					populateGroups();
					self.fieldsGroup[0] = self.fieldsGroup[0].slice(additionalFields.length);
				}

				function initColumnContentsInternal() {
					function createColumnContents() {
						self.fieldsGroup.forEach((group) => {
							group.forEach((field) => {
								setFieldContent(field.key);
							});
						});
					}

					function addAdditionalFields() {
						setFieldContent('subjectId');
						setFieldContent('encounterId');
						if (self.galleries.length !== 0) {
							setFieldContent('galleryId');
						}
					}

					self.columnContent = {};
					createColumnContents();
					addAdditionalFields();
				}

				function setColumnsContentsFromFields() {
					function getFieldKeyFromColumnKey(columnKey) {
						const [fieldKey] = columnKey.split('-').slice(-1);
						return fieldKey;
					}

					function getMatchingFieldsByKey(fieldKey) {
						const biographicData = getFieldsFromBothPersonsByKey(fieldKey);
						sortFields(biographicData);
						return biographicData;
					}

					function isAdditionalField(fieldKey) {
						return ['subjectId', 'encounterId', 'galleryId'].includes(fieldKey);
					}

					function getFieldsFromBothPersonsByKey(fieldKey) {
						let personData = self.person && self.person.biographicData && self.person.biographicData[fieldKey] !== undefined ? self.person.biographicData[fieldKey] : '';
						let otherPersonData = self.otherPerson && self.otherPerson.biographicData && self.otherPerson.biographicData[fieldKey] !== undefined ? self.otherPerson.biographicData[fieldKey] : '';

						if (isAdditionalField(fieldKey)) {
							personData = (self.person && self.person[fieldKey]) || '';
							otherPersonData = (self.otherPerson && self.otherPerson[fieldKey]) || '';
						}

						return [personData, otherPersonData];
					}

					function sortFields(biographicData) {
						biographicData.sort((fieldA, fieldB) => fieldB.length - fieldA.length);
					}

					function getContent(biographicData) {
						return biographicData[0];
					}

					Object.keys(self.columnContent).forEach((columnKey) => {
						const fieldKey = getFieldKeyFromColumnKey(columnKey);
						const biographicData = getMatchingFieldsByKey(fieldKey);
						const content = getContent(biographicData);
						setFieldContent(fieldKey, content);
					});
				}

				const initColumnContentsOnce = (function () {
					let called = false;

					return function () {
						if (called) return;
						called = true;
						initColumnContentsInternal();
					};
				}());

				let personWatcher = () => {};
				let otherPersonWatcher = () => {};
				function startWatchingPersons() {
					personWatcher = $scope.$watch(angular.bind(self, function () {
						return this.person;
					}), (newValue) => {
						if (newValue) {
							initColumnContentsOnce();
						}
						setColumnsContentsFromFields();
					}, true);

					otherPersonWatcher = $scope.$watch(angular.bind(self, function () {
						return this.otherPerson;
					}), () => {
						setColumnsContentsFromFields();
					}, true);
				}

				function removeWatchers() {
					personWatcher();
					otherPersonWatcher();
				}

				const removeFieldsWatcher = $scope.$watch(angular.bind(self, function () {
					return this.fields;
				}), (newValue) => {
					if (newValue && newValue.length > 0) {
						initFieldsGroup();
						removeWatchers();
						startWatchingPersons();
						removeFieldsWatcher();
					}
				}, true);

				self.hasGalleries = function () {
					return self.galleries.length > GalleriesService.getBeginningGalleryIndex();
				};

				self.getTableId = function (personId) {
					return `demographic-data-table-person-${personId}`;
				};

				self.getRowId = function (personId, fieldKey) {
					return `person-${personId}-demographic-data-row-${fieldKey}`;
				};

				self.getColumnId = function (personId, fieldKey) {
					return `person-${personId}-demographic-data-column-${fieldKey}`;
				};

				function getColumnContentByFieldKey(fieldKey) {
					return self.getColumnId(self.person.encounterId, fieldKey);
				}

				function setFieldContent(fieldKey, content = '') {
					const contentKey = getColumnContentByFieldKey(fieldKey);
					self.columnContent[contentKey] = content;
				}

				self.getColumnContent = function (fieldKey) {
					if (Utils.isObjectEmpty(self.columnContent)) return;

					const contentKey = getColumnContentByFieldKey(fieldKey);
					return self.columnContent[contentKey];
				};

				self.getClass = function (a, b) {
					if (a && b) {
						// remove whitespaces
						const str1 = a.trim();
						const str2 = b.trim();

						const distance = JaroWinkler.Distance(str1, str2);
						if (distance < 1.0) {
							if (distance > 0.85 && isNaN(a)) {
								return 'text-warning';
							}
							return 'text-danger';
						}
					}
				};

				self.getGalleryNameById = (galleryId) => {
					if (self.galleries.length === 0) return;

					return GalleriesService.getGalleryNameById(galleryId, self.galleries);
				};

				self.getPanelContainerClass = function (index) {
					if (self.fieldsGroup.length === 1) {
						return '';
					}
					return index % 2 === 0 ? 'no-gutters-right' : 'no-gutters-left';
				};
			}]
		};
	}]);
