import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('SortHelper', () => {
		var SortHelper = function (defaultField, defaultReverse, reverse) {
			this.field = defaultField;
			this.reverse = reverse !== undefined ? reverse : defaultReverse;

			this._defaultField = defaultField;
			this._defaultReverse = defaultReverse;

			this.isNone = function (field) {
				return this.field !== field;
			};

			this.isAsc = function (field) {
				return this.field === field && !this.reverse;
			};

			this.isDesc = function (field) {
				return this.field === field && this.reverse;
			};

			this.sort = function (field) {
				if (this.field === field) {
					this.reverse = !this.reverse;
				} else {
					this.field = field;
					this.reverse = false;
				}
			};

			this.reset = function () {
				this.field = this._defaultField;
				this.reverse = this._defaultReverse;
			};
		};
		return {
			create(defaultField, defaultReverse, reverse) {
				return new SortHelper(defaultField, defaultReverse, reverse);
			}
		};
	});
