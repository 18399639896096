import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const isInDevMode = process.env.NODE_ENV === 'development';
const supportedLanguages = ['en_US'];
const DEFAULT_LNG = 'en_US';

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: DEFAULT_LNG,
		fallbackLng: supportedLanguages,
		debug: isInDevMode,
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: '/resources/locale-{{lng}}.json',
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
