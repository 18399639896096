import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.factory('TimelineTools', () => {
		const TimelineTools = {};

		TimelineTools.internalConfig = {};
		const defaultConfig = {
			colorfulBadges: false,
			showId: false,
			idName: 'transactions.report.id',
			showLegend: false,
			onEventContentRender: content => content
		};

		TimelineTools.colors = [
			{ backgroundColor: '#ffeda0', color: '#212529' },
			{ backgroundColor: '#feb24c', color: 'white' },
			{ backgroundColor: '#9ecae1', color: 'white' },
			{ backgroundColor: '#31a354', color: 'white' },
			{ backgroundColor: '#bcbddc', color: '#212529' },
			{ backgroundColor: '#756bb1', color: 'white' },
			{ backgroundColor: '#edf8b1', color: '#212529' },
			{ backgroundColor: '#7fcdbb', color: '#212529' },
			{ backgroundColor: '#a1d99b', color: '#212529' },
			{ backgroundColor: '#f7fcb9', color: '#212529' },
			{ backgroundColor: '#addd8e', color: '#212529' },
			{ backgroundColor: '#3182bd', color: 'white' },
			{ backgroundColor: '#fde0dd', color: '#212529' },
			{ backgroundColor: '#fa9fb5', color: '#212529' },
			{ backgroundColor: '#c51b8a', color: 'white' },
		];

		TimelineTools.elements = [];

		TimelineTools.elementsTypes = {
			CHECKPOINT: 'CHECKPOINT',
			EVENT: 'EVENT',
		};

		TimelineTools.updateConfig = function (newConfig) {
			Object.assign(TimelineTools.internalConfig, defaultConfig, newConfig);
		};

		TimelineTools.getColorByIndex = function (index) {
			return TimelineTools.colors[index % TimelineTools.colors.length];
		};

		function setColor(element, index) {
			element.backgroundColor = TimelineTools.getColorByIndex(index).backgroundColor;
			element.color = TimelineTools.getColorByIndex(index).color;
		}

		function setType(element, type) {
			element.type = type;
		}

		function processWithType(elementGroups, type) {
			if (elementGroups && elementGroups.length > 0) {
				const elementGroupsCopy = angular.copy(elementGroups);
				let processedElements = [];
				elementGroupsCopy.forEach((elements, groupIndex) => {
					elements.forEach((element) => {
						if (TimelineTools.internalConfig.colorfulBadges) {
							setColor(element, groupIndex);
						}
						setType(element, type);
						element.elementsGroupId = groupIndex;
					});
					processedElements = [...processedElements, ...elements];
				});
				return processedElements;
			}
			return [];
		}

		function getCheckpoints() {
			return processWithType(TimelineTools.checkpointGroups, TimelineTools.elementsTypes.CHECKPOINT);
		}

		function getEvents() {
			return processWithType(TimelineTools.eventGroups, TimelineTools.elementsTypes.EVENT);
		}

		function reasignElements() {
			TimelineTools.elements = [...getEvents(), ...getCheckpoints()]
				.sort((elementA, elementB) => (moment(elementA.changedAt).isAfter(moment(elementB.changedAt)) ? 1 : -1));
		}

		TimelineTools.onElementsChange = function (newEventGroups, newCheckpointGroups, newSeries) {
			if ((newEventGroups && newEventGroups.length > 0) || (newCheckpointGroups && newCheckpointGroups.length > 0)) {
				TimelineTools.checkpointGroups = newCheckpointGroups;
				TimelineTools.eventGroups = newEventGroups;
				TimelineTools.series = newSeries;
				reasignElements();
			}
		};

		return TimelineTools;
	});
