import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('customTranslationLoader', ['$http', '$q', function ($http, $q) {
		return function (options) {
			var deferred = $q.defer();

			var url = options.map[options.key];

			if (typeof url === 'object') {
				deferred.resolve(url);
				return deferred.promise;
			} else if (url != null) {
				$http(angular.extend({
					url,
					method: 'GET',
					params: ''
				}, options.$http)).then((result) => {
					deferred.resolve(result.data);
				}, () => {
					deferred.reject(options.key);
				});
			} else {
				deferred.reject(options.key);
			}

			return deferred.promise;
		};
	}]);
