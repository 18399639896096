import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CaptureFaceCtrl', ['$q', '$scope', '$timeout', 'AbisService', 'AlertService', 'FaceViewService', 'PainterService', 'SettingsService', 'StatusService', 'SubjectService', 'Utils', 'CapturerService', 'ParserResource', 'CapturePageService', 'IcaoStatusService', 'DSParametersSettingsService', 'AuthDataHolder',
		function ($q, $scope, $timeout, AbisService, AlertService, FaceViewService, PainterService, SettingsService, StatusService, SubjectService, Utils, CapturerService, ParserResource, CapturePageService, IcaoStatusService, DSParametersSettingsService, AuthDataHolder) {
			let device;
			$scope.isCapturing = false;
			$scope.isFinalStatus = CapturerService.isFinalStatus;
			$scope.isImageImported = IcaoStatusService.isPristine;

			$scope.iconClass = 'align-middle';
			$scope.headerIconClass = 'icon icon-fill-primary pull-left';

			$scope.isBlur = SettingsService.getShouldBlurImages().face;

			function previewFrame(base64Image) {
				if ($scope.frameUrl !== null && $scope.frameUrl !== undefined) {
					URL.revokeObjectURL($scope.frameUrl);
					$scope.frameUrl = null;
				}

				if (base64Image !== 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
					$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(base64Image, 'image/x-ms-bmp'));
					FaceViewService.refresh($scope.frameUrl, null, null, base64Image);
				}
			}

			StatusService.refresh()
				.catch((err) => {
					if (err.message) {
						AlertService.show(err.message, { type: 'danger', translate: true });
					}
				});
			$scope.$on('$viewContentLoaded', () => {
				$scope.actionPageName = AbisService.getRequestTypeReadable();
				$scope.$watch(() => StatusService.getCameraStatus(), (newValue) => {
					$scope.status = newValue;
				}, true);
				$scope.$watch(() => SubjectService.getFaceData(), (newImage) => {
					previewFrame(newImage);
				});
				$scope.$watch(() => CapturerService.getDevice('Face'), (newDevice) => {
					device = newDevice;
					$scope.isFocusSupported = device && device.methods.includes('focus');
				}, true);
				$scope.$watch(() => SettingsService.getCapturerServiceCameraMirrorView(), (newValue) => {
					$scope.capturerServiceCameraMirrorView = newValue;
				});
				$scope.$watch(() => IcaoStatusService.getIcaoStatuses(), (newValue) => {
					$scope.warnings = newValue;
				});
				FaceViewService.init('js-face-view');
			});

			$scope.$on('$destroy', () => {
				if ($scope.isCapturing) {
					$scope.stopCapture(false);
				}
			});

			function invalidate() {
				$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
				SubjectService.invalidate('faces');
				IcaoStatusService.invalidate();
				$scope.isCapturing = false;
			}

			$scope.focus = function () {
				if (device.methods.includes('focus')) {
					CapturerService.invoke(device.id, 'focus');
				}
			};

			$scope.forceCapture = function () {
				CapturerService.force(device.id);
			};

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			$scope.canvasClick = function (e) {
				if (device.methods.includes('focus')) {
					const element = e.target;
					const postion = element.getBoundingClientRect();
					const scaler = { x: element.width / postion.width, y: element.height / postion.height };
					const scaledPosition = { x: Math.floor(e.offsetX * scaler.x), y: Math.floor(e.offsetY * scaler.y) };
					const boxSize = 200;
					const focusBody = {
						x: scaledPosition.x - boxSize / 2,
						y: scaledPosition.y - boxSize / 2,
						width: boxSize,
						height: boxSize
					};
					FaceViewService.setFocusPoint(focusBody);
					CapturerService.invoke(device.id, 'focus', focusBody);
				}
			};
			function revokeAndNullUrl(url) {
				if (url !== null && url !== undefined) {
					URL.revokeObjectURL(url);
					url = null;
				}
				return url;
			}

			$scope.startCapture = function () {
				CapturerService.refreshDevices().then(() => {
					if ($scope.status !== 'SOURCE_MISSING') {
						invalidate();
						$q.all([
							CapturerService.registerAndLockDevice(device.id),
							(AuthDataHolder.hasAnyAuthority(['PERMISSION_ADMINISTRATION_PARAMETERS']) ? DSParametersSettingsService.getFacesCaptureConfiguration() : {}),
						])
							.then((res) => {
								const [deviceId, config] = res;
								// hardcoded to keep the same behaviour as before
								config['Faces.IcaoGlassesConfidenceThreshold'] = 100;
								config['Faces.IcaoHatConfidenceThreshold'] = 100;
								CapturerService.setConfiguration(deviceId, config)
									.then(() => {
										capture(device.id);
									}, () => {
										AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
									});
							}, (error) => {
								CapturerService.handleError(error, AlertService);
							});
					}
				});
				function capture(deviceId) {
					$scope.isCapturing = true;
					CapturerService.capture(deviceId)
						.then((result) => {
							CapturerService.download(deviceId, result.captureIds[0]).then((result) => {
								previewFrame(result.sensorData);
								$scope.status = 'PROCESS_NEXT_STEP';
								SubjectService.setFaceData(result.sensorData);
								$scope.isCapturing = false;
							}, () => {
								$scope.status = 'CAPTURE_ERROR';
								$scope.errorOccured = true;
								$scope.isCapturing = false;
							});
						}, (error) => {
							$scope.isCapturing = false;
							$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
							FaceViewService.clear();
							if (error.message) {
								AlertService.show(`capture-service.capture.status.${CapturerService.handleErrorMessage(error)}`, { type: 'info' });
							}
							$scope.status = CapturerService.handleError(error);
							$scope.errorOccured = true;
						}).finally(() => {
							CapturerService.unlock(device.id);
						});

					function preview(deviceId, etag) {
						$q.all([CapturerService.preview(deviceId), CapturerService.previewImage(deviceId, etag)])
							.then((response) => {
								const result = response[0];
								const face = response[1];

								if (CapturerService.isFinalStatus($scope.status) || $scope.errorOccured) {
									return;
								}
								$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
								$scope.status = 'IN_PROGRESS';
								$scope.frameUrl = URL.createObjectURL(face.image);
								FaceViewService.refresh($scope.frameUrl, result.icaoView, result.icaoWarnings);

								if (result.icaoWarnings) {
									IcaoStatusService.updateIcao(result.icaoWarnings);
								}

								preview(deviceId, face.etag);
							}, () => {
								$scope.errorOccured = false;
								if (!CapturerService.isFinalStatus($scope.status)) {
									$timeout(() => {
										preview(deviceId, '0');
									}, 100);
								}
							});
					}
					$scope.status = 'STARTING';
					preview(deviceId, '0');
				}
			};

			$scope.stopCapture = function (clearData) {
				if (clearData) {
					invalidate();
				}
				if (device && device.sessionId) {
					CapturerService.cancel(device.id)
						.then(() => {
							$scope.status = 'CANCELED';
						})
						.catch((response) => {
							if (response.status !== 'lockNotHeld') {
								throw new Error(response.status);
							}
						})
						.finally(() => {
							CapturerService.unlock(device.id);
						});
				}
			};

			$scope.biometricsPage = function () {
				CapturePageService.goBack();
			};

			$scope.importImage = function () {
				function setImportData(data) {
					SubjectService.setFaceData($scope.importImageFile.content);
					$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(data, 'image/x-ms-bmp'));
					AlertService.show('biometrics.import.imported', { type: 'success' });

					IcaoStatusService.invalidate();
				}
				if ($scope.importImageFile.type.match(/^image\//)) {
					setImportData($scope.importImageFile.content);
				} else {
					const data = $scope.importImageFile.content;
					ParserResource.parseImage({ data }, (result) => {
						setImportData(result.data);
					}, () => {
						AlertService.show('forms.invalid-image', { type: 'danger' });
					});
				}
			};

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.removeImage = function () {
				invalidate();
			};
		}]);
