import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('DSParametersSettingsService', ['$q', 'store', 'DeviceServerParametersService', 'Utils', 'DeviceServerParameters',
		function ($q, store, DeviceServerParametersService, Utils, DeviceServerParameters) {
			const self = this;
			const DEVICE_SERVER_STORAGE_KEY = 'device-server-parameters';
			let isLoading = false;
			const deviceServer = DeviceServerParameters;

			function save() {
				store.set(DEVICE_SERVER_STORAGE_KEY, deviceServer);
			}

			function load() {
				return angular.copy(store.get(DEVICE_SERVER_STORAGE_KEY));
			}

			function clear() {
				store.remove(DEVICE_SERVER_STORAGE_KEY);
			}

			function fetch() {
				const deferred = $q.defer();
				$q.all([
					DeviceServerParametersService.getFingersConfiguration(),
					DeviceServerParametersService.getFacesConfiguration()
				])
					.then((results) => {
						if (results.length !== 2) {
							deferred.reject();
							throw new Error('Not enough parameter groups');
						}

						deferred.resolve({
							fingers: results[0],
							faces: results[1]
						});
					})
					.catch(error => deferred.reject(error));
				return deferred.promise;
			}

			this.getParameters = function () {
				function moveParameters(newParameters) {
					newParameters.fingers.forEach((param) => {
						const fingerParamIdx = deviceServer.fingers.findIndex(fingerParam => fingerParam.key === param.key);
						if (fingerParamIdx !== -1) {
							deviceServer.fingers[fingerParamIdx] = Object.assign(deviceServer.fingers[fingerParamIdx], param);
						}
					});
					newParameters.faces.capture.forEach((param) => {
						const facesMainParamIdx = deviceServer.faces.capture.findIndex(facesParam => facesParam.key === param.key);
						if (facesMainParamIdx !== -1) {
							deviceServer.faces.capture[facesMainParamIdx] = Object.assign(deviceServer.faces.capture[facesMainParamIdx], param);
						}
					});
					newParameters.faces.icao.forEach((param) => {
						const facesIcaoParamIdx = deviceServer.faces.icao.findIndex(facesParam => facesParam.key === param.key);
						if (facesIcaoParamIdx !== -1) {
							deviceServer.faces.icao[facesIcaoParamIdx] = Object.assign(deviceServer.faces.icao[facesIcaoParamIdx], param);
						}
					});
				}

				isLoading = true;
				const deferred = $q.defer();
				const storeParameters = load();
				if (!storeParameters) {
					fetch()
						.then((results) => {
							moveParameters(results);
							save();
							deferred.resolve(deviceServer);
						})
						.catch((error) => {
							deferred.reject(error);
						})
						.finally(() => {
							isLoading = false;
						});
				} else {
					moveParameters(storeParameters);
					isLoading = false;
					deferred.resolve(deviceServer);
				}
				return deferred.promise;
			};

			const transformParameters = function (parameters) {
				const obj = {
					res: parameters,
					transformToConfiguration() {
						let groupedParameters;
						if (Array.isArray(this.res)) {
							groupedParameters = this.res;
						} else {
							groupedParameters = [];
							Object.entries(this.res).forEach((group) => {
								groupedParameters = groupedParameters.concat(group[1]);
							});
						}
						this.res = groupedParameters.reduce((acc, param) => {
							acc[param.key] = param.value;
							return acc;
						}, {});
						return this;
					},
					removeFingersPrefixes() {
						this.res = this.res.map((finger) => {
							[, finger.key] = finger.key.split('.');
							return finger;
						});
						return obj;
					},
					build() {
						return this.res;
					}
				};
				return obj;
			};

			this.getFacesCaptureConfiguration = function () {
				const deferred = $q.defer();
				const storeParameters = load();
				if (storeParameters) {
					deferred.resolve(transformParameters(storeParameters.faces)
						.transformToConfiguration()
						.build());
				} else {
					DeviceServerParametersService.getFacesConfiguration()
						.then((params) => {
							deferred.resolve(transformParameters(params)
								.transformToConfiguration()
								.build());
						});
				}
				return deferred.promise;
			};

			this.getFingersConfiguration = function () {
				const deferred = $q.defer();
				const storeParameters = load();
				if (storeParameters) {
					deferred.resolve(transformParameters(storeParameters.fingers)
						.removeFingersPrefixes()
						.transformToConfiguration()
						.build());
				} else {
					DeviceServerParametersService.getFingersConfiguration()
						.then((params) => {
							deferred.resolve(transformParameters(params)
								.removeFingersPrefixes()
								.transformToConfiguration()
								.build());
						});
				}
				return deferred.promise;
			};

			this.isParametersLoading = function () {
				return isLoading;
			};

			this.saveParameters = function () {
				save();
			};

			this.resetDeviceParameters = function () {
				clear();
				return this.getParameters();
			};

			function updateParameters() {
				const storeParameters = load();
				if (storeParameters) {
					self.resetDeviceParameters()
						.then((newParameters) => {
							angular.merge(newParameters.fingers, storeParameters.fingers);
							angular.merge(newParameters.faces.icao, storeParameters.faces.icao);
							angular.merge(newParameters.faces.capture, storeParameters.faces.capture);

							deviceServer.fingers = newParameters.fingers;
							deviceServer.faces.icao = newParameters.faces.icao;
							deviceServer.faces.capture = newParameters.faces.capture;
							save();

							isLoading = false;
						});
				}
			}

			this.checkForUpdate = function () {
				isLoading = true;
				DeviceServerParametersService.checkForUpdate()
					.then((response) => {
						if (!Utils.isObjectEmpty(response)) {
							updateParameters();
						} else {
							isLoading = false;
						}
					})
					.catch(() => {
						isLoading = false;
					});
			};

			this.discardParameterChanges = function (index, container) {
				if (index !== -1) {
					container[index].value = container[index].savedValue || container[index].defaultValue;
				}
			};

			this.setDefaultParameter = function (index, container) {
				if (index !== -1) {
					container[index].value = container[index].defaultValue;
				}
			};
		}]);
