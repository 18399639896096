import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('palms', () => ({
		template: require('../../views/directives/palms.html'),
		restrict: 'E',
		replace: true,
		scope: {
			palms: '=',
			palmsScore: '=',
			imgClick: '=',
			unknownPalmsCount: '=',
			showMatchingDetails: '=',
			noHover: '=',
			unknownOnly: '='
		},
		link(scope) {
			scope.iconClass = 'quality-icon';

			const palmOrder = {
				RIGHT: ['RIGHT_LOWER_PALM', 'RIGHT_UPPER_PALM', 'RIGHT_FULL_PALM', 'RIGHT_WRITERS_PALM'],
				LEFT: ['LEFT_LOWER_PALM', 'LEFT_UPPER_PALM', 'LEFT_FULL_PALM', 'LEFT_WRITERS_PALM'],
				UNKNOWN_PALM: ['UNKNOWN_PALM']
			};

			scope.handOrder = ['RIGHT', 'LEFT', 'UNKNOWN_PALM'];
			scope.rightPalmsOrder = ['RIGHT_LOWER_PALM', 'RIGHT_UPPER_PALM', 'RIGHT_FULL_PALM', 'RIGHT_WRITERS_PALM'];
			scope.leftPalmsOrder = ['LEFT_LOWER_PALM', 'LEFT_UPPER_PALM', 'LEFT_FULL_PALM', 'LEFT_WRITERS_PALM'];

			function update() {
				scope.palmOrder = {
					RIGHT: [],
					LEFT: [],
					UNKNOWN_PALM: []
				};

				for (let i = 0; i < scope.handOrder.length; i += 1) {
					const hand = scope.handOrder[i];
					for (let j = 0; j < palmOrder[hand].length; j += 1) {
						let found = false;
						const position = palmOrder[hand][j];
						if (scope.palms && scope.palms.length > 0) {
							for (let k = 0; k < scope.palms.length; k += 1) {
								const palmImage = scope.palms[k];
								if (position === palmImage.position) {
									scope.palmOrder[hand].push({
										position,
										index: k
									});
									found = true;
									if (position !== 'UNKNOWN_PALM') {
										break;
									}
								}
							}
						}
						if (!found && position !== 'UNKNOWN_PALM') {
							scope.palmOrder[hand].push({
								position,
								index: null
							});
						}
						if (!found && position === 'UNKNOWN_PALM' && scope.unknownPalmsCount > 0) {
							for (i = 1; i <= scope.unknownPalmsCount; i += 1) {
								scope.fingerOrder[hand].push({
									position: 'UNKNOWN_PALM',
									index: null
								});
							}
						}
					}
				}
			}

			scope.$watchGroup(['palms', 'unknownPalmsCount'], update, true);
		}
	}));
