import { AuthDataActionTypes, IAuthData, RESET_AUTH_DATA, UPDATE_AUTH_DATA } from './types';

export function UpdateAuthData(newMessage: IAuthData): AuthDataActionTypes {
	return {
		type: UPDATE_AUTH_DATA,
		payload: newMessage,
	};
}

export function ResetAuthData(): AuthDataActionTypes {
	return {
		type: RESET_AUTH_DATA,
	};
}
