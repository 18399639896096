import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('BiographicCtrl', ['$scope', '$translate', 'BiographicResource', '$window', '$timeout', 'TaskPollerService', 'BiographicFlags',
		function ($scope, $translate, BiographicResource, $window, $timeout, TaskPollerService, BiographicFlags) {
			var selectedID = 0;
			var editableID = -1;

			$scope.flags = BiographicFlags.flags;

			$scope.alerts = [];
			$scope.now = new Date();

			$scope.entry = {
				key: '',
				value: '',
			};

			$scope.fields = [];
			BiographicResource.get((r) => {
				r = r.map((field) => {
					if (field.values) {
						field.values = Object.entries(field.values).map(values => ({
							values,
							new: false
						}));
					}
					BiographicFlags.addFlags(field);
					return field;
				});
				$scope.fields = r;
				[$scope.field] = r;
			});

			function swap(a, b) {
				const temp = $scope.fields[b];
				$scope.fields[b] = $scope.fields[a];
				$scope.fields[a] = temp;
			}

			$scope.moveUp = function (index) {
				if (index === 0) return;
				swap(index, index - 1);
				selectedID -= 1;
			};

			$scope.moveDown = function (index) {
				if (index === $scope.fields.length - 1) return;
				swap(index, index + 1);
				selectedID += 1;
			};

			$scope.setField = function (f, index) {
				$scope.field = f;
				selectedID = index;
			};

			$scope.isActive = function (index) {
				return index === selectedID;
			};

			$scope.$watch('field.type', (nVal, oVal) => {
				// if (oVal === 'DATE' || oVal === 'DATE_TIME') return;
				if (nVal) {
					if (nVal === 'DATE') {
						if (!$scope.field.nistFormat) {
							$scope.field.nistFormat = 'yyyyMMdd';
						}

						if (!nVal.viewFormat) {
							$scope.field.viewFormat = 'yyyy-MM-dd';
						}

						if (oVal === 'DATE_TIME') {
							$scope.field.nistFormat = 'yyyyMMdd';
							$scope.field.viewFormat = 'yyyy-MM-dd';
						}
					}

					if (nVal === 'DATE_TIME') {
						if (!$scope.field.nistFormat) {
							$scope.field.nistFormat = 'yyyyMMddHHmmss';
						}

						if (!nVal.viewFormat) {
							$scope.field.viewFormat = 'yyyy-MM-dd HH:mm:ss';
						}

						if (oVal === 'DATE') {
							$scope.field.nistFormat = 'yyyyMMddHHmmss';
							$scope.field.viewFormat = 'yyyy-MM-dd HH:mm:ss';
						}
					}
				}
			}, true);

			$scope.$watch('entry', (nVal) => {
				if ($scope.entry.key === '') return;
				if (!$scope.field.values) {
					$scope.field.values = [];
				}
				$scope.field.values.push({
					values: [nVal.key, nVal.value],
					new: true
				});
				editableID = $scope.field.values.length - 1;

				$scope.entry.value = '';
				$scope.entry.key = '';

				$timeout(() => {
					const inputField = $window.document.getElementById(`t_${editableID}`);
					inputField.focus();
				}, 0);
			}, true);

			$scope.$watch('field', () => {
				editableID = -1;
			});

			$scope.submit = function () {
				if (!$scope.hasNoErrors()) return;

				const submitionData = angular.copy($scope.fields);
				submitionData.forEach((field) => {
					if (field.values) {
						field.values = field.values.map(({ values: [key, val] }) => ([key, val])).reduce((obj, [k, v]) => Object.assign(obj, { [k]: v }), {});
					}
					field.key = field.key.replace(/\s/g, '_');
					field.flags = BiographicFlags.reduce(field.flags);
					delete field.new;
				});

				const startMsg = 'Saving Biographic data...';
				const successMsg = 'Biographic data has been saved';
				$translate([startMsg, successMsg]).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						BiographicResource.set(submitionData, success, error);
						$scope.fields.forEach((field) => { delete field.new; });
					}, $scope.alerts, translations[startMsg], translations[successMsg]);
				});
			};

			$scope.isNewField = function (field) {
				return Object.prototype.hasOwnProperty.call(field || $scope.field, 'new');
			};

			$scope.add = function () {
				const field = {
					key: 'New Key',
					name: 'New Name',
					id: 0,
					type: 'STRING',
					flags: [],
				};
				BiographicFlags.addFlags(field);
				field.new = true;
				$scope.fields.push(field);
				selectedID = $scope.fields.push() - 1;
				$scope.field = $scope.fields[selectedID];
			};

			$scope.remove = function (index) {
				$scope.fields.splice(index, 1);
				if ($scope.fields.length === 0) {
					$scope.field = null;
				}
			};

			$scope.keys = {};

			$scope.keys.isEditable = function (index) {
				return (editableID === index);
			};

			$scope.keys.edit = function (index) {
				editableID = index;
			};

			$scope.keys.remove = function (index) {
				$scope.field.values.splice(index, 1);
			};

			$scope.keys.save = function () {
				editableID = -1;
			};

			$scope.getFlag = function (field, key) {
				return field.flags.filter(item => item[0] === key)[0];
			};

			$scope.invertFlag = function (field, key) {
				const t = $scope.getFlag(field, key);
				t[1] = !t[1];
				return t;
			};

			$scope.flagSection = function (flag, section) {
				const flags = Object.entries(BiographicFlags.flags);
				const f = flags.filter(f => f[0] === flag)[0];
				if (f) {
					return f[1].section === section;
				}
				return false;
			};

			$scope.checkUnique = function (value, key) {
				let count = 0;
				for (var i = 0; i < $scope.fields.length; i += 1) {
					if ($scope.fields[i][key] === value) {
						count += 1;
					}
				}
				return (count > 1);
			};

			$scope.checkUniqueEnum = function (value) {
				let count = 0;
				for (let i = 0; i < $scope.field.values.length; i += 1) {
					if ($scope.field.values[i].values[0] === value) {
						count += 1;
					}
				}
				return (count > 1);
			};

			$scope.checkAllUniqueEnum = function () {
				if (!$scope.field) return false;
				if (!$scope.field.values) {
					$scope.field.values = [];
				}
				for (let i = 0; i < $scope.field.values.length; i += 1) {
					if ($scope.checkUniqueEnum($scope.field.values[i].values[0])) {
						return false;
					}
				}
				return true;
			};

			$scope.getEditable = function () {
				return editableID;
			};

			$scope.hasNoErrors = function () {
				if ($scope.fields.length === 0) {
					return true;
				}

				const t = $scope.alerts;
				$scope.alerts = [];

				for (let i = 0; i < $scope.fields.length; i += 1) {
					if (!$scope.checkForErrors(i)) {
						return false;
					}
				}
				if (!$scope.checkAllUniqueEnum()) return false;

				$scope.alerts = t;

				return true;
			};

			$scope.checkEnumValues = function (t) {
				if (!$scope.field) return false;
				if (t.type === 'ENUM') {
					for (let i = 0; i < t.values.length; i += 1) {
						const temp = t.values[i].values;
						if (temp[0] === '') return false;
						if (temp[1] === '') return false;
					}
				}
				return true;
			};

			$scope.checkForErrors = function (index) {
				const t = $scope.fields[index];

				if (t.key === '') return false;
				if ($scope.checkUnique(t.key, 'key')) return false;

				if (t.id === '') return false;
				if (t.id < 3) return false;
				if (!t.id) return false;
				if (!$scope.isFieldNumber(t.id)) return false;
				if ($scope.checkUnique(t.id, 'id')) return false;

				if (t.name === '') return false;

				if (t.type === 'DATE' || t.type === 'DATE_TIME') {
					if (t.nistFormat === '') return false;
					if (t.viewFormat === '') return false;
				}

				if (t.type === 'ENUM') {
					if (!$scope.checkEnumValues(t)) return false;
					if (t.values.length === 0) {
						return false;
					}
				}
				return true;
			};

			$scope.isFieldNumber = function (id) {
				return id === null || typeof id === 'number';
			};
		}]);
