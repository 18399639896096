angular.module('neurotecAbisWebClientApp')
	.factory('ChartOptions', () => {
		const options = {};

		const baseOptions = {
			layout: {
				padding: {
					top: 5,
					bottom: 15
				}
			},
			title: {
				display: true,
				text: ''
			},
			tooltips: {
				enabled: false,
				custom: null,
			},
			legend: {
				display: true,
				position: 'top'
			},
			responsive: true,
			scales: {
				xAxes: [{}],
				yAxes: [{}]
			}
		};

		options.chartOptions = () => {
			const options = angular.copy(baseOptions);

			options.scales.xAxes[0] = {
				display: true,
				type: 'time',
				time: {
					tooltipFormat: 'MM/DD/YYYY HH:mm',
					displayFormats: {
						millisecond: 'HH:mm:ss.SSS',
						second: 'HH:mm:ss',
						minute: 'HH:mm',
						hour: 'HH:mm',
						day: 'MM/DD/YYYY',
					}
				},
				ticks: {
					display: true,
					autoSkip: true,
					maxTicksLimit: 20,
					barShowStroke: false
				}
			};
			options.scales.yAxes[0] = {
				ticks: {
					beginAtZero: true,
					autoSkip: true,
					maxTicksLimit: 20,
					barShowStroke: false,
					callback: (value) => {
						if (Number.isInteger(value)) {
							return value;
						}
					}
				}
			};

			return options;
		};

		options.pieOptions = () => angular.copy({
			maintainAspectRatio: true,
			legend: {
				display: true,
				position: 'top'
			},
			tooltips: {
				enabled: true,
			}
		});

		options.barOptions = () => {
			const options = angular.copy(baseOptions);
			options.maintainAspectRatio = true;
			options.layout.padding.top = 0;

			options.scales.xAxes[0] = {
				ticks: {
					autoSkip: false,
					maxRotation: 90,
					minRotation: 90,
					maxTicksLimit: 20,
					callback(label) {
						const splitLabel = label.split(' ');
						if (splitLabel.length >= 3) {
							const newLabel = [];
							for (let i = 0; i < splitLabel.length; i += 2) {
								if (splitLabel[i] && splitLabel[i + 1]) {
									newLabel.push(`${splitLabel[i]} ${splitLabel[i + 1]}`);
								} else {
									newLabel.push(splitLabel[i]);
								}
							}
							return newLabel;
						}
						return label;
					}
				}
			};
			options.scales.yAxes[0] = {
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10,
				}
			};

			delete options.legend;
			delete options.tooltips;

			return options;
		};

		options.matchingBarOptions = () => {
			const matchingOptions = options.barOptions();
			delete matchingOptions.scales.xAxes[0].ticks.callback;
			return matchingOptions;
		};

		options.termsOptions = () => {
			const termsOptions = angular.copy(baseOptions);

			Object.assign(termsOptions, {
				layout: {
					padding: {
						left: 10,
						right: 0,
						top: 10,
						bottom: 10
					}
				},
				tooltips: {
					enabled: true,
					position: 'nearest',
					callbacks: {
						label: (item) => {
							const dataPoint = item.yLabel;
							return `${dataPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
						}
					}
				}
			});

			termsOptions.scales.xAxes[0] = {
				ticks: {
					fontSize: 12,
					autoSkip: false,
					beginAtZero: true
				},
				time: {}
			};

			termsOptions.scales.yAxes[0] = {
				ticks: {
					beginAtZero: true,
					autoSkip: true,
					maxTicksLimit: 20,
					callback: (value) => {
						if (Number(value) >= 1000) {
							return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
						}
						return `${value.toFixed(2)}`;
					}
				}
			};

			return termsOptions;
		};

		return options;
	});
