import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('JaroWinkler', function () {
		this.p = 0.1;
		this.l = 4;
		this.bt = 0.7;

		/**
		 * Takes two string values and returns a value d
		 * between 0 and 1 indicating similarity
		 * @param a String to compare
		 * @param b String to compare
		 */
		this.Distance = function (a, b) {
			var s1 = a.length;
			var s2 = b.length;
			var m = this._matching(a, b);
			var t = this._transpositions(a, b);
			var p = this._prefix(a, b);

			// Handle length 1 strings. Not part of JW algorithm.
			if (s1 === 1 && s2 === 1 && a === b) {
				return 1;
			}

			if (m > 0) {
				// calculate the jaro distance
				var dj = (m / s1 + m / s2 + (m - t) / m) / 3;

				// return jaro if less than boost
				if (dj < this.bt) {
					return dj;
				}

				// transform to jaro winkler
				return (dj + (p * this.p * (1 - dj)));
			}
			return 0;
		};

		/**
		 * Calculate matching characters between strings
		 * @param a String to compare
		 * @param b String to compare
		 */
		this._matching = function (a, b) {
			var matches = 0;
			var bound = Math.floor(Math.max(a.length, b.length) / 2) - 1;

			var matched = [];

			for (var i = 0; i < a.length; i += 1) {
				for (var j = Math.max(0, i - bound); j <= Math.min(b.length, i + bound); j += 1) {
					if (a[i] === b[j] && !matched[j]) {
						matched[j] = true;
						matches += 1;
						break;
					}
				}
			}

			return matches;
		};

		/**
		 * Calculate the number of transpositions between the two words
		 * @param a String to compare
		 * @param b String to compare
		 */
		this._transpositions = function (a, b) {
			var t = 0;
			var bound = Math.floor(Math.max(a.length, b.length) / 2) - 1;

			var amatch = '';
			var bmatch = '';
			var matched = [];
			var i;
			var j;

			// get order of string matches between each word
			for (i = 0; i < a.length; i += 1) {
				for (j = Math.max(0, i - bound); j <= Math.min(b.length, i + bound); j += 1) {
					if (a[i] === b[j] && matched[j] == null) {
						amatch += a[i];
						matched[j] = true;
						break;
					}
				}
			}

			matched = [];
			for (i = 0; i < b.length; i += 1) {
				for (j = Math.max(0, i - bound); j <= Math.min(a.length, i + bound); j += 1) {
					if (b[i] === a[j] && matched[j] == null) {
						bmatch += b[i];
						matched[j] = true;
						break;
					}
				}
			}

			// get transpositions
			for (i = 0; i < amatch.length; i += 1) {
				if (amatch[i] !== bmatch[i]) {
					t += 1;
				}
			}

			return Math.floor(t / 2);
		};

		/**
		 * Counts the number of common characters at the beginning
		 * of each word up to a maximum of 4
		 * @param a String to compare
		 * @param b String to compare
		 */
		this._prefix = function (a, b) {
			var p = 0;

			for (p = 0; p < this.l; p += 1) {
				if (a[p] !== b[p]) {
					return p;
				}
			}

			p += 1;
			return p;
		};
	});
