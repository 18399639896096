import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('BaseUrlService', ['SettingsService', function (SettingsService) {
		this.getBaseCapturerServiceUrl = function () {
			return `http://127.0.0.1:${SettingsService.getCapturerServicePort()}`;
		};
		this.getCapturerServiceUrl = function () {
			return 'http://127.0.0.1:';
		};
		this.getBaseManagementUrl = function () {
			return '/mmabis/rs/client/v1';
		};
		this.getBaseOAuthUrl = function () {
			return '/oauth';
		};
		this.getBaseGraphiteUrl = function () {
			return '/graphite';
		};
		this.getBaseElasticsearchUrl = function () {
			return '/elasticsearch';
		};
	}]);
