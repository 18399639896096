import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('SessionMonitor', ['$rootScope', '$interval', '$state', '$uibModal', 'AuthDataHolder', 'AuthService', function ($rootScope, $interval, $state, $uibModal, AuthDataHolder, AuthService) {
		this._inited = false;
		this._promise = null;
		this._renewInProgress = false;
		this._lastUserActionTimestamp = 0;
		this._sessionModal = null;

		this._renewSession = function () {
			if (this._renewInProgress) {
				return;
			}
			this._renewInProgress = true;

			var _this = this;
			AuthService.renew().finally(() => {
				_this._renewInProgress = false;
			});
		};

		this._openSessionModal = function () {
			if (this._sessionModal != null) {
				return;
			}

			var scope = $rootScope.$new(true);

			this._sessionModal = $uibModal.open({
				template: require('../../../views/modal/session-expiring.html'),
				scope,
				controller: 'SessionExpiringCtrl',
				backdrop: 'static'
			});

			var _this = this;
			this._sessionModal.result
				.then((action) => {
					if (action === 'LOGOUT') {
						$rootScope.logoutReason = 'LOGOUT';
						AuthService.logout().finally(() => {
							$state.reload();
						});
					} else if (action === 'RENEW') {
						_this._renewSession();
					}
				})
				.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				}).finally(() => {
					_this._sessionModal = null;
				});
		};

		this._refresh = function () {
			if ($rootScope.logoutReason != null) {
				return;
			}

			if (!AuthDataHolder.isAuthenticated()) {
				return;
			}

			var now = new Date().getTime();
			var sessionLeftMillis = AuthDataHolder.getValidUntil() - now;
			if (sessionLeftMillis < 5 * 60 * 1000) {
				if (sessionLeftMillis < 1000) {
					$rootScope.logoutReason = 'SESSION_EXPIRED';
					AuthDataHolder.invalidate();
					$state.reload();
				} else if (this._sessionModal == null) {
					if (now - this._lastUserActionTimestamp < 5 * 60 * 1000) {
						this._renewSession();
					} else if (!this._renewInProgress) {
						this._openSessionModal();
					}
				}
			}
		};

		this._userActionListener = function () {
			this._lastUserActionTimestamp = new Date().getTime();
		};

		this.init = function () {
			if (this._inited) {
				return;
			}
			this._inited = true;

			var _this = this;
			this._promise = $interval(() => {
				_this._refresh();
			}, 1000);

			this._userActionListener();
			function userActionListener() {
				_this._userActionListener();
			}
			document.addEventListener('mousedown', userActionListener);
			document.addEventListener('keydown', userActionListener);
		};
	}]);
