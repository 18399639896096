import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('signature', ['ParserResource', 'Utils', 'SettingsService', function (ParserResource, Utils, SettingsService) {
		return {
			template: require('../../views/directives/signature.html'),
			restrict: 'E',
			replace: true,
			scope: {
				signature: '=',
				signaturesScore: '=',
				imgClick: '&',
				clickable: '=',
				showMatchingDetails: '='
			},
			link(scope, _element, attrs) {
				scope.isBlur = SettingsService.getShouldBlurImages().signature;

				scope.imageOnError = function () {
					if (scope.signature && scope.signature.image) {
						if (scope.parsedSignature !== null && scope.parsedSignature !== undefined) {
							URL.revokeObjectURL(scope.parsedSignature);
							scope.parsedSignature = null;
						}
						ParserResource.parseImage({ data: scope.signature.image }, (result) => {
							scope.parsedSignature = URL.createObjectURL(Utils.b64toBlob(result.data));
						});
					}
				};

				scope.hasImgClick = function () {
					return (angular.isDefined(attrs.imgClick) && scope.signature != null) &&
							scope.clickable &&
							scope.showMatchingDetails &&
							scope.signature.unclickable !== true;
				};

				scope.imgClickInternal = function () {
					if (scope.hasImgClick()) {
						scope.imgClick();
					}
				};
			}
		};
	}]);
