import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.controller('SimplePreviewModalCtrl', ['$scope', '$uibModalInstance', 'ParserResource', 'ExportUtils', 'SettingsService', function ($scope, $uibModalInstance, ParserResource, ExportUtils, SettingsService) {
		$scope.isBlur = SettingsService.getShouldBlurImages()[$scope.modality] || false;
		$scope.noExport = $scope.noExport === undefined ? false : $scope.noExport;
		$scope.imageOnError = function () {
			ParserResource.parseImage({ data: $scope.imageUrl.replace('data:image/png;base64,', '') }, (result) => {
				$scope.parsedImage = `data:image/png;base64,${result.data}`;
			});
		};
		$scope.getImageUrl()
			.then((imageUrl) => {
				$scope.imageUrl = imageUrl;
			});

		function imgFillSpace() {
			$('.simple-comparison-body > table img').each(function () {
				var img = $(this);
				var parent = img.parent();
				var widthDiff = parent.width() - this.naturalWidth;
				var heightDiff = parent.height() - this.naturalHeight;
				if (widthDiff < heightDiff) {
					if (img.hasClass('fill-height')) {
						img.removeClass('fill-height');
					}
					if (!img.hasClass('fill-width')) {
						img.addClass('fill-width');
					}
				} else {
					if (img.hasClass('fill-width')) {
						img.removeClass('fill-width');
					}
					if (!img.hasClass('fill-height')) {
						img.addClass('fill-height');
					}
				}
			});
		}

		$scope.downloadImage = function () {
			ExportUtils.downloadModality($scope.getImageUrl('ORIGINAL'), `${$scope.modality}_image_`);
		};

		$uibModalInstance.rendered.then(() => {
			imgFillSpace();
			$(window).on('resize', imgFillSpace);
		});
		$uibModalInstance.closed.then(() => {
			$(window).off('resize', imgFillSpace);
		});
	}]);
