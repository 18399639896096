import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ETagService', [function () {
		var self = this;
		self.etag = '';
		self.setETag = function (etag) {
			self.etag = etag;
		};
		self.getETag = function () {
			return self.etag;
		};
	}]);
