import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('CapturePageService', ['AbisService', '$state', function (AbisService, $state) {
		this.goBack = function () {
			const { type } = AbisService.getRequest();
			switch (type) {
			case 'ENROLL':
			case 'ENROLL_WITH_DUPLICATE_CHECK':
				$state.go('actions.enroll', { previousState: $state.params.previousState }, { location: 'replace' });
				break;
			case 'IDENTIFY':
				$state.go('actions.identify', { previousState: $state.params.previousState }, { location: 'replace' });
				break;
			case 'VERIFY':
				$state.go('actions.verify', { previousState: $state.params.previousState }, { location: 'replace' });
				break;
			case 'VERIFY_UPDATE':
			case 'UPDATE':
				$state.go('actions.update', { previousState: $state.params.previousState }, { location: 'replace' });
				break;
			default:
				$state.go('actions.home', { previousState: $state.params.previousState }, { location: 'replace' });
				break;
			}
		};
	}]);
