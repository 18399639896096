import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('LatentEditorCtrl', ['$scope', '$document', 'AlertService', 'blockUI', 'FingerImageResource', 'MinutiaDataResource', 'MinutiaResource', 'SubjectService', 'AbisService', 'SettingsService', 'GalleriesService',
		function ($scope, $document, AlertService, blockUI, FingerImageResource, MinutiaDataResource, MinutiaResource, SubjectService, AbisService, SettingsService, GalleriesService) {
			function loadCanvas() {
				editorCanvas = document.getElementById('editor-canvas');
				editorContext = editorCanvas.getContext('2d');
				mainCanvas = document.getElementById('main-canvas');
				mainContext = mainCanvas.getContext('2d');
				lineCanvas = document.getElementById('line-canvas');
				lineContext = lineCanvas.getContext('2d');
				minutiaeCanvas = document.getElementById('minutia-canvas-hires');
				minutiaeContext = minutiaeCanvas.getContext('2d');
				editorWindow = document.getElementById('editor-window');
				editorWindow.addEventListener('wheel', wheel);
				$scope.editor.loaded = true;
			}

			/* canvases */
			var editorCanvas = null;
			var editorContext = null;
			var mainCanvas = null;
			var mainContext = null;
			var lineCanvas = null;
			var lineContext = null;
			var minutiaeCanvas = null;
			var minutiaeContext = null;
			var image = document.getElementById('finger');
			var editorWindow = null;

			/* scope variables */
			$scope.mode = 'none';
			$scope.toolbarSelected = 'open';
			$scope.showInfo = true;
			$scope.infoAvailable = true;
			$scope.showOverlayAdjustment = true;

			$scope.levelsEnabled = true;

			$scope.minutiacontrol = {
				type: 'BIFURCATION',
				typeList: ['UNKNOWN', 'END', 'BIFURCATION', 'OTHER'],
				angle: 1,
				delete() {
					minutiaDeleteCurrent();
				}
			};

			$scope.changemode = {
				getMode: 'none',
				mode(arg) {
					$scope.mode = arg;
					this.getMode = arg;
				}
			};

			$scope.editor = {
				zoom: 100,
				loaded: false,
				show: false,
				showLines: false,
				showRidges: false,
				showMinutia: false,
				width: 0,
				height: 0,
				drawLine: false,
				drawing: false,
				polyline: '',
				reset() {
					this.showLines = ($scope.resolution.enabled);
					this.showMinutia = $scope.toolbarSelected === 'minutia';
				},
				clMove() {
					drawHelpLine();
				},
				clUp() {
					this.drawLine = false;
					if ($scope.resolution.enabled) {
						measureCalculate();
					}
					drawHelpLine();
				},
				minutiaAction(action) {
					minutiaAction(action);
				},
				mouseMove(e) {
					if (curDown && spacePressed) {
						editorWindow.scrollLeft += (curXPos - e.offsetX);
						editorWindow.scrollTop += (curYPos - e.offsetY);
					}
				},
				mouseDown(e) {
					curYPos = e.offsetY;
					curXPos = e.offsetX;
					curDown = true;
				},
				mouseUp() {
					curDown = false;
					if ($scope.resolution.enabled) {
						this.clUp();
					}
				}
			};

			$scope.coordinates = {
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0
			};

			$scope.recordedCoordinates = {
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
				reset() {
					this.x0 = 0;
					this.y0 = 0;
					this.x1 = 0;
					this.y1 = 0;
				}
			};

			$scope.zoom = {
				zoom: 100,
				enabled: false
			};

			$scope.adjustments = {
				brightness: 0,
				contrast: 0,
				invert: false,
				curveMove(action, x, y) {
					if (action === 'enable') {
						for (var i = 0; i < curvePoints.length; i += 1) {
							if (getDistance(x, y, curvePoints[i].x, curvePoints[i].y) < 20) {
								curveUnselect();
								curvePoints[i].selected = true;
								if (ctrlPressed) {
									curvePointRemove();
								}
							}
						}
						if (curveGetSelected() < 0 && !ctrlPressed) {
							curvePointAdd(x, y);
						}
						curvePoints.sort(compareX);
						curvePointsMoving = true;
					}
					if (action === 'move' && !ctrlPressed) {
						if (curveGetSelected() > -1 && curvePointsMoving) {
							curvePoints[curveGetSelected()].x = x;
							curvePoints[curveGetSelected()].y = y;
						}
						curvePoints[0].x = 0;
						curvePoints[curvePoints.length - 1].x = 255;
						curvePoints.sort(compareX);
					}
					if (action === 'disable') {
						curveUnselect();
						curvePointsMoving = false;
						levelsCalculate();
					}
					histogramDraw();
				},
				reset() {
					if ($scope.levelsEnabled) {
						curvePointsReset();
						$scope.adjustments.curveMove('update', 0, 0);
						levelsCalculate();
					}
					$scope.adjustments.brightness = 0;
					$scope.adjustments.contrast = 0;
					$scope.adjustments.invert = false;
				}
			};

			$scope.progress = {
				step: 0,
				click(action) {
					if (action === 'open') {
						$scope.toolbarSelected = 'open';
						$scope.progress.step = 0;
					}
					if (action === 'adjust') {
						$scope.toolbarSelected = 'adjust';
						$scope.canvasReset();
						$scope.progress.step = 1;
					}
					if (action === 'minutia') {
						$scope.toolbarSelected = 'minutia';
						$scope.canvasReset();
						$scope.progress.step = 2;
					}
				}
			};

			$scope.choices = {
				show: false,
				select(br, cr) {
					$scope.adjustments.brightness = br;
					$scope.adjustments.contrast = cr;
					$scope.canvasDrawAll();
					$scope.choices.show = false;
					$scope.file.show = false;
					$scope.editor.show = true;
					$scope.infoAvailable = true;
					$scope.toolbarSelected = 'adjust';
					$scope.mode = 'brightness';
					$scope.progress.step = 1;
				},
				preview() {
					function adjustment(brightness, contrast) {
						return { brightness, contrast };
					}
					var previews = [];
					previews.push(adjustment(0, 0));
					previews.push(adjustment(-10, 40));
					previews.push(adjustment(-20, -20));
					previews.push(adjustment(-10, 0));
					previews.push(adjustment(0, -30));
					previews.push(adjustment(20, 50));
					for (var i = 1; i <= previews.length; i += 1) {
						var localCanvas = document.getElementById(`prev${i}`);
						localCanvas.width = editorCanvas.width;
						localCanvas.height = editorCanvas.height;
						Object.assign($scope.adjustments, previews[i - 1]);
						$scope.canvasDrawAll();
						localCanvas.getContext('2d').drawImage(editorCanvas, 0, 0);
					}
					$scope.choices.show = true;
					$scope.editor.show = false;
				}
			};

			$scope.file = {
				show: true,
				change() {
					loadCanvas();
					var file = document.getElementById('file-upload-button').files[0];
					var reader = new FileReader();
					reader.onload = function () {
						$scope.fileContent = reader.result;
						$scope.submitWSQImage(reader.result);
					};
					if (file) {
						reader.readAsDataURL(file);
					}
				}
			};

			$scope.resolution = {
				horizontal: 500,
				vertical: 500,
				length: 1,
				units: ['inches', 'centimeters'],
				unit: 'centimeters',
				enabled: false,
				button() {
					this.enabled = !this.enabled;
					resetButtonStyle();
				},
				buttonSet() {
					var btn = document.getElementById('resolution-button');
					if (btn) {
						btn.className = this.enabled === true ? 'btn btn-danger' : 'btn btn-primary';
					}
				}
			};

			var minutia = [];
			var cores = [];
			var deltas = [];
			var doublecores = [];
			var hoveredMinutia = -1;
			var selectedMinutia = -1;
			var levels = [];
			var histogramLevels = [];
			var minutiaRecord = null;

			/* minutia actions */
			function minutiaAction(action) {
				if (!spacePressed) {
					if (action === 'click' && $scope.mode === 'add_minutia') {
						minutiaAdd();
						selectedMinutia = minutia.length - 1;
						minutiaCanvasRedraw();
					}
					if (action === 'move' && ($scope.mode === 'add_minutia' || $scope.mode === 'edit_minutia')) {
						if (ctrlPressed) {
							minutiaRotate();
						} else {
							minutiaMove();
						}
						minutiaCanvasRedraw();
					}
					if (action === 'up' && ($scope.mode === 'add_minutia' || $scope.mode === 'rotate_minutia' || $scope.mode === 'delete_minutia' || $scope.mode === 'edit_minutia')) {
						if ($scope.mode !== 'edit_minutia') {
							minutiaUnselect();
						}
						minutiaUnhover();
						minutiaCanvasRedraw();
					}
					if (action === 'move' && ($scope.mode === 'rotate_minutia' || $scope.mode === 'delete_minutia' || $scope.mode === 'edit_minutia')) {
						minutiaHover();
						if ($scope.mode === 'rotate_minutia') {
							minutiaRotate();
						}
						minutiaCanvasRedraw();
					}
					if (action === 'click' && ($scope.mode === 'rotate_minutia' || $scope.mode === 'delete_minutia' || $scope.mode === 'edit_minutia')) {
						minutiaSelect();
						if ($scope.mode === 'delete_minutia') {
							minutiaDelete();
							minutiaUnhover();
							minutiaUnselect();
						}
						minutiaCanvasRedraw();
					}
				}
			}

			/* histogram */
			function CurvePoint(x, y, selected) {
				if (!(this instanceof CurvePoint)) {
					return new CurvePoint(x, y, selected);
				}
				this.x = x;
				this.y = y;
				this.selected = selected;
			}
			var curvePoints = [];

			var curvePointsMoving = false;
			curvePoints[0] = new CurvePoint(0, 255, false);
			curvePoints[1] = new CurvePoint(255, 0, false);

			function curveUnselect() {
				for (var i = 0; i < curvePoints.length; i += 1) {
					curvePoints[i].selected = false;
				}
			}

			function curvePointsReset() {
				curvePoints = [];
				curvePoints[0] = new CurvePoint(0, 255, false);
				curvePoints[1] = new CurvePoint(255, 0, false);
			}

			function curvePointRemove() {
				var index = curveGetSelected();
				if (index !== 0 && index !== curvePoints.length - 1) {
					curvePoints.splice(index, 1);
				}
			}

			function curveGetSelected() {
				var selected = -1;
				for (var i = 0; i < curvePoints.length; i += 1) {
					if (curvePoints[i].selected) {
						selected = i;
					}
				}
				return selected;
			}

			function curvePointAdd(x, y) {
				curvePoints[curvePoints.length] = new CurvePoint(x, y, true);
			}

			function compareX(a, b) {
				if (a.x < b.x) {
					return -1;
				}
				if (a.x > b.x) {
					return 1;
				}
				return 0;
			}

			function histogramDraw() {
				var canvas = document.getElementById('curve-canvas');
				var context = canvas.getContext('2d');
				context.clearRect(0, 0, 255, 255);
				var i;
				for (i = 0; i < 256; i += 1) {
					context.beginPath();
					context.moveTo(i, 255);
					context.lineTo(i, 255 - histogramLevels[i]);
					context.lineWidth = 1;
					context.strokeStyle = '#999999';
					context.stroke();
				}
				context.beginPath();
				context.moveTo(0, 255);
				context.lineTo(255, 0);
				context.lineWidth = 1;
				context.strokeStyle = '#666666';
				context.stroke();
				for (i = 0; i < curvePoints.length - 1; i += 1) {
					context.beginPath();
					context.moveTo(curvePoints[i].x, curvePoints[i].y);
					context.lineTo(curvePoints[i + 1].x, curvePoints[i + 1].y);
					context.lineWidth = 1;
					context.strokeStyle = '#000000';
					context.stroke();
				}
				for (i = 0; i < curvePoints.length; i += 1) {
					context.beginPath();
					context.arc(curvePoints[i].x, curvePoints[i].y, 4, 0, 2 * Math.PI, false);
					context.lineWidth = 1;
					context.strokeStyle = '#FF0000';
					context.stroke();
				}
			}

			function levelsCalculate() {
				var step;
				for (let i = 0; i < 256; i += 1) {
					levels[i] = i;
				}
				levels[0] = (255 - curvePoints[0].y);
				for (let i = 0; i < curvePoints.length - 1; i += 1) {
					step = -((curvePoints[i + 1].y - curvePoints[i].y) / (curvePoints[i + 1].x - curvePoints[i].x));
					for (var j = curvePoints[i].x; j < curvePoints[i + 1].x; j += 1) {
						levels[j + 1] = levels[j] + step;
					}
				}
				for (let i = 0; i < 256; i += 1) {
					levels[i] = Math.round(levels[i]);
					if (levels[i] < 0) {
						levels[i] = 0;
					}
					if (levels[i] > 255) {
						levels[i] = 255;
					}
				}
				$scope.canvasDrawAll();
			}

			function histogramCalculate() {
				for (let i = 0; i < 256; i += 1) {
					histogramLevels[i] = 0;
				}
				var imgData = mainContext.getImageData(0, 0, mainCanvas.width, mainCanvas.height).data;
				var value;
				for (let i = 0; i < imgData.length; i += 4) {
					value = Math.floor((imgData[i] + imgData[i] + imgData[i]) / 3);
					histogramLevels[value] += 1;
				}
				var max = 0;
				for (let i = 0; i < 256; i += 1) {
					if (histogramLevels[i] > max) {
						max = histogramLevels[i];
					}
				}
				for (let i = 0; i < 256; i += 1) {
					histogramLevels[i] = Math.floor(histogramLevels[i] / max * 255);
				}
			}

			/* adjustments */
			$scope.canvasDrawAll = function () {
				editorContext.drawImage(mainCanvas, 0, 0);
				canvasDrawInvert();
				canvasDrawLevels();
				canvasDrawBrightness();
				canvasDrawContrast();
			};

			function canvasDrawInvert() {
				if ($scope.adjustments.invert) {
					var imgData = editorContext.getImageData(0, 0, editorCanvas.width, editorCanvas.height);
					for (var i = 0; i < imgData.data.length; i += 4) {
						imgData.data[i] = 255 - imgData.data[i];
						imgData.data[i + 1] = 255 - imgData.data[i + 1];
						imgData.data[i + 2] = 255 - imgData.data[i + 2];
					}
					editorContext.putImageData(imgData, 0, 0);
				}
			}

			function canvasDrawLevels() {
				var imgData = editorContext.getImageData(0, 0, editorCanvas.width, editorCanvas.height);
				for (var i = 0; i < imgData.data.length; i += 4) {
					imgData.data[i] = levels[imgData.data[i]];
					imgData.data[i + 1] = levels[imgData.data[i + 1]];
					imgData.data[i + 2] = levels[imgData.data[i + 2]];
				}
				editorContext.putImageData(imgData, 0, 0);
			}

			function canvasDrawContrast() {
				var con = Number($scope.adjustments.contrast);
				var imgData = editorContext.getImageData(0, 0, editorCanvas.width, editorCanvas.height);
				for (var i = 0; i < imgData.data.length; i += 4) {
					imgData.data[i] *= ((con + 50) / 50);
					imgData.data[i + 1] *= ((con + 50) / 50);
					imgData.data[i + 2] *= ((con + 50) / 50);
				}
				editorContext.putImageData(imgData, 0, 0);
			}

			function canvasDrawBrightness() {
				var bri = Number($scope.adjustments.brightness);
				var color = [255, 255, 255, Math.abs(bri) / 50];
				if (bri < 0) {
					color = [0, 0, 0, Math.abs(bri) / 50];
				}
				editorContext.beginPath();
				editorContext.rect(0, 0, editorCanvas.width, editorCanvas.height);
				editorContext.fillStyle = `rgba(${color})`;
				editorContext.fill();
			}

			function drawHelpLine() {
				if ($scope.resolution.enabled && !spacePressed) {
					if (curDown) {
						lineContext.clearRect(0, 0, $scope.editor.width, $scope.editor.height);
						lineContext.beginPath();
						lineContext.moveTo($scope.coordinates.x0, $scope.coordinates.y0);
						lineContext.lineTo($scope.coordinates.x1, $scope.coordinates.y1);
						lineContext.lineWidth = 3;
						lineContext.strokeStyle = '#FF0000';
						lineContext.stroke();
					} else {
						lineContext.clearRect(0, 0, $scope.editor.width, $scope.editor.height);
					}
				}
			}

			$scope.canvasReset = function () {
				mainCanvas.width = image.width;
				mainCanvas.height = image.height;
				editorCanvas.width = image.width;
				editorCanvas.height = image.height;
				$scope.editor.width = image.width;
				$scope.editor.height = image.height;
				mainContext.drawImage(image, 0, 0);
				editorContext.drawImage(mainCanvas, 0, 0);
				$scope.adjustments.reset();
				histogramCalculate();
			};

			function measureCalculate() {
				if ($scope.resolution.enabled && !spacePressed) {
					var verLgt = Math.abs($scope.coordinates.y1 - $scope.coordinates.y0);
					var horLgt = Math.abs($scope.coordinates.x1 - $scope.coordinates.x0);
					var unit = 1;
					if ($scope.resolution.unit === 'centimeters') {
						unit = 2.54;
					}
					$scope.resolution.horizontal = Math.round((Math.sqrt((verLgt * verLgt) + (horLgt * horLgt))) / $scope.resolution.length * unit);
					$scope.resolution.vertical = Math.round((Math.sqrt((verLgt * verLgt) + (horLgt * horLgt))) / $scope.resolution.length * unit);
				}
			}

			/* minutia */
			function minutiaCanvasRedraw() {
				minutiaeContext.clearRect(0, 0, minutiaeCanvas.width, minutiaeCanvas.height);
				if (selectedMinutia > -1) {
					minutiaeContext.beginPath();
					minutiaeContext.arc(hrCoordinate(minutia[selectedMinutia].x), hrCoordinate(minutia[selectedMinutia].y), 6, 0, 2 * Math.PI, false);
					minutiaeContext.lineWidth = 1;
					minutiaeContext.strokeStyle = '#00FFFF';
					minutiaeContext.stroke();
				}
				if (hoveredMinutia > -1) {
					minutiaeContext.beginPath();
					minutiaeContext.arc(hrCoordinate(minutia[hoveredMinutia].x), hrCoordinate(minutia[hoveredMinutia].y), 6, 0, 2 * Math.PI, false);
					minutiaeContext.lineWidth = 1;
					minutiaeContext.strokeStyle = '#FFFFFF';
					minutiaeContext.stroke();
				}
				var i;
				for (i = 0; i < minutia.length; i += 1) {
					minutiaDraw(hrCoordinate(minutia[i].x), hrCoordinate(minutia[i].y), minutia[i].type, minutia[i].angle);
				}
				for (i = 0; i < deltas.length; i += 1) {
					deltaDraw(deltas[i].x, deltas[i].y, deltas[i].angle1, deltas[i].angle2, deltas[i].angle3);
				}
				for (i = 0; i < cores.length; i += 1) {
					coreDraw(cores[i].x, cores[i].y, cores[i].angle);
				}
				for (i = 0; i < doublecores.length; i += 1) {
					doublecoreDraw(doublecores[i].x, doublecores[i].y, doublecores[i].angle);
				}
			}

			function doublecoreDraw(x, y, angle) {
				minutiaeContext.beginPath();
				minutiaeContext.arc(x, y, hrCoordinate(8), 0, 2 * Math.PI, false);
				minutiaeContext.lineWidth = 1;
				minutiaeContext.strokeStyle = '#FFFFFF';
				minutiaeContext.stroke();
				var px = hrCoordinate(10) * Math.cos(angle);
				var py = hrCoordinate(10) * Math.sin(angle);
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x, y);
				minutiaeContext.lineTo(x + px, y + py);
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
			}

			function coreDraw(x, y, angle) {
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x + hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineTo(x + hrCoordinate(5), y - hrCoordinate(5));
				minutiaeContext.lineTo(x - hrCoordinate(5), y - hrCoordinate(5));
				minutiaeContext.lineTo(x - hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineTo(x + hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineWidth = 1;
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
				var px = hrCoordinate(10) * Math.cos(angle);
				var py = hrCoordinate(10) * Math.sin(angle);
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x, y);
				minutiaeContext.lineTo(x + px, y + py);
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
			}

			function deltaDraw(x, y, angle1, angle2, angle3) {
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x + hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineTo(x, y - 5);
				minutiaeContext.lineTo(x - hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineTo(x + hrCoordinate(5), y + hrCoordinate(5));
				minutiaeContext.lineWidth = 1;
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
				var px = hrCoordinate(10) * Math.cos(angle1);
				var py = hrCoordinate(10) * Math.sin(angle1);
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x, y);
				minutiaeContext.lineTo(x + px, y + py);
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
				px = hrCoordinate(10) * Math.cos(angle2);
				py = hrCoordinate(10) * Math.sin(angle2);
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x, y);
				minutiaeContext.lineTo(x + px, y + py);
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
				px = hrCoordinate(10) * Math.cos(angle3);
				py = hrCoordinate(10) * Math.sin(angle3);
				minutiaeContext.beginPath();
				minutiaeContext.moveTo(x, y);
				minutiaeContext.lineTo(x + px, y + py);
				minutiaeContext.strokeStyle = 'rgba(255,255,255,0.7)';
				minutiaeContext.stroke();
			}

			function minutiaDraw(x, y, type, angle) {
				minutiaeContext.beginPath();
				minutiaeContext.arc(x, y, hrCoordinate(4), 0, 2 * Math.PI, false);
				minutiaeContext.lineWidth = 1;
				minutiaeContext.strokeStyle = '#FFFFFF';
				minutiaeContext.stroke();
				var px = hrCoordinate(10) * Math.cos(angle);
				var py = hrCoordinate(10) * Math.sin(angle);
				if (type === 'BIFURCATION') {
					px = hrCoordinate(10) * Math.cos(angle - 0.5);
					py = hrCoordinate(10) * Math.sin(angle - 0.5);
					minutiaeContext.beginPath();
					minutiaeContext.moveTo(x, y);
					minutiaeContext.lineTo(x + px, y + py);
					minutiaeContext.strokeStyle = '#FFFFFF';
					minutiaeContext.stroke();
					px = hrCoordinate(10) * Math.cos(angle + 0.5);
					py = hrCoordinate(10) * Math.sin(angle + 0.5);
					minutiaeContext.beginPath();
					minutiaeContext.moveTo(x, y);
					minutiaeContext.lineTo(x + px, y + py);
					minutiaeContext.strokeStyle = '#FFFFFF';
					minutiaeContext.stroke();
				}
				if (type === 'END') {
					minutiaeContext.beginPath();
					minutiaeContext.moveTo(x, y);
					minutiaeContext.lineTo(x + px, y + py);
					minutiaeContext.strokeStyle = '#FFFFFF';
					minutiaeContext.stroke();
				}
			}

			function minutiaDeleteCurrent() {
				if (selectedMinutia > -1) {
					minutiaDelete();
					minutiaUnselect();
					minutiaCanvasRedraw();
				}
			}

			function minutiaSelect() {
				if (hoveredMinutia > -1) {
					selectedMinutia = hoveredMinutia;
					if ($scope.mode === 'edit_minutia') {
						$scope.minutiacontrol.type = minutia[selectedMinutia].type;
						$scope.minutiacontrol.angle = Math.round(minutia[selectedMinutia].angle / Math.PI * 180);
					}
				}
			}

			function minutiaUnselect() {
				selectedMinutia = -1;
			}
			function minutiaUnhover() {
				hoveredMinutia = -1;
			}

			function minutiaHover() {
				minutiaUnhover();
				var lowestDistance = 10;
				var dist;
				for (var i = 0; i < minutia.length; i += 1) {
					dist = getDistance(minutia[i].x, minutia[i].y, $scope.coordinates.x0, $scope.coordinates.y0);
					if (dist < lowestDistance && dist < 10) {
						hoveredMinutia = i;
						lowestDistance = dist;
					}
				}
			}

			function minutiaMove() {
				if (selectedMinutia > -1 && $scope.editor.drawing) {
					minutia[selectedMinutia].x = $scope.coordinates.x0;
					minutia[selectedMinutia].y = $scope.coordinates.y0;
				}
			}

			function minutiaRotate() {
				if (selectedMinutia > -1 && $scope.editor.drawing) {
					minutia[selectedMinutia].angle = getAngle(minutia[selectedMinutia].x, minutia[selectedMinutia].y, $scope.coordinates.x0, $scope.coordinates.y0, 'positive');
				}
			}

			function minutiaDelete() {
				if (selectedMinutia > -1) {
					minutia.splice(selectedMinutia, 1);
					minutiaUnselect();
					minutiaUnhover();
				}
			}

			function minutiaAdd() {
				var newMinutia = {};
				newMinutia.x = $scope.coordinates.x0;
				newMinutia.y = $scope.coordinates.y0;
				newMinutia.type = $scope.minutiacontrol.type;
				newMinutia.angle = getAngle($scope.coordinates.x0, $scope.coordinates.y0, $scope.coordinates.x1, $scope.coordinates.y1, 'positive');
				minutia[minutia.length] = newMinutia;
			}

			/* prepare canvases */

			function minutiaCanvasPrepare() {
				minutiaeCanvas.width = hrCoordinate($scope.editor.width);
				minutiaeCanvas.height = hrCoordinate($scope.editor.height);
				minutiaCanvasRedraw();
			}

			function downloadURI(uri, name) {
				var link = document.getElementById('download-file');
				link.download = name;
				link.href = uri;
				link.click();
			}

			/* submit */
			$scope.fileChange = function (element) {
				var file = element.files[0];
				var reader = new FileReader();
				reader.onload = function () {
					$scope.fileContent = reader.result;
					$scope.submitWSQImage(reader.result);
				};
				if (file) {
					reader.readAsDataURL(file);
				}
			};

			$scope.submitImage = function () {
				function resetResolutionParameters() {
					$scope.resolution.horizontal = 500;
					$scope.resolution.vertical = 500;
					$scope.resolution.enabled = false;
					resetButtonStyle();
				}
				function convertEditorCanvasSize() {
					editorCanvas.width = editorCanvas.width * 500 / $scope.resolution.horizontal;
					editorCanvas.height = editorCanvas.height * 500 / $scope.resolution.vertical;
					$scope.editor.width = editorCanvas.width;
					$scope.editor.height = editorCanvas.height;
				}
				function evenMainCanvas() {
					mainCanvas.width = editorCanvas.width;
					mainCanvas.height = editorCanvas.height;
				}
				function handleRejection() {
					$scope.progress.step = 0;
					$scope.mode = 'open_file';
					$scope.toolbarSelected = 'open';
					$scope.mode = 'none';
				}

				blockUI.start('app.loading');
				$scope.editor.show = false;
				var data = {};

				evenMainCanvas();
				mainContext.drawImage(editorCanvas, 0, 0);

				convertEditorCanvasSize();
				editorContext.drawImage(mainCanvas, 0, 0, editorCanvas.width, editorCanvas.height);

				resetResolutionParameters();
				if (editorCanvas.width > 1 && editorCanvas.height > 1) {
					data.imageData = editorCanvas.toDataURL();
					data.width = editorCanvas.width;
					data.height = editorCanvas.height;
					data.horizontalResolution = 500;
					data.verticalResolution = 500;
					data = MinutiaResource.sendImg(data, (object) => {
						blockUI.stop();
						$scope.editor.show = true;
						minutia = object.outputData || [];
						cores = object.outputCoreData || [];
						deltas = object.outputDeltaData || [];
						doublecores = object.outputDoublecoreData || [];
						minutiaCanvasPrepare();
						$scope.progress.step = 2;
						$scope.toolbarSelected = 'minutia';
						$scope.mode = 'add_minutia';
					}, () => {
						blockUI.stop();
						AlertService.show('latent-print-editor.alert.submission-fail', { type: 'danger' });
						handleRejection();
					});
				} else {
					AlertService.show('latent-print-editor.alert.select-image', { type: 'danger' });
					handleRejection();
				}
			};

			$scope.submitWSQImage = function (img) {
				blockUI.start('app.loading');
				$scope.editor.show = false;
				var data = {};
				data.imageData = img;
				data = FingerImageResource.sendFingerImage(data, (object) => {
					image.src = `data:image/png;base64,${object.outputData}`;
					image.onload = function () {
						blockUI.stop();
						$scope.editor.show = true;
						$scope.choices.show = true;
						$scope.canvasReset();
						$scope.choices.preview();
					};
				}, () => {
					blockUI.stop();
					AlertService.show('latent-print-editor.alert.submission-fail', { type: 'danger' });
					$scope.editor.show = true;
				});
			};

			$scope.submitMinutia = function () {
				blockUI.start('app.loading');
				$scope.editor.show = false;
				var data = {};
				var ridgeContext = document.getElementById('buffer-canvas');
				data.imageData = editorCanvas.toDataURL();
				data.ridgeData = ridgeContext.toDataURL();
				data.minutiaIn = minutia;
				data.width = editorCanvas.width;
				data.height = editorCanvas.height;
				data.horizontalResolution = $scope.resolution.horizontal;
				data.verticalResolution = $scope.resolution.vertical;
				data = MinutiaDataResource.sendMinutiaData(
					data,
					(object) => {
						blockUI.stop();
						$scope.editor.show = true;
						$scope.toolbarSelected = 'minutia';
						minutiaRecord = object.recordOut;
						minutiaRecord = `data:application/octet-stream;base64,${minutiaRecord}`;
						downloadURI(minutiaRecord, 'output.NFRecord');
					}, () => {
						AlertService.show('latent-print-editor.alert.submission-fail', { type: 'danger' });
						blockUI.stop();
						$scope.editor.show = true;
					}
				);
			};

			/* gui control */
			function resetButtonStyle() {
				$scope.resolution.buttonSet();
				$scope.editor.reset();
			}

			function wheel(e) {
				if (e.deltaY > 0) {
					$scope.zoomOut();
				}
				if (e.deltaY < 0) {
					$scope.zoomIn();
				}
				e.preventDefault();
				$scope.$apply();
			}

			$scope.zoomIn = function () {
				$scope.zoom.zoom += 5;
				if ($scope.zoom.zoom > 300) {
					$scope.zoom.zoom = 300;
				}
			};

			$scope.zoomOut = function () {
				$scope.zoom.zoom -= 5;
				if ($scope.zoom.zoom < 20) {
					$scope.zoom.zoom = 2;
				}
			};

			/* scrolling canvas */
			var curYPos;
			var curXPos;
			var curDown;

			/* math */

			function getAngle(x1, y1, x2, y2, arg) {
				var dx = x2 - x1;
				var dy = y2 - y1;
				var angle = Math.atan2(dy, dx);
				if (arg === 'positive') {
					if (angle < 0) {
						angle += (2 * Math.PI);
					}
					if (angle > 2 * Math.PI) {
						angle -= (2 * Math.PI);
					}
				}
				if (arg === 'degrees') {
					angle = angle / Math.PI * 180;
				}
				return angle;
			}

			function getDistance(x1, y1, x2, y2) {
				var dx = x2 - x1;
				var dy = y2 - y1;
				var distance = Math.sqrt(dx * dx + dy * dy);
				return distance;
			}

			/* hi-res tools */
			function hrCoordinate(x) {
				return x / 100 * $scope.zoom.zoom;
			}

			/* scope tools */
			function setMode(mode) {
				$scope.mode = mode;
			}

			/* scope watch */
			$scope.$watchGroup(['adjustments.brightness', 'adjustments.contrast', 'adjustments.invert'], () => {
				if ($scope.editor.loaded) {
					$scope.canvasDrawAll();
				}
			});

			$scope.$watch('mode', () => {
				resetButtonStyle();
				$scope.editor.reset();
			});

			$scope.$watch('zoom.zoom', () => {
				if ($scope.zoom.enabled) {
					$scope.editor.zoom = $scope.zoom.zoom;
					minutiaeCanvas.width = hrCoordinate($scope.editor.width);
					minutiaeCanvas.height = hrCoordinate($scope.editor.height);
					if ($scope.toolbarSelected === 'minutia') {
						minutiaCanvasRedraw();
					}
				}
			});

			$scope.$watch('toolbarSelected', () => {
				if ($scope.toolbarSelected === 'open') {
					$scope.file.show = true;
					$scope.zoom.enabled = false;
				}
				if ($scope.toolbarSelected === 'adjust') {
					$scope.zoom.enabled = true;
					histogramDraw();
				}
				$scope.resolution.enabled = false;
				$scope.editor.reset();
			});

			$scope.$watch('minutiacontrol.type', () => {
				if (selectedMinutia > -1) {
					minutia[selectedMinutia].type = $scope.minutiacontrol.type;
					minutiaCanvasRedraw();
				}
			});

			$scope.$watch('minutiacontrol.angle', () => {
				if (selectedMinutia > -1) {
					minutia[selectedMinutia].angle = $scope.minutiacontrol.angle / 180 * Math.PI;
					minutiaCanvasRedraw();
				}
			});

			/* keyboard	 */
			var ctrlPressed = false;
			var spacePressed = false;

			function keyboardActions(event, action) {
				const key = event.keyCode;
				if (action === 'down') {
					if (key === 46 && $scope.mode === 'edit_minutia') {
						minutiaDelete();
						minutiaCanvasRedraw();
					}
					if (key === 13) {
						if ($scope.mode === 'invert') {
							$scope.adjustments.invert = !$scope.adjustments.invert;
						}
					}
					if (key === 37) {
						if ($scope.mode === 'brightness') {
							$scope.adjustments.brightness -= 1;
							if ($scope.adjustments.brightness < -50) {
								$scope.adjustments.brightness = -50;
							}
						}
						if ($scope.mode === 'contrast') {
							$scope.adjustments.contrast -= 1;
							if ($scope.adjustments.contrast < -50) {
								$scope.adjustments.contrast = -50;
							}
						}
					}
					if (key === 39) {
						if ($scope.mode === 'brightness') {
							$scope.adjustments.brightness += 1;
							if ($scope.adjustments.brightness > 50) {
								$scope.adjustments.brightness = 50;
							}
						}
						if ($scope.mode === 'contrast') {
							$scope.adjustments.contrast += 1;
							if ($scope.adjustments.contrast > 50) {
								$scope.adjustments.contrast = 50;
							}
						}
					}
					if (key === 32) {
						event.preventDefault();
						spacePressed = true;
						editorWindow.style.cursor = 'move';
					}
					if (key === 17) {
						ctrlPressed = true;
					}
					/* change mode shortcuts */
					if ($scope.toolbarSelected === 'adjust') {
						switch (key) {
						case 66:
							setMode('brightness');
							break;
						case 67:
							setMode('contrast');
							break;
						case 73:
							setMode('invert');
							break;
						case 85:
							setMode('curves');
							break;
						case 82:
							$scope.adjustments.reset();
							break;
						case 77:
							setMode('measure_resolution');
							break;
						case 79:
							setMode('open_file');
							break;
						default:
							// do nothing
						}
					}
					if ($scope.toolbarSelected === 'minutia') {
						switch (key) {
						case 65:
							setMode('add_minutia');
							break;
						case 82:
							setMode('rotate_minutia');
							break;
						case 68:
							setMode('delete_minutia');
							break;
						case 69:
							setMode('edit_minutia');
							break;
						default:
							// do nothing
						}
					}
				}

				if (action === 'up') {
					if (key === 32) {
						spacePressed = false;
						editorWindow.style.cursor = 'auto';
					}
					if (key === 17) {
						ctrlPressed = false;
					}
				}
			}

			function keydown(e) {
				keyboardActions(e, 'down');
			}
			function keyup(e) {
				keyboardActions(e, 'up');
			}

			angular.element($document).on('keydown', keydown);
			angular.element($document).on('keyup', keyup);

			$scope.$on('$destroy', () => {
				angular.element($document).off('keydown', keydown);
				angular.element($document).off('keyup', keyup);
				if (editorWindow != null) {
					editorWindow.removeEventListener('wheel', wheel);
				}
			});

			$scope.identifyFinger = function () {
				function resetSubject() {
					SubjectService.invalidate();
				}

				function setFingerToSubject() {
					SubjectService.setFingers([{
						position: 'UNKNOWN',
						quality: 'UNKNOWN',
						status: 'ok',
						data: $scope.fileContent.replace(/^data:image\/(png|jpeg);base64,/, '')
					}]);
					SubjectService.attachFingersIdentifier();
				}

				function setRequestData() {
					AbisService.setRequest('actions.identify', {
						type: 'IDENTIFY',
						galleryId: SettingsService.getPreferredGallery() ? SettingsService.getPreferredGallery().id : GalleriesService.getDefaultGallery().id,
					});
				}

				function sendRequest() {
					const dto = AbisService.getDto();
					AbisService.postRequest(dto)
						.finally(() => {
							SubjectService.invalidate();
						});
				}

				resetSubject();
				setFingerToSubject();
				setRequestData();
				sendRequest();
			};
		}]);
