import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('inputFile', ['$timeout', 'Base64Tools', function ($timeout, Base64Tools) {
		return {
			template: require('../../views/directives/input-file.html'),
			restrict: 'E',
			replace: true,
			scope: {
				file: '=ifFile',
				raw: '&ifRaw',
				callback: '&ifCallback',
				required: '&ifRequired',
				btnClass: '=btnClass',
				title: '=title',
				text: '=text',
				hideIcon: '=hideIcon',
				inputDisabled: '=ngDisabled',
				btnId: '@'
			},
			link: function link(scope, element) {
				element.on('click', (e) => {
					e.stopPropagation();
				});
				scope.raw = scope.raw() === true;
				scope.required = scope.required() === true;

				var fileInput = element.find('input[type="file"]');
				fileInput.change((event) => {
					var file = event.target.files[0];
					$timeout(() => {
						scope.$apply((scope) => {
							if (scope.raw) {
								scope.file = file;
							} else {
								scope.file = file;

								var reader = new FileReader();
								reader.onload = function (event) {
									scope.$apply((scope) => {
										scope.file.content = Base64Tools.arrayBufferToBase64String(event.target.result);
										fileInput[0].value = null;
										scope.callback();
									});
								};
								reader.readAsArrayBuffer(file);
							}
						});
					});
				});

				scope.browse = function ($event) {
					$event.stopPropagation();
					fileInput.click();
				};
			}
		};
	}]);
