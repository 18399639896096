import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ConfParametersCtrl', ['$scope', '$translate', 'AdminParametersService', 'TaskPollerService',
		function ($scope, $translate, AdminParametersService, TaskPollerService) {
			$scope.duplicateCheck = {};
			$scope.identification = {};
			$scope.verification = {};
			$scope.workflowParams = {};
			$scope.workflowParamsCopy = {};

			$scope.duplicateCheckAlerts = [];
			$scope.identificationAlerts = [];
			$scope.verificationAlerts = [];
			$scope.workflowAlerts = [];

			function createOptions(min, max, isRequired, handleRangeValidation) {
				return {
					min,
					max,
					isRequired,
					isRangeValid: handleRangeValidation
				};
			}

			$scope.options = createOptions(0, 100000, true, (a, b) => !b || a < b);
			$scope.fpirOptions = createOptions(0, 1, true, (a, b) => !b || a > b);

			$scope.opts = { activeTab: 'tab-duplicate-check' };
			$scope.matchingModesTabToModel = {
				'tab-duplicate-check': $scope.duplicateCheck,
				'tab-identification': $scope.identification,
				'tab-verification': $scope.verification,
			};

			$scope.changeTab = function (newTab) {
				$scope.opts.activeTab = newTab;
				$scope.$broadcast('reCalcViewDimensions');
			};

			$translate([
				'admin.configuration.additional-parameters.master-encounter-selector',
				'admin.configuration.additional-parameters.group-subjects-description'
			]).then((translations) => {
				$scope.masterEncounterSelectors = {
					/* eslint-disable dot-notation */
					LATEST: translations['admin.configuration.additional-parameters.master-encounter-selector']
					/* eslint-enable dot-notation */
				};
				$scope.groupSubjectsTooltipText = translations['admin.configuration.additional-parameters.group-subjects-description'];
			});

			function getParametersPayload(scores, mode) {
				return [
					...Object.values(scores),
					mode
				];
			}

			$scope.applyDuplicateCheck = function () {
				if ($scope.duplicateCheckForm.$valid) {
					var startMsg = 'admin.configuration.parameters.saving.duplicate';
					var successMsg = 'admin.configuration.parameters.success.duplicate';
					$translate([startMsg, successMsg]).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							AdminParametersService.save(getParametersPayload($scope.duplicateCheck, $scope.matchingModes.duplicateCheck), success, error);
						}, $scope.duplicateCheckAlerts, translations[startMsg], translations[successMsg]);
					});
				}
			};

			$scope.getDuplicateCheck = function () {
				AdminParametersService.query({
					names: [
						'mmabis.matching.duplicate-check.thresholds.fused.lower',
						'mmabis.matching.duplicate-check.thresholds.fused.top',
						'mmabis.matching.duplicate-check.thresholds.face.lower',
						'mmabis.matching.duplicate-check.thresholds.face.top',
						'mmabis.matching.duplicate-check.thresholds.finger.lower',
						'mmabis.matching.duplicate-check.thresholds.finger.top',
						'mmabis.matching.duplicate-check.thresholds.iris.lower',
						'mmabis.matching.duplicate-check.thresholds.iris.top',
						'mmabis.matching.duplicate-check.thresholds.palm.lower',
						'mmabis.matching.duplicate-check.thresholds.palm.top',
						'mmabis.matching.duplicate-check.thresholds.voice.lower',
						'mmabis.matching.duplicate-check.thresholds.voice.top',
						'mmabis.matching.duplicate-check.thresholds.lower-fpir',
						'mmabis.matching.duplicate-check.thresholds.upper-fpir',
					]
				}, (parameters) => {
					parameters.forEach((parameter) => {
						parameter.value = parseFloat(parameter.value, 10);
					});
					$scope.duplicateCheck = {};
					[
						$scope.duplicateCheck.fusedLower,
						$scope.duplicateCheck.fusedTop,
						$scope.duplicateCheck.faceLower,
						$scope.duplicateCheck.faceTop,
						$scope.duplicateCheck.fingerLower,
						$scope.duplicateCheck.fingerTop,
						$scope.duplicateCheck.irisLower,
						$scope.duplicateCheck.irisTop,
						$scope.duplicateCheck.palmLower,
						$scope.duplicateCheck.palmTop,
						$scope.duplicateCheck.voiceLower,
						$scope.duplicateCheck.voiceTop,
						$scope.duplicateCheck.lowerFpir,
						$scope.duplicateCheck.upperFpir
					] = parameters;
				});
			};

			$scope.applyIdentification = function () {
				if ($scope.identificationForm.$valid) {
					var startMsg = 'admin.configuration.parameters.saving.identification';
					var successMsg = 'admin.configuration.parameters.success.identification';
					$translate([startMsg, successMsg]).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							AdminParametersService.save(getParametersPayload($scope.identification, $scope.matchingModes.identification), success, error);
						}, $scope.identificationAlerts, translations[startMsg], translations[successMsg]);
					});
				}
			};

			$scope.getIdentification = function () {
				AdminParametersService.query({
					names: [
						'mmabis.matching.identification.thresholds.fused',
						'mmabis.matching.identification.thresholds.face',
						'mmabis.matching.identification.thresholds.finger',
						'mmabis.matching.identification.thresholds.iris',
						'mmabis.matching.identification.thresholds.palm',
						'mmabis.matching.identification.thresholds.voice',
						'mmabis.matching.identification.thresholds.fpir',
					]
				}, (parameters) => {
					parameters.forEach((parameter) => {
						parameter.value = parseFloat(parameter.value, 10);
					});
					$scope.identification = {};
					[
						$scope.identification.fused,
						$scope.identification.face,
						$scope.identification.finger,
						$scope.identification.iris,
						$scope.identification.palm,
						$scope.identification.voice,
						$scope.identification.fpir
					] = parameters;
				});
			};

			$scope.applyVerification = function () {
				if ($scope.verificationForm.$valid) {
					var startMsg = 'admin.configuration.parameters.saving.verification';
					var successMsg = 'admin.configuration.parameters.success.verification';
					$translate([startMsg, successMsg]).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							AdminParametersService.save(getParametersPayload($scope.verification, $scope.matchingModes.verification), success, error);
						}, $scope.verificationAlerts, translations[startMsg], translations[successMsg]);
					});
				}
			};

			$scope.getVerification = function () {
				AdminParametersService.query({
					names: [
						'mmabis.matching.verification.thresholds.fused',
						'mmabis.matching.verification.thresholds.face',
						'mmabis.matching.verification.thresholds.finger',
						'mmabis.matching.verification.thresholds.iris',
						'mmabis.matching.verification.thresholds.palm',
						'mmabis.matching.verification.thresholds.voice',
						'mmabis.matching.verification.thresholds.fpir',
					]
				}, (parameters) => {
					parameters.forEach((parameter) => {
						parameter.value = parseFloat(parameter.value, 10);
					});
					$scope.verification = {};
					[
						$scope.verification.fused,
						$scope.verification.face,
						$scope.verification.finger,
						$scope.verification.iris,
						$scope.verification.palm,
						$scope.verification.voice,
						$scope.verification.fpir
					] = parameters;
				});
			};

			function workflowParamsChanged() {
				const params = ['groupEncounters', 'areCommentsMandatory'];
				return params.some(paramKey => $scope.workflowParamsCopy[paramKey].value !== $scope.workflowParams[paramKey].value);
			}

			$scope.applyWorkflowParams = function () {
				if ($scope.workflowForm.$valid && workflowParamsChanged()) {
					var startMsg = 'admin.configuration.parameters.saving.workflow';
					var successMsg = 'admin.configuration.parameters.success.workflow';
					$translate([startMsg, successMsg]).then((translations) => {
						TaskPollerService.pollAndAlert((success, error) => {
							AdminParametersService.save(Object.values($scope.workflowParams), success, error);
							$scope.workflowParamsCopy = angular.copy($scope.workflowParams);
						}, $scope.workflowAlerts, translations[startMsg], translations[successMsg]);
					});
				}
			};

			$scope.applyOtherParameters = function () {
				$scope.applyWorkflowParams();
				$scope.applyImageProcessingParams();
				$scope.applyMatchingParams();
			};

			$scope.getWorkflowParams = function () {
				AdminParametersService.query({
					names: [
						'master-encounter-selector',
						'group-encounters',
						'adjudication-hit-comments-mandatory'
					]
				}, (parameters) => {
					if (parameters.length !== 3) {
						return;
					}
					$scope.workflowParams = {};
					[
						$scope.workflowParams.masterEncounterSelector,
						$scope.workflowParams.groupEncounters,
						$scope.workflowParams.areCommentsMandatory
					] = parameters;

					$scope.workflowParams.groupEncounters.value = $scope.workflowParams.groupEncounters.value === 'true';
					$scope.workflowParams.areCommentsMandatory.value = $scope.workflowParams.areCommentsMandatory.value === 'true';
					$scope.workflowParamsCopy = angular.copy($scope.workflowParams);
				});
			};

			$scope.$on('$viewContentLoaded', () => {
				$scope.getDuplicateCheck();
				$scope.getIdentification();
				$scope.getVerification();
				$scope.getWorkflowParams();
				$scope.getMatchingModes();
			});

			$scope.addParameter = function (parameters) {
				parameters.push({ name: '', value: '' });
			};

			$scope.removeParameter = function (parameters, index) {
				parameters.splice(index, 1);
			};

			$scope.getMatchingModes = function () {
				AdminParametersService.query({
					names: [
						'mmabis.matching.duplicate-check.matching-mode',
						'mmabis.matching.identification.matching-mode',
						'mmabis.matching.verification.matching-mode'
					]
				}, (parameters) => {
					if (parameters.length !== 3) {
						return;
					}
					$scope.matchingModes = {};
					[
						$scope.matchingModes.duplicateCheck,
						$scope.matchingModes.identification,
						$scope.matchingModes.verification
					] = parameters;
					Object.keys($scope.matchingModes).forEach((key) => {
						createFpirModeIdentifier($scope.matchingModes[key]);
					});
				});
			};

			$scope.getClassIfModeMatchesModality = function (mode, modality) {
				const modes = {
					FUSED: 'FUSED',
					FPIR: 'FPIR',
					MODALITY_BASED: 'MODALITY_BASED',
					ANOMALOUS: 'ANOMALOUS'
				};
				const activeClass = 'parameter-active';
				switch (modality) {
				case 'fused':
					return (mode === modes.FUSED || mode === modes.ANOMALOUS || mode === modes.FPIR) ? activeClass : '';
				case 'face':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'iris':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'finger':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'palm':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				case 'voice':
					return (mode === modes.MODALITY_BASED || mode === modes.ANOMALOUS) ? activeClass : '';
				default:
					return '';
				}
			};

			function createFpirModeIdentifier(obj) {
				obj.isModeFpir = () => obj.value === 'FPIR';
			}

			function registerParameterGroup(functionNameSuffix, variableNamePrefix, parametersGroup, startMsg, successMsg) {
				var parameterPrefix = `${parametersGroup}/`;

				var applyFunctionName = `apply${functionNameSuffix}`;
				var getFunctionName = `get${functionNameSuffix}`;

				var originalParamsName = `${variableNamePrefix}OriginalParams`;
				var paramsName = `${variableNamePrefix}Params`;
				var alertsName = `${variableNamePrefix}Alerts`;

				var formName = `${variableNamePrefix}Form`;

				$scope[originalParamsName] = [];
				$scope[paramsName] = [];
				$scope[alertsName] = [];

				$scope[applyFunctionName] = function () {
					if ($scope[formName].$valid) {
						var oldOriginalMap = {};
						$scope[originalParamsName].forEach((parameter) => {
							oldOriginalMap[parameter.name] = parameter;
						});

						var newOriginal = [];
						$scope[paramsName].forEach((parameter) => {
							var prefixedName = parameterPrefix + parameter.name;
							newOriginal.push({
								name: prefixedName,
								value: parameter.value
							});
							delete oldOriginalMap[prefixedName];
						});

						var toRemove = [];
						Object.values(oldOriginalMap).forEach(parameter => toRemove.push({
							name: parameter.name,
							value: null
						}));

						var parameters = newOriginal.concat(toRemove);
						if (parameters.length === 0) {
							return;
						}

						$translate([startMsg, successMsg]).then((translations) => {
							TaskPollerService.pollAndAlert((success, error) => {
								AdminParametersService.save(parameters, success, error);
							}, $scope[alertsName], translations[startMsg], translations[successMsg], () => {
								$scope[originalParamsName] = newOriginal;
							});
						});
					}
				};

				$scope[getFunctionName] = function () {
					AdminParametersService.query({ names: [parametersGroup] }, (parameters) => {
						$scope[originalParamsName] = parameters;
						$scope[paramsName] = [];
						parameters.forEach(parameter => $scope[paramsName].push({
							name: parameter.name.substring(parameterPrefix.length),
							value: parameter.value
						}));
					});
				};

				$scope[getFunctionName]();
			}

			registerParameterGroup(
				'ImageProcessingParams', 'imageProcessing', 'image-processing',
				'admin.configuration.parameters.saving.image-processing', 'admin.configuration.parameters.success.image-processing'
			);

			registerParameterGroup(
				'MatchingParams', 'matching', 'matching',
				'admin.configuration.parameters.saving.matching', 'admin.configuration.parameters.success.matching'
			);
		}]);
