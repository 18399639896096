import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('graph', [function () {
		return {
			template: require('../../../views/directives/admin/graph.html'),
			restrict: 'E',
			replace: true,
			scope: {
				chartType: '@',
				statistic: '=',
				isDataEmpty: '&',
				graphId: '@',
				graphTooltipId: '@',
				graphTitle: '@',
				titleClass: '@?',
			},
			link: (scope, _element, attrs) => {
				scope.isSmall = Object.hasOwnProperty.call(attrs, 'isSmall');
				scope.showIfNoData = Object.hasOwnProperty.call(attrs, 'showIfNoData');
			}
		};
	}]);
