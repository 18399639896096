import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.service('InputUtils', function () {
		this.isValidDatetime = function (date, format) {
			if (typeof date === 'undefined' || !date) {
				return true;
			}
			if (!moment(date, format, false).isValid()) {
				return false;
			}
			return true;
		};
	});
