import angular from 'angular';

angular.module('neurotecAbisWebClientApp').service('FPIRConverterService', [function () {
	this.fpirToScore = function (fpir, dbSize) {
		if (dbSize === 0) return null;

		var far = 1 - ((1 - fpir) ** (1.0 / dbSize));

		if (far === 0) return null;

		var tr = -12.0 * Math.log10(far);
		return Math.round(tr);
	};
}]);
