import angular from 'angular';

angular
	.module('neurotecAbisWebClientApp')
	.service('DocumentSubjectService', [
		function () {
			let subject = {};

			this.invalidate = function () {
				subject = {};
			};

			this.setScannedSubject = function (data) {
				subject = data;
			};

			this.getScannedSubject = function () {
				return subject;
			};
		}]);
