angular.module('neurotecAbisWebClientApp')
	.factory('TaskPollerService', ['$timeout', '$sce', 'TasksService', function ($timeout, $sce, TasksService) {
		return {
			httpResponseToErrorMessage(httpResponse) {
				if (httpResponse.status !== -1) {
					if (httpResponse.data != null && httpResponse.data.message) {
						return httpResponse.data.message;
					} else if (httpResponse.statusText) {
						return `${httpResponse.status} ${httpResponse.statusText}`;
					}
					return `Error code: ${httpResponse.status}`;
				}
				return 'Service is unavailable';
			},
			pollUntilResult(taskId, finishedCallback, cancelledCallback, errorCallback, notifyCallback) {
				var obj = this;
				function fetch() {
					TasksService.getSilent({ id: taskId }).$promise.then(promiseSuccess, promiseError);
				}
				function promiseSuccess(result) {
					if (result.status === 'FINISHED') {
						if (typeof (finishedCallback) === 'function') {
							finishedCallback();
						}
					} else if (result.status === 'CANCELED') {
						if (typeof (cancelledCallback) === 'function') {
							cancelledCallback();
						}
					} else if (result.status === 'ERROR') {
						if (typeof (errorCallback) === 'function') {
							errorCallback(result);
						}
					} else {
						if (typeof (notifyCallback) === 'function') {
							notifyCallback(result);
						}
						$timeout(fetch, 1000);
					}
				}
				function promiseError(httpResponse) {
					if (typeof (errorCallback) === 'function') {
						errorCallback({
							errorMessage: obj.httpResponseToErrorMessage(httpResponse)
						});
					}
				}
				$timeout(fetch, 500);
			},
			pollAndAlert(run, alerts, initialMsg, completedMsg, completedCallback) {
				var obj = this;
				alerts.length = 0;
				alerts.push({ type: 'info', msg: initialMsg });
				run((task) => {
					obj.pollUntilResult(task.id, () => {
						alerts.pop();
						alerts.push({ type: 'success', msg: completedMsg });
						if (typeof (completedCallback) === 'function') {
							completedCallback();
						}
					}, () => {
						alerts.pop();
						alerts.push({ type: 'warning', msg: 'Cancelled' });
					}, (result) => {
						alerts.pop();
						alerts.push({ type: 'danger', msg: result.errorMessage });
					}, (result) => {
						alerts.pop();
						var alert = {
							type: 'info',
							msg: initialMsg
						};
						if (typeof result.progress === 'number' && result.progress >= 0) {
							alert.html = $sce.trustAsHtml(`<div class="progress" style="margin-top:5px; margin-bottom:0px"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="${result.progress}" aria-valuemin="0" aria-valuemax="100" style="width:${result.progress}%">${result.progress}%</div></div>`);
						}
						alerts.push(alert);
					});
				}, (httpResponse) => {
					alerts.pop();
					alerts.push({
						type: 'danger',
						msg: obj.httpResponseToErrorMessage(httpResponse)
					});
				});
			}
		};
	}]);
