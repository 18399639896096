import React from 'react';
import NCol from './base/NCol';

interface IActionCardProps {
	id: string;
	name: string;
	url?: string;
	icon?: JSX.Element;
}

const ActionCard: React.FC<IActionCardProps> = (props) => {
	return (
		<NCol xs={6} sm={4} md={3}>
			<a id={props.id} href={props.url}>
				<div className="tile shadow">
					<div className="tile-content">
						<div className="content">{props.icon}</div>
					</div>
					<div className="tile-text img-responsive">{props.name}</div>
				</div>
			</a>
		</NCol>
	);
};

export default ActionCard;
