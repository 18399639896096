import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('progressPath', () => ({
		template: require('../../../views/directives/latent-editor/progress-path.html'),
		restrict: 'E',
		replace: true,
		scope: {
			progress: '='
		}
	}));
