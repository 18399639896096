import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('SubjectsCtrl', ['$q', '$confirm', '$scope', '$state', '$translate', 'AuthDataHolder', 'AlertService', 'blockUI', 'NotificationsService', 'FilterUtils', 'SortHelper', 'store', 'EncountersResource', 'TransactionsResource', 'BiographicDataService', 'GalleriesService', 'SettingsService', 'ConvertionUtils', 'Utils', 'FilterDataHolder', 'MultiselectModule', 'DatetimePickerService', 'EncounterService',
		function ($q, $confirm, $scope, $state, $translate, AuthDataHolder, AlertService, blockUI, NotificationsService, FilterUtils, SortHelper, store, EncountersResource, TransactionsResource, BiographicDataService, GalleriesService, SettingsService, ConvertionUtils, Utils, FilterDataHolder, MultiselectModule, DatetimePickerService, EncounterService) {
			$scope.isObjectEmpty = obj => Utils.isObjectEmpty(obj);
			$scope.sort = SortHelper.create('subjectId', false);
			$scope.hasKey = BiographicDataService.hasKey;
			$scope.searchableFieldsCount = 2; // subjectID and actions
			$scope.cnv = ConvertionUtils;

			$scope.galleries = [];
			$scope.galleryStartIndex = 0;

			const FILTER_NAME = ':subjects:filterData';

			function checkAndRetainFilter() {
				const retainFilter = $state.previous.includes('subject');
				if (!retainFilter) {
					FilterDataHolder.remove(FILTER_NAME);
				} else {
					$scope.filterData = FilterDataHolder.get(FILTER_NAME);
					copyProcessedFilterData(filterDataInternal);
					$scope.loadMore();
				}
			}

			$scope.statuses = {};
			$scope.statusKeys = [];
			function loadStatuses() {
				const deferred = $q.defer();
				$translate([
					'subject.status.ENROLLABLE', 'subject.status.TEMPORARY_ENROLLED', 'subject.status.ENROLLED', 'subject.status.DELETED',
					'transactions.status.registered', 'transactions.status.in-progress', 'transactions.status.adjudication-waiting',
					'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict', 'transactions.status.rejected',
					'transactions.status.ok', 'transactions.status.matched', 'transactions.status.not-matched', 'transactions.status.duplicate-found'
				]).then((translations) => {
					$scope.statuses = {
					/* jshint sub:true */
						ENROLLABLE: translations['subject.status.ENROLLABLE'],
						TEMPORARY_ENROLLED: translations['subject.status.TEMPORARY_ENROLLED'],
						ENROLLED: translations['subject.status.ENROLLED'],
						DELETED: translations['subject.status.DELETED'],
					/* jshint sub:false */
					};
					$scope.statusKeys = Object.keys($scope.statuses);

					$scope.transactionStatuses = {
						/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						REJECTED: translations['transactions.status.rejected'],
						OK: translations['transactions.status.ok'],
						MATCHED: translations['transactions.status.matched'],
						NOT_MATCHED: translations['transactions.status.not-matched'],
						DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
						/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			function loadMSModule() {
				$translate([
					'transactions.subject-id',
					'transactions.gallery',
				]).then((translations) => {
					let msData = [
						{ id: 'subjectId', label: translations['transactions.subject-id'] }
					];
					if ($scope.galleries.length > $scope.galleryStartIndex) {
						msData.push({ id: 'galleryId', label: translations['transactions.gallery'] });
					}

					const DEFAULT_MSMODEL_VISIBLE_ATTRIBUTES_COUNT = 7;
					msData = [
						...msData,
						...$scope.datefields.map(field => ({ id: field.key, label: field.name })),
						...$scope.generalfields.map(field => ({ id: field.key, label: field.name }))
					];
					const defaultModel = msData.slice(0, DEFAULT_MSMODEL_VISIBLE_ATTRIBUTES_COUNT).map(group => ({ id: group.id }));

					const msCustomTexts = {
						buttonDefaultText: translations['adjudication.column-visibility']
					};

					$scope.msModule.moduleData = $scope.msModule.initialize(msData, defaultModel, msCustomTexts, 'subjects:columnsToShow', {
						dynamicTitle: false,
						scrollableHeight: '200px',
						scrollable: true
					});
					$scope.isVisibleColumn = $scope.msModule.isVisibleColumn;
				});
			}

			$scope.msModule = MultiselectModule;
			$scope.multiSelectEventsHandler = {
				onSelectionChanged: $scope.msModule.eventsHandler.onSelectionChanged
			};

			BiographicDataService.get().then((fields) => {
				const f = angular.copy(fields);
				$scope.fields = angular.copy(f);
				$scope.generalfields = [];
				$scope.datefields = [];
				f.forEach((field) => {
					if ($scope.hasKey(field.flags, 'SEARCH_FIELD') && !$scope.hasKey(field.flags, 'HIDE_SUBJECT_FIELD')) {
						if (field.type === 'DATE' || field.type === 'DATE_TIME') {
							field.viewFormat = field.viewFormat.replace(/y/g, 'Y').replace(/d/g, 'D');
							getDateTimeFormat(field)
								.then((options) => {
									field.options = options;
								});
							$scope.datefields.push(field);
						} else {
							$scope.generalfields.push(field);
						}
						$scope.searchableFieldsCount += 1;
					}
				});

				fetchGalleryOptions()
					.then(() => {
						$scope.searchableFieldsCount += $scope.galleries.length > $scope.galleryStartIndex;
						loadStatuses()
							.then(() => {
								checkAndRetainFilter();
								loadMSModule();
							});
					});
			});

			function fetchGalleryOptions() {
				const deferred = $q.defer();
				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					GalleriesService.getAvailableGalleries()
						.then((galleries) => {
							$scope.galleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
							$scope.galleries.unshift(
								GalleriesService.getAllGalleriesOption(),
								GalleriesService.getDefaultGallery(),
							);

							$scope.galleryStartIndex = GalleriesService.getBeginningGalleryIndexForFiltering();
							$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex];
							$scope.onGalleryChoose();

							deferred.resolve();
						});
				} else {
					$scope.galleries = [];
					deferred.resolve();
				}
				return deferred.promise;
			}

			$scope.onGalleryChoose = function () {
				$scope.filterData.galleryId = $scope.selectedGallery ? $scope.selectedGallery.id : '';
			};

			$scope.defaultDateOptions = { opens: 'right' };
			let isLoaded = false;
			function getDefaultOptions() {
				if (!isLoaded) {
					return DatetimePickerService.getDefaultOptions()
						.then((options) => {
							$scope.defaultDateOptions = { ...options, ...$scope.defaultDateOptions };
							isLoaded = true;
						});
				}
				return $q(resolve => resolve());
			}

			function getDateTimeFormat(field) {
				return $q((resolve) => {
					getDefaultOptions()
						.then(() => {
							const options = angular.copy($scope.defaultDateOptions);
							options.locale.format = field.viewFormat;
							resolve(options);
						});
				});
			}

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$translate([
				'cases.filter'
			]).then((translations) => {
				$scope.searchFilter = {
					name: translations['cases.filter'],
					checked: true
				};
			});

			$scope.updateSelection = function (position, searchFilters) {
				angular.forEach(searchFilters, (searchFilter, index) => {
					if (position !== index) {
						searchFilter.checked = false;
					} else {
						searchFilter.checked = true;
					}
				});
			};

			$scope.maxPagesToLoad = 50; // page * size <= 10k
			$scope.itemsPerPage = 200;

			$scope.maxSubjectsPerFetch = 30;

			$scope.loadingDisabled = true;
			$scope.wasLoaded = false;
			$scope.loading = false;
			$scope.itemsLoaded = 0;
			$scope.pageItemsLoaded = 0;
			$scope.pagesLoaded = 0;
			$scope.hasNextPage = false;

			$scope.subjects = {};
			$scope.transactions = {};

			var filterDataInternal = {};
			$scope.filterData = {};

			$scope.datetimePickerOptions = {
				format: 'YYYY-MM-DD',
				showClear: true,
				allowInputToggle: true
			};

			$scope.sexes = {
				Male: 'subject.sex.options.m',
				Female: 'subject.sex.options.f'
			};

			function loadToggle() {
				$scope.loadingDisabled = !($scope.hasNextPage && $scope.pagesLoaded < $scope.maxPagesToLoad);
			}

			$scope.$watch('pagesLoaded', loadToggle);
			$scope.$watch('hasNextPage', loadToggle);
			$scope.$watch(() => SettingsService.getPreferredGallery(), (newValue) => {
				$scope.selectedGallery = newValue || ($scope.galleries && $scope.galleries[1]);
				$scope.onGalleryChoose();
			});

			function fetchEncounters(itemsPerFetch = 0) {
				$scope.loading = true;
				let continueLoading = false;

				const subjects = {};
				EncountersResource.query(angular.extend({
					page: $scope.pagesLoaded,
					size: $scope.itemsPerPage,
					sort: $scope.sort.field,
					reverse: $scope.sort.reverse
				}, filterDataInternal), (value, responseHeaders) => {
					value.forEach((item) => {
						const identifier = getSubjectGroupIdentifier(item.galleryId, item.subjectId);
						if ($scope.subjects[identifier] == null) {
							subjects[item.subjectId] = item;
							$scope.itemsLoaded += 1;
							itemsPerFetch += 1;
						}
					});

					$scope.pagesLoaded += 1;
					$scope.hasNextPage = responseHeaders('X-Has-Next-Page') === 'true';

					Object.assign($scope.subjects, subjects);

					if ($scope.itemsLoaded && $scope.hasNextPage && $scope.pagesLoaded === $scope.maxPagesToLoad) {
						AlertService.show('subject.limit-reached', { type: 'info' });
					} else if (itemsPerFetch < $scope.maxSubjectsPerFetch && value.length > 0 && $scope.hasNextPage) {
						continueLoading = true;
						fetchEncounters(itemsPerFetch);
					}
				}).$promise.finally(() => {
					$scope.loading = continueLoading;
				});
			}

			function getSubjectGroupIdentifier(galleryId, subjectId) {
				if (galleryId) {
					return `${galleryId}-${subjectId}`;
				}
				return subjectId;
			}

			$scope.loadMore = function () {
				if ($scope.loading) {
					return;
				}

				$scope.loading = true;
				$scope.wasLoaded = true;

				if (store.get(':subjects:sort')) {
					$scope.sort = SortHelper.create(store.get(':subjects:sort').field, true, store.get(':subjects:sort').reverse);
				}

				fetchEncounters();
			};

			function reload() {
				if ($scope.loading) {
					return;
				}

				$scope.subjects = {};
				$scope.itemsLoaded = 0;
				$scope.pagesLoaded = 0;
				$scope.hasNextPage = true;
				$scope.loadingDisabled = false;
				$scope.loadMore();
			}

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix);
			};

			function isFormValid() {
				let dateParsing = true;
				$scope.datefields.forEach((date) => {
					dateParsing = dateParsing && $scope.dateFieldsValid(date.key);
				});
				return $scope.filterForm.$valid && dateParsing;
			}

			function copyProcessedFilterData(to) {
				function filterStatuses() {
					return $scope.statusKeys.reduce((acc, key) => {
						if ($scope.filterData.status[key]) {
							acc.push(key);
						}
						return acc;
					}, []);
				}

				$scope.generalfields.forEach(field => FilterUtils.filterBySingleField($scope.filterData, to, field.key));
				$scope.datefields.forEach(field => FilterUtils.filterByDateFieldsEx($scope.filterData, to, field.key, (field.type === 'DATE_TIME')));
				FilterUtils.filterBySingleField($scope.filterData, to, 'subjectId');

				if ($scope.filterData.galleryId !== GalleriesService.getAllGalleriesOption().id) {
					FilterUtils.filterBySingleField($scope.filterData, to, 'galleryId');
				}

				if ($scope.filterData.status) {
					to.status = filterStatuses();
				} else {
					to.status = $scope.statusKeys;
				}
			}

			$scope.filter = function () {
				if (isFormValid()) {
					filterDataInternal = {};
					copyProcessedFilterData(filterDataInternal);
					reload();
					FilterDataHolder.add(FILTER_NAME, $scope.filterData);
				}
			};

			function resetGallerySelection() {
				$scope.selectedGallery = SettingsService.getPreferredGallery() || $scope.galleries[$scope.galleryStartIndex] || GalleriesService.getDefaultGallery();
			}

			$scope.resetFilter = function () {
				$scope.filterData = {};
				$scope.filterForm.$setPristine();
				resetGallerySelection();
				$scope.onGalleryChoose();
			};

			$scope.sortBy = function (field) {
				if ($scope.loading) {
					return;
				}
				$scope.sort.sort(field);
				store.set(':subjects:sort', $scope.sort);
				reload();
			};

			$scope.updateSubject = function (event, subject) {
				event.stopPropagation();
				blockUI.start();
				EncountersResource.get({ encounterId: subject.encounterId }).$promise
					.then((encounter) => {
						EncounterService.loadEncounter(encounter, encounter.encounterId)
							.then(() => {
								$state.go('actions.update', { subject: encounter, previousState: $state.current });
								blockUI.stop();
							})
							.catch(() => {
								blockUI.stop();
								AlertService.show('subject.failed-to-load', { type: 'danger', msTimeout: 6000, translate: true });
							});
					});
			};

			$scope.deleteSubject = function (event, subject) {
				event.stopPropagation();

				if (!$scope.isDeleteValid(subject)) {
					AlertService.show('subject.actions.delete.invalid-status', { type: 'danger', msTimeout: 6000, translate: true });
					return;
				}

				$translate('subject.actions.confirm-delete', subject).then((translation) => {
					$confirm({ text: translation }).then(() => {
						blockUI.start();
						var data = {
							subject: {
								subjectId: subject.subjectId
							},
							type: 'DELETE'
						};
						if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
							data.galleryId = subject.galleryId;
						}

						TransactionsResource.post(
							data,
							(transaction) => {
								NotificationsService.add(transaction.requestId);
								AlertService.show('subject.actions.to-be-deleted');
								blockUI.stop();
							}, (error) => {
								$translate('transactions.request-failed').then((translation) => {
									AlertService.show(`${translation}: ${error.data.message}`, { type: 'danger', msTimeout: 6000, translate: false });
								});
								blockUI.stop();
							}
						);
					});
				});
			};

			$scope.getSubjectLinkPayload = function (subject) {
				return { subjectId: subject.subjectId, encounterId: subject.encounterId, galleryId: subject.galleryId };
			};

			$scope.isSubjectViewAvailable = AuthDataHolder.hasAuthority('PERMISSION_SUBJECT_VIEW') && AuthDataHolder.hasAuthority('PERMISSION_ENCOUNTER_VIEW');
			$scope.getGalleryNameById = galleryId => GalleriesService.getGalleryNameById(galleryId, $scope.galleries);

			function isFilteredByStatus() {
				return $scope.filterData
					&& $scope.filterData.status;
			}

			$scope.selectAllStatuses = function () {
				function markAllStatusesAs(value) {
					$scope.statusKeys.forEach((key) => {
						$scope.filterData.status[key] = value;
					});
				}
				if (!isFilteredByStatus()) {
					$scope.filterData.status = {};
				}
				markAllStatusesAs(!$scope.allStatusesSelected());
			};

			$scope.allStatusesSelected = function () {
				if (!isFilteredByStatus()) return false;
				return !$scope.statusKeys.some(key => !$scope.filterData.status[key]);
			};

			$scope.isDeleteValid = function (encounter) {
				function isFinalStatus(encounterStatus) {
					return !['TEMPORARY_ENROLLED', 'DELETED'].some(status => status === encounterStatus);
				}
				return isFinalStatus(encounter.status);
			};
		}]);
