import { NLayoutSpacing, NLayoutViewPorts } from '../types/NLibrary';
import { propsToClasses } from './useAlignContentClasses';
import useClassNames, { ClassesProduct } from './useClassNames';

type Spacing = 'spacing';
type SpacingItems = `${Spacing}${NLayoutViewPorts}`;
export type SpacingProps = { [Property in SpacingItems]: NLayoutSpacing } & { [Property in Spacing]: NLayoutSpacing };

const getSpacingClasses = (suffix?: NLayoutViewPorts): Array<[string, NLayoutSpacing]> => {
	if (suffix !== undefined) {
		return [
			[`fs-p-${suffix.toLowerCase()}-0`, 0],
			[`fs-p-${suffix.toLowerCase()}-1`, 1],
			[`fs-p-${suffix.toLowerCase()}-2`, 2],
			[`fs-p-${suffix.toLowerCase()}-3`, 3],
			[`fs-p-${suffix.toLowerCase()}-4`, 4],
			[`fs-p-${suffix.toLowerCase()}-5`, 5],
			[`fs-p-${suffix.toLowerCase()}-6`, 6],
			[`fs-p-${suffix.toLowerCase()}-7`, 7],
			[`fs-p-${suffix.toLowerCase()}-8`, 8],
			[`fs-p-${suffix.toLowerCase()}-9`, 9],
			[`fs-p-${suffix.toLowerCase()}-10`, 10],
		];
	}
	return [
		['fs-p-0', 0],
		['fs-p-1', 1],
		['fs-p-2', 2],
		['fs-p-3', 3],
		['fs-p-4', 4],
		['fs-p-5', 5],
		['fs-p-6', 6],
		['fs-p-7', 7],
		['fs-p-8', 8],
		['fs-p-9', 9],
		['fs-p-10', 10],
	];
};

const useSpacingClasses = (props: Partial<SpacingProps>): [string, ClassesProduct] => {
	const [containerClass, classes] = useClassNames({
		...propsToClasses(props, 'spacing', getSpacingClasses),
	});
	return [containerClass, classes];
};

export default useSpacingClasses;
