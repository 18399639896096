import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ScanCtrl', ['$scope', '$rootScope', '$state', '$q', '$translate', 'Utils', 'AbisService', 'SubjectService', 'blockUI', 'EncountersResource', 'EncounterService', 'DocumentSubjectService', 'AuthDataHolder', 'ConvertionUtils', 'TransactionsResource', 'CapturerService', 'PainterService', 'StatusService', 'AlertService', 'BiometricsService', 'BiographicDataService',
		function ($scope, $rootScope, $state, $q, $translate, Utils, AbisService, SubjectService, blockUI, EncountersResource, EncounterService, DocumentSubjectService, AuthDataHolder, ConvertionUtils, TransactionsResource, CapturerService, PainterService, StatusService, AlertService, BiometricsService, BiographicDataService) {
			$scope.device = {};

			$scope.filterDataInternal = {};
			$scope.encounters = [];
			$scope.cnv = ConvertionUtils;
			$scope.verifyStatus = '';

			if ($state.params.transactionID) {
				$scope.types = {};
				$scope.statuses = {};
				const transactionId = $state.params.transactionID;
				$scope.person = DocumentSubjectService.getScannedSubject();
				$scope.opts = { activeTab: 'tab-results' };
				$scope.showVerifyView = true;
				blockUI.start('app.loading');
				TransactionsResource.get({ id: transactionId }).$promise
					.then((response) => {
						$scope.transaction = response;
						$scope.person.subjectId = response.subjectId;
						if (AuthDataHolder.hasAnyAuthority('PERMISSION_ENCOUNTER_VIEW')) {
							EncounterService.getHits(transactionId).then((hits) => {
								if (hits.length > 0) {
									const [{ encounterId }] = hits;
									EncounterService.getHit(transactionId, encounterId)
										.then((encounter) => {
											$scope.encounters.push(encounter);
											loadBiometrics(encounter.encounterId);
										})
										.finally(blockUI.stop);
								} else {
									blockUI.stop();
								}
							});
						}
					});

				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
					'transactions.type.identify', 'transactions.type.verify', 'transactions.type.update', 'transactions.type.delete',
					'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
				]).then((translations) => {
					$scope.types = {
						/* jshint sub:true */
						ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
						ENROLL: translations['transactions.type.enroll'],
						IDENTIFY: translations['transactions.type.identify'],
						VERIFY: translations['transactions.type.verify'],
						VERIFY_UPDATE: translations['transactions.type.update'],
						DELETE: translations['transactions.type.delete']
						/* jshint sub:false */
					};

					$scope.statuses = {
						/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						REJECTED: translations['transactions.status.rejected'],
						OK: translations['transactions.status.ok'],
						MATCHED: translations['transactions.status.matched'],
						NOT_MATCHED: translations['transactions.status.not-matched'],
						DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
						/* jshint sub:false */
					};
				});
			} else {
				$scope.person = {
					biographicData: {},
					faces: [],
					fingers: [],
					irises: [],
					palms: [],
					signature: {}
				};
				$scope.opts = $scope.opts || { activeTab: 'tab-scan' };
				$scope.showVerifyView = false;
			}


			function camelize(str) {
				return str
					.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase()))
					.replace(/\s+/g, '');
			}

			const KNOWN_FIELDS = ['BirthDate', 'DocumentNumber', 'ExpiryDate', 'Givenname', 'Surname', 'IssueCountry', 'Nationality', 'Sex'];
			function scannerFieldToBioField(property) {
				return property === 'Givenname' ? 'name' : camelize(property);
			}
			$scope.isBioFieldsRequirementsFulfilled = true;
			function checkForNecesaryFields() {
				const requiredFields = ['DocumentNumber', 'IssueCountry'].map(f => scannerFieldToBioField(f));
				BiographicDataService.get().then((fields) => {
					$scope.isBioFieldsRequirementsFulfilled = requiredFields.every(requiredField => fields.some(f => f.key === requiredField));
				});
			}
			checkForNecesaryFields();

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.homePage = function () {
				$state.go('actions.home');
			};

			function invalidate() {
				SubjectService.invalidate();
				$scope.person = {
					biographicData: {},
					faces: [],
					fingers: [],
					irises: [],
					palms: []
				};
				$scope.encounter = {};
				$scope.encounters = [];
				$scope.filterDataInternal = {};
				$scope.errorOccured = false;
				$scope.enrollEnabled = false;
			}

			$scope.isObjectEmpty = obj => Utils.isObjectEmpty(obj);

			function updateModalities() {
				if ($scope.person) {
					if ($scope.encounter && !$scope.isObjectEmpty($scope.encounter)) {
						$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.person, $scope.encounter);
					} else {
						$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.person);
					}
				}
			}

			$scope.modalitiesOptions = {
				showIrises: false,
				showFingers: { common: false, unknownCount: 0 },
				showPalms: false,
				showSignature: false
			};

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			StatusService.refresh();
			$scope.$watch(() => StatusService.getDocumentScannerStatus(), (newValue) => {
				if (newValue === 'CAPTURING_READY') {
					$scope.status = `${newValue}_SCAN_DOCUMENT`;
				} else {
					$scope.status = newValue;
				}
			}, true);
			$scope.$watch(() => CapturerService.getDevice('Document'), (newDevice) => {
				$scope.device = newDevice;
			}, true);

			function revokeAndNullUrl(url) {
				if (url !== null && url !== undefined) {
					URL.revokeObjectURL(url);
					url = null;
				}
				return url;
			}

			$scope.verify = function () {
				SubjectService.invalidate('faces');
				SubjectService.invalidate('fingers');
				SubjectService.invalidate('irises');
				SubjectService.invalidate('palms');
				SubjectService.invalidate('signature');
				AbisService.setPriority(80);
				$state.go('actions.verify', {
					previousState: $state.current,
					subjectId: $scope.encounter.subjectId
				});
			};

			function generateSubjectId() {
				return $rootScope.uuidv4();
			}

			$scope.startCapture = function () {
				CapturerService.refreshDevices().then(() => {
					if ($scope.status !== 'SOURCE_MISSING') {
						$scope.opts.activeTab = 'tab-scan';
						invalidate();
						CapturerService.registerAndLockDevice($scope.device.id)
							.then((deviceId) => {
								const config = {
									DocumentFaceImage: true,
									DocumentBiographicData: true
								};
								CapturerService.setConfiguration(deviceId, config)
									.then(() => {
										capture($scope.device.id);
									}, () => {
										AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
										$scope.errorOccured = true;
									});
							}, (error) => {
								CapturerService.handleError(error, AlertService);
								$scope.errorOccured = true;
							});
					}
				});

				function addBiographicFieldIfExists(object, property) {
					if (KNOWN_FIELDS.includes(property)) {
						$scope.person.biographicData[scannerFieldToBioField(property)] = object[property];
					}
				}

				function capture(deviceId) {
					$scope.status = 'STARTING';

					function checkIfAllModalitiesSucceeded(modalities) {
						['BiographicData', 'Face'].forEach((expectedModality) => {
							if (modalities.filter(modality => modality.metadata.Submodality === expectedModality).length === 0) {
								AlertService.show(`biometrics.scan.${expectedModality === 'BiographicData' ? 'biographic-data-reading-failed' : 'face-reading-failed'}`, { msTimeout: 6000, type: 'danger' });
							}
						});
					}

					function downloadPromise(deviceId, captureId) {
						return CapturerService.download(deviceId, captureId);
					}
					CapturerService.capture(deviceId)
						.then((result) => {
							var promises = [];
							for (var i = 0; i < result.captureIds.length; i += 1) {
								var promise = downloadPromise($scope.device.id, result.captureIds[i]);
								promises.push(promise);
							}
							$q.all(promises).then((results) => {
								checkIfAllModalitiesSucceeded(results);
								results.forEach((modality) => {
									const { metadata } = modality;
									switch (metadata.Submodality) {
									case 'BiographicData':
										Object.keys(metadata).forEach((property) => {
											addBiographicFieldIfExists(metadata, property);
										});

										if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
											$scope.person.galleryId = '';
										}

										$scope.person.subjectId = '';
										SubjectService.setId($scope.person.subjectId);
										SubjectService.setBiographicData($scope.person.biographicData);
										break;
									case 'Face': {
										$scope.person.faces.push({
											image: modality.sensorData,
											imageUrl: URL.createObjectURL(Utils.b64toBlob(modality.sensorData, 'image/x-ms-bmp'))
										});
										SubjectService.setFaceData(modality.sensorData);
										break;
									}
									default:
										break;
									}
								});
								updateModalities();
								$scope.status = 'PROCESS_NEXT_STEP';
							}, (error) => {
								$scope.status = CapturerService.handleErrorMessage(error);
								$scope.errorOccured = true;
							}).finally(() => {
								DocumentSubjectService.setScannedSubject($scope.person);
								CapturerService.unlock($scope.device.id);
							});
						}, (error) => {
							$scope.frameUrl = revokeAndNullUrl($scope.frameUrl);
							if (error.message) {
								AlertService.show(`capture-service.capture.status.${CapturerService.handleErrorMessage(error)}`, { type: 'info' });
							}
							$scope.status = CapturerService.handleError(error);
							$scope.errorOccured = true;
						});
					$scope.status = 'IN_PROGRESS';
				}
			};

			function prepareBiometrics(encounter) {
				['irises', 'fingers', 'palms', 'signature'].forEach((biometric) => {
					if (encounter[biometric]) {
						if (Array.isArray(encounter[biometric])) {
							encounter[biometric].forEach((elem) => {
								elem.index = encounter[biometric].indexOf(elem);
								elem.matchingDetails = [{}];
							});
						} else {
							encounter[biometric].index = 0;
							encounter[biometric].matchingDetails = [{}];
						}
					}
				});
			}

			function loadBiometrics(encounterId) {
				blockUI.start('app.loading');
				const [encounter] = $scope.encounters;
				EncounterService.loadEncounter(encounter, encounterId)
					.then(() => {
						prepareBiometrics(encounter);
						$scope.encounter = encounter;
					})
					.finally(() => {
						updateModalities();
						blockUI.stop();
					});
			}

			function loadMore() {
				blockUI.start('app.loading');
				EncountersResource.query(angular.extend({
					status: ['TEMPORARY_ENROLLED', 'ENROLLED']
				}, $scope.filterDataInternal), (value) => {
					if (value.length === 0) {
						AlertService.show('subject.not-found', { msTimeout: 6000, type: 'info' });
						$scope.enrollEnabled = true;
						return;
					}
					$scope.opts.activeTab = 'tab-results';
					blockUI.start('app.loading');
					EncountersResource.get({ encounterId: value[0].encounterId }).$promise
						.then((encounter) => {
							$scope.encounters.push(encounter);
							loadBiometrics(encounter.encounterId);
						})
						.finally(blockUI.stop);
				}).$promise
					.finally(() => {
						blockUI.stop();
					});
			}

			$scope.searchForSubject = function () {
				if ($scope.encounters.length === 0) {
					$scope.filterDataInternal = {
						documentNumber: $scope.person.biographicData.documentNumber,
						issueCountry: $scope.person.biographicData.issueCountry
					};
					loadMore();
					$scope.showVerifyView = false;
				} else {
					$scope.opts.activeTab = 'tab-results';
				}
			};

			$scope.enroll = function () {
				SubjectService.setId(generateSubjectId());
				$state.go('actions.enroll', { previousState: $state.current });
			};
		}]);
