import { useMemo } from 'react';

type PrimitiveClasses = string | number;
type CallableCondition = () => boolean;
export type Condition = boolean | CallableCondition;
export interface ConditionalClasses {
	[key: string]: Condition;
}

export type ArrayClasses = Array<InputClassName>;

export type InputClassName = PrimitiveClasses | ArrayClasses | ConditionalClasses;

export type ClassesProduct = string[];

const isArrayClasses = (elem: InputClassName): elem is ArrayClasses => {
	return Array.isArray(elem as ArrayClasses);
};

const isConditionalClasses = (elem: InputClassName): elem is ConditionalClasses => {
	return (elem as ConditionalClasses) != null && typeof elem === 'object';
};

const isCallableCondition = (elem: Condition): elem is CallableCondition => {
	return elem instanceof Function;
};

function goThroughClasses(providedArguments: InputClassName[]): ClassesProduct {
	const classes = new Set();
	for (let i = 0; i < providedArguments.length; i++) {
		const arg = providedArguments[i];
		if (isArrayClasses(arg)) {
			if (arg.length === 0) continue;
			const inner = goThroughClasses(arg);
			inner.forEach(classes.add, classes);
		} else if (isConditionalClasses(arg)) {
			Object.entries(arg).forEach(([key, value]) => {
				if ((isCallableCondition(value) && value()) || (!isCallableCondition(value) && value)) {
					classes.add(key);
				}
			});
		} else if (arg) {
			classes.add(arg.toString());
		}
	}
	return Array.from(classes) as ClassesProduct;
}

const useClassNames = function (...classArguments: InputClassName[]): [string, ClassesProduct] {
	const classes = useMemo(() => goThroughClasses(classArguments), [classArguments]);
	return [classes.join(' '), classes];
};

export default useClassNames;
