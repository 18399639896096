import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CaptureIrisesCtrl', ['$scope', '$timeout', '$q', 'AlertService', 'AbisService', 'BiometricsService', 'CapturerService', 'PainterService', 'StatusService', 'SubjectService', 'Utils', 'ParserResource', 'CapturePageService', 'SettingsService', 'AuthDataHolder',
		function ($scope, $timeout, $q, AlertService, AbisService, BiometricsService, CapturerService, PainterService, StatusService, SubjectService, Utils, ParserResource, CapturePageService, SettingsService, AuthDataHolder) {
			$scope.importImageFiles = [];
			$scope.isFinalStatus = CapturerService.isFinalStatus;
			$scope.frameUrl = Utils.getBlankBase64Image();
			$scope.fps = 0;
			$scope.warnings = null;
			$scope.capturingPosition = null;
			$scope.irisImages = {};
			$scope.parsedImages = {};
			$scope.missingIrises = BiometricsService.getMissingIrisesMap();
			$scope.isCapturing = () => CapturerService.isCaptureRunning('Iris');
			$scope.scenarioSelected = '';

			$scope.isBlur = SettingsService.getShouldBlurImages().iris;

			function bindIrisImages(irises) {
				function addUnknownIris(idx) {
					if (!Array.isArray($scope.irisImages[irises[idx].position])) {
						$scope.irisImages[irises[idx].position] = [];
					}
					$scope.irisImages[irises[idx].position].push(URL.createObjectURL(Utils.b64toBlob(irisImage, 'image/x-ms-bmp')));
				}

				Object.keys($scope.irisImages).forEach((iris) => {
					URL.revokeObjectURL($scope.irisImages[iris]);
				});
				$scope.irisImages = {};

				let irisImage;
				for (let i = 0; i < irises.length; i += 1) {
					irisImage = $scope.parsedImages[irises[i].position] || irises[i].data;
					if (irises[i].position === 'UNKNOWN') {
						addUnknownIris(i);
					} else {
						$scope.irisImages[irises[i].position] = URL.createObjectURL(Utils.b64toBlob(irisImage, 'image/x-ms-bmp'));
					}
				}
			}

			StatusService.refresh()
				.catch((err) => {
					if (err.message) {
						AlertService.show(err.message, { type: 'danger', translate: true });
					}
				});
			$scope.$on('$viewContentLoaded', () => {
				$scope.actionPageName = AbisService.getRequestTypeReadable();
				$scope.$watch(() => StatusService.getIrisScannerStatus(), (newValue) => {
					$scope.status = newValue;
				});
				$scope.$watch(() => SubjectService.getIrises(), (newValue) => {
					var irises = newValue;
					if (irises.length !== 0) {
						bindIrisImages(irises);
					} else {
						$scope.irisImages = {};
					}
				}, true);
				$scope.$watch(() => CapturerService.getDevice('Iris'), (newDevice) => {
					$scope.device = newDevice;
					if ($scope.device) {
						$scope.scenarioSelected = $scope.device.scenarios.selected;
					}
				}, true);
				$scope.$watch(() => CapturerService.getDevices('Iris'), (newDevices) => {
					$scope.devices = newDevices;
				}, true);
				$scope.isCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Iris');
				$scope.$watch(() => SettingsService.isCaptureOptionsVisible('Iris'), (newValue) => {
					$scope.isCaptureOptionsVisible = newValue;
				});
				$scope.$watch('scenarioSelected', (newScenario) => {
					if ($scope.device) {
						CapturerService.setDeviceByModality('Iris', $scope.device.id, newScenario);
					}
				});
				// broadcast from clickables.js
				$scope.$on('missingIrises:updated', (_event, missingIrises) => {
					if (!CapturerService.isCaptureRunning('Iris')) {
						bindIrisImages(SubjectService.getIrises());
						Object.keys(missingIrises).forEach((iris) => {
							if (missingIrises[iris] && $scope.parsedImages[iris]) {
								delete $scope.parsedImages[iris];
							}
						});
						$scope.missingIrises = missingIrises;
					}
				});
			});

			function getIrisByPosition(irises, position) {
				for (var i = 0; i < irises.length; i += 1) {
					if (position === irises[i].position) {
						return irises[i];
					}
				}
			}

			$scope.imageOnError = function (position) {
				if ($scope.parsedImages[position] !== undefined) {
					URL.revokeObjectURL($scope.irisImages[position]);
					$scope.irisImages[position] = URL.createObjectURL(Utils.b64toBlob($scope.irisImages[position], 'image/x-ms-bmp'));
				} else {
					const irises = SubjectService.getIrises();
					if (irises && irises.length > 0) {
						const imageData = getIrisByPosition(irises, position).data;
						ParserResource.parseImage({ data: imageData }, (result) => {
							$scope.irisImages[position] = URL.createObjectURL(Utils.b64toBlob(result.data, 'image/x-ms-bmp'));
							$scope.parsedImages[position] = result.data;
						});
					}
				}
			};

			function invalidate() {
				const irises = SubjectService.getIrises();
				for (let i = 0; i < irises.length; i += 1) {
					URL.revokeObjectURL($scope.irisImages[irises[i].position]);
				}
				$scope.irisImages.LEFT = null;
				$scope.irisImages.RIGHT = null;
				$scope.irisImages.BOTH = null;
				SubjectService.invalidate('irises');
				BiometricsService.resetMissingIrises();
				$scope.errorOccured = false;
				PainterService.removeBorders('iris');
			}

			$scope.$on('$destroy', () => {
				if ($scope.isCapturing()) {
					$scope.stopCapture(false);
				}
			});

			$scope.captureCompound = function () {
				function getCaptureQueue() {
					const queue = [];
					const isLeftMissing = BiometricsService.getMissingIrisesPositions().indexOf('LEFT') !== -1;
					const isRightMissing = BiometricsService.getMissingIrisesPositions().indexOf('RIGHT') !== -1;

					if (isLeftMissing && isRightMissing) {
						return;
					} else if (isLeftMissing && !isRightMissing) {
						queue.push('RIGHT');
					} else if (!isLeftMissing && isRightMissing) {
						queue.push('LEFT');
					} else if ($scope.device.scenarios.selected === 'bothEyes') {
						queue.push('BOTH');
					} else if ($scope.device.scenarios.selected === 'allPlainEyes') {
						queue.push('LEFT', 'RIGHT');
					}
					return queue;
				}

				let captureQueue;
				const tempMissingIrises = BiometricsService.getMissingIrisesPositions();
				const tempMissingIrisesMap = BiometricsService.getMissingIrisesMap();
				invalidate();
				SubjectService.setMissingIrises(tempMissingIrises);
				BiometricsService.setMissingIrises(tempMissingIrisesMap);

				StatusService.refresh().then(() => {
					if ($scope.status === 'SOURCE_MISSING') {
						return;
					}
					captureQueue = getCaptureQueue();
					queuedCapture(captureQueue);
				});
			};

			function queuedCapture(queue) {
				if (!$scope.device || queue.length === 0) {
					CapturerService.stopCapturingModality($scope.device.modality);
					return;
				}

				CapturerService.startModalityCapture($scope.device.modality);
				$scope.capturingPosition = queue.shift();
				CapturerService.registerAndLockDevice($scope.device.id)
					.then((deviceId) => {
						const config = {
							Submodality: Utils.upperUnderscoreToCamelCase($scope.device.positions.indexOf($scope.capturingPosition) === -1 ? 'UNKNOWN' : $scope.capturingPosition) // FIXME: Temporal.
						};
						CapturerService.setConfiguration(deviceId, config)
							.then(() => {
								capture();
							}, () => {
								AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
							});
					}, (error) => {
						CapturerService.handleError(error, AlertService);
						$scope.errorOccured = true;
					});

				function capture() {
					$scope.parsedImages = {};

					function downloadPromise(deviceId, captureId) {
						return CapturerService.download(deviceId, captureId);
					}

					PainterService.addBorder(`${Utils.underscoreToDash($scope.capturingPosition)}-container`, true);
					CapturerService.capture($scope.device.id)
						.then((result) => {
							PainterService.removeBorders('iris');
							if (result.captureIds.length === 1) {
								CapturerService.download($scope.device.id, result.captureIds[0]).then((result) => {
									$scope.status = 'PROCESS_NEXT_STEP';
									const irises = [{
										position: $scope.capturingPosition,
										image: result.sensorData
									}];
									SubjectService.setIrises(irises);
									PainterService.removeBorders('iris');
									$scope.capturingPosition = null;
									URL.revokeObjectURL($scope.frameUrl);
									queuedCapture(queue);
								}, (error) => {
									CapturerService.stopCapturingModality($scope.device.modality);
									CapturerService.handleError(error);
									$scope.errorOccured = true;
									PainterService.removeBorders('iris');
								});
							} else if (result.captureIds.length > 1) {
								const promises = [];
								for (let i = 0; i < result.captureIds.length; i += 1) {
									const promise = downloadPromise($scope.device.id, result.captureIds[i]);
									promises.push(promise);
								}
								$q.all(promises).then((results) => {
									const irises = [];
									for (let i = 0; i < results.length; i += 1) {
										irises.push({
											position: Utils.camelCaseToUpperUnderscore(results[i].metadata.Submodality),
											image: results[i].sensorData
										});
									}
									SubjectService.setIrises(irises);
									$scope.status = 'PROCESS_NEXT_STEP';
									$scope.capturingPosition = null;
									URL.revokeObjectURL($scope.frameUrl);
									PainterService.removeBorders('iris');
									queuedCapture(queue);
								}, (error) => {
									CapturerService.stopCapturingModality($scope.device.modality);
									if (error.message) {
										AlertService.show(`capture-service.capture.status.${CapturerService.handleErrorMessage(error)}`, { type: 'info' });
									}
									CapturerService.handleError(error);
									$scope.errorOccured = true;
									PainterService.removeBorders('iris');
								});
							}
						}, (error) => {
							$scope.irisImages.LEFT = null;
							$scope.irisImages.RIGHT = null;
							$scope.irisImages.BOTH = null;
							if (error.message) {
								AlertService.show(`capture-service.capture.status.${CapturerService.handleErrorMessage(error)}`, { type: 'info' });
							}
							CapturerService.stopCapturingModality($scope.device.modality);
							$scope.status = CapturerService.handleError(error);
							$scope.errorOccured = true;
						})
						.finally(() => {
							PainterService.removeBorders('iris');
							CapturerService.unlock($scope.device.id);
						});
				}

				function revokeAndNullIris(position) {
					if ($scope.irisImages[position] !== null) {
						URL.revokeObjectURL($scope.irisImages[position]);
						$scope.irisImages[position] = null;
					}
				}

				function preview(deviceId, etag) {
					CapturerService.previewImage(deviceId, etag).then((response) => {
						if (CapturerService.isFinalStatus($scope.status) || response.isFinalImage || $scope.errorOccured) {
							revokeAndNullIris('BOTH');
							return;
						}
						revokeAndNullIris($scope.capturingPosition);
						$scope.status = 'IN_PROGRESS';
						if (response.image.size > 0) {
							$scope.irisImages[$scope.capturingPosition] = URL.createObjectURL(response.image, 'image/x-ms-bmp');
						}
						preview(deviceId, response.etag);
					}, () => {
						$scope.errorOccured = false;
						if (!CapturerService.isFinalStatus($scope.status)) {
							$timeout(() => {
								preview(deviceId, '0');
							}, 100);
						}
					});
				}

				$scope.status = 'STARTING';
				preview($scope.device.id, '0');
			}

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			$scope.forceCapture = function () {
				CapturerService.force($scope.device.id);
			};

			$scope.stopCapture = function (clearData) {
				if (clearData) {
					invalidate();
				}
				if ($scope.device !== null) {
					CapturerService.cancel($scope.device.id).finally(() => {
						CapturerService.unlock($scope.device.id);
					});
				}
			};

			$scope.biometricsPage = function () {
				CapturePageService.goBack();
			};

			$scope.onEyeClick = function (id) {
				const idPositionMap = {
					'left-eye': 'LEFT',
					'right-eye': 'RIGHT'
				};
				if ($scope.irisImages[idPositionMap[id]]) {
					$timeout(() => { // Need to break out of the current $apply() cycle to perform this
						const svg = document.getElementById(id);
						const path = angular.element(svg.getElementsByClassName('cursor-pointer')[0]);
						path.triggerHandler('click');
					}, 0);
				}
			};

			$scope.onUnknownEyeClick = function (index) {
				if ($scope.irisImages.UNKNOWN[index]) {
					SubjectService.removeIrisByIndex(index);
				}
			};

			$scope.importImage = function (position) {
				function setImportData(data, notValidImage) {
					removeUnknownIrisesIfPresent();
					SubjectService.setIrises([{
						position,
						image: $scope.importImageFiles[position].content
					}]);
					$scope.frameUrl = URL.createObjectURL(Utils.b64toBlob(data, 'image/x-ms-bmp'));
					if ($scope.parsedImages[position] !== undefined) {
						delete $scope.parsedImages[position];
					}
					if (notValidImage) {
						$scope.parsedImages[position] = data;
					}

					AlertService.show('biometrics.import.imported', { type: 'success' });
				}
				if ($scope.importImageFiles[position].type.match(/^image\//)) {
					setImportData($scope.importImageFiles[position].content, false);
				} else {
					const data = $scope.importImageFiles[position].content;
					ParserResource.parseImage({ data }, (result) => {
						setImportData(result.data, true);
					}, () => {
						AlertService.show('forms.invalid-image', { type: 'danger' });
					});
				}
			};

			function removeUnknownIrisesIfPresent() {
				SubjectService.removeIrisByPosition(['UNKNOWN', 'UNKNOWN']);
			}

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.removeImage = function (position) {
				function revokeIrisByPosition() {
					URL.revokeObjectURL($scope.irisImages[position]);
					$scope.irisImages[position] = null;
				}

				revokeIrisByPosition();
				SubjectService.removeIrisByPosition(position);
			};
		}]);
