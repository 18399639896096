import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.directive('comments', () => ({
		template: require('../../views/directives/comments.html'),
		restrict: 'E',
		replace: true,
		scope: {
			history: '='
		},
		controller: ['$scope', '$translate', 'AuthDataHolder', function ($scope, $translate, AuthDataHolder) {
			$scope.userName = AuthDataHolder.getUserInfo().name;
			$scope.needsSummary = function (comment) {
				return comment !== null && comment.length > COLLAPSE_BOUND;
			};

			$scope.statuses = {};
			const COLLAPSE_BOUND = 50;

			$translate([
				'adjudication.case.status.adjudication-waiting', 'adjudication.case.status.adjudication-in-progress', 'adjudication.case.status.in-progress', 'adjudication.case.status.adjudication-conflict',
				'adjudication.case.status.duplicate', 'adjudication.case.status.unique', 'adjudication.case.status.undecided'
			]).then((translations) => {
				$scope.statuses = {
					/* jshint sub:true */
					ADJUDICATION_WAITING: translations['adjudication.case.status.adjudication-waiting'],
					ADJUDICATION_IN_PROGRESS: translations['adjudication.case.status.adjudication-in-progress'],
					IN_PROGRESS: translations['adjudication.case.status.in-progress'],
					ADJUDICATION_CONFLICT: translations['adjudication.case.status.adjudication-conflict'],
					UNIQUE: translations['adjudication.case.status.unique'],
					DUPLICATE: translations['adjudication.case.status.duplicate'],
					UNDECIDED: translations['adjudication.case.status.undecided']
					/* jshint sub:false */
				};
			});

			$scope.timestampToTimeDate = function (timestamp) {
				return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
			};
			$scope.getSummarizedText = function (comment) {
				return comment !== null ? `${comment.substring(0, COLLAPSE_BOUND)}...` : '';
			};

			$scope.$watch('history', () => {
				$scope.isCommentCollapsed = $scope.history.map(activity => $scope.needsSummary(activity.comment));
			}, true);
		}]
	}));
