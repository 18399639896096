import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('fpirTable', [function () {
		return {
			template: require('../../../views/directives/admin/fpir-table.html'),
			restrict: 'E',
			replace: true,
			scope: {
				lowerFpir: '=',
				upperFpir: '=',
			},
			controllerAs: 'fpirTableCtrl',
			bindToController: true,
			controller: ['$scope', 'GalleriesService', 'FPIRConverterService', function ($scope, GalleriesService, FPIRConverterService) {
				const self = this;

				this.fetchGallerySizes = function () {
					return GalleriesService.getGallerySizes()
						.then((galleries) => {
							self.gallerySizes = galleries;
						});
				};

				this.getScoresFromSize = FPIRConverterService.fpirToScore;
			}],
		};
	}]);
