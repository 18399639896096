import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('ParametersResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/parameters`, {}, {
			get: {
				method: 'GET',
				isArray: true,
			}
		});
	}]);
