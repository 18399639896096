import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('ScenarioService', ['BiometricsService', 'store', 'Utils', 'FingerScenarios', 'PalmScenarios',
		function (BiometricsService, store, Utils, FingerScenarios, PalmScenarios) {
			const self = this;
			const storeName = 'selectedScenarios';
			const statuses = ['ok', 'capturing', 'queued', 'skipped'];

			const modalitiesScenarios = {
				Finger: FingerScenarios,
				Palm: PalmScenarios
			};

			let selectedScenario = {
				Finger: FingerScenarios.getDefaultScenario(),
				Palm: PalmScenarios.getDefaultScenario()
			};

			function hasPositionsAnyElements(positions, elements) {
				return elements.some(identifier => positions.some(finger => finger.position === identifier));
			}

			const scenariosIdentifier = {
				fourFourTwo: scenario => hasPositionsAnyElements(scenario.flat(), ['PLAIN_LEFT_FOUR_FINGERS', 'PLAIN_RIGHT_FOUR_FINGERS', 'PLAIN_THUMBS']),
				allPlainFingers: (scenario) => {
					const positions = scenario.flat();
					return !hasPositionsAnyElements(positions, ['PLAIN_LEFT_FOUR_FINGERS', 'PLAIN_RIGHT_FOUR_FINGERS', 'PLAIN_THUMBS'])
					&& hasPositionsAnyElements(positions, ['LEFT_LITTLE_FINGER', 'LEFT_THUMB', 'RIGHT_INDEX_FINGER']);
				},
				unknownPlainFingers: (scenario) => {
					const positions = scenario.flat();
					return hasPositionsAnyElements(positions, ['UNKNOWN']);
				},
				lowerPalms: scenario => hasPositionsAnyElements(scenario.flat(), ['LEFT_LOWER_PALM', 'RIGHT_LOWER_PALM']),
				upperPalms: scenario => hasPositionsAnyElements(scenario.flat(), ['LEFT_UPPER_PALM', 'RIGHT_UPPER_PALM']),
				lowerAndUpperPalms: scenario => hasPositionsAnyElements(scenario.flat(), ['LEFT_LOWER_PALM', 'RIGHT_LOWER_PALM', 'LEFT_UPPER_PALM', 'RIGHT_UPPER_PALM']),
				fullPalms: scenario => hasPositionsAnyElements(scenario.flat(), ['RIGHT_FULL_PALM', 'LEFT_FULL_PALM']),
			};

			const scenarios = Object.assign(
				{},
				modalitiesScenarios.Finger.getScenarios(),
				modalitiesScenarios.Palm.getScenarios()
			);

			function load() {
				const storedSelection = store.get(storeName);
				if (storedSelection) {
					selectedScenario = storedSelection;
				}
			}
			load();

			function save() {
				store.set(storeName, selectedScenario);
			}

			function isUnknownModality(modality) {
				return !Object.prototype.hasOwnProperty.call(selectedScenario, modality);
			}

			function throwIfUnknownModality(modality) {
				if (isUnknownModality(modality)) {
					throw new Error(`Unknown modality: ${modality}`);
				}
			}

			this.getSelected = function (modality = 'Finger') {
				throwIfUnknownModality(modality);
				return selectedScenario[modality];
			};

			this.setSelected = function (selected, modality = 'Finger') {
				throwIfUnknownModality(modality);
				selectedScenario[modality] = selected || modalitiesScenarios[modality].getDefaultScenario();
				save();
			};

			this.getOptions = function (modality = 'Finger') {
				if (Object.prototype.hasOwnProperty.call(modalitiesScenarios, modality)) {
					return modalitiesScenarios[modality].getOptions();
				}
				return [];
			};

			// TODO: Extract irises modality into separate service
			this.getCapturerOptionsByDeviceProperties = function (modality, positions) {
				let scenariosPayload = {
					selected: null,
					options: []
				};

				if (modality === 'Iris') {
					if (Utils.inArray('BOTH', positions)) {
						scenariosPayload.options.push('bothEyes');
						scenariosPayload.selected = 'bothEyes';
					}
					if (Utils.inArray('UNKNOWN', positions) || (Utils.inArray('LEFT', positions) && Utils.inArray('RIGHT', positions))) {
						scenariosPayload.options.push('allPlainEyes');
						if (scenariosPayload.selected === null) {
							scenariosPayload.selected = 'allPlainEyes';
						}
					}
					return scenariosPayload;
				} else if (!isUnknownModality(modality)) {
					scenariosPayload = modalitiesScenarios[modality].getCapturerOptionsByDeviceProperties(positions);
				}
				return scenariosPayload;
			};

			this.getCaptureQueue = function (scenario) {
				return scenario.map(step => ({ position: step[0].position, id: step[0].id }));
			};

			this.getStep = function (scenario, identifier, modality = 'Finger') {
				throwIfUnknownModality(modality);
				return modalitiesScenarios[modality].getStep(scenario, identifier);
			};

			function throwIfUnknownStatus(status) {
				if (!Utils.inArray(status, statuses)) {
					throw new Error(`Unknown capturer scenario status ${status}`);
				}
			}

			this.setStepStatus = function (scenario, identifier, status, modality = 'Finger') {
				throwIfUnknownStatus(status);
				throwIfUnknownModality(modality);
				modalitiesScenarios[modality].setStepStatus(scenario, identifier, status);
			};

			function throwIfUnknownScenario(scenarioName) {
				if (!scenarios[scenarioName]) {
					throw new Error('Unknown scenario');
				}
			}

			this.getEmptyScenario = function (scenarioName) {
				throwIfUnknownScenario(scenarioName);
				var emptyScenario = [];
				angular.copy(scenarios[scenarioName].queue, emptyScenario);
				return emptyScenario;
			};

			// TODO: Refactor this train wreck of loops
			function recreateScenarioDto(scenarioName, originalCapturedSteps) {
				var scenarioDto = self.getEmptyScenario(scenarioName);
				const capturedStepsStack = angular.copy(originalCapturedSteps);
				for (var i = 0; i < scenarioDto.length; i += 1) {
					var emptyStep = scenarioDto[i];
					var mainEmptyScenarioPosition = BiometricsService.getSlapOrFirstFinger(emptyStep).position;
					for (var j = 0; j < capturedStepsStack.length; j += 1) {
						var capturedStep = capturedStepsStack[j];
						var mainCapturedScenarioPosition = BiometricsService.getSlapOrFirstFinger(capturedStep).position;
						// if (mainEmptyScenarioPosition === mainCapturedScenarioPosition || BiometricsService.getPositions(mainCapturedScenarioPosition).indexOf(mainEmptyScenarioPosition) !== -1) {
						if (mainEmptyScenarioPosition === mainCapturedScenarioPosition) {
							for (var m = 0; m < emptyStep.length; m += 1) {
								var notFound = true;
								for (var n = 0; n < capturedStep.length; n += 1) {
									if (emptyStep[m].position === capturedStep[n].position
										&& emptyStep[m].id === capturedStep[n].id) {
										Object.assign(emptyStep[m], capturedStepsStack[j].splice(n, 1)[0]);
										if (capturedStepsStack[j].length === 0) {
											capturedStepsStack.splice(j, 1);
											j -= 1;
										}
										// emptyStep[m].quality = capturedStep[n].quality;
										notFound = false;
									}
									if (emptyStep[m].position === 'UNKNOWN') {
										notFound = false;
									}
								}
								if (notFound) {
									emptyStep[m].status = 'skipped';
								}
							}
						}
					}
				}
				return scenarioDto;
			}

			this.recreateScenarioDto = function (scenarioName, capturedSteps, modality = null) {
				if (modality !== 'Palm') {
					return recreateScenarioDto(scenarioName, capturedSteps);
				}
				const emptyScenario = self.getEmptyScenario(scenarioName);
				return modalitiesScenarios.Palm.recreateScenario(emptyScenario, capturedSteps);
			};

			this.getScenarioConfig = function (scenarioName) {
				throwIfUnknownScenario(scenarioName);
				return scenarios[scenarioName].config;
			};

			this.getScenarioSettings = function (scenarioName) {
				throwIfUnknownScenario(scenarioName);
				return scenarios[scenarioName].settings;
			};

			function getCapturedModalities(scenario) {
				return scenario.filter(step => step[0].status !== 'queued');
			}

			function getFirstQueuedStep(scenario) {
				return scenario.find(step => step[0].status === 'queued');
			}

			this.makeScenarioIterative = function (scenario) {
				const newScenario = getCapturedModalities(scenario);
				const firstQueuedStep = getFirstQueuedStep(scenario);
				if (firstQueuedStep) {
					newScenario.push(firstQueuedStep);
				}
				return newScenario;
			};

			this.identifyScenario = function (scenario, modality = 'Finger') {
				const modalityScenarios = modalitiesScenarios[modality].getOptions();
				let newScenario = null;
				for (let i = 0; i < modalityScenarios.length; i += 1) {
					const modalityScenario = modalityScenarios[i];
					if (scenariosIdentifier[modalityScenario](scenario)) {
						newScenario = modalityScenario;
						break;
					}
				}
				if (newScenario) {
					self.setSelected(newScenario);
				}
			};
		}]);
