import angular from 'angular';

angular.module('neurotecAbisWebClientApp').service('FARConverterService', [function () {
	const FAR_LOG_RATIO = 12;

	this.toFar = function (threshold) {
		if (threshold < 0) threshold = 0;
		const far = 10.0 ** (-threshold / FAR_LOG_RATIO);
		return far;
	};

	this.fromFar = function (far) {
		if (far > 1) far = 1;
		else if (far <= 0.0) far = 1E-100;
		return Math.round(-Math.log10(far) * FAR_LOG_RATIO);
	};
}]);
