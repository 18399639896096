import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('horizontalScroll', () => ({
		template: require('../../views/directives/horizontal-scroll-selection.html'),
		restrict: 'E',
		replace: true,
		scope: {
			elements: '=',
			subjectsMarked: '=',
			selectedIdx: '=',
			selectSubject: '&',
			showCount: '=',
			active: '=',
			myInterval: '=',
			noWrapSlides: '=',
			slideClass: '@',
			isCaseOwned: '=',
			badge: '=?',
			invalidateSlideStatusWatcher: '&?'
		},
		controller: ['$scope', '$window', function ($scope, $window) {
			$scope.slides = [];
			$scope.slideIndex = null;
			$scope.scrollValue = Number.isInteger($scope.active) ? $scope.active : 1;

			const rawSlideSize = 55;
			const margingForAlignment = 5;
			$scope.slideSize = rawSlideSize + margingForAlignment;
			$scope.showCountInternal = 0;

			function getActiveSlide() {
				return Math.trunc($scope.selectedIdx / $scope.showCountInternal);
			}

			function configureSlides() {
				$scope.slides = [];
				const initialArrayLength = $scope.elements.length;
				const arrayOfElems = angular.copy($scope.elements);

				if ($scope.showCountInternal > 0) {
					for (let i = 0; i < initialArrayLength; i += $scope.showCountInternal) {
						$scope.slides.push(arrayOfElems.slice(i, i + $scope.showCountInternal).map(sliceId => ({
							id: sliceId,
							touched: false,
							dirty: false
						})));
					}
					$scope.active = getActiveSlide();
				}
			}

			function reconfigureSlides() {
				const slides = [];
				const initialArrayLength = $scope.elements.length;
				const arrayOfElems = angular.copy($scope.elements);

				if ($scope.showCountInternal > 0) {
					for (let i = 0; i < initialArrayLength; i += $scope.showCountInternal) {
						slides.push(arrayOfElems.slice(i, i + $scope.showCountInternal).map((sliceId) => {
							for (let j = 0; j < $scope.slides.length; j += 1) {
								for (let k = 0; k < $scope.slides[j].length; k += 1) {
									const elem = $scope.slides[j][k];
									if (elem.id === sliceId) {
										return $scope.slides[j][k];
									}
								}
							}

							return {
								id: sliceId,
								touched: false,
								dirty: false
							};
						}));
					}
					$scope.slides = slides;
					$scope.active = getActiveSlide();
				}
			}

			function determineSliderWidth() {
				const sliderStyle = document.getElementById('scroll-selection-slider-style');
				const width = Math.floor(100 / $scope.slides.length);
				sliderStyle.innerHTML = `.carousel-slider .slider::-webkit-slider-thumb { width: ${width}%; }
										.carousel-slider .slider::-moz-range-thumb { width: ${width}%; }`;
			}

			function matchSlideCountToWidth() {
				const carouselDimensions = document.getElementById('horizontal-scroll-carousel').getBoundingClientRect();
				const maxSlidesInCarousel = Math.floor(carouselDimensions.width / $scope.slideSize);
				$scope.showCountInternal = $scope.showCount > maxSlidesInCarousel ? (maxSlidesInCarousel - 1) : $scope.showCount;
			}

			function onResize() {
				matchSlideCountToWidth();
				reconfigureSlides();
			}

			function debounce(callback, wait, immediate = false) {
				let timeout = null;
				return function (...args) {
					const callNow = immediate && !timeout;
					const next = () => callback.apply(this, args);

					clearTimeout(timeout);
					timeout = setTimeout(next, wait);

					if (callNow) {
						next();
					}
				};
			}

			function init() {
				angular.element(() => {
					matchSlideCountToWidth();
					const resizeFunc = debounce(onResize, 100);
					$window.addEventListener('resize', resizeFunc);
					$scope.$on('$destroy', () => $window.removeEventListener('resize', resizeFunc));

					configureSlides();
					determineSliderWidth();
				});
			}

			function setHitsPristine() {
				$scope.slides.forEach((slide) => {
					slide.forEach((elem) => {
						elem.touched = false;
						elem.dirty = false;
					});
				});
			}

			let stopWatchingForInvalidation = null;
			function setWatchers() {
				if ($scope.invalidateSlideStatusWatcher) {
					stopWatchingForInvalidation = $scope.$watch($scope.invalidateSlideStatusWatcher(), (_, oldValue) => {
						if (oldValue !== undefined) {
							setHitsPristine();
							markHitDirty($scope.selectedIdx);
						}
					});
				}
			}

			function stopWatchers() {
				if (stopWatchingForInvalidation) {
					stopWatchingForInvalidation();
				}
			}

			$scope.$watch('elements', (newValue) => {
				if (newValue.length !== 0) {
					init();
					stopWatchers();
					setWatchers();
				}
			}, true);

			function markDirtyHitsTouched(oldValue, newValue) {
				if (newValue !== undefined && oldValue !== undefined && $scope.slides.length > 0) {
					$scope.slides.forEach((slide) => {
						slide.forEach((elem) => {
							if (elem.dirty) {
								elem.touched = true;
							}
						});
					});
				}
			}

			function scrollWithSelectedIdx(newValue) {
				const page = Math.trunc(($scope.selectedIdx) / $scope.showCountInternal);
				if (Number.isInteger(newValue) && $scope.scrollValue !== page) $scope.scrollValue = page;
			}

			function markHitDirty(newValue) {
				if (newValue !== undefined && $scope.slides.length > 0) {
					$scope.slides[getActiveSlide()][newValue - $scope.active * $scope.showCountInternal].dirty = true;
				}
			}

			$scope.$watch('selectedIdx', (newValue, oldValue) => {
				scrollWithSelectedIdx(newValue);
				markDirtyHitsTouched(oldValue, newValue);
				markHitDirty(newValue);
			});

			$scope.slideToElement = function (parentIdx, childIdx) {
				return $scope.elements[parentIdx * $scope.showCountInternal + childIdx];
			};

			$scope.statusToTranslation = {
				UNIQUE: 'unique',
				DUPLICATE: 'duplicate',
				UNDECIDED: 'undecided',
				CONFLICT: 'conflict'
			};
		}]
	}))
	.directive('uibCustomCarousel', () => ({
		restrict: 'A',
		require: 'uibCarousel',
		link($scope, _$element, _$attr, uibCarouselCtrl) {
			$scope.$watch('scrollValue', (newValue, oldValue) => {
				if (!Number.isInteger(oldValue) || !Number.isInteger(newValue)
					|| uibCarouselCtrl.slides.length === 0) return;

				if (newValue > oldValue) {
					uibCarouselCtrl.select(uibCarouselCtrl.slides[newValue], 'next');
				} else {
					uibCarouselCtrl.select(uibCarouselCtrl.slides[newValue], 'prev');
				}
			});
			$scope.$watch(() => uibCarouselCtrl.getCurrentIndex(), (newValue) => {
				if (Number.isInteger(newValue)) $scope.scrollValue = newValue;
			}, true);
		}
	}));
