import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('CapturePalmsCtrl', ['$scope', '$state', '$translate', '$confirm', '$q', '$timeout', 'CapturerService', 'AbisService', 'SubjectService', 'AlertService', 'ScenarioService', 'StatusService', 'Utils', 'PainterService', 'ParserResource', 'CapturePageService', 'namingService', 'SettingsService', 'AuthDataHolder',
		function ($scope, $state, $translate, $confirm, $q, $timeout, CapturerService, AbisService, SubjectService, AlertService, ScenarioService, StatusService, Utils, PainterService, ParserResource, CapturePageService, namingService, SettingsService, AuthDataHolder) {
			$scope.isFinalStatus = CapturerService.isFinalStatus;
			$scope.importImageFiles = [];
			$scope.capturingPosition = null;
			$scope.palmImages = {};
			$scope.palmPosition = '';
			$scope.parsedImages = {};
			$scope.failedScan = false;
			$scope.previousPosition = '';
			$scope.errorOccured = false;

			$scope.hasPalms = () => (SubjectService.getPalmsCount() > 0);
			$scope.upperSnakeToKebabCase = namingService.upperSnakeToKebabCase;

			$scope.isBlur = SettingsService.getShouldBlurImages().palm;

			StatusService.refresh()
				.catch((err) => {
					if (err.message) {
						AlertService.show(err.message, { type: 'danger', translate: true });
					}
				});
			$scope.$on('$viewContentLoaded', () => {
				var capturedSteps;
				$scope.actionPageName = AbisService.getRequestTypeReadable();
				$scope.scenario = ScenarioService.getEmptyScenario('fullPalms');

				$scope.$watch(() => StatusService.getPalmScannerStatus(), (newValue) => {
					$scope.status = newValue;
				});

				$scope.$watch(() => SubjectService.getPalms(), (newCapturedSteps) => {
					capturedSteps = newCapturedSteps;
					$scope.scenario = capturedSteps ? ScenarioService.recreateScenarioDto($scope.scenarioSelected, capturedSteps, 'Palm') : null;
				}, true);
				$scope.$watch(() => CapturerService.getDevices('Palm'), (newDevices) => {
					$scope.devices = newDevices;
				}, true);
				$scope.$watch(() => CapturerService.getDevice('Palm'), (newDevice) => {
					$scope.device = newDevice;
					if (newDevice) {
						$scope.optionsPayload = $scope.device.scenarios.options;
						$scope.scenarioSelected = $scope.device.scenarios.selected;
					}
				}, true);
				$scope.scenarioSelected = ScenarioService.getSelected('Palm');
				$scope.$watch('scenarioSelected', (newValue, oldValue) => {
					$scope.scenarioSelected = newValue;
					const isDifferentScenario = newValue !== oldValue && newValue !== undefined && oldValue !== undefined;
					if (isDifferentScenario) {
						ScenarioService.setSelected(newValue, 'Palm');
						invalidate();
						$scope.scenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
					} else {
						$scope.scenario = $scope.scenarioSelected && capturedSteps ? ScenarioService.recreateScenarioDto($scope.scenarioSelected, capturedSteps, 'Palm') : null;
					}
				});
				$scope.$watch(() => ScenarioService.getSelected('Palm'), (newValue) => {
					$scope.scenarioSelected = newValue;
				});
				$scope.isCaptureOptionsVisible = SettingsService.isCaptureOptionsVisible('Palm');
				$scope.$watch(() => SettingsService.isCaptureOptionsVisible('Palm'), (newValue) => {
					$scope.isCaptureOptionsVisible = newValue;
				});
				$scope.$watch('device.id', (newFingerScannerId) => {
					CapturerService.setDeviceByModality('Palm', newFingerScannerId);
				});
				$scope.$watch(() => ScenarioService.getOptions('Palm'), (newOptions) => {
					$scope.scenarioOptions = newOptions;
					try {
						$scope.optionsPayload = $scope.device.scenarios.options || $scope.scenarioOptions;
					} catch (e) {
						$scope.optionsPayload = $scope.scenarioOptions;
					}
				});
			});

			$scope.$on('$destroy', () => {
				if ($scope.isCapturing) {
					$scope.stopCapture(false);
				}
			});

			$scope.stopCapture = function (doClearData) {
				if (doClearData) {
					invalidate();
				}
				if ($scope.device !== null) {
					CapturerService.cancel($scope.device.id).finally(() => {
						CapturerService.unlock($scope.device.id);
					});
				}
			};

			function revokeAndNullUrl(url) {
				if (url !== null && url !== undefined) {
					URL.revokeObjectURL(url);
					url = null;
				}
				return url;
			}

			function invalidate() {
				SubjectService.invalidate('palms');
				var palms = SubjectService.getPalms();
				for (let i = 0; i < palms.length; i += 1) {
					URL.revokeObjectURL($scope.palmImages[palms[i].position]);
					$scope.palmImages[palms[i].position] = null;
				}
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);
				$scope.palmPosition = '';
				$scope.errorOccured = false;
				$scope.isCapturing = false;
			}

			function previewFrame(image, quality) {
				if (quality === undefined) {
					$scope.previewQuality = null;
				}
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);

				if (typeof image === 'string') {
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(image, 'image/x-ms-bmp'));
				} else if (image.size === 0) {
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(Utils.getBlankBase64Image(), 'image/x-ms-bmp'));
				} else {
					$scope.previewImage = URL.createObjectURL(image);
				}
			}

			function getPalmsByPosition(palms, position) {
				for (var i = 0; i < palms.length; i += 1) {
					for (var j = 0; j < palms[i].length; j += 1) {
						if (position === palms[i][j].position) {
							return palms[i][j];
						}
					}
				}
			}

			$scope.imageOnError = () => {
				if ($scope.parsedImages[$scope.palmPosition] !== undefined) {
					$scope.previewImage = revokeAndNullUrl($scope.previewImage);
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob($scope.parsedImages[$scope.palmPosition], 'image/x-ms-bmp'));
				} else {
					const steps = SubjectService.getPalms();
					if (steps != null) {
						const imageData = getPalmsByPosition(steps, $scope.palmPosition);
						if (imageData && imageData.data) {
							ParserResource.parseImage({ data: imageData }, (result) => {
								$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(result.data, 'image/x-ms-bmp'));
								$scope.parsedImages[$scope.palmPosition] = result.data;
							});
						}
					}
				}
			};

			$scope.recapturePosition = function (position, $event) {
				$event.stopPropagation();
				queuedCapture([{ position }]);
			};

			$scope.captureCompound = function () {
				var captureQueue;
				invalidate();
				$scope.scenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
				CapturerService.refreshDevices().then(() => {
					if ($scope.status === 'SOURCE_MISSING') {
						return;
					}

					if (!Utils.inArray($scope.scenarioSelected, $scope.device.scenarios.options)) {
						AlertService.show('scenarios.device-not-supported', { type: 'danger' });
						return;
					}

					captureQueue = ScenarioService.getCaptureQueue($scope.scenario);

					if (captureQueue === null) {
						throw new Error('Unknown capture scenario');
					}

					queuedCapture(captureQueue);
				});
			};

			function queuedCapture(queue) {
				if (!$scope.device) return;

				if (queue.length === 0) {
					$scope.previewImage = revokeAndNullUrl($scope.previewImage);
					$scope.isCapturing = false;
					return;
				}

				if ($scope.failedScan) {
					queue.unshift({ position: $scope.previousPosition });
					$scope.failedScan = false;
					$scope.previousPosition = '';
				}

				$scope.capturingPosition = queue[0].position;
				CapturerService.registerAndLockDevice($scope.device.id)
					.then((deviceId) => {
						var config = {
							Submodality: Utils.upperUnderscoreToCamelCase($scope.device.positions.indexOf($scope.capturingPosition) === -1 ? 'UNKNOWN' : $scope.capturingPosition) // FIXME: Temporal.
						};
						$timeout(() => {
							CapturerService.setConfiguration(deviceId, config)
								.then(() => {
									capture($scope.device.id);
								}, () => {
									AlertService.show('capture-service.capture.alert.invalid-device-config', { type: 'danger' });
								});
						}, 2000);
					}, (error) => {
						CapturerService.handleError(error, AlertService);
						$scope.errorOccured = true;
					});

				function handleErrors(error) {
					if (error.message) {
						$scope.errorMessage = CapturerService.handleErrorMessage(error);
					}
					const tempStatus = CapturerService.handleError(error);
					if (!CapturerService.isFinalStatus(tempStatus)) {
						AlertService.show(`capture-service.capture.status.${$scope.errorMessage}`, { type: 'info' });
						$scope.status = 'RETRYING';
						const retryDelay = 3000;
						$timeout(() => {
							$scope.errorMessage = '';
							queuedCapture(queue);
						}, retryDelay);
					} else {
						$scope.isCapturing = false;
						$scope.status = tempStatus;
						$scope.errorOccured = true;
					}
				}

				function capture(deviceId) {
					$scope.isCapturing = true;
					CapturerService.capture(deviceId).then((result) => {
						var promises = [];
						for (var i = 0; i < result.captureIds.length; i += 1) {
							var promise = downloadPromise($scope.device.id, result.captureIds[i]);
							promises.push(promise);
						}
						$q.all(promises).then((results) => {
							var emptyStep = ScenarioService.getStep(ScenarioService.getEmptyScenario($scope.scenarioSelected), $scope.capturingPosition, 'Palm');
							$scope.status = 'PROCESS_NEXT_STEP';
							function getPalmsWithStatuses() {
								var palms = [];
								for (var k = 0; k < emptyStep.length; k += 1) {
									var palm = emptyStep[k];
									var notFound = true;
									var isUnknown = results.length === 1 && results[0].metadata.Submodality === 'Unknown';
									for (var m = 0; m < results.length; m += 1) {
										var resultPosition = Utils.camelCaseToUpperUnderscore(results[m].metadata.Submodality);
										if (palm.position === resultPosition || isUnknown) {
											palms.push({
												position: palm.position,
												status: 'ok',
												data: results[m].sensorData
											});
											notFound = false;
										}
									}
									// var isMissing = BiometricsService.getMissingFingersPositions().indexOf(plam.position) !== -1;
									if (notFound) {
										palms.push({
											position: palm.position,
											status: 'skipped'
										});
									}
								}
								return palms;
							}
							var palms = getPalmsWithStatuses();
							SubjectService.setPalms(palms);
							$scope.previousPosition = queue.shift().position;
							queuedCapture(queue);
						}, (error) => {
							handleErrors(error);
						});
					}, (error) => {
						$scope.isCapturing = false;
						previewFrame(Utils.getBlankBase64Image());
						handleErrors(error);
					}).finally(() => {
						$scope.scenario = ScenarioService.recreateScenarioDto($scope.scenarioSelected, SubjectService.getPalms(), 'Palm');
						if (queue.length === 0) {
							$scope.isCapturing = false;
							revokeAndNullUrl($scope.previewImage);
							CapturerService.unlock($scope.device.id);
						}
					});

					function preview(deviceId, etag) {
						CapturerService.previewImage(deviceId, etag).then((response) => {
							if (CapturerService.isFinalStatus($scope.status) || response.isFinalImage || $scope.errorOccured) {
								return;
							}

							if (response.status) {
								$scope.biometricStatus = Utils.camelCaseToUpperUnderscore(response.status);
							} else {
								$scope.biometricStatus = '';
							}

							ScenarioService.setStepStatus($scope.scenario, $scope.capturingPosition, 'capturing', 'Palm');
							$scope.status = 'IN_PROGRESS';
							previewFrame(response.image);
							preview(deviceId, response.etag);
						}, () => {
							$scope.errorOccured = false;
							if (!CapturerService.isFinalStatus($scope.status)) {
								$timeout(() => {
									preview(deviceId, '0');
								}, 100);
							}
						});
					}
					$scope.status = 'STARTING';
					preview(deviceId, '0');
				}
			}

			$scope.previewPosition = function (position) {
				if ($scope.fingerPosition === position) return;

				$scope.palmPosition = position;
				$scope.previewImage = revokeAndNullUrl($scope.previewImage);

				var steps = SubjectService.getPalms();
				if (!!steps && steps.length !== 0) {
					if ($scope.parsedImages[$scope.palmPosition] !== undefined) {
						$scope.previewImage = URL.createObjectURL(Utils.b64toBlob($scope.parsedImages[$scope.palmPosition], 'image/x-ms-bmp'));
					} else {
						const imageData = getPalmsByPosition(steps, position);
						if (imageData) {
							previewFrame(imageData.data, imageData.quality);
						}
					}
				}
			};

			$scope.continueCapture = function () {
				function updateScenario() {
					const scenario = ScenarioService.getEmptyScenario($scope.scenarioSelected);
					const scannedPalms = SubjectService.getPalms();
					for (let i = 0; i < scenario.length; i += 1) {
						let include = true;
						scenario[i] = scenario[i].filter((elem) => {
							for (let j = 0; j < scannedPalms.length; j += 1) {
								if (scannedPalms[j].some(e => e.position === elem.position)) include = false;
							}
							return include;
						});

						if (scenario[i].length === 0) {
							scenario.splice(i, 1);
							i -= 1;
						}
					}
					return scenario;
				}
				$scope.errorOccured = false;
				const newScenario = updateScenario();
				const captureQueue = ScenarioService.getCaptureQueue(newScenario);
				if (captureQueue.length !== 0) {
					CapturerService.refreshDevices().then(() => {
						if ($scope.status === 'SOURCE_MISSING') {
							return;
						}

						if (!Utils.inArray($scope.scenarioSelected, $scope.device.scenarios.options)) {
							AlertService.show('scenarios.device-not-supported', { type: 'danger' });
						}

						queuedCapture(captureQueue);
					});
				} else {
					$scope.stopCapture(false);
					$scope.isCapturing = false;
				}
			};

			$scope.statusColor = function (status) {
				return PainterService.getStatusColor(status);
			};

			$scope.removePosition = function (position, $event) {
				$event.stopPropagation();
				$translate('scenarios.confirm-remove').then((translation) => {
					$confirm({ text: translation }).then(() => {
						SubjectService.removePalmsByPosition(position);
						previewFrame();
					}, () => {
						// do nothing
					});
				});
			};

			function downloadPromise(deviceId, captureId) {
				return CapturerService.download(deviceId, captureId);
			}

			$scope.biometricsPage = function () {
				CapturePageService.goBack();
			};

			$scope.importImage = function (position) {
				function setImportData(data, notImage) {
					SubjectService.setPalms([{
						position,
						status: 'ok',
						isImported: true,
						data: $scope.importImageFiles[position].content
					}]);
					$scope.palmPosition = position;
					$scope.previewImage = URL.createObjectURL(Utils.b64toBlob(data, 'image/x-ms-bmp'));
					if ($scope.parsedImages[position] !== undefined) {
						delete $scope.parsedImages[position];
					}
					if (notImage) {
						$scope.parsedImages[$scope.palmPosition] = data;
					}

					AlertService.show('biometrics.import.imported', { type: 'success' });
				}
				if ($scope.importImageFiles[position].type.match(/^image\//)) {
					setImportData($scope.importImageFiles[position].content, false);
				} else {
					const data = $scope.importImageFiles[position].content;
					ParserResource.parseImage({ data }, (result) => {
						setImportData(result.data, true);
					}, () => {
						AlertService.show('forms.invalid-image', { type: 'danger' });
					});
				}
			};
			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};
		}]);
