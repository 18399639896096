import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('clickableSvg', ['$rootScope', 'BiometricsService', 'PainterService', 'SubjectService', function ($rootScope, BiometricsService, PainterService, SubjectService) {
		return {
			template(tElement, tAttrs) {
				switch (tAttrs.id) {
				case 'hands':
					return require('../../views/directives/clickables/hands.html');
				case 'left-eye':
					return require('../../views/directives/clickables/left-eye.html');
				case 'right-eye':
					return require('../../views/directives/clickables/right-eye.html');
				case 'both-eyes':
					return require('../../views/directives/clickables/both-eyes.html');
				default:
					throw new Error('Unknown view');
				}
			},
			restrict: 'E',
			replace: true,
			link(scope, element, attr) {
				function assignMissingModality() {
					switch (attr.id) {
					case 'hands':
						scope.isMissing = BiometricsService.getMissingFingersMap();
						break;
					case 'left-eye':
					case 'right-eye':
					case 'both-eyes':
						scope.isMissing = BiometricsService.getMissingIrisesMap();
						break;
					default:
						throw new Error('Unknown view');
					}
				}
				assignMissingModality();
				PainterService.paintMissingPositions(scope.isMissing);

				scope.patchMissingPositions = function () {
					assignMissingModality();
					switch (attr.id) {
					case 'hands':
						BiometricsService.setMissingFingers(scope.isMissing);
						SubjectService.setMissingFingers(Object.keys(scope.isMissing).filter(pos => scope.isMissing[pos]));
						PainterService.paintMissingPositions(scope.isMissing);
						$rootScope.$broadcast('missingFingers:updated', scope.isMissing);
						break;
					case 'left-eye':
					case 'right-eye':
					case 'both-eyes':
						BiometricsService.setMissingIrises(scope.isMissing);
						SubjectService.setMissingIrises(Object.keys(scope.isMissing).filter(pos => scope.isMissing[pos]));
						SubjectService.removeIrisByPosition(Object.keys(scope.isMissing).filter(pos => scope.isMissing[pos]));
						$rootScope.$broadcast('missingIrises:updated', scope.isMissing);
						PainterService.paintMissingPositions(scope.isMissing);
						break;
					default:
						throw new Error('Unknown view');
					}
				};
			}
		};
	}])
	.directive('clickableRegion', ['$compile', 'Utils', 'CapturerService', 'BiometricsService', function ($compile, Utils, CapturerService, BiometricsService) {
		return {
			restrict: 'A',
			scope: true,
			link(scope, element, attr) {
				var attribute = Utils.dashToUnderscore(attr.regionId);
				scope.regionClick = function () {
					if (CapturerService.isCaptureRunning('Iris')) return;

					if (attr.regionId.includes('finger') || attr.regionId.includes('thumb')) {
						const missingFingers = BiometricsService.getMissingFingersMap();
						missingFingers[attribute] = !missingFingers[attribute];
						BiometricsService.setMissingFingers(missingFingers);
					} else {
						const missingIrises = BiometricsService.getMissingIrisesMap();
						missingIrises[attribute] = !missingIrises[attribute];
						BiometricsService.setMissingIrises(missingIrises);
					}

					scope.$parent.patchMissingPositions();
				};
				element.attr('ng-click', 'regionClick()');
				element.removeAttr('clickable-region');
				$compile(element)(scope);
			}
		};
	}]);
