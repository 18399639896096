import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('finger', ['$sce', '$translate', 'PainterService', 'ParserResource', 'Utils', 'SettingsService', function ($sce, $translate, PainterService, ParserResource, Utils, SettingsService) {
		return {
			template: require('../../views/directives/finger.html'),
			restrict: 'E',
			replace: true,
			scope: {
				finger: '=',
				fingersScore: '=',
				position: '=',
				imgClick: '&',
				showMatchingDetails: '=',
				noHover: '='
			},
			link(scope, _element, attrs) {
				scope.iconClass = 'quality-icon';
				scope.isBlur = SettingsService.getShouldBlurImages().finger;

				scope.imageOnError = function () {
					if (scope.finger && scope.finger.image) {
						if (scope.parsedFinger !== null && scope.parsedFinger !== undefined) {
							URL.revokeObjectURL(scope.parsedFinger);
							scope.parsedFinger = null;
						}
						ParserResource.parseImage({ data: scope.finger.image }, (result) => {
							scope.parsedFinger = URL.createObjectURL(Utils.b64toBlob(result.data));
						});
					}
				};

				scope.hasImgClick = function () {
					function hasHitDetails() {
						return scope.finger.matchingDetails && (scope.finger.matchingDetails != null &&
							scope.finger.matchingDetails.length > 0 &&
							scope.showMatchingDetails);
					}
					return angular.isDefined(attrs.imgClick) &&
							scope.finger != null &&
							(hasHitDetails() || scope.finger.unclickable !== true);
				};

				scope.imgClickInternal = function () {
					if (scope.hasImgClick()) {
						scope.imgClick();
					}
				};

				function getNFIQRating(rating) {
					let r = '';
					switch (rating) {
					case 'EXCELLENT': r = 'A'; break;
					case 'VERY_GOOD': r = 'B'; break;
					case 'GOOD': r = 'C'; break;
					case 'FAIR': r = 'D'; break;
					case 'POOR': r = 'F'; break;
					default: break;
					}
					return r;
				}

				function getNFIQ2Rating(rating) {
					return rating;
				}

				function getNFIQ21Rating(rating) {
					return rating;
				}

				scope.hasAnyNfiq = function () {
					return scope.finger.nfiq || scope.finger.nfiq2 || scope.finger.nfiq21;
				};

				scope.rating = '';
				scope.$watch(() => scope.finger, (newValue) => {
					if (newValue !== null && newValue !== undefined) {
						if (scope.hasAnyNfiq()) {
							scope.rating = getNFIQ21Rating(scope.finger.nfiq21) || getNFIQ2Rating(scope.finger.nfiq2) || getNFIQRating(scope.finger.nfiq);
						}

						scope.overlay = newValue.isFailedToExtract;

						$translate(['subject.quality', `biometrics.fingers.position.${scope.position}`]).then((t) => {
							const nfiq = (function () {
								if (!scope.finger.nfiq) return 'Unknown';
								const t = scope.finger.nfiq.replace('_', ' ');
								return t.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
							}());
							scope.tooltipText = $sce.trustAsHtml(`<div>
							${scope.position ? `${t[`biometrics.fingers.position.${scope.position}`]} <br/>` : ''}
							${newValue.quality ? `${t['subject.quality']}: ${newValue.quality} <br/>` : ''}
							${nfiq ? `${`NFIQ: ${nfiq}`} <br/>` : ''}
							${scope.finger.nfiq2 ? `${`NFIQ 2: ${scope.finger.nfiq2}`} <br/>` : ''}
							${scope.finger.nfiq21 ? `${`NFIQ 2.1: ${scope.finger.nfiq21}`}` : ''}`);
						});
						scope.markFingerAsHovered = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.markFingerAsHovered(newValue.matchingDetails, newValue.index);
							}
						};
						scope.unhoverBiometric = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.unhoverBiometric();
							}
						};
						scope.shouldFingerHighlight = function () {
							return !scope.noHover ? PainterService.shouldFingerHighlight(newValue.index, newValue.matchingDetails && newValue.matchingDetails.length > 0 && newValue.matchingDetails[0].hitIndex !== undefined) : false;
						};
					}
				});
			}
		};
	}]);
